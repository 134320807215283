import { React, useState } from "react";
import UserHeader from "../../theme/userHeader";
import { useHistory, NavLink } from "react-router-dom";
import Title from "../../../../views/common/Helmet";
import { t } from "i18next";

const AddCourier = () => {
  //get the id from parameter
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [state, setState] = useState({
    courier_name: "",
    courier_link: "",
  });

  const handleCourier = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // form validation
  function handleFormValidation() {
    const { courier_name, courier_link } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!courier_name) {
      formIsValid = true;
      formErrors["courierNameErr"] = t("translation2:err_courier_name_req");
    }
    if (!courier_link) {
      formIsValid = true;
      formErrors["courierLinkErr"] = t("translation2:err_courier_link_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    var response = await fetch(`/api/insert_courier`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      history.push("/admin/courier", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_courier")} />
      {/* Header */}
      <UserHeader title={t("text_add_courier")} />
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            {/* card-header */}
            <div className="card-header">
              <strong>{t("text_courier")}</strong>
            </div>
            <div className="card-body">
              <div className="col-md-12">
                <form
                  className="needs-validation p-2"
                  id="validate"
                  method="POST"
                >
                  <div className="row">
                    {/* courier name */}
                    <div className="form-group col-md-6">
                      <label htmlFor="courier_name">
                        {t("text_courier_name")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        name="courier_name"
                        value={state.courier_name}
                        onChange={handleCourier}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.courierNameErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.courierNameErr}
                      </label>
                    </div>
                    {/* courier link */}
                    <div className="form-group col-md-6">
                      <label htmlhtmlFor="courier_link">
                        {t("text_courier_link")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        name="courier_link"
                        value={state.courier_link}
                        onChange={handleCourier}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.courierLinkErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.courierLinkErr}
                      </label>
                    </div>
                  </div>
                  {/* submit */}
                  <div className="form-group text-center mt-3">
                    <input
                      type="submit"
                      value="Submit"
                      name="submit"
                      className={`btn btn-lightpink ${
                        demouser === "1" && "disabled"
                      }`}
                      onClick={demouser === "0" && handleSubmit}
                    />
                    {/* cancel */}
                    <NavLink
                      className="btn btn-secondary ms-1"
                      to="/admin/courier"
                      name="cancel"
                    >
                      {t("text_btn_cancel")}
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCourier;
