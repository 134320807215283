import { React, useEffect, useState } from "react";
import UserHeader from "../theme/userHeader";
import { useLocation, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Title from "../../../views/common/Helmet";
import { t } from "i18next";

const Admin = () => {
  const $ = window.$;
  const location = useLocation();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  // manage state
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (location.state) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  //delete
  const handleDelete = (id, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>You won't be able to revert this?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var response = await fetch(`/api/delete_adminuser/${id}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
        });
        var res_data = await response.json();
        setShow(true);
        setMessage(res_data.message);
        table1.ajax.reload(null, false);
      }
    });
  };

  // edit
  function handleEdit(id) {
    history.push(`/admin/admin/edit/${id}`);
  }

  // admin table
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#admintbl");
      var table1 = table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: "/api/get_adminuser",
          headers: {
            Authorization: user.api_token,
          },
          type: "POST",
          dataSrc: "get_admin_data",
        },
        columns: [
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          { data: "name" },
          { data: "email" },
          { data: "craeted_date" },
          {
            data: "id",
            render: function (data, type, row) {
              if (data == 1) {
                return null;
              } else {
                if (demouser === "1") {
                  return (
                    '<i class="fa fa-edit text-lightpink fa-lg pointer" id="edit-btn"></i>' +
                    '<i class="fa fa-trash-o text-lightblue fa-lg ps-1 disabled"></i>'
                  );
                } else {
                  return (
                    '<i class="fa fa-edit text-lightpink fa-lg pointer" id="edit-btn"></i>' +
                    '<i class="fa fa-trash-o text-lightblue fa-lg ps-1 pointer" aria-hidden="true" id="del-btn"></i>'
                  );
                }
              }
            },
          },
        ],
        Destroy: true,
      });
      // edit
      $("#admintbl tbody").on("click", "#edit-btn", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().id;
        handleEdit(id);
      });
      // delete
      $("#admintbl tbody").on("click", "#del-btn", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().id;
        handleDelete(id, table1);
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    // error throw
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      <Title props={t("text_admin")} />
      {/* Header */}
      <UserHeader
        title={t("text_admin")}
        path={"/admin/admin/insert"}
        icon={<i className="fa fa-plus"></i>}
        btnText={t("text_add_admin")}
      />
      {/* Message */}
      <div
        className={
          "alert alert-success alert-dismissible fade show " +
          (show ? "d-block" : "d-none")
        }
        role="alert"
      >
        {location.state && !message ? (
          <span>{location.state.state.data}</span>
        ) : (
          <span></span>
        )}
        {!location.state && message ? <span>{message}</span> : <span></span>}
        {location.state && message ? <span>{message}</span> : <span></span>}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
      {/* datatable */}
      <div className="row">
        <div className="col-md-12">
          <div className="card-body">
            <table
              id="admintbl"
              className="display table table-bordered mb-2"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>{t("text_sr_no")}</th>
                  <th>{t("text_name")}</th>
                  <th>{t("text_email")}</th>
                  <th>{t("text_date")}</th>
                  <th>{t("text_actions")}</th>
                </tr>
              </thead>
              <tbody></tbody>
              <tfoot>
                <tr>
                  <th>{t("text_sr_no")}</th>
                  <th>{t("text_name")}</th>
                  <th>{t("text_email")}</th>
                  <th>{t("text_date")}</th>
                  <th>{t("text_actions")}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
