import { React, useState, useEffect } from "react";
import UserHeader from "../../theme/userHeader";
import { useLocation, useHistory } from "react-router-dom";
import Title from "../../../../views/common/Helmet";
import Swal from "sweetalert2";
import { t } from "i18next";

const Page = () => {
  const $ = window.$;
  const location = useLocation();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  // manage state
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [danger, setDanger] = useState(false);
  const [selected, setSelected] = useState("");
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (location.state) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  // delete
  const handleDelete = (id, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>You won't be able to revert this?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var response = await fetch(`/api/delete_page/${id}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
        });
        var res_data = await response.json();
        table1.ajax.reload(null, false);
        setShow(true);
        setMessage(res_data.message);
        setDanger(false);
      }
    });
  };

  //change the status
  const handleStatus = (id, status, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>Are you sure to change status?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const value = {
          page_publish: status == 1 ? "0" : "1",
        };
        var response = await fetch(`/api/change_page_status/${id}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
          body: JSON.stringify(value),
        });
        var res_data = await response.json();
        table1.ajax.reload(null, false);
        setShow(true);
        setMessage(res_data.message);
        setDanger(false);
      }
    });
  };

  //edit-change multiple the status
  const handleMultiStatus = (id, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>Are you sure to change status?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const value = {
          action: "change_publish",
          page_id: id,
        };
        var response = await fetch(`/api/change_page_multi_status`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
          body: JSON.stringify(value),
        });
        var res_data = await response.json();
        console.log("qef", res_data);
        table1.ajax.reload(null, false);
        setShow(true);
        setMessage(res_data.message);
        setDanger(false);
        setSelected("");
        setCheck(false);
      } else if (result.dismiss == "cancel") {
        table1.ajax.reload(null, false);
        setSelected("");
        setCheck(false);
      }
    });
  };

  // delete multi game
  const handleMultiDelete = (id, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>You won't be able to revert this?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const value = {
          action: "delete",
          page_id: id,
        };
        var response = await fetch(`/api/change_page_multi_status`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
          body: JSON.stringify(value),
        });
        var res_data = await response.json();
        table1.ajax.reload(null, false);
        setShow(true);
        setMessage(res_data.message);
        setDanger(true);
        setSelected("");
        setCheck(false);
      } else if (result.dismiss == "cancel") {
        table1.ajax.reload(null, false);
        setSelected("");
        setCheck(false);
      }
    });
  };

  //view
  const handleEdit = (id) => {
    history.push(`/admin/pages/edit/${id}`);
  };

  // table pages
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#pages");
      var table1 = table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: "/api/get_pages",
          headers: {
            Authorization: user.api_token,
          },
          type: "POST",
          dataSrc: "get_pages_data",
        },
        columns: [
          { data: null, sortable: false },
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          { data: "page_title" },
          { data: "page_slug" },
          {
            data: "page_publish",
            render: function (data, type, row) {
              if (demouser === "1") {
                if (data == 1) {
                  return "<span class='badge badge-success'>active</span>";
                } else {
                  return "<span class='badge bg-danger'>inactive</span>";
                }
              } else {
                if (data == 1) {
                  return "<span class='badge badge-success pointer' id='status-btn'>active<i class='fa fa-pencil'></i></span>";
                } else {
                  return "<span class='badge bg-danger pointer' id='status-btn'>inactive<i class='fa fa-pencil'></i></span>";
                }
              }
            },
          },
          {
            data: null,
            render: function () {
              if (demouser === "1") {
                return (
                  '<i class="fa fa-edit text-lightpink fa-lg pointer" id="edit-btn"></i>' +
                  '<i class="fa fa-trash-o text-lightblue fa-lg ps-1 disabled"></i>'
                );
              } else {
                return (
                  '<i class="fa fa-edit text-lightpink fa-lg pointer" id="edit-btn"></i>' +
                  '<i class="fa fa-trash-o text-lightblue fa-lg ps-1 pointer" aria-hidden="true" id="del-btn"></i>'
                );
              }
            },
          },
        ],
        columnDefs: [
          {
            orderable: false,
            className: "select-checkbox",
            checkboxes: true,
            targets: 0,
            render: function (data, type, full, meta) {
              return (
                '<input type="checkbox"  value="' +
                $("<div/>").text(data.page_id).html() +
                '">'
              );
            },
          },
        ],
        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[2, "asc"]],
        Destroy: true,
      });
      // edit
      $("#pages tbody").on("click", "#edit-btn", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().page_id;
        handleEdit(id);
      });
      // status
      $("#pages tbody").on("click", "#status-btn", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().page_id;
        const status = table1.row(row).data().page_publish;
        handleStatus(id, status, table1);
      });
      // delete
      $("#pages tbody").on("click", "#del-btn", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().page_id;
        handleDelete(id, table1);
      });
      // Handle click on "Select all" control
      $("#checkall").on("click", function () {
        // Check/uncheck all checkboxes in the table
        var rows = table1.rows().nodes();
        console.log(rows);
        $('input[type="checkbox"]', rows).prop("checked", this.checked);
      });
      var arr = [];
      $("#multi_action").on("change", function () {
        table1.$('input[type="checkbox"]').each(function () {
          // If checkbox doesn't exist in DOM
          var val = $("#multi_action option:selected").text();
          console.log(val);
          if ($.contains(document, this)) {
            // If checkbox is checked
            if (this.checked) {
              arr.push(this.value);
            }
          }
          console.log("f", arr);
          if (val == "change_publish") {
            if (arr.length === 0) {
              setShow(true);
              setMessage("please do select records");
              setDanger(true);
            } else {
              handleMultiStatus(arr, table1);
            }
          } else {
            if (arr.length === 0) {
              setShow(true);
              setMessage("please do select records");
              setDanger(true);
            } else {
              handleMultiDelete(arr, table1);
            }
          }
        });
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    // error throw
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      <Title props={t("text_page")} />
      {/* Header */}
      <UserHeader
        title={t("text_page")}
        path={"/admin/pages/insert"}
        icon={<i className="fa fa-plus"></i>}
        btnText={t("text_add_page")}
      />
      <div
        class={
          "alert alert-dismissible fade show " +
          (show ? "d-block " : "d-none ") +
          (danger ? "alert-danger" : "alert-success")
        }
        role="alert"
      >
        {location.state && !message ? (
          <span>{location.state.state.data}</span>
        ) : (
          <span></span>
        )}
        {!location.state && message ? <span>{message}</span> : <span></span>}
        {location.state && message ? <span>{message}</span> : <span></span>}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
      {/* datatable */}
      <div className="row">
        <div className="col-md-12">
          <div className="card-body">
            <table
              id="pages"
              className="display table table-bordered mb-2 "
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th colSpan={6}>
                    <label htmlFor="action">
                      {t("text_actions_performed")} :&nbsp;
                    </label>
                    <select
                      name="action"
                      id="multi_action"
                      value={selected}
                      onChange={(e) => setSelected(e.target.value)}
                    >
                      <option value="">{t("text_select")}</option>
                      <option value="change_publish">
                        {t("text_change_publish")}
                      </option>
                      <option value="Delete">{t("text_delete")}</option>
                    </select>
                  </th>
                </tr>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="checkall"
                      id="checkall"
                      checked={check}
                      onChange={(e) => setCheck(e.target.value)}
                    />
                  </th>
                  <th>{t("text_sr_no")}</th>
                  <th>{t("text_page_title")}</th>
                  <th>{t("text_page_slug")}</th>
                  <th>{t("text_actions")}</th>
                  <th>{t("text_view")}</th>
                </tr>
              </thead>
              <tbody></tbody>
              <tfoot>
                <tr>
                  <th></th>
                  <th>{t("text_sr_no")}</th>
                  <th>{t("text_page_title")}</th>
                  <th>{t("text_page_slug")}</th>
                  <th>{t("text_actions")}</th>
                  <th>{t("text_view")}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;
