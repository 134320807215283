import { React, useState } from "react";
import { t } from "i18next";
const ChangePassword = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [state1, setState1] = useState({
    formErrors: {},
  });
  // manage state
  const [show, setShow] = useState(false);
  //handle alert message
  const [message, setMessage] = useState({
    status: false,
    msg: "",
  });
  const [state, setState] = useState({
    old_password: "",
    new_password: "",
    c_passowrd: "",
    password_update: "Update",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };
  function handleFormValidation() {
    const { old_password, new_password, c_passowrd } = state;
    let formErrors = {};
    let formIsValid = true;

    if (!old_password) {
      formIsValid = true;
      formErrors["oldPsdErr"] = t("translation2:err_old_password_req");
    }

    if (!new_password) {
      formIsValid = true;
      formErrors["newPsdErr"] = t("translation2:err_new_password_req");
    }

    if (!c_passowrd) {
      formIsValid = true;
      formErrors["cPsdErr"] = t("translation2:err_c_passowrd_req");
    }

    if (c_passowrd && !c_passowrd == new_password) {
      formIsValid = true;
      formErrors["VcPsdErr"] = t("translation2:err_c_passowrd_equal");
    }

    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    var response = await fetch(`/api/update_profile_data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    console.log(res_data);
    setShow(true);
    setMessage({ status: res_data.status, msg: res_data.message });
  };
  return (
    <>
      <div className="row mb-4" id="companysetting">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            <div
              className={
                (!message.status
                  ? "alert alert-danger "
                  : "alert alert-success ") +
                "alert-dismissible fade show " +
                (show ? "d-block" : "d-none")
              }
              role="alert"
            >
              {message.msg}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
            <div className="card-header">
              <strong>{t("text_change_password")}</strong>
            </div>
            <div className="card-body">
              <div className="col-md-12">
                <form
                  className="needs-validation"
                  id="change-password-form"
                  method="POST"
                >
                  <div className="row">
                    <div className="form-group mb-3 col-12">
                      <label className="mb-3" htmlFor="old_password">
                        {t("text_old_password")}
                      </label>
                      <input
                        id="old_password"
                        type="password"
                        className="form-control"
                        name="old_password"
                        value={state.old_password}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.oldPsdErr ? "d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.oldPsdErr}
                      </label>
                    </div>
                    <div className="form-group mb-3 col-12">
                      <label className="mb-3" htmlFor="new_password">
                        {t("text_new_password")}
                      </label>
                      <input
                        id="new_password"
                        type="password"
                        className="form-control"
                        name="new_password"
                        value={state.new_password}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.newPsdErr ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.newPsdErr}
                      </label>
                    </div>
                    <div className="form-group mb-3 col-12">
                      <label className="mb-3" htmlFor="c_passowrd">
                        {t("text_confirm_password")}
                      </label>
                      <input
                        id="c_passowrd"
                        type="password"
                        className="form-control"
                        name="c_passowrd"
                        value={state.c_passowrd}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.cPsdErr ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.cPsdErr}
                      </label>
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.VcPsdErr ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.VcPsdErr}
                      </label>
                    </div>
                  </div>
                  <div className="form-group mb-3 text-center">
                    <input
                      type="submit"
                      value="Update"
                      name="password_update"
                      className="btn btn-lightpink"
                      onClick={handleSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
