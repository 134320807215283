import { React, useEffect } from "react";
import { TotalMatch } from "../../../store/Action/AdminData";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const TotalMatches = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(TotalMatch());
  }, [dispatch]);
  const data = res.data;

  return (
    <>
      {res.loading ? (
        <div className="loader">
          <Spinner />
        </div>
      ) : (
        <div className="col-lg-3 col-md-6 dash-box pointer">
          <div className="bg-lightgreen small-box card card-sm-3">
            <div className="card-icon ">
              <i className="fa fa-gamepad"></i>
            </div>
            <div className="card-wrap">
              <div className="card-header">
                <h4>{t("text_total_match")}</h4>
              </div>
              <div className="card-body">{data.matches}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.TotalMatch,
});

export default connect(mapStateToProps)(TotalMatches);
