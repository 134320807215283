// Private routes shows to only logged in users

//account
import Account from "../views/admin/account/account";
import MyProfile from "../views/admin/myPages/my_profile";
import MyWallet from "../views/admin/myPages/my_wallet";
import MyMatch from "../views/admin/myPages/my_matches";
import MyStatistics from "../views/admin/myPages/my_statistic";
import MyOrders from "../views/admin/myPages/my_orders";
import OrderDetails from "../views/admin/myPages/my_order_detail/order_detail";
import MyReferral from "../views/admin/myPages/my_referral";
import Announcement from "../views/admin/account/announcement";
import LeaderBoard from "../views/admin/account/leaderboard";
import TopPlayers from "../views/admin/account/top_players";
import CustomerSupport from "../views/admin/others/customer_support";
import AppTutorial from "../views/admin/others/app_tutorial";
import AboutUs from "../views/admin/others/about_us";
import TermsAndCondition from "../views/admin/others/terms_and_condition";
//add-withdraw
import Add from "../views/admin/myPages/my_wallet_details/Add";
import Withdraw from "../views/admin/myPages/my_wallet_details/Withdraw";
//payment
import PayTm from "../views/App/account/payment/PayTm";
import PayPal from "../views/admin/payment/paypal";
import PayStack from "../views/admin/payment/PayStack";
import Razorpay from "../views/App/account/payment/Razorpay";
import Instamojo from "../views/App/account/payment/instamojo";
import Cashfree from "../views/App/account/payment/CashFree";
import Tron from "../views/admin/payment/Tron";
//earn
import Earn from "../views/admin/earn/earn";
import ReferEarn from "../views/admin/earn/refer_earn";
import WatchAndEarn from "../views/admin/earn/watch_and_earn";
import ProductDetail from "../views/admin/buy/product_detail";
import BuyForm from "../views/admin/buy/buy_form";
// lottery
import LuckyDraw from "../views/admin/lottery/luckydraw";
import Lottery from "../views/admin/lottery/lottery";
import Register from "../views/admin/lottery/lottery_detail/register";
//play
import Play from "../views/admin/play/play";
import GamePages from "../views/admin/play/match_components/game_pages";
import Result_Match from "../views/admin/myPages/my_match_detail/result/resultDetail";
import Ongoing_Match from "../views/admin/myPages/my_match_detail/OnGoing/onGoingDetail";
import Upcoming_Match from "../views/admin/myPages/my_match_detail/upComing/upComingDetail";
import Position from "../views/admin/myPages/my_match_detail/upComing/positionDetails/Position";
import BuyProduct from "../views/admin/buy/buy_product";
import JoiningPosition from "../views/admin/myPages/my_match_detail/upComing/positionDetails/joining_position";
// import Error from "../views/common/Error";

const PrivateAdminRoutes = [
  // account
  { path: "/accounts", component: Account },
  { path: "/account/my_profile", component: MyProfile },
  { path: "/account/my_match", component: MyMatch },
  { path: "/account/my_statistics", component: MyStatistics },
  { path: "/account/my_wallet", component: MyWallet },
  { path: "/account/my_orders", component: MyOrders },
  { path: "/account/my_orders/order_details/:id", component: OrderDetails },
  { path: "/account/my_referral", component: MyReferral },
  { path: "/account/announcement", component: Announcement },
  { path: "/account/app_tutorial", component: AppTutorial },
  { path: "/account/top_player", component: TopPlayers },
  { path: "/account/leader_board", component: LeaderBoard },
  { path: "/account/customer_support", component: CustomerSupport },
  { path: "/account/about-us", component: AboutUs },
  { path: "/account/terms & conditions", component: TermsAndCondition },
  //add-withdraw
  { path: "/account/my_wallet/add", component: Add },
  { path: "/account/my_wallet/withdraw", component: Withdraw },
  // patment
  { path: "/account/my_wallet/add/PayTm", component: PayTm },
  { path: "/account/my_wallet/add/PayPal", component: PayPal },
  { path: "/account/my_wallet/add/PayStack", component: PayStack },
  { path: "/account/my_wallet/add/Razorpay", component: Razorpay },
  { path: "/account/my_wallet/add/Instamojo", component: Instamojo },
  { path: "/account/my_wallet/add/Cashfree", component: Cashfree },
  { path: "/account/my_wallet/add/Tron", component: Tron },
  // play
  { path: "/play", component: Play },
  { path: "/play/game_pages/:id", component: GamePages },
  { path: "/play/result_match/:id", component: Result_Match },
  { path: "/play/ongoing_match/:id", component: Ongoing_Match },
  { path: "/play/upcoming_match/:id", component: Upcoming_Match },
  { path: "/play/upcoming_match/position/:id", component: Position },
  {
    path: "/play/upcoming_match/position/joining_position/:id",
    component: JoiningPosition,
  },
  //earn
  { path: "/refer_earn", component: Earn },
  { path: "/Refer and Earn", component: ReferEarn },
  { path: "/Buy Product/productdetail/buyform/:id", component: BuyForm },
  { path: "/Watch and Earn", component: WatchAndEarn },
  { path: "/Buy product", component: BuyProduct },
  { path: "/Buy Product/productdetail/:id", component: ProductDetail },
  // lottery
  { path: "/Luckey Draw", component: LuckyDraw },
  { path: "/Luckey Draw/lotterydetail/:id", component: Lottery },
  { path: "/Luckey Draw/register", component: Register },
  // { path: "*", component: Error },
];

export default PrivateAdminRoutes;
