import { useEffect, React } from "react";
import { Dashboard } from "../../../store/Action/UserFatchData";
import { Logout } from "../../../store/Action/FatchData";
import coin from "../../../views/assets/images/coin.png";
import favicon from "../../assets/images/favicon.png";
import { Scrollbars } from "react-custom-scrollbars";
import Title from "../../../views/common/Helmet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import MainTheme from "../theme/MainTheme";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { t } from "i18next";

function Account({ dispatch, res }) {
  useEffect(() => {
    dispatch(Dashboard());
  }, [dispatch]);
  var data = res.data;

  // account icon and link object
  const Acc = [
    {
      link: "/account/my_profile",
      icon: "fa fa-user-circle",
      text: t("text_my_profile"),
    },
    {
      link: "/account/my_wallet",
      icon: "fa fa-google-wallet",
      text: t("text_my_wallet"),
    },
    {
      link: "/account/my_match",
      icon: "fa fa-gamepad",
      text: t("text_my_matches"),
    },
    {
      link: "/account/my_orders",
      icon: "fa fa-first-order",
      text: t("text_my_orders"),
    },
    {
      link: "/account/my_statistics",
      icon: "fa fa-bar-chart",
      text: t("text_my_statistics"),
    },
    {
      link: "/account/my_referral",
      icon: "fa fa-users",
      text: t("text_my_referrals"),
    },
    {
      link: "/account/announcement",
      icon: "fa fa-bullhorn",
      text: t("text_announcement"),
    },
    {
      link: "/account/top_player",
      icon: "fa fa-star",
      text: t("text_top_players"),
    },
    {
      link: "/account/leader_board",
      icon: "fa fa-area-chart",
      text: t("text_leaderboard"),
    },
    {
      link: "/account/app_tutorial",
      icon: "fa fa-question-circle",
      text: t("text_app_tutorial"),
    },
    {
      link: "/account/about-us",
      icon: "fa fa-info-circle",
      text: t("text_about_us"),
    },
    {
      link: "/account/customer_support",
      icon: "fa fa-headphones",
      text: t("text_customer_supports"),
    },
    {
      link: "/account/terms & conditions",
      icon: "fa fa-file-text-o",
      text: t("text_terms_conditions"),
    },
  ];

  return (
    <>
      {/* toastify */}
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Title props={t("text_dashboard")} />
      <MainTheme title={t("text_me")} footer>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <Scrollbars>
            <div className="content-section">
              <div className="bm-user-info">
                <div className="row">
                  {/* profile image */}
                  {data.member.profile_image ? (
                    <div className="col-3">
                      <img
                        src={data.member.profile_image}
                        alt="profile"
                        className="profile-img float-right img-fluid img-responsive"
                      />
                    </div>
                  ) : (
                    <img
                      src={favicon}
                      alt="profile"
                      className="profile-img float-right img-fluid img-responsive"
                    />
                  )}
                  <div className="col-9">
                    {/* username */}
                    <p className="f-18">
                      {t("text_user_name")} : {data.member.user_name}
                    </p>
                    <br />
                    {/* balance */}
                    <p className="f-18">
                      {t("text_balance")} :&nbsp;
                      <img src={coin} alt="img-coin" className="coin" />
                      {Number(
                        parseInt(data.member.wallet_balance) +
                          parseInt(data.member.join_money)
                      ).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="bm-game-info">
                <div className="row">
                  {/* match played */}
                  <div className="col-4 text-center">
                    <p>
                      &nbsp;
                      {data.tot_match_play.total_match === "" ||
                      data.tot_match_play.total_match == null
                        ? "0"
                        : data.tot_match_play.total_match}
                      <br /> {t("text_matches_played")}
                    </p>
                  </div>
                  {/* total kill */}
                  <div className="col-4 text-center">
                    <p>
                      {data.tot_kill.total_kill === "" ||
                      data.tot_kill.total_kill == null
                        ? "0"
                        : data.tot_kill.total_kill}
                      <br />
                      {t("text_total_killed")}
                    </p>
                  </div>
                  {/* total win */}
                  <div className="col-4 text-center">
                    <p>
                      <img src={coin} alt="img" className="coin" />
                      {Number(data.tot_win.total_win).toFixed(2)}
                      <br /> {t("text_amount_won")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="bm-content-listing">
                <div className="bm-account-listing mb-5">
                  <ul>
                    {Acc.map((data, index) => (
                      // account list items
                      <li key={index}>
                        <Link to={data.link}>
                          <span className="icon">
                            <i className={data.icon}></i>
                          </span>
                          <span className="text f-20">
                            {data.text}
                            <i className="fa fa-angle-right"></i>
                          </span>
                        </Link>
                      </li>
                    ))}
                    {/* logout */}
                    <li>
                      <Link to="/login" onClick={Logout}>
                        <span className="icon">
                          <i className="fa fa-power-off"></i>
                        </span>
                        <span className="text f-20 border-0">
                          {t("text_logout")}
                          <i className="fa fa-angle-right"></i>
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Scrollbars>
        )}
      </MainTheme>
    </>
  );
}

//redux connect
const mapStateToProps = (state) => ({
  res: state.dashboard,
});

export default connect(mapStateToProps)(Account);
