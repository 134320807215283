import { React, useEffect, useState } from "react";
import UserHeader from "../../theme/userHeader";
import {
  GetChallengeDetail,
  DownloadResult,
} from "../../../../store/Action/AdminData";
import { connect } from "react-redux";
import { useParams, NavLink, useHistory } from "react-router-dom";
import Title from "../../../../views/common/Helmet";
import { saveAs } from "file-saver";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const ChallengeDetail = ({ dispatch, res, res1 }) => {
  const params = useParams();
  const id = params.id;
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));

  // manage state
  const [state, setState] = useState({
    member_id: "",
    challenge_status: "",
  });
  const [state1, setState1] = useState({
    formErrors: {},
  });

  useEffect(() => {
    dispatch(GetChallengeDetail(id));
  }, [dispatch, id]);
  const data = res.data;

  function handleDownloadAdd() {
    const ids = data.result_by_addedd_detail[0].challenge_result_upload_id;

    dispatch(DownloadResult(ids));
    if (res1.loading) return false;
    const data1 = res1.data.download_img;
    // const data1 = "https://picsum.photos/200";
    const name = res1.data.img_name;
    saveAs(data1, name);
    console.log("base64", data1);
  }

  // download result image
  function handleDownloadAccept() {
    const ids = data.result_by_accepted_detail[0].challenge_result_upload_id;
    dispatch(DownloadResult(ids));
    if (res1.loading) return false;
    const data1 = res1.data.download_img;
    // const data1 = "https://picsum.photos/200";
    const name = res1.data.img_name;
    saveAs(data1, name);
  }

  // download result image
  function handleChange(e) {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
      member_id: data.challenge_details[0].member_id,
    });
  }

  // form validation
  function handleFormValidation() {
    const { challenge_status } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!challenge_status) {
      formIsValid = true;
      formErrors["statusErr"] = t("translation2:err_status_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  // submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    console.log(state);
    var response = await fetch(`/api/update_challange_detail/${params.id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      history.push("/admin/challenges", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_challenges")} />
      {/* Header */}
      <UserHeader
        alignCenter
        title={t("text_challenges")}
        className="text-center"
      />
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="row justify-content-center text-center mb-3">
            <div className="col-sm-offset-3 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="image_div">
                        <img
                          src={data.challenge_details[0].profile_image}
                          className="rounded-circle img-fluid"
                        />
                      </div>
                      <br />
                      <h6>{data.challenge_details[0].ludo_king_username}</h6>
                    </div>
                    <div className="col-sm-6 text-center mt-3">
                      <h6 className="auto_id_div">
                        {data.challenge_details[0].auto_id}
                      </h6>
                      has challenge for
                      <h6 className="coin_div">
                        {data.challenge_details[0].coin} {t("text_coins")}
                      </h6>
                    </div>
                    <div className="col-sm-3">
                      <div className="image_div">
                        <img
                          src={data.challenge_details[0].accepted_profile_image}
                          className="rounded-circle"
                        />
                      </div>
                      <br />
                      <h6>
                        {data.challenge_details[0].accepted_ludo_king_username}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="challenge_detail_footer card-footer text-white px-3 py-0 fs-5">
                  <div className="row">
                    <div className="col-sm-4 bg-lightgreen py-3">
                      Winning : {data.challenge_details[0].winning_price}&nbsp;
                      {t("text_coins")}
                    </div>
                    <div className="col-sm-4 bg-lightpink py-3">
                      {data.challenge_details[0].challenge_status == 1
                        ? "Activate"
                        : data.challenge_details[0].challenge_status == 2
                        ? "cancelled"
                        : data.challenge_details[0].challenge_status == 3
                        ? "Completed"
                        : null}
                    </div>
                    <div className="col-sm-4 bg-lightgreen py-3">
                      {t("text_room_code")} :&nbsp;
                      {data.challenge_details[0].room_code}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserHeader alignCenter title={t("text_roomcode_history")} />
          <div className="row justify-content-center">
            <div className="col-sm-offset-3 col-sm-6">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>{t("text_room_code")}</th>
                    <th>{t("text_date_created")}</th>
                  </tr>
                </thead>
                {data.challenge_details[0].challenge_status !== 2 ? (
                  <tbody>
                    <tr>
                      <td>{data.challenge_details[0].room_code}</td>
                      <td>{data.challenge_details[0].date_created}</td>
                    </tr>
                  </tbody>
                ) : null}
              </table>
            </div>
          </div>
          {data.challenge_details[0].challenge_status !== 2 && (
            <>
              <UserHeader alignCenter title={t("text_uploaded_result")} />
              <div className="row justify-content-center text-dark">
                <div className="col-sm-offset-3 col-sm-3">
                  <div className="card">
                    <div className="card-body text-center">
                      <div className="row">
                        <div className="col-12">
                          <h5>
                            {data.challenge_details[0].ludo_king_username}
                          </h5>
                        </div>
                        {data.result_by_addedd_detail.length && (
                          <>
                            <hr />
                            <div>Requested:win</div>
                            <div>
                              {data.result_by_addedd_detail[0].result_image && (
                                <img
                                  src={
                                    data.result_by_addedd_detail[0].result_image
                                  }
                                  alt="logo"
                                  style={{ width: "100px" }}
                                />
                              )}
                            </div>
                            <div className="d-flex justify-content-center">
                              {data.result_by_addedd_detail[0].result_image && (
                                <button
                                  className="btn btn-lightpink"
                                  onClick={() => handleDownloadAdd()}
                                >
                                  {t("text_download")}
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card">
                    <div className="card-body text-center">
                      <div className="row">
                        <div className="col-12">
                          <h5>
                            {
                              data.challenge_details[0]
                                .accepted_ludo_king_username
                            }
                          </h5>
                        </div>
                        {data.result_by_accepted_detail.length && (
                          <>
                            <hr />
                            <div>Requested:win</div>
                            <div>
                              {data.result_by_accepted_detail[0]
                                .result_image ? (
                                <img
                                  src={
                                    data.result_by_accepted_detail[0]
                                      .result_image
                                  }
                                  alt="logo"
                                />
                              ) : null}
                            </div>
                            <div className="d-flex justify-content-center">
                              {data.result_by_accepted_detail[0]
                                .result_image && (
                                <button
                                  className="btn btn-lightpink"
                                  onClick={() => handleDownloadAccept()}
                                >
                                  {t("text_download")}
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {data.challenge_details[0].challenge_status == 1 && (
            <>
              <UserHeader alignCenter title={t("text_deside_result")} />
              <div className="row justify-content-center mb-3">
                <div className="col-sm-offset-3 col-sm-6">
                  <div className="card">
                    <div className="card-body">
                      <form
                        id="challenge-result-form"
                        enctype="multipart/form-data"
                        method="POST"
                      >
                        <div className="row">
                          <div className="col-md-12 form-group mb-1 text-dark">
                            <label
                              className="col-md-3"
                              htmlFor="challenge_status"
                            >
                              <b>
                                Status
                                <span className="required" aria-required="true">
                                  &nbsp;*&nbsp;
                                </span>
                              </b>
                            </label>
                            <div className="col-md-9 mb-1">
                              <select
                                name="challenge_status"
                                className="form-control challenge_status"
                                onChange={handleChange}
                              >
                                <option value="">Select status</option>
                                <option value="2">{t("text_cancelled")}</option>
                                <option value="3">{t("text_complete")}</option>
                              </select>
                            </div>
                          </div>
                          <label
                            htmlFor="challenge_status"
                            generated="true"
                            className={
                              "error " +
                              (state1.formErrors.statusErr
                                ? " d-block"
                                : "d-none")
                            }
                          >
                            {state1.formErrors.statusErr}
                          </label>
                          <div className="form-group col-md-12 mb-3 d-flex justify-content-left">
                            <input
                              type="submit"
                              value="Submit"
                              name="submit"
                              className="btn btn-lightpink"
                              onClick={handleSubmit}
                            />
                            <NavLink
                              to="/admin/challenges"
                              className="btn btn-secondary ms-1 d-block"
                              name="cancel"
                            >
                              {t("text_btn_cancel")}
                            </NavLink>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.GetChallengeDetail,
  res1: state.DownloadResult,
});

export default connect(mapStateToProps)(ChallengeDetail);
