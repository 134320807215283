import { React, useEffect, useState } from "react";
import MainTheme from "../theme/MainTheme";
import { Dashboard } from "../../../store/Action/UserFatchData";
import HowReferEarn from "../../assets/images/how_refer_earn.jpg";
import copy from "copy-to-clipboard";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const ReferEarn = ({ dispatch, res }) => {
  // manage state for copy username
  const [copyWord, setCopy] = useState("");
  useEffect(() => {
    dispatch(Dashboard());
  }, [dispatch]);
  // copy the clipboard
  const clipBoard = () => {
    copy(data.member.user_name);
    // copy message
    setCopy("copy the clipBoard");
    setTimeout(() => {
      setCopy("");
    }, 1000);
  };
  var data = res.data;

  return (
    <>
      <MainTheme title={t("text_refer_more_earn_more")}>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="offset-md-3 col-md-6">
                <div className="card text-center box-shadow">
                  <div className="card-body">
                    <h4 className="text-lightgreen text-uppercase mb-3">
                      {t("text_refer_more_earn_more")}
                    </h4>
                    <p className="mb-4 text-dark">
                      {data.web_config.referandearn_description}
                    </p>
                    <h6 className="text-lightgreen text-uppercase mb-3">
                      {t("text_your_referral_code")}
                    </h6>
                    {/* copy username */}
                    <h6
                      id="refer-code"
                      onClick={clipBoard}
                      className="text-dark"
                      style={{ cursor: "pointer" }}
                    >
                      {data.member.user_name}
                      <i className="fa fa-copy ml-3"></i>
                    </h6>
                    <span
                      className="copied text-white bg-black rounded px-2"
                      style={{
                        position: "absolute",
                        left: "35%",
                        zIndex: "10",
                      }}
                    >
                      {copyWord}
                    </span>
                    {/* image */}
                    <img
                      src={HowReferEarn}
                      alt="img"
                      style={{ width: "100%" }}
                      className="img-responsive mt-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.dashboard,
});

export default connect(mapStateToProps)(ReferEarn);
