import { React, useState } from "react";
import coin from "../../assets/images/coin.png";
import MainTheme from "../../theme/MainTheme";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { t } from "i18next";

const Register = () => {
  var user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const location = useLocation();

  const [state, setState] = useState({
    submit: "joinnow",
    lottery_id: location.state.lottery_id,
    member_id: user.member_id,
  });
  const notifyS = (data) => toast.success(data);
  const notifyE = (data) => toast.error(data);

  //onclick of join button
  const join = async (e) => {
    e.preventDefault();
    var response = await fetch(`/api/lottery_join`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      setTimeout(() => {
        history.push(`/Luckey Draw/lotterydetail/${location.state.lottery_id}`);
      }, 3000);
      notifyS(res_data.message);
    } else {
      notifyE(res_data.message);
    }
  };

  return (
    <>
      {/* toastify */}
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <MainTheme props="user test">
        <div className="row text-black p-2">
          <div className="col-12">
            <div className="row d-flex mb-3">
              <div
                className="col-3 m-auto bm_text_lightgreen"
                style={{ height: "100%" }}
              >
                <i
                  className="fa fa-google-wallet"
                  style={{ fontSize: "50px" }}
                ></i>
              </div>
              <div className="col-9 text-end">
                <span className="d-block">
                  {t("text_total_balance")} :
                  <strong>
                    <i>
                      <img src={coin} className="coin" alt="coin" />
                    </i>
                    1400
                  </strong>
                </span>
                <span className="d-block">
                  {t("text_total_payable_amount")} :
                  <strong>
                    <i>
                      <img src={coin} className="coin" alt="coin" />
                    </i>
                    {location.state.lottery_prize}
                  </strong>
                </span>
              </div>
            </div>
            <div className="flex items-center justify-evenly">
              <button className="btn btn-lightpink">
                {/* cancel button  */}
                <NavLink
                  to={`/Luckey Draw/lotterydetail/${location.state.lottery_id}`}
                  className="no-underline text-white text-uppercase"
                >
                  {t("text_btn_cancel")}
                </NavLink>
              </button>
              <span
                className="btn btn-lightgreen text-white text-uppercase ms-2"
                onClick={join}
              >
                {t("text_btn_join")}
              </span>
            </div>
          </div>
        </div>
      </MainTheme>
    </>
  );
};

export default Register;
