import { React, useState } from "react";
import coin from "../../../assets/images/coin.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactHtmlParser from "react-html-parser";
import { t } from "i18next";

const UpComing = ({ props }) => {
  // manage states
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = (data1, data2, data3) => {
    setShow(true);
    setIndex({ match_name: data1, m_id: data2, prize_description: data3 });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="mx-auto">
        <Modal.Header className="modal-header btn-green text-center d-block">
          <Button
            className="btn"
            onClick={handleClose}
            style={{
              width: "10px",
              textAlign: "center",
              border: "1px solid gray",
              borderRadius: "50%",
              background: "transparent",
              color: "black",
              float: "right",
            }}
          >
            <i className="fa fa-close"></i>
          </Button>
          <h5 className="modal-title text-uppercase">{t("text_prize_pool")}</h5>
          <p>
            {/* match id  */}
            {index.match_name}
            {t("text_for_macth_id")}
            {index.m_id}
          </p>
        </Modal.Header>
        <Modal.Body className="mb-0 bg-white">
          <div className="modal-body text-black p-0">
            {ReactHtmlParser(index.prize_description)}
          </div>
        </Modal.Body>
      </Modal>
      <div className="d-flex flex-wrap justify-content-xl-between">
        <div className=" border-md-right flex-grow-1 p-3 item">
          <div className="row">
            {props.my_match.length > 0 ? (
              props.my_match
                .filter((item) => item.match_status === "1")
                .map((data, index) => {
                  return (
                    <>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                        <div className="tour-card card br-5 overflow-hidden">
                          {/* <span className="pin-match">
                            <i
                              className="fa fa-thumb-tack text-primary"
                              aria-hidden="true"
                            ></i>
                          </span> */}
                          <NavLink
                            to={{
                              pathname: `/play/upcoming_match/${data.m_id}`,
                              state: data.match_banner,
                            }}
                          >
                            <img
                              src={data.match_banner}
                              alt="img"
                              className="img-fluid card-img-top"
                            />
                          </NavLink>
                          <div className="card-body">
                            {/* type */}
                            <span className="badge bg-lightpink p-2 text-white">
                              {data.type}&nbsp;
                            </span>
                            {/* map */}
                            <span className="badge bg-lightblue p-2 text-white ms-2">
                              {data.MAP}&nbsp;
                            </span>
                            {/* match name and match id */}
                            <h6 className="card-title mt-3 text-dark">
                              <i className="fa fa fa-bomb"></i>&nbsp;
                              {data.match_name} {t("text_for_macth_id")}
                              {data.m_id}
                            </h6>
                            {/* Progressbar */}
                            <div className="row border-bottom">
                              <div className="col-8 m-auto">
                                <ProgressBar
                                  style={{ height: "5px" }}
                                  variant="primary"
                                  now={
                                    (data.no_of_player /
                                      data.number_of_position) *
                                    100
                                  }
                                />
                              </div>
                              <div className="col-4 text-center">
                                <div className="bm-card-info-item">
                                  <span className="text-secondary ">
                                    {data.no_of_player}/
                                    {data.number_of_position}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row border-bottom mb-3">
                              {/* match time */}
                              <div className="col-md-4">
                                <div className="bm-card-table-item text-center">
                                  <span className="bm-card-table-item-default text-lightgreen">
                                    &nbsp;
                                    {data.match_time}
                                  </span>
                                </div>
                              </div>
                              {/* prize description and modal */}
                              <div className="col-md-4 modal-div">
                                <span
                                  onClick={
                                    data.prize_description &&
                                    (() =>
                                      handleShow(
                                        data.match_name,
                                        data.m_id,
                                        data.prize_description
                                      ))
                                  }
                                  className={`${
                                    data.prize_description && "pointer"
                                  }`}
                                >
                                  {/* prise pole */}
                                  <span className="bm-card-table-item-default text-primary text-uppercase">
                                    &nbsp;
                                    {t("text_prize_pool")}
                                  </span>
                                  <span className="bm-card-table-item-default text-primary">
                                    <img
                                      src={coin}
                                      alt="coin-img"
                                      className="coin"
                                    />
                                    {data.win_prize}&nbsp;
                                    {data.prize_description && (
                                      <i
                                        className="fa fa-angle-down bm_text_lightgreen"
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                  </span>
                                </span>
                              </div>
                              {/* Per kill */}
                              <div className="col-md-4">
                                <div className="bm-card-table-item text-center">
                                  <span className="bm-card-table-item-default text-lightblue text-uppercase">
                                    &nbsp;
                                    {t("text_per_kill")}
                                  </span>
                                  <br />
                                  <span className="bm-card-table-item-default text-lightblue">
                                    <i className="text-lightblue">
                                      <img
                                        src={coin}
                                        alt="coin-img"
                                        className="coin"
                                      />
                                    </i>
                                    &nbsp;
                                    {data.per_kill}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
            ) : (
              // If there is no Any Upcoming Match
              <div className="row">
                <div className="col-md-12 text-center text-dark">
                  &nbsp;
                  <strong>{t("text_no_upcoming_macth")}</strong>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpComing;
