import { React, useEffect } from "react";
import { Slider } from "../../../store/Action/UserFatchData";
import { connect } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Sliders = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Slider());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <>
          {/* Slider */}
          <Carousel className="pb-3 m-2">
            {data.slider.map((data, index) => {
              return (
                <Carousel.Item key={index} interval={2000}>
                  {data.slider_link != "undefined" || "" ? (
                    <a
                      className="pointer"
                      onClick={() => window.open(data.slider_link)}
                    >
                      <img
                        className="d-block w-100 br-5"
                        src={data.slider_image}
                        alt="slide"
                      />
                    </a>
                  ) : (
                    <img
                      className="d-block w-100 br-5"
                      src={data.slider_image}
                      alt="slide"
                    />
                  )}
                </Carousel.Item>
              );
            })}
          </Carousel>
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.Slider,
});

export default connect(mapStateToProps)(Sliders);
