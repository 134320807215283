import { React, useEffect } from "react";
import Title from "../../../views/common/Helmet";
import MainTheme from "../theme/MainTheme";
import { connect } from "react-redux";
import { top_player_data } from "../../../store/Action/UserFatchData";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Fade from "react-reveal/Fade";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const TopPlayers = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(top_player_data());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_top_players")} />
      <MainTheme title={t("text_top_players")}>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-lg-12">
                <div className="card mt-3">
                  <div
                    className="card-body dashboard-tabs p-0 bg-lightgray"
                    id="tabs-1"
                  >
                    {res.loading ? (
                      <div className="flex items-center justify-center mt-10">
                        <div>
                          <img
                            alt="loader-img"
                            className="w-28 h-28"
                            src="/loader.svg"
                          />
                        </div>
                      </div>
                    ) : (
                      <Tabs>
                        <TabList className="d-flex flex-wrap items-stretch justify-evenly nav-tabs">
                          {data.game.map((item) => {
                            return (
                              <Tab
                                key={item.game_id}
                                className="p-3 pointer top-tab-admin"
                              >
                                <img
                                  src={item.game_logo}
                                  alt="content"
                                  style={{ width: "40px" }}
                                />
                              </Tab>
                            );
                          })}
                        </TabList>
                        <div className="d-flex flex-wrap justify-content-xl-between">
                          <div className="border-md-right flex-grow-1 p-3 item">
                            {data.game.map((item, index) => {
                              return (
                                <TabPanel key={item.game_id}>
                                  <Fade>
                                    <div
                                      className="btn-lightpink text-white text-center  mt-1 p-3"
                                      style={{ captionSide: "top" }}
                                      key={index}
                                    >
                                      {item.game_name}
                                    </div>
                                    <table className="table tr-bordered bg-white box-shadow rounded-bottom">
                                      <thead>
                                        <tr className="bg-black text-white">
                                          <th className=" text-white p-2">
                                            {t("text_user_name")}
                                          </th>
                                          <th className=" text-white p-2">
                                            {t("text_winning")}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="text-black">
                                        {data.top_players[item.game_name].map(
                                          (data, index) => {
                                            return (
                                              <tr key={index}>
                                                <td className="p-2">
                                                  {data.user_name}
                                                </td>
                                                <td className="p-2">
                                                  {data.winning}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </Fade>
                                </TabPanel>
                              );
                            })}
                          </div>
                        </div>
                      </Tabs>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// reduc connect
const mapStateToProps = (state) => ({
  res: state.top_player,
});

export default connect(mapStateToProps)(TopPlayers);
