import { React, useEffect } from "react";
import { All_game } from "../../../../store/Action/UserFatchData";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Title from "../../../common/Helmet";
import MainTheme from "../../theme/MainTheme";
import UserHeader from "../../theme/UserHeader";
import Spinner from "react-bootstrap/Spinner";
import OngoingTab from "./OngoingTab";
import UpcomingTab from "./UpcomingTab";
import ResultTab from "./ResultTab";
import { t } from "i18next";

const GamePages = ({ dispatch, res }) => {
  const params = useParams();
  useEffect(() => {
    dispatch(All_game());
  }, [dispatch]);
  var data = res.data;

  var gameName;
  data &&
    data.all_game.map((data) => {
      if (data.game_id == params.id) {
        gameName = data.game_name;
      }
    });

  return (
    <>
      <Title props={t("text_all_games")} />
      <MainTheme>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <UserHeader props={gameName} />
            <UserHeader />
            <div className="row">
              <div className="col-lg-12">
                <div className="card mt-3">
                  <div
                    className="card-body dashboard-tabs p-0 bg-lightgray"
                    id="tabs-1"
                  >
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        {/* ongoing tab  */}
                        <a
                          className="navlink text-uppercase active"
                          data-toggle="tab"
                          href="#ongoing"
                        >
                          {t("text_ongoing")}
                        </a>
                      </li>
                      <li className="nav-item">
                        {/* upcoming tab  */}
                        <a
                          className="navlink text-uppercase "
                          data-toggle="tab"
                          href="#upcoming"
                        >
                          {t("text_upcoming")}
                        </a>
                      </li>
                      <li className="nav-item">
                        {/* result tab  */}
                        <a
                          className="navlink text-uppercase"
                          data-toggle="tab"
                          href="#result"
                        >
                          {t("text_results")}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      {/* ongoing  */}
                      <div
                        className="tab-pane fade show active"
                        id="ongoing"
                        role="tabpanel"
                        aria-labelledby="ongoing-tab"
                      >
                        <OngoingTab />
                      </div>
                      {/* upcoming  */}
                      <div
                        className="tab-pane fade"
                        id="upcoming"
                        role="tabpanel"
                        aria-labelledby="upcoming-tab"
                      >
                        <UpcomingTab />
                      </div>
                      {/* result */}
                      <div
                        className="tab-pane fade"
                        id="result"
                        role="tabpanel"
                        aria-labelledby="result"
                      >
                        <ResultTab />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.All_game,
});

export default connect(mapStateToProps)(GamePages);
