import { React, useEffect, useState } from "react";
import UserHeader from "../../theme/userHeader";
import { t } from "i18next";
import { useHistory, NavLink } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { GetGameName } from "../../../../store/Action/AdminData";
import { connect } from "react-redux";
import Title from "../../../../views/common/Helmet";
import Select2 from "./Select2";

const AddMatches = ({ res, dispatch }) => {
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  // handle state
  const [state, setState] = useState({
    formErrors: {},
  });

  const [editor, setEditor] = useState({
    match_desc: "",
  });
  const [editor1, setEditor1] = useState({
    match_sponsor: "",
  });
  const [editor2, setEditor2] = useState({
    prize_description: "",
  });
  const [editor3, setEditor3] = useState({
    match_private_desc: "",
  });

  //store room data

  //edit match details store
  const [match, setMatch] = useState({
    game_id: "",
    match_name: "",
    match_url: "",
    match_time: "",
    win_prize: "",
    per_kill: "",
    type: "",
    entry_fee: "",
    number_of_position: "",
    MAP: "",
    match_banner: "",
    match_type: "",
    image_id: "",
  });

  useEffect(() => {
    dispatch(GetGameName());
  }, [dispatch]);

  if (res.loading) return false;
  const match_data = res.data.game_name;

  //handle file
  const handleFile = (e) => {
    const file = e.target.files[0];
    setMatch({
      ...match,
      match_banner: file,
    });
  };
  //match data edit
  const handleMatch = (e) => {
    const { name, value } = e.target;

    setMatch({
      ...match,
      [name]: value,
    });
  };
  const handleData = (value) => {
    setMatch({
      ...match,
      image_id: value,
    });
  };

  function handleFormValidation() {
    const {
      match_time,
      win_prize,
      match_type,
      per_kill,
      MAP,
      game_id,
      entry_fee,
      type,
      number_of_position,
      match_banner,
      match_name,
      match_url,
    } = match;
    const { match_desc } = editor;
    const { match_sponsor } = editor1;
    const { prize_description } = editor2;
    const { match_private_desc } = editor3;

    let formErrors = {};
    let formIsValid = true;
    console.log("dsagdj");
    if (!match_desc) {
      formIsValid = true;
      formErrors["matchDescErr"] = t("translation2:err_match_desc_req");
    }
    if (!match_sponsor) {
      formIsValid = true;
      formErrors["matchSponsorErr"] = t("translation2:err_match_sponsor");
    }
    if (!prize_description) {
      formIsValid = true;
      formErrors["prizeDescErr"] = t("translation2:err_desc_req");
    }
    if (!match_private_desc) {
      formIsValid = true;
      formErrors["matchPrivateDescErr"] = t("translation2:err_desc_req");
    }
    if (!type) {
      formIsValid = true;
      formErrors["typeErr"] = t("translation2:err_type_req");
    }
    if (!match_banner) {
      formIsValid = true;
      formErrors["matchBannerErr"] = t("translation2:err_match_banner_req");
    }
    if (!game_id) {
      formIsValid = true;
      formErrors["gameIdErr"] = t("translation2:err_game_id_req");
    }
    if (!match_name) {
      formIsValid = true;
      formErrors["matchNameErr"] = t("translation2:err_match_name_req");
    }
    if (!match_url) {
      formIsValid = true;
      formErrors["matchUrlErr"] = t("translation2:err_match_url_req");
    }
    if (
      match_url &&
      !match_url.match(
        "^(https?:\\/\\/)?" + // protocol`
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$`"
      )
    ) {
      formIsValid = true;
      formErrors["matchVUrlErr"] = t("translation2:err_match_url_valid");
    }
    if (!match_time) {
      formIsValid = true;
      formErrors["matchTimeErr"] = t("translation2:err_match_time_req");
    }
    if (!win_prize) {
      formIsValid = true;
      formErrors["winPrizeErr"] = t("translation2:err_win_prize_req");
    }
    if (!per_kill) {
      formIsValid = true;
      formErrors["perKillErr"] = t("translation2:err_per_kill_req");
    }
    if (!match_type) {
      formIsValid = true;
      formErrors["matchTypeErr"] = t("translation2:err_match_type_req");
    }
    if (!MAP) {
      formIsValid = true;
      formErrors["MapErr"] = t("translation2:err_MAP_req");
    }
    if (!number_of_position) {
      formIsValid = true;
      formErrors["NumberErr"] = t("translation2:err_number_of_position_number");
    }
    if (!entry_fee) {
      formIsValid = true;
      formErrors["entryFeeErr"] = t("translation2:err_entry_fee_req");
    }
    setState({ formErrors: formErrors });
    return formIsValid;
  }

  //match update
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(match);
    let formData = new FormData();
    formData.append("game_id", match.game_id);
    formData.append("match_name", match.match_name);
    formData.append("match_url", match.match_url);
    formData.append("match_time", match.match_time);
    formData.append("win_prize", match.win_prize);
    formData.append("per_kill", match.per_kill);
    formData.append("type", match.type);
    formData.append("entry_fee", match.entry_fee);
    formData.append("number_of_position", match.number_of_position);
    formData.append("MAP", match.MAP);
    formData.append("match_type", match.match_type);
    formData.append("match_banner", match.match_banner);
    formData.append("match_desc", editor.match_desc);
    formData.append("match_sponsor", editor1.match_sponsor);
    formData.append("prize_description", editor2.prize_description);
    formData.append("match_private_desc", editor3.prize_description);
    formData.append("image_id", match.image_id);

    // form validation
    handleFormValidation();
    var response = await fetch(`/api/match_insert_data`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    console.log("ss", res_data);
    if (res_data.status === true) {
      history.push("/admin/matches", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_match")} />
      <UserHeader title={t("text_match")} />
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            <div className="card-header">
              <strong>{t("text_match")}</strong>
            </div>
            <div className="card-body">
              <div className="col-md-12">
                <form className="needs-validation" id="validate">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="game_id" className="mt-3">
                        {t("text_game")}&nbsp;
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <select
                        className="form-control mt-3"
                        name="game_id"
                        value={match.game_id}
                        onChange={handleMatch}
                      >
                        <option value="">Select..</option>
                        {match_data.map((data, index) => {
                          return (
                            <option value={data.game_name} key={index}>
                              {data.game_name}
                            </option>
                          );
                        })}
                      </select>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.gameIdErr ? " d-block" : "d-none")
                        }
                      >
                        {state.formErrors.gameIdErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <input
                      type="hidden"
                      className="form-control mt-3"
                      name="match_id"
                      value="57"
                    />
                    {/* match name */}
                    <div className="form-group col-md-6">
                      <label htmlFor="match_name" className="mt-3">
                        {t("text_match_name")}&nbsp;
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-3"
                        name="match_name"
                        value={match.match_name}
                        onChange={handleMatch}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.matchNameErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state.formErrors.matchNameErr}
                      </label>
                    </div>
                    {/* match url */}
                    <div className="form-group col-md-6">
                      <label htmlFor="match_url" className="mt-3">
                        {t("text_match_url")}&nbsp;
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-3"
                        name="match_url"
                        value={match.match_url}
                        onChange={handleMatch}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.matchUrlErr ? " d-block" : "d-none")
                        }
                      >
                        {state.formErrors.matchUrlErr}
                      </label>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.matchVUrlErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state.formErrors.matchVUrlErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    {/* match schedule */}
                    <div className="form-group col-md-6">
                      <label htmlFor="match_time" className="mt-3">
                        {t("text_match_schedule")}&nbsp;
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-3"
                        id="datetimepicker1"
                        name="match_time"
                        value={match.match_time}
                        onChange={handleMatch}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.matchTimeErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state.formErrors.matchTimeErr}
                      </label>
                    </div>
                    {/* prize pole */}
                    <div className="form-group col-md-6">
                      <label htmlFor="win_prize" className="mt-3">
                        {t("text_prize_pool")}&nbsp;
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-3"
                        name="win_prize"
                        value={match.win_prize}
                        onChange={handleMatch}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.winPrizeErr ? " d-block" : "d-none")
                        }
                      >
                        {state.formErrors.winPrizeErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    {/* per kill */}
                    <div className="form-group col-md-6">
                      <label htmlFor="per_kill" className="mt-3">
                        {t("text_per_kill")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="email"
                        type="text"
                        className="form-control mt-3"
                        name="per_kill"
                        value={match.per_kill}
                        onChange={handleMatch}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.perKillErr ? " d-block" : "d-none")
                        }
                      >
                        {state.formErrors.perKillErr}
                      </label>
                    </div>
                    {/* team */}
                    <div className="form-group col-md-6">
                      <label htmlFor="type" className="mt-3">
                        {t("text_team")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <select
                        className="form-control mt-3"
                        name="type"
                        value={match.type}
                        onChange={handleMatch}
                      >
                        <option value="">Select..</option>
                        <option value="Solo" selected="">
                          {t("text_solo")}
                        </option>
                        <option value="Duo">{t("text_duo")}</option>
                        <option value="Squad">{t("text_squad")}</option>
                        <option value="Squad5">{t("text_squad5")}</option>
                      </select>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.typeErr ? " d-block" : "d-none")
                        }
                      >
                        {state.formErrors.typeErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    {/* entry fee */}
                    <div className="form-group col-md-6">
                      <label htmlFor="entry_fee" className="mt-3">
                        {t("text_entry_fee")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        id="entry_fee"
                        className="form-control mt-3"
                        name="entry_fee"
                        value={match.entry_fee}
                        onChange={handleMatch}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.entryFeeErr ? " d-block" : "d-none")
                        }
                      >
                        {state.formErrors.entryFeeErr}
                      </label>
                    </div>
                    {/* total player */}
                    <div className="form-group col-md-6">
                      <label htmlFor="number_of_position" className="mt-3">
                        {t("text_total_player")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-3"
                        name="number_of_position"
                        value={match.number_of_position}
                        onChange={handleMatch}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.NumberErr ? " d-block" : "d-none")
                        }
                      >
                        {state.formErrors.NumberErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    {/* MAP */}
                    <div className="form-group col-md-6">
                      <label htmlFor="MAP" className="mt-3">
                        {t("text_map")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-3"
                        name="MAP"
                        value={match.MAP}
                        onChange={handleMatch}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.MapErr ? " d-block" : "d-none")
                        }
                      >
                        {state.formErrors.MapErr}
                      </label>
                    </div>
                    {/* match type */}
                    <div className="col-md-6 mb-3 ">
                      <label htmlFor="match_type" className="mt-3">
                        {t("text_match_type")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      <div className="form-group col-md-12 d-flex mt-3">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="free"
                            name="match_type"
                            type="radio"
                            className="custom-control-input"
                            value="free"
                            onChange={handleMatch}
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="free"
                          >
                            {t("text_free")}
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="paid"
                            name="match_type"
                            type="radio"
                            className="custom-control-input"
                            value="paid"
                            checked=""
                            onChange={handleMatch}
                          />
                          &nbsp;
                          <label
                            className="custom-control-label "
                            htmlFor="paid"
                          >
                            {t("text_paid")}
                          </label>
                        </div>
                      </div>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.matchTypeErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state.formErrors.matchTypeErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-5">
                      <label htmlFor="match_banner" className="mt-3">
                        {t("text_browse_banner")}&nbsp;
                      </label>
                      <br />
                      <input
                        id="match_banner"
                        type="file"
                        className="file-input d-block mt-3"
                        name="match_banner"
                        onChange={handleFile}
                      />
                      {/* <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.matchBannerErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state.formErrors.matchBannerErr}
                      </label> */}
                      <p>
                        <b>{t("text_note")} : </b>&nbsp;
                        {t("text_image_note_1000x500")}
                      </p>
                      <input
                        type="hidden"
                        id="file-input"
                        name="old_match_banner"
                        value=""
                        className="form-control mt-3-file"
                      />
                    </div>
                    <div className="form-group col-md-1 m-auto text-center">
                      <b>
                        <u>{t("text_or")}</u>
                      </b>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="image_id" className="mt-3">
                        {t("text_select_banner")}
                      </label>
                      <Select2 onHandle={handleData} />
                    </div>
                  </div>
                  <div className="row">
                    {/* prize description */}
                    <div className="form-group col-12 my-2">
                      <label htmlFor="prize_description my-2">
                        {t("text_prize_description")}
                      </label>
                      <CKEditor
                        data={editor2.prize_description}
                        onChange={(event, editor) => {
                          const data = event.editor.getData();
                          setEditor2({
                            prize_description: data,
                          });
                        }}
                      />
                      <label
                        htmlFor="prize_description"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.prizeDescErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state.formErrors.prizeDescErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-12 my-2">
                      <label htmlFor="match_sponsor my-2">
                        {t("text_match_sponsor")}
                      </label>
                      <CKEditor
                        data={editor1.match_sponsor}
                        onChange={(event, editor) => {
                          const data = event.editor.getData();
                          setEditor1({
                            match_sponsor: data,
                          });
                        }}
                      />
                      <label
                        htmlFor="match_sponsor"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.matchSponsorErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state.formErrors.matchSponsorErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    {/* match description */}
                    <div className="form-group col-12 my-2">
                      <label htmlFor="match_desc my-2">
                        {t("text_match_description")}
                      </label>
                      <CKEditor
                        data={editor.match_desc}
                        onChange={(event, editor) => {
                          const data = event.editor.getData();
                          setEditor({
                            match_desc: data,
                          });
                        }}
                      />
                      <label
                        htmlFor="match_desc"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.matchDescErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state.formErrors.matchDescErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    {/* match private description */}
                    <div className="form-group col-12 my-2">
                      <label htmlFor="match_private_desc my-2">
                        {t("text_match_private_description")}
                      </label>
                      <CKEditor
                        data={editor3.match_private_desc}
                        onChange={(event, editor) => {
                          const data = event.editor.getData();
                          setEditor3({
                            match_private_desc: data,
                          });
                        }}
                      />

                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.match_private_desc
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state.formErrors.match_private_desc}
                      </label>
                    </div>
                  </div>
                  <div className="form-group text-center d-flex justify-content-center">
                    <input
                      type="submit"
                      value="Submit"
                      name="submit"
                      className={`btn btn-lightpink ${
                        demouser === "1" && "disabled"
                      }`}
                      onClick={demouser === "0" && handleSubmit}
                    />
                    <NavLink
                      className="btn btn-secondary ms-1"
                      to="/admin/matches"
                      name="cancel"
                    >
                      {t("text_btn_cancel")}
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// redux connect
const mapStateToProps = (state) => ({
  res: state.GetGameName,
});

export default connect(mapStateToProps)(AddMatches);
