import { React, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { t } from "i18next";
import { useParams } from "react-router-dom";

const WalletDetail = () => {
  const params = useParams();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  //manage the wallet
  const [wallete, setWallet] = useState({
    amount: "",
    wallet: "",
    comment: "",
    plus_minus: "",
  });
  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });
  //handle the input field data
  const handleInput = (e) => {
    const { name, value } = e.target;
    setWallet({
      ...wallete,
      [name]: value,
    });
  };
  function handleFormValidation() {
    const { amount, wallet, comment, plus_minus } = wallete;
    let formErrors = {};
    let formIsValid = true;
    if (!amount) {
      formIsValid = true;
      formErrors["amountErr"] = t("translation2:err_amount_number");
    }
    if (!wallet) {
      formIsValid = true;
      formErrors["walletErr"] = t("translation2:err_wallet_req");
    }
    if (!comment) {
      formIsValid = true;
      formErrors["commentErr"] = t("translation2:err_comment_detail");
    }
    if (!plus_minus) {
      formIsValid = true;
      formErrors["plusMinusVErr"] = t("translation2:err_plus_minus_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  // submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("add_wallet", "Submit");
    formData.append("amount", wallete.amount);
    formData.append("wallet", wallete.wallet);
    formData.append("comment", wallete.comment);
    formData.append("plus_minus", wallete.plus_minus);
    handleFormValidation();
    // member wallet
    var response = await fetch(`/api/member_wallet_data/${params.id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(wallete),
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      history.push("/admin/all_users", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
    // else{
    //   history.push("/admin/all_users", {
    //     state: {
    //       status: false,
    //       data: res_data.message
    //     }
    //   })
    // }
  };

  return (
    <>
      <div className="row mb-4">
        <div className=" col-md-12">
          <div className="card bg-light text-dark">
            <div className="card-header">
              <strong>{t("text_wallet")}</strong>
            </div>
            <div className="card-body">
              <div className="col-md-12">
                <form
                  className="wallet_form"
                  name="wallet_form"
                  id="wallet_form"
                >
                  <div className="row">
                    <input
                      type="hidden"
                      className="form-control"
                      name="member_id"
                      value="263"
                    />
                    <div className="form-group col-md-6">
                      <label htmlFor="Amount">{t("text_amount")}</label>
                      <input
                        id="amount"
                        type="text"
                        className="form-control numbers"
                        name="amount"
                        value={wallete.amount}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.amountErr ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.amountErr}
                      </label>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="wallet">{t("text_wallet")}</label>
                      <select
                        id="wallet"
                        type="text"
                        className="form-control"
                        name="wallet"
                        defaultValue={wallete.wallet}
                        onChange={handleInput}
                      >
                        <option value="">{t("text_select_wallet")}</option>
                        <option value="join_money">
                          {t("text_join_money")}
                        </option>
                        <option value="wallet_balance">
                          {t("text_win_money")}
                        </option>
                      </select>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.walletErr ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.walletErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="comment">{t("text_comment")}</label>
                      <textarea
                        id="comment"
                        type="text"
                        className="form-control"
                        name="comment"
                        value={wallete.comment}
                        onChange={handleInput}
                      ></textarea>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.commentErr ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.commentErr}
                      </label>
                    </div>
                    <div className="form-group col-md-6 mt-3">
                      <label htmlFor="gender">{t("text_plus_minus")}</label>

                      <div
                        className="form-group col-md-12"
                        style={{ paddinTop: "10px" }}
                      >
                        <span style={{ fontSize: "20px" }}>
                          <input
                            id="plus_minus1"
                            value="+"
                            type="radio"
                            name="plus_minus"
                            onChange={handleInput}
                          />
                          &nbsp;+
                        </span>
                        <span style={{ marginLeft: "20px", fontSize: "20px" }}>
                          <input
                            id="plus_minus2"
                            value="-"
                            type="radio"
                            name="plus_minus"
                            onChange={handleInput}
                          />
                          &nbsp;-
                        </span>
                      </div>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.plusMinusVErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.plusMinusVErr}
                      </label>
                    </div>
                  </div>
                  <div className="form-group d-flex justify-content-center mt-3">
                    <input
                      type="submit"
                      value="Submit"
                      name="submit"
                      className={`btn btn-lightpink ${
                        demouser === "1" && "disabled"
                      }`}
                      onClick={demouser === "0" && handleSubmit}
                    />
                    <NavLink to="/admin/all_users">
                      <input
                        type="submit"
                        value="cancel"
                        name="cancel"
                        className="btn btn-secondary ms-1"
                      />
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletDetail;
