import { React, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useLocation, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import MainTheme from "../../theme/MainTheme";

const PayPal = () => {
  const history = useHistory();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));

  const [orderID, setOrderID] = useState("");
  const [state, setState] = useState({
    member_id: location.state.state.data.CUST_ID,
    state: "approved",
    id: location.state.state.message.MID,
    amount: Math.round(location.state.state.data.TXN_AMOUNT),
  });    

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Battlemania",
            amount: {
              currency_code: location.state.state.data.data.currency_code,
              value: Math.round(location.state.state.data.TXN_AMOUNT),
            },
          },
        ],
      })
      .then((orderID) => {
        setOrderID(location.state.state.message.ORDER_ID);
        return orderID;
      });
  };

  // success notification
  const notifyS = (data) => toast.success(data);
  // error notification
  const notifyE = (data) => toast.error(data);

  // check Approval
  const onApprove = async () => {
    var response = await fetch(`/api/paypal_response`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      notifyS(res_data.message);
      setTimeout(() => {
        history.push("/account/my_wallet");
      }, 2000);
    } else {
      notifyE(res_data.message);
      setTimeout(() => {
        history.push("/account/my_wallet");
      }, 2000);
    }
  };

  return (
    <>
      {/* toastify */}
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <MainTheme title="Paypal" backPath>
        <div className="p-3">
          <PayPalScriptProvider
            options={{
              "client-id": location.state.state.data.data.client_id,
            }}
          >
            <PayPalButtons
              className="text-center mt-5"
              createOrder={createOrder}
              onApprove={onApprove}
            />
          </PayPalScriptProvider>
        </div>
      </MainTheme>
    </>
  );
};

export default PayPal;
