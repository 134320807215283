import { React, useEffect } from "react";
import Title from "../../../views/common/Helmet";
import { connect } from "react-redux";
import { top_player_data } from "../../../store/Action/UserFatchData";
import MainTheme from "../theme/MainTheme";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Fade from "react-reveal/Fade";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Top_player = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(top_player_data());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_top_players")} />
      <MainTheme title={t("text_top_players")} backPath>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <Tabs>
            {/* tabs */}
            <TabList
              className="d-flex flex-wrap items-stretch justify-evenly nav-tabs"
              style={{ marginBottom: "0" }}
            >
              {/* tab image */}
              {data.game.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    className="p-3 top-tab-app pointer"
                    id="top-tab"
                    style={{ listStyleType: "none", borderBottom: "none" }}
                  >
                    <img
                      src={item.game_logo}
                      alt="content"
                      style={{ width: "40px" }}
                    />
                  </Tab>
                );
              })}
            </TabList>
            {/* tab content */}
            {data.game.map((item, index) => {
              return (
                <TabPanel key={item.game_id}>
                  <Fade>
                    <div
                      className="btn-lightpink text-white text-center mt-1 p-3"
                      style={{ captionSide: "top" }}
                      key={index}
                    >
                      {item.game_name}
                    </div>
                    {/* tab content table  */}
                    <table className="table table-responsive bg-white">
                      <thead className="btn-green ">
                        <tr className="bg-green-900">
                          <th className=" text-white p-2">
                            {t("text_user_name")}
                          </th>
                          <th className=" text-white p-2">
                            {t("text_winning")}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-black">
                        {data.top_players[item.game_name].map((data, index) => {
                          return (
                            <tr className="text-center" key={index}>
                              <td className="p-2">{data.user_name}</td>
                              <td className="p-2">{data.winning}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Fade>
                </TabPanel>
              );
            })}
          </Tabs>
        )}
      </MainTheme>
    </>
  );
};

// reduc connect
const mapStateToProps = (state) => ({
  res: state.top_player,
});

export default connect(mapStateToProps)(Top_player);
