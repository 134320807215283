export const initialState = {
  data: {},
  loading: true,
};

// login
export function Admin_LoginReducer(state = initialState, action) {
  switch (action.type) {
    case "ADMIN_LOGIN":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//send otp
export function AdminSendOtpReducer(state = initialState, action) {
  switch (action.type) {
    case "ADMIN_SEND_OTP":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
// permission
export function PermissionReducer(state = initialState, action) {
  switch (action.type) {
    case "PERMISSION":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//total user
export function TotalUserReducer(state = initialState, action) {
  switch (action.type) {
    case "TOTAL_USER":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//total user
export function ProfileDataReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_PROFILE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//total matches
export function TotalMatchReducer(state = initialState, action) {
  switch (action.type) {
    case "TOTAL_MATCHES":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//total received amount
export function TotalReceivedAmountReducer(state = initialState, action) {
  switch (action.type) {
    case "TOTAL_RECEIVED_AMOUNT":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//total withdraw amount
export function TotalWithdrawAmountReducer(state = initialState, action) {
  switch (action.type) {
    case "TOTAL_WITHDRAW_AMOUNT":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
// get game
export function GetPackageReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_PACKAGE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get country
export function GetCountryReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_COUNTRY":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function GetTopPlayersReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_TOP_PLAYERS":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get game name
export function GetGameNameReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_GAME_NAME":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
// get challenge detail
export function GetChallengeDetailReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_CHALLENGE_DETAIL":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get leader_board
export function GetLeaderBoardReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_LEADER_BOARD":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get insert lottery
export function GetInsertLotteryReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_INSERT_LOTTERY":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get pages
export function GetPagesReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_PAGES":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get pages
export function GetImageReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_IMAGE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
// get screenshots
export function GetScreenshotsReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_SCREENSHOTS":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get features
export function GetFeaturesReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_FEATURES":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get how to play
export function GetHowToPlayReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_HOW_TO_PLAY":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get how to install
export function GetCurrencyReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_CURRENCY":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get app upload
export function GetAppUploadReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_APP_UPLOAD":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get withdraw method
export function GetWithdrawMethodReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_WITHDRAW_METHOD":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get payment method
export function GetPaymentMethodReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_PAYMENT_METHOD":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get admin
export function GetAdminReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ADMIN":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get permission
export function CheckAllPermissionReducer(state = initialState, action) {
  switch (action.type) {
    case "CHECK_ALL_PERMISSSION":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
// get permission
export function CheckPermissionReducer(state = initialState, action) {
  switch (action.type) {
    case "CHECK_PERMISSSION":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// get license
export function GetLicenseReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_LICENSE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
// check license
export function CheckLicenseReducer(state = initialState, action) {
  switch (action.type) {
    case "CHECK_LICENSE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
// update game details reducer
export function GameUpdateReducer(state = initialState, action) {
  switch (action.type) {
    case "GAME_UPDATE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// delete add details reducer
export function GameAddReducer(state = initialState, action) {
  switch (action.type) {
    case "GAME_ADD":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// id vise get game details reducer
export function GetGameIdReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// update room details reducer
export function RoomUpdateReducer(state = initialState, action) {
  switch (action.type) {
    case "ROOM_UPDATE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// specific match details reducer
export function SpecificMatchReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_SPECIFIC_MATCH":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// specific match details reducer
export function SpecificOrderReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_SPECIFIC_ORDER":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//couier details update reducer
export function courierReducer(state = initialState, action) {
  switch (action.type) {
    case "COURIER_UPDATE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//status update reducer
export function orderStatusReducer(state = initialState, action) {
  switch (action.type) {
    case "ORDER_STATUS":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//status update reducer
export function lotteryMemberReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_LOTTERY_MEMBER":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//lottery member update reducer
export function updateLotteryMemberReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_LOTTERY_MEMBER":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//get member data reducer
export function getMemberReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_MEMBER_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//get member join data reducer
export function getMemberJoinReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_MEMBER_JOIN_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//withdraw request reducer
export function withdrawReducer(state = initialState, action) {
  switch (action.type) {
    case "WITHDRAW_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//top player reducer
export function topPlayerReducer(state = initialState, action) {
  switch (action.type) {
    case "TOP_PLAYER_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//game reducer
export function GameReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_GAME_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
//game reducer
export function GetMemberPositionReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_MEMBER_POSITION":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// member list
export function GetMemberListReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_MEMBER_LIST":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// download result
export function DownloadResultReducer(state = initialState, action) {
  switch (action.type) {
    case "DOWNLOAD_RESULT":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
// download result
export function MemberEditReducer(state = initialState, action) {
  switch (action.type) {
    case "MEMBER_EDIT":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// game match
export function GetGameMatchReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_GAME_MATCH":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// all user
export function GetAllUserReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_USER":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
