import { React, useEffect } from "react";
import UserHeader from "../theme/header";
import UserFooter from "../theme/footer";
import { Earn_data } from "../../../store/Action/UserFatchData";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Title from "../../../views/common/Helmet";
import { Scrollbars } from "react-custom-scrollbars";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Earn = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Earn_data());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_refer_earn")} />
      <main className="bm-full-width bm-full-height">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-xl-4 col-left">
              <div className="bm-modal">
                <UserHeader title={t("text_earn")} headCoin />
                <div className="bm-mdl-center bm-full-height">
                  {res.loading ? (
                    <div className="loader my-auto">
                      <Spinner />
                    </div>
                  ) : (
                    <Scrollbars>
                      {data.banner.map((data, index) => {
                        return (
                          <div className="bm-content-listing" key={index}>
                            {data.banner_link != "Watch and Earn" &&
                            data.banner_link != "My Rewards" ? (
                              <Link to={data.banner_link}>
                                <div>
                                  <img
                                    src={data.banner_image}
                                    alt="banner-img"
                                    style={{
                                      width: "100%",
                                      borderRadius: "5px",
                                    }}
                                    title={data.banner_title}
                                  />
                                </div>
                              </Link>
                            ) : null}
                          </div>
                        );
                      })}
                    </Scrollbars>
                  )}
                </div>
                <UserFooter />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.earn,
});

export default connect(mapStateToProps)(Earn);
