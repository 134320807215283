import { React, useEffect } from "react";
import { Transaction_data } from "../../../../store/Action/UserFatchData";
import { Dashboard } from "../../../../store/Action/UserFatchData";
import coin from "../../../assets/images/coin.png";
import { Scrollbars } from "react-custom-scrollbars";
import Title from "../../../common/Helmet";
import { ToastContainer } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import MainTheme from "../../theme/MainTheme";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { t } from "i18next";

const My_wallet = ({ dispatch, res_transaction, res_account }) => {
  useEffect(() => {
    dispatch(Transaction_data());
    dispatch(Dashboard());
  }, [dispatch]);
  var data = res_transaction.data;
  var dashboard = res_account.data;

  return (
    <>
      <Title props={t("text_my_wallet")} />
      {/* toastify */}
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <MainTheme title={t("text_my_wallet")} backPath>
        <Scrollbars>
          <div className="bm-content-listing">
            {res_account.loading || res_transaction.loading ? (
              <div className="loader my-auto">
                <Spinner />
              </div>
            ) : (
              <>
                <span
                  className="d-block btn-green text-center rounded-top p-1 text-uppercase "
                  style={{
                    marginLeft: "inherit",
                    marginRight: "inherit",
                  }}
                >
                  {t("text_total_balance")}
                </span>
                <div className="container wallet">
                  <div className="row btn-lightgreen rounded-bottam p-3 text-black">
                    <div className="col-7">
                      <h6>
                        <span>
                          <img src={coin} className="coin" alt="img-coin" />
                          &nbsp;
                          {Number(
                            parseInt(dashboard.member.wallet_balance) +
                              parseInt(dashboard.member.join_money)
                          ).toFixed(2)}
                        </span>
                      </h6>
                      {/* win money */}
                      <p>
                        {t("text_win_money")} :&nbsp;
                        <span>
                          <img src={coin} className="coin" alt="img-coin" />
                        </span>
                        &nbsp;
                        {Number(dashboard.tot_win.total_win).toFixed(2)}
                      </p>
                      {/* join money */}
                      <p className="mt-1">
                        {t("text_join_money")} :&nbsp;
                        <span>
                          <img src={coin} className="coin" alt="img-coin" />
                          &nbsp;
                          {Number(dashboard.member.join_money).toFixed(2)}
                        </span>
                      </p>
                    </div>
                    <div className="col-5 text-right text-end">
                      {/* add button */}
                      <Link
                        to="/account/my_wallet/add"
                        className="btn btn-md f-14 btn-lightpink text-uppercase mb-1"
                      >
                        {t("text_action_add")}
                      </Link>
                      {/* withdraw button */}
                      <Link
                        to="/account/my_wallet/withdraw"
                        className="btn btn-md f-14 btn-lightpink text-uppercase"
                      >
                        {t("text_withdraw")}
                      </Link>
                    </div>
                  </div>
                  {/* earning */}
                  <div className="row mt-2 text-center">
                    <div className="col-6 p-0 pr-1 ">
                      <div className="w-100 btn-green text-center rounded-top p-1 text-uppercase ">
                        {t("text_earning")}&nbsp;
                      </div>
                      <div className="btn-lightgreen w-100 rounded-bottam p-3 text-black">
                        <span>
                          <img src={coin} className="coin" alt="img-coin" />
                        </span>
                        &nbsp;
                        {Number(dashboard.tot_win.total_win).toFixed(2)}
                      </div>
                    </div>
                    {/* payouts */}
                    <div className="col-6 p-0 pl-1">
                      <div className="w-100 btn-green text-center rounded-top p-1 text-uppercase ">
                        {t("text_payouts")}&nbsp;
                      </div>
                      <div className="btn-lightgreen w-100 rounded-bottam p-3 text-black">
                        <span>
                          <img src={coin} className="coin" alt="img-coin" />
                        </span>
                        &nbsp;
                        {Number(dashboard.tot_withdraw.tot_withdraw).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
                {/* wallet history */}
                <h6 className="text-black text-center mt-2 text-uppercase">
                  {t("text_wallet_history")}
                </h6>
                {data.transaction.map((data, index) => (
                  <div key={data.transaction_id}>
                    {data.withdraw > 0 ? (
                      // debit
                      <div
                        className="row my-2 rounded bg-lightgray box-shadow p-2 d-flex"
                        key={index}
                      >
                        <div className="col-2 m-auto">
                          <span className="text-danger text-uppercase">
                            {t("text_debit")}
                          </span>
                        </div>
                        <div className="col-6 text-dark text-center">
                          <p>
                            {data.note} - #{data.transaction_id}&nbsp;
                          </p>
                          <p className="mt-1">{data.date}</p>
                        </div>
                        <div className="col-4 text-end">
                          <span className="text-danger">
                            &nbsp;-&nbsp;
                            <span>
                              <img src={coin} className="coin" alt="img-coin" />
                            </span>
                            &nbsp;
                            {data.withdraw}
                          </span>
                          <p className="text-primary my-1">
                            &nbsp;
                            <span>
                              <img src={coin} className="coin" alt="img-coin" />
                            </span>
                            &nbsp;
                            {Number(data.join_money).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    ) : (
                      // credit
                      <div className="row my-2 rounded bg-lightgray box-shadow p-2 d-flex">
                        <div className="col-2 m-auto">
                          <span className="bm_text_lightgreen text-uppercase">
                            {t("text_credit")}
                          </span>
                        </div>
                        <div className="col-6 text-dark text-center">
                          <p>
                            {data.note} - #{data.transaction_id}&nbsp;
                          </p>
                          <p className="mt-1">{data.date}</p>
                        </div>
                        <div className="col-4 text-end">
                          <span className="bm_text_lightgreen">
                            &nbsp;+&nbsp;
                            <span>
                              <img src={coin} className="coin" alt="img-coin" />
                            </span>
                            &nbsp;
                            {data.deposit}
                          </span>
                          <p className="text-primary my-1">
                            &nbsp;
                            <span>
                              <img src={coin} className="coin" alt="img-coin" />
                            </span>
                            &nbsp;
                            {Number(data.join_money).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
        </Scrollbars>
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res_transaction: state.transaction,
  res_account: state.dashboard,
});

export default connect(mapStateToProps)(My_wallet);
