import React from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";

const UpcomingFooter = (data) => {
  console.log("datat", data);
  return (
    <>
      <div className="bm-mdl-footer text-white ">
        {/* join status wise show  */}
        {data.data.match.join_status ? (
          <span className="btn btn-sm d-block f-18 btn-lightpink text-uppercase">
            {t("text_already_joined")}
          </span>
        ) : (
          // link for join now
          <Link
            to={{
              pathname: `/play/position/${data.data.match.m_id}`,
              state: {
                type: data.data.match.type,
                game_name: data.data.match.match_name,
                joined: data.data.match.join_status,
                match_fees: data.data.match.entry_fee,
              },
            }}
            className="btn btn-sm d-block f-18 btn-lightpink text-uppercase"
          >
            <span className="text-uppercase">{t("text_btn_join_now")}</span>
          </Link>
        )}
      </div>
    </>
  );
};
export default UpcomingFooter;
