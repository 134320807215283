import { React, useEffect } from "react";
import { LotteryResult } from "../../../../store/Action/UserFatchData";
import Spinner from "react-bootstrap/Spinner";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { t } from "i18next";

const Result = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(LotteryResult());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        data.result &&
        data.result.map((data, index) => {
          return (
            <div class="bm-content-listing tournaments" key={index}>
              <NavLink
                to={{
                  pathname: `/Luckey Draw/lotterydetail/${data.lottery_id}`,
                  state: data,
                }}
                className="text-decoration-none"
              >
                <div class="card br-5 hide-over mb-3 p-2">
                  <div className="row">
                    {/* lottery image */}
                    {data.lottery_image != null ? (
                      <div class="col-md-6">
                        <img
                          src={data.lottery_image}
                          class="img-fluid card-img-top mCS_img_loaded"
                        />
                      </div>
                    ) : (
                      <div class="col-md-6">
                        <img
                          src=""
                          class="img-fluid card-img-top mCS_img_loaded"
                        />
                      </div>
                    )}
                    <div class="col-md-6">
                      {/* lottery id and title */}
                      <h6 class="mt-2 mb-0 text-black">
                        {data.lottery_title} - {t("text_lottery")} #
                        {data.lottery_id}
                      </h6>
                      {/* date created */}
                      <div class="text-black mb-1">
                        {t("text_draw_on")} : {data.date_created}
                      </div>
                      {/* won prize */}
                      <div class="text-black mb-1">
                        {t("text_won_prize")} : {data.lottery_prize}
                      </div>
                      {/* won by */}
                      <div class="text-black mb-1">
                        {t("text_won_by")} : {data.won_by}
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          );
        })
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.LotteryResult,
});

export default connect(mapStateToProps)(Result);
