import { React, useEffect } from "react";
import { Link } from "react-router-dom";
import { All_game } from "../../../../store/Action/UserFatchData";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const AllGames = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(All_game());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <>
          {/* All Games */}
          <div className="row">
            {data.all_game.map((img, index) => (
              <div key={index} className="col-md-4">
                {/* Link for every Single Game */}
                <Link to={`/play/game_pages/${img.game_id}`}>
                  <div
                    style={{
                      backgroundImage: `url(${img.game_image})`,
                      minHeight: "260px",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      position: "relative",
                      borderRadius: "5px 5px 0 0",
                    }}
                  >
                    <span className=" text-dark bm-tot-match">
                      {t("text_matches_avilable")} : &nbsp;
                      {img.total_upcoming_match}
                    </span>
                  </div>
                </Link>
                {/* game name */}
                <div className="text-black f-18 text-center p-2 mb-4 game-name">
                  {img.game_name}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.All_game,
});

export default connect(mapStateToProps)(AllGames);
