import { React, useEffect } from "react";
import { Lottery_data } from "../../../../store/Action/UserFatchData";
import coin from "../../../../views/assets/images/coin.png";
import { Scrollbars } from "react-custom-scrollbars";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { t } from "i18next";

const Ongoing = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Lottery_data());
  }, [dispatch]);
  var data = res.data;
  return (
    <>
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <Scrollbars>
          {data.ongoing &&
            data.ongoing.map((data, index) => (
              <div className="bm-content-listing tournaments" key={index}>
                <div
                  className="card br-5 hide-over"
                  style={{ minHeight: "unset" }}
                >
                  {/* lottery fees */}
                  <span className="lottery-join">
                    <i>
                      <span>
                        <img src={coin} className="coin" alt="img-coin" />
                      </span>
                    </i>
                    {data.lottery_fees}
                  </span>
                  <NavLink
                    to={{
                      pathname: `/Luckey Draw/lotterydetail/${data.lottery_id}`,
                      state: data,
                    }}
                  >
                    {/* lottery image */}
                    <img
                      src={data.lottery_image}
                      className="img-fluid card-img-top1 mCS_img_loaded"
                      alt="images"
                    />
                  </NavLink>
                  <div className="card-body">
                    {/* lottery id and title */}
                    <h6 className="card-title mb-0">
                      <a href="#lottery" className="text-dark">
                        {data.lottery_title}&nbsp;
                        {t("text_for_lottery_id")}
                        {data.lottery_id}&nbsp;
                      </a>
                    </h6>
                    {/* lottery time */}
                    <div className="bm_text_lightpink mb-1">
                      {data.lottery_time}
                    </div>
                    {/* progressbar */}
                    <div className="row">
                      <div className="col-8">
                        <span className="text-primary">
                          {data.total_joined}/{data.lottery_size}
                          &nbsp;
                        </span>
                        <ProgressBar
                          variant="primary"
                          now={(data.total_joined / data.lottery_size) * 100}
                        />
                      </div>
                      <div className="col-4">
                        {data.join_status == false ? (
                          <NavLink
                            className="btn btn-sm btn-block btn-lightpink text-uppercase"
                            to={{
                              pathname: "/Luckey Draw/register",
                              state: {
                                lottery_id: data.lottery_id,
                                lottery_prize: data.lottery_prize,
                                lottery_title: data.lottery_title,
                              },
                            }}
                          >
                            <span>
                              <img src={coin} className="coin" alt="img-coin" />
                            </span>
                            {data.lottery_fees}&nbsp;
                            {t("text_register")}
                            <i className="fa fa-angle-down text-white"></i>
                          </NavLink>
                        ) : (
                          <a
                            style={{ cursor: "auto" }}
                            className="btn btn-sm btn-block btn-lightgreen text-uppercase"
                          >
                            <span>
                              <img src={coin} className="coin" alt="img-coin" />
                            </span>
                            {data.lottery_fees}&nbsp;
                            {t("text_registered")}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Scrollbars>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.lottery,
});

export default connect(mapStateToProps)(Ongoing);
