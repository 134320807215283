import React from "react";
import coin from "../../../../views/assets/images/coin.png";
import { t } from "i18next";

const Description = ({ data }) => {
  return (
    <>
      <div className="bm-content-listing text-black">
        {/* lottery id */}
        <h6 className="bm_text-lightgreen">
          {data.lottery_title}&nbsp;
          {t("text_for_lottery_id")}
          {data.lottery_id}
        </h6>
        <table className="table table-borderless">
          <tbody>
            {/* lottery time */}
            <tr className="border-0">
              <td style={{ verticalAlign: "middle" }}>
                <i className="fa fa-clock-o" style={{ fontSize: "25px" }}></i>
              </td>
              <td>
                {t("text_result_on")}: <br />
                {data.lottery_time}
              </td>
            </tr>
            {/* lottery price */}
            <tr className="border-0">
              <td style={{ verticalAlign: "middle" }}>
                <i className="fa fa-trophy" style={{ fontSize: "25px" }}></i>
              </td>
              <td>
                {t("text_play_for")}: <br />
                <span>
                  <img src={coin} className="coin" alt="img-coin" />
                </span>
                {data.lottery_prize}
              </td>
            </tr>
            {/* lottery fees */}
            <tr className="border-0">
              <td style={{ verticalAlign: "middle" }}>
                <i className="fa fa-ticket" style={{ fontSize: "25px" }}></i>
              </td>
              <td>
                {t("text_fees")}: <br />
                <span>
                  <img src={coin} className="coin" alt="img-coin" />
                </span>
                {data.lottery_fees}
              </td>
            </tr>
          </tbody>
        </table>
        {/* about lottery */}
        <h6 className="bm_text-lightgreen  mt-3">{t("text_about_lottery")}</h6>
        <span>
          <p>{data.lottery_rules}</p>
        </span>
      </div>
    </>
  );
};

export default Description;
