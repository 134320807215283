import { React, useEffect } from "react";
import Title from "../../common/Helmet";
import MainTheme from "../theme/MainTheme";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Product_data } from "../../../store/Action/UserFatchData";
import coin from "../../assets/images/coin.png";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const BuyProduct = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Product_data());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_product")} />
      <MainTheme title={t("text_buy_now")}>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            {/* All Products */}
            <div className="row">
              {data.product.map((data, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div className="card card-sm-3 mb-4">
                    {/* product image with link */}
                    <Link to={`/Buy Product/productdetail/${data.product_id}`}>
                      <img
                        src={data.product_image}
                        className="img-responsive"
                        alt="img"
                        style={{ width: "100%" }}
                      />
                    </Link>
                    {/* product name and prices */}
                    <div className="p-1">
                      <h6 className="text-dark">{data.product_name}</h6>
                      <div className="row">
                        <div className="col-6 text-info">
                          <strike>
                            <span>
                              <img src={coin} className="coin" alt="img" />
                            </span>
                            {/* fix the price number with .00 */}
                            {Number(data.product_actual_price).toFixed(2)}
                          </strike>
                        </div>
                        <div
                          className="col-6 pull-right text-info"
                          style={{ textAlign: "right" }}
                        >
                          <span>
                            <img src={coin} className="coin" alt="img" />
                          </span>
                          {/* fix the price number with .00 */}
                          {Number(data.product_selling_price).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.product,
});

export default connect(mapStateToProps)(BuyProduct);
