import { React, useState, useEffect } from "react";
import UserHeader from "../../theme/userHeader";
import { t } from "i18next";
import { NavLink, useParams, useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Title from "../../../../views/common/Helmet";
import OfflineP from "./OfflineP";
import PaytmP from "./PaytmP";
import PayPalP from "./PayPalP";
import PayStackP from "./PayStackP";
import InstaMojoP from "./InstaMojoP";
import RazorpayP from "./RazorpayP";
import CashFreeP from "./CashFreeP";
import TronP from "./TronP";
import PayUP from "./PayUP";

const EditPaymentMethod = () => {
  const history = useHistory();
  const params = useParams();
  const user = JSON.parse(sessionStorage.getItem("user"));

  // manage state
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});
  const [message, setMessage] = useState({});
  const [display, setDisplay] = useState({
    PayTm: false,
    PayPal: false,
    Offline: false,
    PayStack: false,
    Instamojo: false,
    Razorpay: false,
    Cashfree: false,
    Tron: false,
    PayU: false,
  });

  useEffect(async (e) => {
    var response = await fetch(`/api/get_insert_payment_method/${params.id}`, {
      headers: { Authorization: user.api_token },
    });
    var res_data = await response.json();
    if (res_data) {
      setLoading(false);
    }
    var data = res_data.get_withdraw_method[0];
    console.log(res_data);
    var pay_name = res_data.get_withdraw_method[0].payment_name;
    setState({
      id: data.id,
      payment_name: data.payment_name,
      payment_description: data.payment_description,
      payment_status: data.payment_status,
      name: data.name,
      mid: data.mid,
      mkey: data.mkey,
      wname: data.wname,
      itype: data.itype,
      currency: data.currency,
      currency_point: data.currency_point,
      date: data.date,
      status: data.status,
      created_date: data.created_date,
    });
    setDisplay({
      [pay_name]: true,
    });
  }, []);
  const handleData = (value) => {
    setMessage(value);
  };

  // submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    // handleFormValidation();
    var response = await fetch(`/api/update_payment_method/${message.id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(message),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      history.push("/admin/payment_method", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_payment_method")} />
      <UserHeader title={t("text_payment_method")} />
      {loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card bg-light text-dark">
              <div className="card-header">
                <strong>
                  {state.payment_name} {t("text_integration")}
                </strong>
              </div>

              <div className="card-body">
                <div className="col-md-12">
                  <form
                    method="POST"
                    className="member_form"
                    name="validate"
                    id="validate"
                  >
                    {display.Offline && (
                      <OfflineP props={state} onMessage={handleData} />
                    )}
                    {display.Cashfree && (
                      <CashFreeP props={state} onMessage={handleData} />
                    )}
                    {display.Instamojo && (
                      <InstaMojoP props={state} onMessage={handleData} />
                    )}
                    {display.PayPal && (
                      <PayPalP props={state} onMessage={handleData} />
                    )}
                    {display.PayStack && (
                      <PayStackP props={state} onMessage={handleData} />
                    )}
                    {display.PayU && (
                      <PayUP props={state} onMessage={handleData} />
                    )}
                    {display.PayTm && (
                      <PaytmP props={state} onMessage={handleData} />
                    )}
                    {display.Tron && (
                      <TronP props={state} onMessage={handleData} />
                    )}
                    {display.Razorpay && (
                      <RazorpayP props={state} onMessage={handleData} />
                    )}
                    <div className="form-group d-flex justify-content-center mb-3">
                      <input
                        type="submit"
                        value="Update"
                        name="update_paytm"
                        className="btn btn-lightpink"
                        onClick={handleSubmit}
                      />
                      <NavLink
                        className="btn btn-secondary ms-1 d-block"
                        to="/admin/payment_method"
                      >
                        {t("text_btn_cancel")}
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditPaymentMethod;
