import { React, useEffect, useState } from "react";
import UserHeader from "../../theme/userHeader";
import coin from "../../../assets/images/coin.png";
import { useHistory } from "react-router-dom";
import "../../../assets/css/style.css";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import Title from "../../../../views/common/Helmet";
import { t } from "i18next";

const Matches = () => {
  const $ = window.$;
  const history = useHistory();
  const location = useLocation();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  // manage state
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [danger, setDanger] = useState(false);
  const [selected, setSelected] = useState("");
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (location.state) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  // delete
  const handleDelete = (id, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>You won't be able to revert this!</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var response = await fetch(`/api/delete_match_data/${id}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
        });
        var res_data = await response.json();
        table1.ajax.reload(null, false);
        setShow(true);
        setMessage(res_data.message);
        setDanger(false);
      }
    });
  };

  //change the pin status
  const handlePin = (id, value, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" +
        "<p>Are you sure to change match pin status?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (value == 0) {
          var v = "1";
        } else {
          v = "0";
        }
        const result = {
          pin_match: v,
        };
        var response = await fetch(`/api/pin_match_data/${id}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
          body: JSON.stringify(result),
        });
        var res_data = await response.json();
        if (res_data) {
          table1.ajax.reload(null, false);
          setShow(true);
          setMessage(res_data.message);
          setDanger(false);
        }
      }
    });
  };

  //change the match status swal
  const handleStatus = (status, id, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>Are you sure to change status?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          match_status: status,
        };
        var response = await fetch(`/api/change_match_status/${id}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
          body: JSON.stringify(data),
        });
        var res_data = await response.json();
        table1.ajax.reload(null, false);
        setShow(true);
        setMessage(res_data.message);
        setDanger(false);
      }
    });
  };

  // delete multi game
  const handleMultiDelete = (id, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>You won't be able to revert this?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const value = {
          action: "delete",
          match_id: id,
        };
        var response = await fetch(`/api/change_match_multi_status`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
          body: JSON.stringify(value),
        });
        var res_data = await response.json();
        table1.ajax.reload(null, false);
        setShow(true);
        setMessage(res_data.message);
        setDanger(false);
        setSelected("");
        setCheck(false);
      } else if (result.dismiss == "cancel") {
        table1.ajax.reload(null, false);
        setSelected("");
        setCheck(false);
      }
    });
  };

  // edit
  const handleEdit = (id) => {
    return history.push(`/admin/matches/edit/${id}`);
  };

  //detail
  const handleDetail = (id) => {
    history.push(`/admin/matches/member_join_match/${id}`);
  };

  //position
  const handlePosition = (id) => {
    history.push(`/admin/matches/member_position/${id}`);
  };

  //match table
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#match");
      var table1 = table.DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        ajax: {
          url: "/api/get_match",
          headers: {
            Authorization: user.api_token,
          },
          type: "POST",
          dataSrc: "all_matches",
        },
        columns: [
          { data: null, sortable: false },
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          { data: "game_name" },
          { data: "match_name" },
          { data: "room_description" },
          { data: "match_time" },
          { data: "number_of_position" },
          { data: "no_of_player" },
          { data: "win_prize" },
          { data: "entry_fee" },
          {
            data: "match_type",
            render: function (data, type, row) {
              if (data == 1) {
                return "paid";
              } else {
                return "unpaid";
              }
            },
          },
          {
            data: "match_status",
            render: function (data, type, row) {
              if (data == "0") var a = "selected";
              if (data == "1") var b = "selected";
              if (data == "2") var c = "selected";
              if (data == "3") var d = "selected";
              if (data == "4") var e = "selected";
              if (data == "4") {
                return `<select id='btn-status' disabled><option value='0' ${a}>deactive</option><option value='1' ${b}>active</option><option value='2' ${c}>complete</option><option value='3' ${d}>start</option><option value='4' ${e}>cancel</option></select>`;
              } else {
                return `<select id='btn-status'><option value='0' ${a}>deactive</option><option value='1' ${b}>active</option><option value='2' ${c}>complete</option><option value='3' ${d}>start</option><option value='4' ${e}>cancel</option></select>`;
              }
            },
          },
          {
            data: "m_id",
            render: function (data, type, row) {
              if (demouser === "1" && data <= 23) {
                return (
                  '<i class="fa fa-edit text-lightpink fa-lg pointer" id="edit-btn"></i>' +
                  '<i class="fa fa-trash-o text-lightblue fa-lg ps-1 disabled"></i>'
                );
              } else {
                return (
                  '<i class="fa fa-edit text-lightpink fa-lg pointer" id="edit-btn"></i>' +
                  '<i class="fa fa-trash-o text-lightblue fa-lg ps-1 pointer" aria-hidden="true" id="del-btn"></i>'
                );
              }
            },
          },
          {
            data: "m_id",
            render: function (data, type, row) {
              var pin = row.pin_match;
              if (demouser === "1" && data <= 23 && pin == 0) {
                return (
                  '<i class="fa fa-thumb-tack text-skyblue fa-lg"></i>' +
                  '<i class="fa fa-trophy text-lightblue fa-lg ps-1"></i>' +
                  '<i class="fa fa-bullseye text-lightblue fa-lg ps-1"></i>'
                );
              } else if (demouser === "1" && data <= 23 && pin == 1) {
                return (
                  '<i class="fa fa-thumb-tack text-lightblue fa-lg"></i>' +
                  '<i class="fa fa-trophy text-lightblue fa-lg ps-1"></i>' +
                  '<i class="fa fa-bullseye text-lightblue fa-lg ps-1"></i>'
                );
              } else if (demouser === "0" && pin == 0) {
                return (
                  '<i class="fa fa-thumb-tack text-skyblue pointer fa-lg" data-original-title="Pin" data-placement="top" id="pin"></i>' +
                  '<i class="fa fa-trophy text-lightblue pointer fa-lg ps-1" data-original-title="Detail" data-placement="top" aria-hidden="true" id="detail"></i>' +
                  '<i class="fa fa-bullseye text-lightblue pointer fa-lg ps-1" data-original-title="Position" data-placement="top" id="position"></i>'
                );
              } else {
                return (
                  '<i class="fa fa-thumb-tack text-lightblue pointer fa-lg" data-original-title="Pin" data-placement="top" id="pin"></i>' +
                  '<i class="fa fa-trophy text-lightblue pointer fa-lg ps-1" data-original-title="Detail" data-placement="top" aria-hidden="true" id="detail"></i>' +
                  '<i class="fa fa-bullseye text-lightblue pointer fa-lg ps-1" data-original-title="Position" data-placement="top" id="position"></i>'
                );
              }
            },
          },
        ],
        columnDefs: [
          {
            orderable: false,
            className: "select-checkbox",
            checkboxes: true,
            targets: 0,
            render: function (data, type, full, meta) {
              return (
                '<input type="checkbox"  value="' +
                $("<div/>").text(data.m_id).html() +
                '">'
              );
            },
          },
        ],
        select: {
          style: "multi",
          selector: "td:first-child",
        },
        Destroy: true,
      });
      // edit
      $("#match tbody").on("click", "#edit-btn", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().m_id;
        handleEdit(id);
      });
      // status
      $("#match tbody").on("click", "#btn-status", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().m_id;
        const status = $("#btn-status option:selected").val();
        handleStatus(status, id, table1);
      });
      // delete
      $("#match tbody").on("click", "#del-btn", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().m_id;
        handleDelete(id, table1);
      });
      // pin
      $("#match tbody").on("click", "#pin", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().m_id;
        const match = table1.row(row).data().pin_match;
        handlePin(id, match, table1);
      });
      // detail
      $("#match tbody").on("click", "#detail", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().m_id;
        handleDetail(id);
      });
      // position
      $("#match tbody").on("click", "#position", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().m_id;
        handlePosition(id);
      });
      $("#checkall").on("click", function () {
        // Check/uncheck all checkboxes in the table
        var rows = table1.rows().nodes();
        console.log(rows);
        $('input[type="checkbox"]', rows).prop("checked", this.checked);
      });
      var arr = [];
      $("#multi_action").on("change", function () {
        table1.$('input[type="checkbox"]').each(function () {
          // If checkbox doesn't exist in DOM
          var val = $("#multi_action option:selected").text();
          if ($.contains(document, this)) {
            // If checkbox is checked
            if (this.checked) {
              arr.push(this.value);
            }
          }
          if (val == "Delete") {
            if (arr == null) {
              setShow(true);
              setMessage("message");
              setDanger(true);
            } else {
              handleMultiDelete(arr, table1);
            }
          }
        });
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      <Title props={t("text_match")} />
      {/* Header */}
      <UserHeader
        title={t("text_matches")}
        path={"/admin/add_matches"}
        icon={<i className="fa fa-plus"></i>}
        btnText={t("text_add_match")}
      />
      {/* message */}
      <div className="row">
        <div className="col-md-12">
          {show && (
            <div
              className={
                (!location.state.state.data.status
                  ? "alert alert-danger "
                  : "alert alert-success ") +
                "alert-dismissible fade show " +
                (show ? "d-block " : "d-none ") +
                (danger ? "alert-danger" : "alert-success")
              }
              role="alert"
            >
              {location.state.state.data.msg}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
        </div>
      </div>
      {/* datatable */}
      <div className="row">
        <div className="col-md-12">
          <div className="card-body">
            <table id="match" className="display table table-bordered mb-2">
              <thead className="mt-5">
                <tr>
                  <th colSpan={14}>
                    <label htmlFor="action">
                      {t("text_actions_performed")} :&nbsp;
                    </label>
                    <select
                      name="action"
                      id="multi_action"
                      value={selected}
                      onChange={(e) => setSelected(e.target.value)}
                    >
                      <option value="">{t("text_select")}</option>
                      <option value="Delete">{t("text_delete")}</option>
                    </select>
                  </th>
                </tr>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="checkall"
                      id="checkall"
                      checked={check}
                      onChange={(e) => setCheck(e.target.value)}
                    />
                  </th>
                  <th>{t("text_match_id")}</th>
                  <th>{t("text_game_name")}</th>
                  <th>{t("text_match_name")}</th>
                  <th>{t("text_room_id_pass")}</th>
                  <th>{t("text_match_schedule")}</th>
                  <th>{t("text_total_player")}</th>
                  <th>{t("text_total_player_joined")}</th>
                  <th>
                    {t("text_win_prize")}
                    {"("}
                    <img src={coin} className="coin" alt="img-coin" />
                    {")"}
                  </th>
                  <th>
                    {t("text_entry_fee")}
                    {"("}
                    <img src={coin} className="coin" alt="img-coin" />
                    {")"}
                  </th>
                  <th>{t("text_match_type")}</th>
                  <th>{t("text_match_satus")}</th>
                  <th>{t("text_actions")}</th>
                  <th>{t("text_view")}</th>
                </tr>
              </thead>
              <tbody></tbody>
              <tfoot>
                <tr>
                  <th></th>
                  <th>{t("text_match_id")}</th>
                  <th>{t("text_game_name")}</th>
                  <th>{t("text_match_name")}</th>
                  <th>{t("text_room_id_pass")}</th>
                  <th>{t("text_match_schedule")}</th>
                  <th>{t("text_total_player")}</th>
                  <th>{t("text_total_player_joined")}</th>
                  <th>
                    {t("text_win_prize")}
                    {"("}
                    <img src={coin} className="coin" alt="img-coin" />
                    {")"}
                  </th>
                  <th>
                    {t("text_entry_fee")}
                    {"("}
                    <img src={coin} className="coin" alt="img-coin" />
                    {")"}
                  </th>
                  <th>{t("text_match_type")}</th>
                  <th>{t("text_match_satus")}</th>
                  <th>{t("text_actions")}</th>
                  <th>{t("text_view")}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Matches;
