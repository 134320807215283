import { React, useState, useEffect } from "react";
import UserHeader from "../theme/userHeader";
import { NavLink, useHistory, useParams } from "react-router-dom";
import Title from "../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const EditAnnouncement = () => {
  const params = useParams();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  //handle the validation
  const [loading, setLoading] = useState(true);
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [state, setState] = useState({
    announcement_desc: "hello guys",
  });
  useEffect(() => {
    async function announ() {
      var response = await fetch(`/api/get_insert_announcement/${params.id}`, {
        headers: { Authorization: user.api_token },
      });
      var res_data = await response.json();
      if (res_data) {
        setLoading(false);
      }
      setState({
        announcement_desc: res_data.get_country[0].announcement_desc,
      });
    }
    announ();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // form validation
  function handleFormValidation() {
    const { announcement_desc } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!announcement_desc) {
      formIsValid = true;
      formErrors["announcementDescErr"] = t(
        "translation2:err_announcement_req"
      );
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    var response = await fetch(`/api/update_announcement/${params.id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      history.push("/admin/announcement", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_announcement")} />
      <UserHeader title={t("text_announcement")} />
      {loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card bg-light text-dark">
              <div className="card-header">
                <strong>{t("text_announcement")}</strong>
              </div>
              <div className="card-body">
                <div className="col-md-12 p-2">
                  <form
                    className="needs-validation"
                    id="validate"
                    method="POST"
                  >
                    <div className="row">
                      <div className="form-group col-12">
                        <label htmlFor="announcement_desc">
                          {t("text_announcement")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <textarea
                          id="announcement_desc"
                          type="text"
                          className="form-control mt-2"
                          name="announcement_desc"
                          value={state.announcement_desc}
                          onChange={handleChange}
                        >
                          {state.announcement_desc}
                        </textarea>
                        <label
                          htmlFor="announcement_desc"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.announcementDescErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.announcementDescErr}
                        </label>
                      </div>
                    </div>
                    <div className="form-group  mt-3  d-flex justify-content-center text-center">
                      <input
                        type="submit"
                        value="Submit"
                        name="submit"
                        className={`btn btn-lightpink ${
                          demouser === "1" && "disabled"
                        }`}
                        onClick={demouser === "0" && handleSubmit}
                      />
                      <NavLink to="/admin/announcement">
                        <input
                          type="submit"
                          value="cancel"
                          name="cancel"
                          className=" btn btn-secondary ms-1"
                        />
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditAnnouncement;
