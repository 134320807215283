import { React, useEffect } from "react";
import { Announcement_data } from "../../../../store/Action/UserFatchData";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Announcement = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Announcement_data());
  }, [dispatch]);
  var data = res.data;

  const max = Math.max.apply(
    null,
    data.announcement && data.announcement.map((i) => i.announcement_id)
  );

  var ann_desc =
    data.announcement &&
    data.announcement.map((data) => {
      if (data.announcement_id == max) {
        return data.announcement_desc;
      }
    });

  return (
    <>
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <span className="btn btn-lightpink my-2 p-1 d-block">
          <div className="text-start">
            <strong>{t("text_announcement")}:</strong>
          </div>
          <div className="text-start">{ann_desc}</div>
        </span>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.announcement,
});

export default connect(mapStateToProps)(Announcement);
