import { React, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import coin from "../../../assets/images/coin.png";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { t } from "i18next";

const Description = ({ props }) => {
  var user = JSON.parse(localStorage.getItem("user"));
  var { id } = useParams();

  // manage state
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState({
    match_id: id,
    member_id: user.member_id,
    pubg_id: "",
    match_join_member_id: props.join_position.length
      ? props.join_position[0].match_join_member_id
      : "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //update the data
  const handleUpdate = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setUpdate({
      ...update,
      [name]: value,
    });
  };
  const updateData = async (e) => {
    var response = await fetch(`/api/change_player_name`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(update),
    });

    var res_data = await response.json();
    if (res_data) {
      handleClose();
      window.location.reload();
    }
  };
  return (
    <>
      <Modal show={show} onHide={handleClose} className="mx-auto">
        <Modal.Header className="modal-header bg-modal text-center d-block text-dark">
          <Button
            className="btn "
            onClick={handleClose}
            style={{
              width: "10px",
              textAlign: "center",
              border: "1px solid gray",
              borderRadius: "50%",
              background: "transparent",
              color: "black",
              float: "right",
            }}
          >
            ×
          </Button>
          <h5 className="modal-title">{t("text_edit_player_name")}</h5>
        </Modal.Header>
        <Modal.Body className="mb-0 bg-white">
          <div className="modal-body text-black pb-0">
            <input
              type="text"
              id="pubg_id"
              name="pubg_id"
              className="form-control valid"
              value={update.pubg_id}
              onChange={handleUpdate}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-primary" onClick={updateData}>
            {t("text_btn_save")}
          </Button>
          <Button className="btn-gray" onClick={handleClose}>
            {t("text_btn_close")}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex flex-wrap justify-content-xl-between">
        <div className="border-md-right flex-grow-1 p-3">
          {/* match name */}
          <h6 className="text-lightgreen">
            {props.match.match_name} {t("text_for_macth_id")}
            {props.match.m_id}
          </h6>
          {/* team */}
          <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
            &nbsp;
            {t("text_team")} : <strong> {props.match.type}</strong>
          </span>
          {/* entry fees */}
          <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
            &nbsp;
            {t("text_entry_fee")}:&nbsp;
            <strong>
              &nbsp;
              <i style={{ color: "#000" }}>
                <img src={coin} alt="coin-img" className="coin" />
              </i>
              &nbsp;
              {props.match.entry_fee}&nbsp;
            </strong>
          </span>
          {/* match type */}
          <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
            {t("text_match_type")}: <strong> {t("text_free")} </strong>
          </span>
          {/* map */}
          <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
            &nbsp;
            {t("text_map")} : <strong> {props.match.MAP} </strong>
          </span>
          {/* match schedule */}
          <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
            &nbsp;
            {t("text_match_schedule")} :&nbsp;
            <strong> {props.match.match_time} </strong>
          </span>
          {/* table */}
          <div className="f-result mt-3 shadow-sm rounded-0">
            <table className="table ">
              <thead className="btn-green">
                <tr>
                  <th>{t("text_team")}</th>
                  <th>{t("text_position")}</th>
                  <th>{t("text_player_name")}</th>
                </tr>
              </thead>
              <tbody>
                {props.join_position.map((data, index) => (
                  <tr key={index}>
                    <td>
                      {t("text_team")}
                      {data.team}
                    </td>
                    <td>{data.position}</td>
                    <td>
                      <input
                        type="hidden"
                        name="match_join_member_id"
                        value="1"
                      />
                      <div
                        className="modal-div"
                        data-toggle="modal"
                        data-target="#myModal"
                        data-id="gopu"
                        style={{ cursor: "pointer" }}
                      >
                        {"   "}
                        {data.pubg_id}
                        <span onClick={handleShow} className="no-underline">
                          <i
                            className="fa fa-pencil"
                            style={{ color: "black", paddingLeft: "10px" }}
                          ></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* prize details */}
          <h6 className="text-lightgreen"> {t("text_prize_details")}</h6>
          {/* winning prize */}
          <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
            &nbsp;
            {t("text_winning_prize")} :&nbsp;
            <strong>
              &nbsp;
              <i style={{ color: "#000" }}>
                <img src={coin} alt="coin-img" className="coin" />
              </i>
              &nbsp;
              {props.match.win_prize}&nbsp;
            </strong>
          </span>
          {/* per kill */}
          <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
            &nbsp;
            {t("text_per_kill")} :&nbsp;
            <strong>
              &nbsp;
              <i style={{ color: "#000" }}>
                <img src={coin} alt="coin-img" className="coin" />
              </i>
              &nbsp;
              {props.match.per_kill}&nbsp;
            </strong>
          </span>
          {/* match sponsor */}
          {props.match.match_sponsor && (
            <>
              <h6 className="text-lightgreen text-dark">
                {t("text_match_sponser")}
              </h6>
              <span className="text-dark">
                {ReactHtmlParser(props.match.match_sponsor)}
              </span>
            </>
          )}
          {/* match description */}
          <h6 className="text-lightgreen text-dark">{t("text_about_match")}</h6>
          <span className="text-dark">
            {ReactHtmlParser(props.match.match_desc)}
          </span>
        </div>
      </div>
    </>
  );
};

export default Description;
