import React from "react";
import { t } from "i18next";

const HowToPlay = ({ props }) => {
  return (
    <>
      <section className="bm-section-padding bm-dark-bg" id="howtoplay">
        <div className="container">
          <h6 className="section-title bm_section_title">
            {t("text_howtoplay")}
          </h6>
          <p className="bm_section_subtitle text-center bm_mb30">
            {t("text_begin_your_game_now")}
          </p>
          {props.htp_contents.map((data, index) => {
            if ((index + 1) % 2 !== 0) {
              return (
                <div key={index}>
                  <div className="bm-img-text-box">
                    <div className="row left-img d-flex">
                      <div className="col-lg-6 col_left text-center">
                        <div className="img-wrapper">
                          <img
                            src={data.htp_content_image}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 m-auto col_right">
                        <div className="text-wrapper">
                          <h4>{data.htp_content_title}</h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data.htp_content_text,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <div className="bm-img-text-box">
                    <div className="row right-img d-flex">
                      <div className="col-lg-6 m-auto col_left">
                        <div className="text-wrapper">
                          <h4>{DataTransferItem.htp_content_title}</h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data.htp_content_text,
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="col-lg-6 col_right text-center">
                        <div className="img-wrapper">
                          <img
                            src={data.htp_content_image}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </section>
    </>
  );
};

export default HowToPlay;
