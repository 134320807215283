import { React, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  PageData,
  all_language,
  Logout,
} from "../../../store/Action/FatchData";
import { connect } from "react-redux";
import bm_logo from "../../assets/images/bm_logo.png";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";

function Header({ dispatch, res, res_page, path }, subHeader) {
  var is_logedin = localStorage.getItem("login_status");
  useEffect(() => {
    dispatch(all_language());
    dispatch(PageData());
  }, [dispatch]);
  const data = res.data.supported_language;
  const page_data = res_page.data.page;

  const arr = [];
  if (page_data) {
    page_data.map((data) => {
      if (data.page_order == 0 && data.parent == 9) {
        return arr.push(data);
      }
    });
  }

  const { t, i18n } = useTranslation([
    "translation",
    "translation1",
    "translation2",
  ]);

  return (
    <>
      {res.loading ? (
        <div className="loader">
          <Spinner />
        </div>
      ) : (
        <>
          <nav className="navbar navbar-expand-lg navbar-dark bm-dark-bg shadow fixed-top">
            <div className="container">
              <NavLink className="navbar-brand" to="/">
                <img src={bm_logo} alt="logo" />
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ms-auto">
                  {page_data &&
                    page_data.map((data, index) => {
                      return data.page_order != 0 && data.parent == 0 ? (
                        <li className="nav-item" key={index}>
                          <NavLink
                            exact
                            className="nav-link"
                            to={
                              data.page_slug == "home"
                                ? "/"
                                : `/${data.page_slug}`
                            }
                          >
                            {data.page_menutitle}
                          </NavLink>
                        </li>
                      ) : (
                        data.page_order != 0 && data.parent == 9 && (
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              id="navbarDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {data.page_menutitle}
                            </a>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <li>
                                <NavLink
                                  className="dropdown-item"
                                  to={data.page_slug}
                                >
                                  {data.page_menutitle}
                                </NavLink>
                              </li>
                              {arr.map((data, index) => {
                                return (
                                  <li key={index}>
                                    <NavLink
                                      className="dropdown-item"
                                      to={data.page_slug}
                                    >
                                      {data.page_menutitle}
                                    </NavLink>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        )
                      );
                    })}
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {t("text_account")}
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      {!is_logedin ? (
                        <>
                          <li>
                            <NavLink className="dropdown-item" to="/login">
                              {t("text_login")}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              className="dropdown-item"
                              to="/registration"
                            >
                              {t("text_register")}
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <NavLink className="dropdown-item" to="/accounts">
                              {t("text_dashboard")}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              className="dropdown-item"
                              to="/login"
                              onClick={Logout}
                            >
                              {t("text_logout")}
                            </NavLink>
                          </li>
                        </>
                      )}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {t("text_language")}
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      {Object.keys(data).map((key, index) => {
                        return (
                          <li key={index}>
                            <a
                              className="dropdown-item pointer"
                              onClick={() => i18n.changeLanguage(key)}
                            >
                              {data[key]}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          {subHeader &&
            page_data &&
            page_data.map((data, index) => {
              return (
                "/" + data.page_slug == path && (
                  <div
                    className="page-header d-flex"
                    key={index}
                    style={{
                      backgroundImage: `url(${data.page_banner_image})`,
                    }}
                  >
                    <div className="black-overlay"></div>
                    <div className="container m-auto">
                      <div className="row align-items-center">
                        <div className="col-md-12 text-center">
                          <h1 className="text-uppercase text-white">
                            {data.page_title}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
        </>
      )}
    </>
  );
}

// redux connect
const mapStateToProps = (state) => ({
  res: state.all_language,
  res_page: state.PageData,
});

export default connect(mapStateToProps)(Header);
