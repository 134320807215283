import { React, useState } from "react";
import UserHeader from "../../theme/userHeader";
import { useHistory, NavLink } from "react-router-dom";
import Title from "../../../../views/common/Helmet";
import { t } from "i18next";

const AddCurrency = () => {
  //get the id from parameter
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [state, setState] = useState({
    currency_name: "",
    currency_code: "",
    currency_symbol: "",
    currency_decimal_place: "",
  });

  const handleCurrency = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // form validation
  function handleFormValidation() {
    const { currency_name, currency_code, currency_symbol } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!currency_name) {
      formIsValid = true;
      formErrors["currencyNameErr"] = t("translation2:err_currency_name_req");
    }
    if (!currency_code) {
      formIsValid = true;
      formErrors["currencyCodeErr"] = t("translation2:err_currency_code_req");
    }
    if (!currency_symbol) {
      formIsValid = true;
      formErrors["currencySymbolErr"] = t(
        "translation2:err_currency_symbol_req"
      );
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    // insert currency
    var response = await fetch(`/api/insert_currency`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      history.push("/admin/currency", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_currency")} />
      {/* Header */}
      <UserHeader
        title={t("text_currency")}
        path={"/admin/currency"}
        icon={<i className="fa fa-eye"></i>}
        btnText={t("text_view_currency")}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            {/* card-header */}
            <div className="card-header">
              <strong>{t("text_currency")}</strong>
            </div>
            <div className="card-body">
              <form method="POST" id="validate">
                <div className="row">
                  {/* currency name */}
                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="currency_name" className="mb-3">
                      {t("currency_name")}
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <input
                      id="currency_name"
                      type="text"
                      className="form-control"
                      name="currency_name"
                      value={state.currency_name}
                      onChange={handleCurrency}
                    />
                    <label
                      htmlFor="currency_name"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.currencyNameErr
                          ? " d-block"
                          : "d-none")
                      }
                    >
                      {state1.formErrors.currencyNameErr}
                    </label>
                  </div>
                  {/* currency code */}
                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="currency_code" className="mb-3">
                      {t("currency_code")}
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <input
                      id="currency_code"
                      type="text"
                      className="form-control"
                      name="currency_code"
                      value={state.currency_code}
                      onChange={handleCurrency}
                    />
                    <label
                      htmlFor="currency_code"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.currencyCodeErr
                          ? " d-block"
                          : "d-none")
                      }
                    >
                      {state1.formErrors.currencyCodeErr}
                    </label>
                  </div>
                </div>
                {/* currency symbol */}
                <div className="row">
                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="currency_symbol" className="mb-3">
                      {t("currency_symbol")}
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <input
                      id="currency_symbol"
                      type="text"
                      className="form-control"
                      name="currency_symbol"
                      value={state.currency_symbol}
                      onChange={handleCurrency}
                    />
                    <label
                      htmlFor="currency_symbol"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.currencySymbolErr
                          ? " d-block"
                          : "d-none")
                      }
                    >
                      {state1.formErrors.currencySymbolErr}
                    </label>
                  </div>
                  {/* decimal places */}
                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="currency_decimal_place" className="mb-3">
                      {t("text_decimal_places")}
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <input
                      id="currency_decimal_place"
                      type="text"
                      className="form-control"
                      name="currency_decimal_place"
                      value={state.currency_decimal_place}
                      onChange={handleCurrency}
                    />
                  </div>
                </div>
                {/* submit */}
                <div className="form-group d-flex justify-content-center mb-3">
                  <input
                    type="submit"
                    value="Submit"
                    name="submit"
                    className={`btn btn-lightpink ${
                      demouser === "1" && "disabled"
                    }`}
                    onClick={demouser === "0" && handleSubmit}
                  />
                  {/* cancel */}
                  <NavLink
                    className="btn btn-secondary ms-1 d-block"
                    to="/admin/currency"
                    name="cancel"
                  >
                    {t("text_btn_cancel")}
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCurrency;
