import { React, useEffect } from "react";
import { GetImage } from "../../../../store/Action/AdminData";
import { connect } from "react-redux";

const Select2 = ({ dispatch, res, onHandle }) => {
  const $ = window.$;

  useEffect(() => {
    dispatch(GetImage());
  }, [dispatch]);
  if (res.loading) return false;
  const data = res.data;

  function formatState(opt) {
    if (!opt.id) {
      return opt.text;
    }

    var optimage = $(opt.element).attr("data-image");

    if (!optimage) {
      return opt.text.toUpperCase();
    } else {
      var $opt = $(
        '<span><img src="' +
          optimage +
          '" width="60px" /> ' +
          opt.text +
          "</span>"
      );
      return $opt;
    }
  }

  $(document).ready(function () {
    window
      .$(".js-example-basic-single")
      .select2({
        templateResult: formatState,
        templateSelection: formatState,
      })
      .on("change", function (e) {
        var getID = $(this).select2("data");
        onHandle(getID[0].id);
      });
  });

  return (
    <>
      <select
        name="image_id"
        style={{ width: "100%" }}
        className=" js-example-basic-single mt-3"
      >
        <option></option>
        {data.img.map((data, index) => {
          return (
            <option
              value={data.image_id}
              data-image={data.image_name}
              key={index}
            >
              {data.image_title}
            </option>
          );
        })}
      </select>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.getImage,
});

export default connect(mapStateToProps)(Select2);
