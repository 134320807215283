import { React } from "react";
import { Paystack_data } from "../../../../store/Action/UserFatchData";
import { useLocation, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { PaystackButton } from "react-paystack";
import MainTheme from "../../theme/MainTheme";
import { connect } from "react-redux";

const PayStack = ({ dispatch, res }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  let location = useLocation();
  const history = useHistory();
  const value = Math.round(location.state.state.data.TXN_AMOUNT);
  console.log(location);

  // success notification
  const notifyS = (data) => toast.success(data);
  const notifyE = (data) => toast.error(data);

  const config = {
    reference: new Date().getTime().toString(),
    email: user.email_id,
    amount: value,
    publicKey: location.state.state.data.data.public_key,
  };

  // on success payment
  const handlePaystackSuccessAction = (reference) => {
    let data = {
      reference: reference.trxref,
      amount: value,
    };
    //PayStack response Api
    dispatch(Paystack_data(data));
    if (res.data.status === true) {
      notifyS(res.data.message);
      setTimeout(() => {
        history.push("/account/my_wallet");
      }, 3000);
    } else {
      notifyE(res.data.message);
      setTimeout(() => {
        history.push("/account/my_wallet");
      }, 3000);
    }
  };

  // on failed payment
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    alert("closed");
  };

  const componentProps = {
    ...config,
    text: "Pay Now",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  return (
    <>
      {/* toastify */}
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <MainTheme title="PayStack" backPath>
        <div>
          <h1 className="text-dark text-center py-4">
            Please Do not refresh the page...
          </h1>
          <div className="text-center">
            <PaystackButton {...componentProps} />
          </div>
        </div>
      </MainTheme>
    </>
  );
};

//redux connect
const mapStateToProps = (state) => ({
  res: state.paystack,
});

export default connect(mapStateToProps)(PayStack);
