import Header from "../theme/header";
import Footer from "../theme/footer";
import { GetCountry, RegistrationApi } from "../../../store/Action/FatchData";
import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import Title from "../../common/Helmet";
import { Link } from "react-router-dom";
import { t } from "i18next";

function Registration({ dispatch, country_data, registration_data }) {
  // manage state
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [input, setInput] = useState({});
  useEffect(() => {
    dispatch(GetCountry());
  }, [dispatch]);
  if (country_data.loading) return false;
  const allCountryData = country_data.data;
  const handleInput = (event) => {
    var name = event.target.name;
    var value = event.target.value;
    setInput((values) => ({ ...values, [name]: value }));
  };

  // form validation
  function handleFormValidation() {
    const { UserName, Email, MobileNo, CountryCode, Password, CPassword } =
      input;
    let formErrors = {};
    let formIsValid = true;
    if (!UserName) {
      formIsValid = true;
      formErrors["UserName"] = t("translation2:err_user_name_req");
    }
    if (!Email) {
      formIsValid = true;
      formErrors["Email"] = t("translation2:err_email_id_req");
    }
    if (!MobileNo) {
      formIsValid = true;
      formErrors["MobileNo"] = t("translation2:err_mobile_no_req");
    }
    if (!CountryCode) {
      formIsValid = true;
      formErrors["CountryCode"] = t("translation2:err_p_code_req");
    }
    if (!Password) {
      formIsValid = true;
      formErrors["Password"] = t("translation2:err_password_req");
    }
    if (!CPassword) {
      formIsValid = true;
      formErrors["CPassword"] = t("translation2:err_new_password_req");
    }
    if (Password && Password.length < 6) {
      formIsValid = true;
      formErrors["Password"] = t("translation2:err_password_min");
    }
    if (CPassword && CPassword.length < 6) {
      formIsValid = true;
      formErrors["CPassword"] = t("translation2:err_password_min");
    }
    if (
      Password &&
      CPassword &&
      CPassword.length >= 6 &&
      Password.length >= 6 &&
      Password != CPassword
    ) {
      formIsValid = true;
      formErrors["CPassword"] = t("translation2:err_password_equal");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  // handle submit
  const handleSubmit = (e) => {
    let register_params = {
      user_name: input.UserName ? input.UserName : "",
      password: input.Password ? input.Password : "",
      cpassword: input.CPassword ? input.CPassword : "",
      mobile_no: input.MobileNo ? input.MobileNo : "",
      email_id: input.Email ? input.Email : "",
      country_code: input.CountryCode ? input.CountryCode : "",
      promo_code: input.PromoCode ? input.PromoCode : "",
      submit: "register",
    };
    e.preventDefault();
    handleFormValidation();
    dispatch(RegistrationApi(register_params));
  };

  return (
    <>
      <Title props={t("Registration")} />
      <Header />
      <div className="page-header d-flex">
        <div className="black-overlay"></div>
        <div className="container m-auto">
          <div className="row align-items-center">
            <div className="col-md-12 text-center">
              <h1 className="text-uppercase text-dark">{t("Registration")}</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="bm-light-bg text-dark">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col_right mx-auto">
              <div className="card cnt-card my-5">
                <div className="card-body">
                  {/* registration form start */}
                  <form id="register-form">
                    {/* username */}
                    <div className="form-group mb-3">
                      <label>
                        {t("text_user_name")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        name="UserName"
                        type="text"
                        className="form-control"
                        value={input.UserName}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="UserName"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.UserName ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.UserName}
                      </label>
                    </div>

                    {/* country-code with country-name */}
                    <div className="form-group mb-3">
                      <label>
                        {t("text_country_code")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <select
                        name="CountryCode"
                        className="form-control dropdown-toggle"
                        value={input.CountryCode}
                        onChange={handleInput}
                      >
                        <option value="">Select</option>
                        {allCountryData.all_country.map((country, index) => {
                          return (
                            <option value={country.p_code} key={index}>
                              {country.p_code +
                                " (" +
                                country.country_name +
                                ")"}
                            </option>
                          );
                        })}
                      </select>
                      <label
                        htmlFor="CountryCode"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.CountryCode
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.CountryCode}
                      </label>
                    </div>

                    {/* email */}
                    <div className="form-group mb-3">
                      <label>
                        {t("text_email")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        name="Email"
                        type="text"
                        className="form-control"
                        value={input.Email}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="Email"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.Email ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.Email}
                      </label>
                    </div>

                    {/* mobile-no */}
                    <div className="form-group mb-3">
                      <label>
                        {t("text_mobile_no")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        name="MobileNo"
                        type="text"
                        className="form-control"
                        value={input.MobileNo}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="MobileNo"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.MobileNo ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.MobileNo}
                      </label>
                    </div>

                    {/* promo code */}
                    <div className="form-group mb-3">
                      <label>
                        {t("text_promo_code")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        name="PromoCode"
                        type="text"
                        className="form-control"
                        value={input.PromoCode}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="PromoCode"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.PromoCode ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.PromoCode}
                      </label>
                    </div>

                    {/* password */}
                    <div className="form-group mb-3">
                      <label>
                        {t("text_password")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        name="Password"
                        type="password"
                        className="form-control"
                        value={input.Password}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="Password"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.Password ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.Password}
                      </label>
                    </div>
                    {/* confirm password */}
                    <div className="form-group mb-3">
                      <label>
                        {t("text_enter_confirm_password")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        name="CPassword"
                        type="password"
                        className="form-control"
                        value={input.CPassword}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="CPassword"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.CPassword ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.CPassword}
                      </label>
                    </div>
                    {/* submit */}
                    <div className="form-group my-3">
                      <input
                        className="btn btn-submit btn-block btn-lg btn-lightpink form-control"
                        type="submit"
                        onClick={handleSubmit}
                      />
                    </div>
                    {/* already have account? */}
                    <div className="pt-2 text-center mb-3">
                      {t("You have an Account ?")}
                      <Link to="/login" className="bm_link_lightpink">
                        {t("text_sign_in")}
                      </Link>
                    </div>
                  </form>
                  {/* registration form end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

// redux connect
const mapStateToProps = (state) => ({
  country_data: state.get_all_country,
  registration_data: state.registration,
});

export default connect(mapStateToProps)(Registration);
