import { React, useState, useEffect } from "react";
import UserHeader from "../theme/userHeader";
import { GetMemberList } from "../../../store/Action/AdminData";
import { connect } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import Title from "../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Notification = ({ dispatch, res }) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));
  const location = useLocation();

  // manage state
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [select, setSelect] = useState(false);
  const [select1, setSelect1] = useState(false);
  const [member, setMember] = useState({
    start: "",
    end: "",
  });
  const [state, setState] = useState({
    notification_title: "Welcome to Battlemania",
    message: "Battlemania push notification test",
    url: "",
    notification_image: "",
    send_to: "",
  });

  // member list
  useEffect(() => {
    dispatch(GetMemberList());
  }, [dispatch]);
  const data = res.data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // handle Member
  const handleMember = (e) => {
    const { name, value } = e.target;
    var start = "";
    var end = "";
    if (name == "start") {
      start = value;
    } else if (name == "end") {
      end = value;
    }
    console.log(end);

    var total = [];
    let i;
    for (i = start; i <= end; i++) {
      total.push(i);
    }
    console.log("w", total);
    // setMember({
    //   ...member,
    //   [name]: value
    // })
    setState({
      ...state,
      member: total,
    });
  };
  console.log(member, "sbdsf");

  // handle image file
  const handleFile = (e) => {
    const file = e.target.files[0];
    setState({
      ...state,
      notification_image: file,
    });
  };

  // submit
  const handleSubmit = async (e) => {
    const data = {
      ...state,
    };
    console.log("abcde", data);
    var formData = new FormData();
    formData.append("notification_title", data.notification_title);
    formData.append("message", data.message);
    formData.append("url", data.url);
    formData.append("notification_image", data.notification_image);
    formData.append("send_to", data.send_to);
    data.member && formData.append("member", data.member);
    var response = await fetch(`/api/push_notification`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      setShow(true);
      setMessage(res_data.message);
    }
  };

  // select options
  const handleSelect = (e) => {
    console.log(e.target.value);
    if (e.target.value == "all") {
      setSelect(false);
      setSelect1(false);
      setState({
        ...state,
        send_to: "",
      });
    }
    if (e.target.value == "single_member") {
      setSelect(true);
      setSelect1(false);
      setState({
        ...state,
        send_to: "single_member",
      });
    } else if (e.target.value == "multi_member") {
      setSelect1(true);
      setSelect(false);
      setState({
        ...state,
        send_to: "multi_member",
      });
    } else {
      setSelect(false);
      setSelect1(false);
    }
  };

  return (
    <>
      <Title props={t("text_one_signal_notification")} />
      {/* Header */}
      <UserHeader title={t("text_one_signal_notification")} />
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <>
          {/* message */}
          <div
            className={
              "alert alert-success alert-dismissible fade show " +
              (show ? "d-block" : "d-none")
            }
            role="alert"
          >
            {location.state && !message ? (
              <span>{location.state.state.data}</span>
            ) : (
              <span></span>
            )}
            {!location.state && message ? (
              <span>{message}</span>
            ) : (
              <span></span>
            )}
            {location.state && message ? <span>{message}</span> : <span></span>}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShow(false)}
            ></button>
          </div>
          <div className="row text-dark">
            <div className="col-md-12">
              <div className="card bg-light text-dark">
                {/* card-header */}
                <div className="card-header">
                  <strong>{t("text_one_signal_notification")}</strong>
                </div>
                <div className="card-body">
                  <div className="col-md-12">
                    <form method="POST" id="validate">
                      <div className="row">
                        {/* send to */}
                        <div className="form-group col-6">
                          <label htmlFor="send_to">{t("text_send_to")}</label>
                          <select
                            name="send_to"
                            className="form-control send_to"
                            onChange={handleSelect}
                          >
                            <option defaultValue="all">
                              {t("text_all_user")}
                            </option>
                            <option value="single_member">
                              {t("text_single_member")}
                            </option>
                            <option value="multi_member">
                              {t("text_multiple_member")}
                            </option>
                          </select>
                        </div>
                        <div
                          className={
                            "form-group col-6 single_member " +
                            (select ? "d-block" : "d-none")
                          }
                        >
                          <label htmlFor="member">{t("text_member")}</label>
                          <select
                            name="member"
                            className="form-control"
                            onChange={handleChange}
                          >
                            <option value="">{t("text_select")}</option>
                            {data.member_list.map((data, index) => {
                              return (
                                <option value={data.member_id} key={index}>
                                  {data.user_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div
                          className={
                            "form-group col-6 multi_member " +
                            (select1 ? "d-block" : "d-none")
                          }
                        >
                          <label htmlFor="member">{t("text_select")}</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">From</span>
                            </div>
                            <input
                              type="number"
                              id="sendtype-2-input"
                              name="start"
                              className="form-control text-center"
                              // value={member.start}
                              onChange={handleMember}
                            />
                            <div className="input-group-prepend">
                              <span className="input-group-text">to</span>
                            </div>
                            <input
                              type="number"
                              id="sendtype-2-input"
                              name="end"
                              className="form-control text-center"
                              // value={member.end}
                              onChange={handleMember}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">Members</span>
                            </div>
                          </div>
                          <br />
                        </div>
                        <div className="form-group col-12 mb-3">
                          <label htmlFor="notification_title">
                            {t("text_title")}
                            <span className="required" aria-required="true">
                              &nbsp;*&nbsp;
                            </span>
                          </label>
                          <input
                            id="game_rules"
                            type="text"
                            className="form-control "
                            name="notification_title"
                            value={state.notification_title}
                            onChange={handleChange}
                          />
                        </div>
                        {/* push notification message */}
                        <div className="form-group col-12 mb-3">
                          <label htmlFor="message">
                            {t("text_message")}
                            <span className="required" aria-required="true">
                              &nbsp;*&nbsp;
                            </span>
                          </label>
                          <textarea
                            id="message"
                            type="text"
                            className="form-control"
                            name="message"
                            value={state.message}
                            onChange={handleChange}
                          >
                            {t("text_push_notification")}
                          </textarea>
                        </div>
                        <div className="form-group col-6 mb-3">
                          <label htmlFor="url">{t("text_redirect_url")}</label>
                          <input
                            id="url"
                            type="text"
                            className="form-control"
                            name="url"
                            value={state.url}
                            onChange={handleChange}
                          />
                        </div>
                        {/* image */}
                        <div className="form-group col-6 mb-3">
                          <label htmlFor="notification_image">
                            {t("text_image")}
                          </label>
                          <br />
                          <input
                            id="image"
                            type="file"
                            className="file-input d-block"
                            name="notification_image"
                            style={{
                              padding: "none",
                              borderRadius: "none",
                              border: "none",
                            }}
                            onChange={handleFile}
                          />
                        </div>
                      </div>
                      {/* submit */}
                      <div className="form-group d-flex justify-content-center mb-3">
                        <input
                          type="button"
                          value="Submit"
                          name="submit"
                          className={`btn btn-lightpink ${
                            demouser === "1" && "disabled"
                          }`}
                          onClick={demouser === "0" && handleSubmit}
                        />
                        {/* cancel */}
                        <NavLink
                          className="btn btn-secondary ms-1 d-block"
                          to="/admin/notification"
                          name="cancel"
                        >
                          {t("text_btn_cancel")}
                        </NavLink>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.GetMemberList,
});

export default connect(mapStateToProps)(Notification);
