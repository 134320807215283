import { React, useEffect } from "react";
import { TotalReceivedAmount } from "../../../store/Action/AdminData";
import { connect } from "react-redux";
import coin from "../../assets/images/coin.png";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const TotalReceivedAmounts = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(TotalReceivedAmount());
  }, [dispatch]);
  const data = res.data;

  return (
    <>
      {res.loading ? (
        <div className="loader">
          <Spinner />
        </div>
      ) : (
        <>
          {/* Total Received Amount */}
          <div className="col-lg-3 col-md-6 dash-box pointer">
            <div className="bg-lightblue small-box card card-sm-3">
              <div className="card-icon ">
                <i className="fa fa-credit-card"></i>
              </div>
              <div className="card-wrap">
                <div className="card-header">
                  <h4>{t("text_received_payment")}</h4>
                </div>
                <div className="card-body">
                  <img src={coin} alt="coin-img" className="coin" />
                  &nbsp;
                  {data.received_amount[0].total_payment}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.TotalReceivedAmount,
});

export default connect(mapStateToProps)(TotalReceivedAmounts);
