import { React, useEffect, useState } from "react";
import Title from "../../../../../common/Helmet";
import MainTheme from "../../../../theme/MainTheme";
import { connect } from "react-redux";
import { Join_Match } from "../../../../../../store/Action/UserFatchData";
import coin from "../../../../assets/images/coin.png";
import { Link, useLocation, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";

const JoiningPosition = ({ dispatch, res_join }) => {
  //   get User Details from Local Storage
  const user = JSON.parse(localStorage.getItem("user"));
  var location = useLocation();
  const history = useHistory();

  const [inputValues, setInputValues] = useState({
    0: "",
    1: "",
    2: "",
    3: "",
  });

  function handleChange(e) {
    const value = e.target.value;
    setInputValues({
      ...inputValues,
      [e.target.name]: value,
    });
  }
  const get_team_position_pubgid = location.state.selectedposition.map(
    (data, index) => ({
      team: data.split(/[-]/)[0],
      position: data.split(/[-]/)[1],
      pubg_id: inputValues[index],
    })
  );
  //join match data
  const jsonData = {
    submit: "joinnow",
    match_id: location.state.match_id,
    member_id: user.member_id,
    join_status: location.state.joined,
    teamposition: get_team_position_pubgid,
  };
  console.log("json", jsonData);
  // join match api call
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(Join_Match(jsonData));
  };
  const data1 = res_join.data;
  function notifyS(data1) {
    toast.success(data1);
  }
  const notifyE = (data1) => toast.error(data1);
  console.log("as", data1);
  if (data1.status == true) {
    notifyS(data1.message);
    setTimeout(() => {
      history.push("/play");
    }, 3000);
  } else {
    notifyE(data1.message);
  }

  return (
    <>
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Title props={t("text_joining_position")} />
      <MainTheme>
        <div className="row text-black p-2">
          <div className="col-12">
            <div className="row d-flex mb-3">
              <div
                className="col-3 m-auto bm_text_lightgreen"
                style={{ height: "100%" }}
              >
                <i
                  className="fa fa-google-wallet"
                  style={{ fontSize: "50px" }}
                ></i>
              </div>
              <div className="col-9 text-end">
                <span className="d-block">
                  {t("text_your_curr_balance")} :&nbsp;
                  <strong>
                    &nbsp;
                    <i>
                      <img src={coin} className="coin" alt="img-coin" />
                    </i>
                    &nbsp;
                    {user.wallet_balance + user.join_money}
                  </strong>
                </span>
                <span className="d-block">
                  {t("text_match_entry_per_per")} :&nbsp;
                  <strong>
                    &nbsp;
                    <i>
                      <img src={coin} className="coin" alt="img-coin" />
                    </i>
                    &nbsp;
                    {location.state.match_fees}&nbsp;
                  </strong>
                </span>
                <span className="d-block">
                  {t("text_tot_payable_amt")} :&nbsp;
                  <strong>
                    &nbsp;
                    <i>
                      <img src={coin} className="coin" alt="img-coin" />
                    </i>
                    &nbsp;
                    {location.state.match_fees *
                      location.state.selectedposition.length}
                    &nbsp;
                  </strong>
                </span>
              </div>
            </div>
            <div className=" my-5">
              <form
                className="profile-form"
                method="post"
                name="position-form"
                id="position-form"
              >
                <table
                  className="table tr-bordered bg-white box-shadow"
                  style={{ tableLayout: "fixed" }}
                >
                  <caption
                    className="btn-green text-white text-center"
                    style={{
                      captionSide: "top",
                    }}
                  >
                    {t("text_select_position")}
                  </caption>
                  <tbody>
                    <tr className="thead-dark text-white">
                      <th className="bg-black bg-opacity-75 p-3 w-10">#</th>
                      <th className="bg-black bg-opacity-75 p-3 w-15">
                        {t("text_team")}
                      </th>
                      <th className="bg-black bg-opacity-75 p-3 w-20">
                        {t("text_position")}
                      </th>
                      <th className="bg-black bg-opacity-75 p-3 w-50">
                        {location.state.game_name}
                      </th>
                    </tr>
                    {location.state.selectedposition.map((data, index) => {
                      return (
                        <tr
                          key={index}
                          className="bg-white text-black text-center"
                        >
                          <td className="p-2">
                            <input
                              type="hidden"
                              name="no[]"
                              value={index + 1}
                            />
                            {index + 1}
                          </td>
                          <td className="p-2">
                            &nbsp;
                            <input
                              type="hidden"
                              name="position_1[]"
                              value={data.split(/[-]/)[0]}
                            />
                            {t("text_team")} {data.split(/[-]/)[0]}
                          </td>
                          <td className="p-2">
                            <input
                              type="hidden"
                              name="position_1[]"
                              value={data.split(/[-]/)[1]}
                            />
                            {data.split(/[-]/)[1]}
                          </td>
                          <td className="p-2">
                            <input
                              type="text"
                              className="game_id  form-control border-bottom rounded-0 "
                              name={index}
                              value={inputValues.name}
                              onChange={handleChange}
                              placeholder=""
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {res_join.message && (
                  <div className="text-sm text-lightpink my-5 text-center">
                    {res_join.message}
                  </div>
                )}
                <div className="flex items-center justify-evenly">
                  <button className="btn btn-lightpink">
                    {/* cancel button  */}
                    <Link
                      to="/play"
                      className="no-underline text-white text-uppercase"
                    >
                      {t("text_btn_cancel")}
                    </Link>
                  </button>

                  <button
                    className="btn btn-lightgreen text-white text-uppercase ms-2"
                    onClick={handleSubmit}
                  >
                    {/* join button  */}
                    <span
                      // to="/play"

                      className="no-underline text-white text-uppercase"
                    >
                      {t("text_btn_join")}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </MainTheme>
    </>
  );
};
// redux connect
const mapStateToProps = (state) => ({
  res_join: state.join_match,
});
export default connect(mapStateToProps)(JoiningPosition);
