// Redux Action for main side

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//notify when login success
const notify = (data) => toast.success(data);
const notifyErr = (data) => toast.error(data);

// demouser
export function DemoUser() {
  return async (dispatch) => {
    var response = await fetch("/api/version/android");
    var res_data = await response.json();
    var return_response = {
      type: "GET_DEMOUSER",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Home
export function HomeFatch() {
  return async (dispatch) => {
    var response = await fetch("/api/home_page");
    var res_data = await response.json();
    var return_response = {
      type: "HOME_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// How to Install
export function HowToInstall() {
  return async (dispatch) => {
    var response = await fetch("/api/how_to_install_page");
    var res_data = await response.json();
    var return_response = {
      type: "HOW_TO_INSTALL",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// All Language
export function all_language() {
  return async (dispatch) => {
    var response = await fetch("/api/all_language");
    var res_data = await response.json();
    var return_response = {
      type: "All_LANGUAGE",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
// page
export function PageData() {
  return async (dispatch) => {
    var response = await fetch("/api/page_data");
    var res_data = await response.json();
    var return_response = {
      type: "PAGE_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
// Footer
export function FooterData() {
  return async (dispatch) => {
    var response = await fetch("/api/footer_detail");
    var res_data = await response.json();
    var return_response = {
      type: "FOOTER_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Country
export function GetCountry() {
  return async (dispatch) => {
    var response = await fetch("/api/all_country");
    var res_data = await response.json();
    var return_response = {
      type: "GET_COUNTRY_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// registration
export function RegistrationApi(data) {
  return async (dispatch) => {
    var response = await fetch("/api/registrationAcc", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    if (res_data.status) {
      window.location.href = "/login";
    } else {
      var return_response = {
        type: "REGISTRATION_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    }
  };
}

// Login
export function LoginApi(data) {
  return async (dispatch) => {
    var response = await fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      localStorage.setItem("data", JSON.stringify(res_data));
      localStorage.setItem("login_status", JSON.stringify(res_data.status));
      localStorage.setItem("view_status", 0);
      localStorage.setItem("user", JSON.stringify(res_data.message));
      localStorage.setItem(
        "api_token",
        JSON.stringify(res_data.message.api_token).replace(/['"]+/g, "")
      );
      notify(res_data.message);
      window.location.href = "/accounts";
    }
    console.log("data", data);
    if (
      data.user_name != "" &&
      data.password != "" &&
      res_data.status == false
    ) {
      notifyErr(res_data.message);
    }
    var return_response = {
      type: "LOGIN_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

export function GoogleLoginApi(data) {
  return async (dispatch) => {
    var response = await fetch("/api/registerGoogle", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      localStorage.setItem("login_status", JSON.stringify(res_data.status));
      localStorage.setItem("user", JSON.stringify(res_data.message));
      localStorage.setItem(
        "api_token",
        JSON.stringify(res_data.message.api_token).replace(/['"]+/g, "")
      );
      window.location.href = "/accounts";
    } else {
      var return_response = {
        type: "GOOGLE_LOGIN_DATA",
        payload: res_data,
      };
      notify();
      dispatch(return_response);
    }
  };
}

// Add Money
export function Add_money_data(data) {
  return async (dispatch) => {
    var response = await fetch("/api/add_money", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "ADD_MONEY_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// CheckSum
export function VerifyCheckSumApi(data) {
  return async (dispatch) => {
    var response = await fetch("/api/verifyChecksum", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "VERIFY_CHECK_SUM_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Razorpay
export function razporpayApi(data) {
  return async (dispatch) => {
    var response = await fetch("/api/razorpay_response", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "RAZPORPAY_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Paypal
export function paypalApi(data) {
  return async (dispatch) => {
    var response = await fetch("/api/paypal_response", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "PAYPAL_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Cashfree
export function cashfreeApi(data) {
  return async (dispatch) => {
    var response = await fetch("/api/cashfree_response", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "CASHFREE_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//Instamojo
export function instamojoApi(data) {
  return async (dispatch) => {
    var response = await fetch("/api/instamojo_response", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "INSTAMOJO_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Logout
export function Logout() {
  localStorage.removeItem("data");
  localStorage.removeItem("login_status");
  localStorage.removeItem("view_status");
  localStorage.removeItem("user");
  localStorage.removeItem("api_token");
  window.location.href = "/login";
}
