import { useEffect, React } from "react";
import { connect } from "react-redux";
import { ParticipateMatch } from "../../../../../store/Action/UserFatchData";
import { useParams } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const JoinedMember = ({ dispatch, res }) => {
  var { id } = useParams();

  useEffect(() => {
    dispatch(ParticipateMatch(id));
  }, [dispatch, id]);
  var data = res.data;

  return (
    <>
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <Scrollbars>
          <div className="bm-content-listing text-black">
            {data.match_participate.length ? (
              // All Participate
              <ul className="list-unstyled member-list">
                {data.match_participate.map((data, index) => (
                  <li key={index}>
                    {/* index + 1 start count from 1 */}
                    {index + 1}.&nbsp; &nbsp; &nbsp;
                    {/* &nbsp; for white space */}
                    <strong className="pl-3">{data.pubg_id}</strong>
                  </li>
                ))}
              </ul>
            ) : (
              // if there is no any participates
              <p className="text-black text-center">
                {t("text_no_joined_member")}
              </p>
            )}
          </div>
        </Scrollbars>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.participate,
});

export default connect(mapStateToProps)(JoinedMember);
