// Private routes shows to only logged in users

// account
import Account from "../views/App/account/account";
import Announcement from "../views/App/account/announcement";
import LeaderBoard from "../views/App/account/leader_board";
import Top_player from "../views/App/account/top_player";
import App_Tutorial from "../views/App/account/app_tutorial";
// my pages
import MyStatistic from "../views/App/account/myPages/my_statistics";
import MyProfile from "../views/App/account/myPages/my_profile";
import My_wallet from "../views/App/account/myPages/my_wallet";
import MyMatch from "../views/App/account/myPages/my_match";
import MyOrder from "../views/App/account/myPages/my_orders";
import MyReferral from "../views/App/account/myPages/my_referral";
// others
import CustomerSupport from "../views/App/account/others/customer_support";
import Aboutus from "../views/App/account/others/aboutus";
import Terms from "../views/App/account/others/terms";
// add-withdraw
import Add from "../views/App/account/Add";
import Withdraw from "../views/App/account/withdraw";
// payment
import PayTm from "../views/App/account/payment/PayTm";
import PayPal from "../views/App/account/payment/paypal";
import PayStack from "../views/App/account/payment/PayStack";
import Instamojo from "../views/App/account/payment/instamojo";
import CashFree from "../views/App/account/payment/CashFree";
import Tron from "../views/App/account/payment/Tron";
import PayU from "../views/App/account/payment/PayU";
// earn
import Earn from "../views/App/earn/earn";
import WatchAndEarn from "../views/App/earn/WatchandEarn";
import Earndetails from "../views/App/earn/Earndetails";
import productdetail from "../views/App/buy/Productdetail";
import order_details from "../views/App/buy/order_details";
import Buyform from "../views/App/buy/Buyform";
// lottery
import luckydraw from "../views/App/lottery/Luckydraw";
import lotterydetail from "../views/App/lottery/Lotterydetail";
import Register from "../views/App/lottery/register";
// play
import Play from "../views/App/play_detail/play";
import Game_pages from "../views/App/play/game_pages";
import Result_Match from "../views/App/play/match-component/result_match";
import Ongoing_Match from "../views/App/play/match-component/ongoing_match";
import Upcoming_Match from "../views/App/play/match-component/upcoming_match";
import Position from "../views/App/play/match-component/upcoming_detail/position/position";
import product from "../views/App/buy/Buyproduct";
import Joining_Position from "../views/App/play/match-component/upcoming_detail/position/Joining_Position";
// import Error from "../views/common/Error";

const PrivateRoutes = [
  // account
  { path: "/accounts", component: Account },
  { path: "/account/my_profile", component: MyProfile },
  { path: "/account/my_match", component: MyMatch },
  { path: "/account/my_statistics", component: MyStatistic },
  { path: "/account/my_wallet", component: My_wallet },
  { path: "/account/my_orders", component: MyOrder },
  { path: "/account/my_orders/order_details/:id", component: order_details },
  { path: "/account/my_referral", component: MyReferral },
  { path: "/account/announcement", component: Announcement },
  { path: "/account/app_tutorial", component: App_Tutorial },
  { path: "/account/top_player", component: Top_player },
  { path: "/account/leader_board", component: LeaderBoard },
  { path: "/account/customer_support", component: CustomerSupport },
  { path: "/account/about-us", component: Aboutus },
  { path: "/account/terms & conditions", component: Terms },
  //add-withdraw
  { path: "/account/my_wallet/add", component: Add },
  { path: "/account/my_wallet/withdraw", component: Withdraw },
  // patment
  { path: "/account/my_wallet/add/PayTm", component: PayTm },
  { path: "/account/my_wallet/add/PayPal", component: PayPal },
  { path: "/account/my_wallet/add/PayStack", component: PayStack },
  { path: "/account/my_wallet/add/Instamojo", component: Instamojo },
  { path: "/account/my_wallet/add/Cashfree", component: CashFree },
  { path: "/account/my_wallet/add/Tron", component: Tron },
  { path: "/account/my_wallet/add/PayU", component: PayU },
  // play
  { path: "/play", component: Play },
  { path: "/play/game_pages/:id", component: Game_pages },
  { path: "/play/result_match/:id", component: Result_Match },
  { path: "/play/ongoing_match/:id", component: Ongoing_Match },
  { path: "/play/upcoming_match/:id", component: Upcoming_Match },
  { path: "/play/position/:id", component: Position },
  { path: "/play/joining_position", component: Joining_Position },
  //earn
  { path: "/refer_earn", component: Earn },
  { path: "/Refer and Earn", component: Earndetails },
  { path: "/Luckey Draw", component: luckydraw },
  { path: "/buyform/:id", component: Buyform },
  { path: "/Watch and Earn", component: WatchAndEarn },
  { path: "/Buy product", component: product },
  { path: "/Buy Product/productdetail/:id", component: productdetail },
  { path: "/luckey_Draw", component: luckydraw },
  { path: "/Luckey Draw/lotterydetail/:id", component: lotterydetail },
  { path: "/Luckey Draw/register", component: Register },
  // { path: "*", component: Error },
];

export default PrivateRoutes;
