import { React, useEffect } from "react";
import Title from "../../../views/common/Helmet";
import { connect } from "react-redux";
import { Announcement_data } from "../../../store/Action/UserFatchData";
import MainTheme from "../theme/MainTheme";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Announcement = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Announcement_data());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_announcement")} />
      <MainTheme title={t("text_announcement")} backPath footer>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          data.announcement.map((data, index) => (
            <div
              className="btn btn-sm btn-white shadow m-2 d-block "
              style={{ textAlign: "left" }}
              key={index}
            >
              <div>{data.announcement_desc}</div>
            </div>
          ))
        )}
      </MainTheme>
    </>
  );
};

//redux connect
const mapStateToProps = (state) => ({
  res: state.announcement,
});

export default connect(mapStateToProps)(Announcement);
