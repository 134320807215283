import { React, useEffect, useState } from "react";
import UserHeader from "../../theme/userHeader";
import { GetPaymentMethod } from "../../../../store/Action/AdminData";
import { connect } from "react-redux";
import { useLocation, NavLink } from "react-router-dom";
import Title from "../../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";
import { t } from "i18next";

const PaymentMethod = ({ dispatch, res }) => {
  const location = useLocation();
  const user = JSON.parse(sessionStorage.getItem("user"));

  // manage state
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({});

  useEffect(() => {
    dispatch(GetPaymentMethod());
    if (location.state) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [dispatch]);
  const data = res.data;

  //change the status
  const handleStatus = (status, id) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>Are you sure to change status?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const value = {
          status: status,
        };
        var response = await fetch(`/api/change_payment_method_status/${id}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
          body: JSON.stringify(value),
        });
        var res_data = await response.json();
        console.log(res_data);
        setShow(true);
        setMessage(res_data.message);
        window.location.reload();
      }
    });
  };

  return (
    <>
      <Title props={t("text_payment_method")} />
      <UserHeader title={t("text_payment_method")} />
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <>
          {/* <div
        className={
          "alert alert-success alert-dismissible fade show " +
          (show ? "d-block" : "d-none")
        }
        role="alert"
      >
        {location.state && !message ? (
          <span>{location.state.state.data}</span>
        ) : (
          <span></span>
        )}
        {!location.state && message ? <span>{message}</span> : <span></span>}
        {location.state && message ? <span>{message}</span> : <span></span>}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div> */}
          <div className="row">
            <div className="col-md-12 mt-3">
              <table
                className="display table table-bordered mb-2"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>{t("text_sr_no")}</th>
                    <th>{t("text_payment_name")}</th>
                    <th>{t("text_status")}</th>
                    <th>{t("text_actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.get_pg_detail_data.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.payment_name}</td>
                        <td>
                          {data.status == "1" ? (
                            <span
                              className="badge badge-success pointer"
                              onClick={() => handleStatus("0", data.id)}
                            >
                              {t("text_active")}
                              <i className="fa fa-pencil"></i>
                            </span>
                          ) : (
                            <span
                              className="badge bg-danger pointer"
                              onClick={() => handleStatus("1", data.id)}
                            >
                              {t("text_inactive")}
                              <i className="fa fa-pencil"></i>
                            </span>
                          )}
                        </td>
                        <td className="">
                          <NavLink
                            to={`/admin/payment_method/edit/${data.id}`}
                            style={{ fontSize: "18px" }}
                          >
                            <i className="fa fa-edit text-lightpink"></i>
                          </NavLink>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <th>{t("text_sr_no")}</th>
                    <th>{t("text_payment_name")}</th>
                    <th>{t("text_status")}</th>
                    <th>{t("text_actions")}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.GetPaymentMethod,
});

export default connect(mapStateToProps)(PaymentMethod);
