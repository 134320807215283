import React, { useEffect } from "react";
import UserHeader from "../theme/userHeader";
import ProfileSetting from "./profile_setting";
import CompanySetting from "./company_setting";
import SocialSetting from "./social_setting";
import ChangePassword from "./change_password";
import { ProfileData } from "../../../store/Action/AdminData";
import { connect } from "react-redux";
import Title from "../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const MyProfile = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(ProfileData());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_profile_setting")} />
      <UserHeader title={t("text_profile_setting")} />
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <>
          <ProfileSetting props={data.profile_setting} />
          <CompanySetting props={data.company_setting} />
          <SocialSetting props={data.social_setting} />
          <ChangePassword />
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.profile,
});

export default connect(mapStateToProps)(MyProfile);
