import { React, useEffect } from "react";
import Title from "../../common/Helmet";
import MainTheme from "../theme/MainTheme";
import { connect } from "react-redux";
import { Product_data } from "../../../store/Action/UserFatchData";
import { useParams } from "react-router-dom";
import coin from "../../assets/images/coin.png";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const ProductDetail = ({ dispatch, res }) => {
  const { id } = useParams();

  useEffect(() => {
    dispatch(Product_data());
  }, [dispatch]);

  if (res.loading !== true) {
    var data = res.data.product.filter((data) => {
      return data.product_id === id;
    });
  }

  return (
    <>
      <Title props={t("text_product")} />
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <>
          <MainTheme title={data[0].product_name}>
            <div className="bm-mdl-center bm-full-height">
              <div className="bm-content-listing">
                <div className="col-md-6">
                  <div className="match-info">
                    {/* Product Image */}
                    <img
                      src={data[0].product_image}
                      alt="product-img"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    className="px-3"
                    style={{ position: "relative", top: "-20px" }}
                  >
                    <div
                      className="card mb-2 p-2"
                      style={{ minHeight: "unset" }}
                    >
                      {/* Short Description */}
                      <h6 className="text-black">
                        {data[0].product_short_description}
                      </h6>
                      {/* Product Name */}
                      <div className="text-black">{data[0].product_name}</div>
                      <div className="row">
                        <h6 className="col-6 text-info">
                          <strike>
                            <img src={coin} alt="coin-img" className="coin" />
                            {/* Actual Price */}
                            {Number(data[0].product_actual_price).toFixed(2)}
                          </strike>
                        </h6>
                        <h6 className="col-6 pull-right text-info text-end">
                          <img src={coin} alt="coin-img" className="coin" />
                          {/* Selling Price */}
                          {Number(data[0].product_selling_price).toFixed(2)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Description */}
                <div className="card mb-2 p-2" style={{ minHeight: "unset" }}>
                  <h6 className="text-black"> {t("text_description")}</h6>
                  <div className="text-black">
                    {/* convert jsx to html */}
                    {ReactHtmlParser(data[0].product_description)}
                  </div>
                </div>
                {/* BUtton Buy Now / Link to Buy Form */}
                <Link
                  className="btn btn-sm btn-lightpink text-uppercase"
                  to={{
                    pathname: `/Buy Product/productdetail/buyform/${id}`,
                    state: {
                      title: data[0].product_name,
                      amount: data[0].product_selling_price,
                    },
                  }}
                >
                  {t("text_buy_now")}
                </Link>
              </div>
            </div>
          </MainTheme>
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.product,
});

export default connect(mapStateToProps)(ProductDetail);
