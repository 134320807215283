import React, { useEffect } from "react";
import { t } from "i18next";
import coin from "../../../assets/images/coin.png";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import { GetMemberJoinData } from "../../../../store/Action/AdminData";

const MatchDetails = ({ res, dispatch }) => {
  const $ = window.$;
  const params = useParams();

  useEffect(() => {
    dispatch(GetMemberJoinData(params.id));
  }, [dispatch]);
  var data = res.data;

  // matchdetail datatable
  $(document).ready(function () {
    $("#join").DataTable({
      paging: false,
      ordering: false,
      info: false,
    });
    $.fn.dataTableExt.sErrMode = "throw";
  });

  return (
    <>
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className=" table-responsive">
              <strong className="text-dark">Match Details:</strong>
              <table className="table table-striped table-bordered">
                <thead style={{ backgroundColor: "#343a40", color: "#fff" }}>
                  <tr>
                    <th>{t("text_match_id")}</th>
                    <th>{t("text_type")}</th>
                    <th>{t("text_map")}</th>
                    <th>{t("text_match_type")}</th>
                    <th>{t("text_total_player")}</th>
                    <th>{t("text_total_player_joined")}</th>
                    <th>
                      {t("text_entry_fee")}(
                      <img src={coin} className="coin" alt="coin-img" />)
                    </th>
                    <th>
                      {t("text_win_prize")}(
                      <img src={coin} className="coin" alt="coin-img" />)
                    </th>
                    <th>
                      {t("text_per_kill")}(
                      <img src={coin} className="coin" alt="coin-img" />)
                    </th>
                    <th>{t("text_status")}</th>
                    <th>{t("text_match_schedule")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.get_match_data.map((data, index) => {
                    return (
                      <tr role="row" className="odd" key={index}>
                        <td>{data.m_id}</td>
                        <td>{data.type}</td>
                        <td>{data.MAP}</td>
                        <td>
                          &nbsp;
                          {data.match_type == "1"
                            ? t("text_paid")
                            : t("text_unpaid")}
                        </td>
                        <td>{data.number_of_position}</td>
                        <td>{data.no_of_player}</td>
                        <td>
                          <img src={coin} className="coin" alt="coin-img" />
                          &nbsp;
                          {Number(data.entry_fee).toFixed(2)}
                        </td>
                        <td>
                          <img src={coin} className="coin" alt="coin-img" />
                          &nbsp;
                          {Number(data.win_prize).toFixed(2)}
                        </td>
                        <td>
                          <img src={coin} className="coin" alt="coin-img" />
                          &nbsp;
                          {Number(data.per_kill).toFixed(2)}
                        </td>
                        <td>
                          {data.match_status == "0"
                            ? t("text_deactive")
                            : data.match_status == "1"
                            ? t("text_active")
                            : data.match_status == "2"
                            ? t("text_complete")
                            : data.match_status == "3"
                            ? t("text_start")
                            : t("text_cancel")}
                        </td>
                        <td>{data.match_time}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.memberJoin,
});

export default connect(mapStateToProps)(MatchDetails);
