import { React, useState, useEffect } from "react";
import UserHeader from "../theme/userHeader";
import { useHistory, useParams, NavLink } from "react-router-dom";
import Title from "../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const EditCountry = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  //get the id from parameter
  const params = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  //store the data of api
  const [insertData, SetInsertData] = useState({});
  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [state, setState] = useState({
    country_name: "",
    p_code: "",
  });

  //on page refresh api is call
  useEffect(async () => {
    var response = await fetch(`/api/get_insert_country/${params.id}`, {
      headers: { Authorization: user.api_token },
    });
    var res_data = await response.json();
    if (res_data) {
      setLoading(false);
    }
    SetInsertData(res_data.get_country[0]);
    setState({
      country_name: res_data.get_country[0].country_name,
      p_code: res_data.get_country[0].p_code,
    });
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // form validation
  function handleFormValidation() {
    const { country_name, p_code } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!country_name) {
      formIsValid = true;
      formErrors["countryNameErr"] = t("translation2:err_country_name_req");
    }
    if (!p_code) {
      formIsValid = true;
      formErrors["pcodeErr"] = t("translation2:err_p_code_req");
    }
    setState({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    var response = await fetch(`/api/update_country/${params.id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      history.push("/admin/country", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_country")} />
      <UserHeader title={t("text_country")} />
      {loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card bg-light text-dark">
              <div className="card-header">
                <strong>{t("text_country")}</strong>
              </div>
              <div className="card-body ">
                <div className="col-md-12 p-2">
                  <form
                    className="needs-validation"
                    id="validate"
                    method="POST"
                  >
                    <div className="row">
                      {/* country name */}
                      <div className="form-group col-md-6">
                        <label htmlFor="country_name">
                          {t("text_country_name")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          name="country_name"
                          value={state.country_name}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="country_name"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.countryNameErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.countryNameErr}
                        </label>
                      </div>
                      {/* country code */}
                      <div className="form-group col-md-6">
                        <label htmlFor="p_code">
                          {t("text_country_code")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control valid mt-2"
                          name="p_code"
                          value={state.p_code}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="p_code"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.pcodeErr ? " d-block" : "d-none")
                          }
                        >
                          {state1.formErrors.pcodeErr}
                        </label>
                      </div>
                    </div>
                    {/* submit */}
                    <div className="form-group d-flex justify-content-center mt-3">
                      <input
                        type="submit"
                        value="Submit"
                        name="submit"
                        className={`btn btn-lightpink ${
                          demouser === "1" && "disabled"
                        }`}
                        onClick={demouser === "0" && handleSubmit}
                      />
                      {/* cancel */}
                      <NavLink
                        className="btn btn-secondary ms-1 d-block"
                        to="/admin/country"
                        name="cancel"
                      >
                        {t("text_btn_cancel")}
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditCountry;
