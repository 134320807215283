import { React, useEffect } from "react";
import UserHeader from "../../../theme/UserHeader";
import MainTheme from "../../../theme/MainTheme";
import { Single_match_data } from "../../../../../store/Action/UserFatchData";
import Description from "./description";
import { NavLink } from "react-router-dom";
import JoinMember from "./JoinMember";
import { useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const UpComingDetail = ({ dispatch, res }) => {
  const location = useLocation();
  var { id } = useParams();

  useEffect(() => {
    dispatch(Single_match_data(id));
  }, [dispatch, id]);
  var data = res.data;
  console.log("da", data);

  return (
    <>
      <MainTheme>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <UserHeader props={data.match.match_name} />
            <div className="row">
              <div className="col-lg-12">
                {/* Game Image */}
                <img
                  src={location.state}
                  alt="game-img"
                  className="img-fluid1 img-responsive"
                />
                <div className="card my-3">
                  <div
                    className="card-body dashboard-tabs p-0 bg-lightgray"
                    id="tabs-1"
                  >
                    <ul className="nav nav-tabs" role="tablist">
                      {/* Description Tab */}
                      <li className="nav-item">
                        <a
                          className="navlink active"
                          id="description-tab"
                          data-toggle="tab"
                          href="#description"
                          role="tab"
                          aria-controls="Description"
                          aria-selected="true"
                        >
                          &nbsp;
                          {t("text_description")}
                        </a>
                      </li>
                      {/* Join Member Tab */}
                      <li className="nav-item">
                        <a
                          className="navlink"
                          id="join_member-tab"
                          data-toggle="tab"
                          href="#join_member"
                          role="tab"
                          aria-controls="join_member"
                          aria-selected="false"
                        >
                          {t("text_joined_member")}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content py-0 px-0">
                      {/* Description Tab */}
                      <div
                        className="tab-pane fade show active"
                        id="description"
                        role="tabpanel"
                        aria-labelledby="description-tab"
                      >
                        <Description props={data} />
                      </div>
                      {/* Join Member Tab */}
                      <div
                        className="tab-pane fade show "
                        id="join_member"
                        role="tabpanel"
                        aria-labelledby="join_member-tab"
                      >
                        <JoinMember props={data} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {data.match.join_status ? (
                      <span className="btn btn-lightpink">
                        {t("text_already_joined")}
                      </span>
                    ) : (
                      // link for join now
                      <NavLink
                        to={{
                          pathname: `/play/upcoming_match/position/${data.match.m_id}`,
                          state: {
                            type: data.match.type,
                            game_name: data.match.match_name,
                            match_fees: data.match.entry_fee,
                            joined: data.match.join_status,
                          },
                        }}
                      >
                        {/* Button Join  */}
                        <span className="btn btn-sm  btn-lightpink">
                          {t("text_btn_join_now")}
                        </span>
                      </NavLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.singlematch,
});

export default connect(mapStateToProps)(UpComingDetail);
