import { React } from "react";
import MainTheme from "../theme/MainTheme";
import Title from "../../../views/common/Helmet";
import { Scrollbars } from "react-custom-scrollbars";
import Announcement from "./Announcement";
import AllGames from "./All_games";
import Sliders from "./Sliders";
import { t } from "i18next";

const Play = () => {
  return (
    <>
      <Title props={t("text_all_games")} />
      <MainTheme title={t("text_all_games")} headCoin footer>
        <Scrollbars>
          <Announcement />
          <Sliders />
          <AllGames />
        </Scrollbars>
      </MainTheme>
    </>
  );
};

export default Play;
