//Redux Action for user side

// Dashboard
export function Dashboard() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/dashboard/" + user.member_id, {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "DASHBOARD_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// My Profile
export function MyProfileData() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/my_profile/" + user.member_id, {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "MY_PROFILE_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//Update My Profile
export function UpdateMyProfile(data) {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/update_myprofile", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
      body: data,
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      window.location.href = "/accounts";
    } else {
      var return_response = {
        type: "UPDATE_MY_PROFILE_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    }
  };
}

//Change Password
export function ChangePassword(data) {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/update_myprofile", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
      body: data,
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      window.location.href = "/accounts";
    } else {
      var return_response = {
        type: "CHANGE_PASSWORD_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    }
  };
}

//My Match
export function GetMyMatch(data) {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch(`/api/my_match/${user.member_id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
      body: data,
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      window.location.href = "/accounts";
    } else {
      var return_response = {
        type: "GET_MY_MATCH",
        payload: res_data,
      };
      dispatch(return_response);
    }
  };
}

//All Games
export function All_game() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/all_game", {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "ALL_GAME_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Earn
export function Earn_data() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/banner", {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "EARN_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//Slider
export function Slider() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/slider", {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "SLIDER_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// lottery
export function Lottery_data() {
  var user = JSON.parse(localStorage.getItem("user"));
  var status = "ongoing";
  return async (dispatch) => {
    var response = await fetch(`/api/lottery/${user.member_id}/${status}`, {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "LOTTERY_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// lottery
export function LotteryResult() {
  var user = JSON.parse(localStorage.getItem("user"));
  var status = "result";
  return async (dispatch) => {
    var response = await fetch(`/api/lottery/${user.member_id}/${status}`, {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "LOTTERY_RESULT_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//all ongoing match
export function All_ongoing_match(game_id) {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch(
      `/api/all_ongoing_match/${game_id}/${user.member_id}`,
      {
        headers: {
          Authorization: "Bearer " + user.api_token,
        },
      }
    );
    var res_data = await response.json();
    var return_response = {
      type: "ALL_ONGOING_MATCH_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//Upcoming Match
export function All_play_match(game_id) {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch(
      `/api/all_play_match/${game_id}/${user.member_id}`,
      {
        headers: {
          Authorization: "Bearer " + user.api_token,
        },
      }
    );
    var res_data = await response.json();
    var return_response = {
      type: "ALL_PLAY_MATCH_DATA",
      payload: res_data,
    };

    dispatch(return_response);
  };
}

//Match Result
export function All_game_result(game_id) {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch(
      `/api/all_game_result/${game_id}/${user.member_id}`,
      {
        headers: {
          Authorization: "Bearer " + user.api_token,
        },
      }
    );
    var res_data = await response.json();
    var return_response = {
      type: "ALL_GAME_RESULT_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Single Game Result
export function ResultMatch(match_id) {
  var user = JSON.parse(localStorage.getItem("user"));

  return async (dispatch) => {
    var response = await fetch(`/api/single_game_result/${match_id}`, {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "RESULT_MATCH_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//game match
export function GameMatch(match_id, member_id) {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch(
      `/api/join_match_single/${match_id}/${member_id}`,
      {
        headers: {
          Authorization: "Bearer " + user.api_token,
        },
      }
    );
    var res_data = await response.json();
    var return_response = {
      type: "GAME_MATCH_DATA",
      payload: res_data,
    };

    dispatch(return_response);
  };
}

//Participate
export function ParticipateMatch(match_id) {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch(`/api/match_participate/${match_id}`, {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "PARTICIPATE_DATA",
      payload: res_data,
    };

    dispatch(return_response);
  };
}

//My Statistics
export function My_statistics_data() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch(
      "/api/app_view_my_statistics/" + user.member_id,
      {
        headers: {
          Authorization: "Bearer " + user.api_token,
        },
      }
    );
    var res_data = await response.json();
    var return_response = {
      type: "MY_STATISTICS_DATA",
      payload: res_data,
    };

    dispatch(return_response);
  };
}
//My app tutorial
export function AppTutorialData() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/youtube_link", {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "APP_TUTORIAL",
      payload: res_data,
    };

    dispatch(return_response);
  };
}
//My LeaderBoard
export function MyLeaderBoardData() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/leader_board", {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "MY_LEADER_BOARD_DATA",
      payload: res_data,
    };

    dispatch(return_response);
  };
}

//Terms
export function TermsData() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/terms_conditions");
    var res_data = await response.json();
    var return_response = {
      type: "TERMS_DATA",
      payload: res_data,
    };

    dispatch(return_response);
  };
}

// Product
export function Product_data() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/product", {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "PRODUCT_DATA",
      payload: res_data,
    };

    dispatch(return_response);
  };
}

//About Us
export function AboutUsData() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/about_us");
    var res_data = await response.json();
    var return_response = {
      type: "ABOUT_US_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Referral
export function Referral_data() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/my_refrrrals/" + user.member_id, {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "REFERRAL_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//Customer Support
export function CustomerSupportData() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/customer_support", {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "CUSTOMER_SUPPORT_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Announcement
export function Announcement_data() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/announcement", {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "ANNOUNCEMENT_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Top Player
export function top_player_data() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/top_players", {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "TOP_PLAYER_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// My Order
export function my_order_data() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/my_order/" + user.member_id, {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "MY_ORDER_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Transaction(wallet)
export function Transaction_data() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch(`/api/app_view_transaction`, {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "TRANSACTION_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Single Match
export function Single_match_data(match_id) {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch(
      `/api/single_match/${match_id}/${user.member_id}`,
      {
        headers: {
          Authorization: "Bearer " + user.api_token,
        },
      }
    );
    var res_data = await response.json();
    var return_response = {
      type: "SINGLE_MATCH_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Change Player Name(wallet)
export function Change_player_name_data(data) {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch(`/api/change_player_name`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "CHANGE_PLAYER_NAME_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Join Match
export function Join_Match(params) {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch(`/api/join_match_process`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    var res_data = await response.json();
    var return_response = {
      type: "JOIN_MATCH",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Product Detail
export function Product_buy_data(params) {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch(`/api/product_order`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    var res_data = await response.json();
    var return_response = {
      type: "PRODUCT_BUY_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//watch and Earn
export function Watch_Earn() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/watch_earn/" + user.member_id, {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "WATCH_AND_EARN_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Payment
export function Payment() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/payment", {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "PAYMENT_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Withdraw Method
export function withdraw_method_data() {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch("/api/withdraw_method", {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "WITHDRAW_METHOD_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Withdraw
export function Withdraw_data(data) {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch(`/api/withdraw`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "WITHDRAW_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Paystack
export function Paystack_data(data) {
  var user = JSON.parse(localStorage.getItem("user"));
  return async (dispatch) => {
    var response = await fetch(`/api/paystack_response`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "PAYSTACK_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// Cashfree
export function cash_data(data) {
  return async (dispatch) => {
    var response = await fetch(`https://sandbox.cashfree.com/pg/orders`, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "x-api-version": "2022-02-01",
        "x-client-id": "171677c798dcf17ce201b38fd5776171",
        "x-client-secret": "ec279c228a854ec73c60f8d3d447ad51c17f0d71",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "CASH_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// export function cash_data(data) {
//   // var user = JSON.parse(localStorage.getItem("user"));
//   return async (dispatch) => {
//     var response = await fetch(`https://sandbox.cashfree.com/pg/orders`, {
//       method: "POST",
//       mode: "no-cors",

//       headers: {
//         "x-api-version": "2022-01-01",
//         "x-client-id": "171677c798dcf17ce201b38fd5776171",
//         "x-client-secret": "ec279c228a854ec73c60f8d3d447ad51c17f0d71",
//         "Content-Type": "application/json",
//         "Accept": "application/json"
//       },
//       body: JSON.stringify(data),
//     });
//     var res_data = await response.json()
//     // console.log(res_data);
//     console.log(res_data);
//     var return_response = {
//       type: "CASH_DATA",
//       payload: res_data,
//     };
//     dispatch(return_response);
//   };
// }
