import { React, useEffect } from "react";
import { GetMyMatch } from "../../../store/Action/UserFatchData";
import UpComing from "./my_match_detail/upComing/upComing";
import OnGoing from "./my_match_detail/OnGoing/onGoing";
import Result from "./my_match_detail/result/result";
import Spinner from "react-bootstrap/Spinner";
import MainTheme from "../theme/MainTheme";
import Title from "../../common/Helmet";
import { connect } from "react-redux";
import { t } from "i18next";

const MyMatches = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(GetMyMatch());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_my_matches")} />
      <MainTheme title={t("text_my_matches")}>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-lg-12">
                <div className="card mt-3">
                  <div
                    className="card-body dashboard-tabs p-0 bg-lightgray"
                    id="tabs-1"
                  >
                    <ul className="nav nav-tabs" role="tablist">
                      {/* Ongoing Tab */}
                      <li className="nav-item">
                        <a
                          className="navlink"
                          id="onGoing-tab"
                          data-toggle="tab"
                          href="#onGoing"
                          role="tab"
                          aria-controls="OnGoing"
                          aria-selected="true"
                        >
                          {t("text_ongoing")}
                        </a>
                      </li>
                      {/* Upcoming Tab */}
                      <li className="nav-item">
                        <a
                          className="navlink active"
                          id="upcoming-tab"
                          data-toggle="tab"
                          href="#upcoming"
                          role="tab"
                          aria-controls="Upcoming"
                          aria-selected="false"
                        >
                          {t("text_upcoming")}
                        </a>
                      </li>
                      {/* Result Tab */}
                      <li className="nav-item">
                        <a
                          className="navlink"
                          id="results-tab"
                          data-toggle="tab"
                          href="#results"
                          role="tab"
                          aria-controls="Results"
                          aria-selected="false"
                        >
                          {t("text_results")}
                        </a>
                      </li>
                    </ul>
                    {/* Tab Content */}
                    <div className="tab-content py-0 px-0">
                      {/* Ongoing Tab */}
                      <div
                        className="tab-pane fade show"
                        id="onGoing"
                        role="tabpanel"
                        aria-labelledby="onGoing-tab"
                      >
                        <OnGoing props={data} />
                      </div>
                      {/* UpComing Tab */}
                      <div
                        className="tab-pane fade show active"
                        id="upcoming"
                        role="tabpanel"
                        aria-labelledby="upcoming-tab"
                      >
                        <UpComing props={data} />
                      </div>
                      {/* Result tab */}
                      <div
                        className="tab-pane fade show "
                        id="results"
                        role="tabpanel"
                        aria-labelledby="results-tab"
                      >
                        <Result props={data} />
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.get_my_match,
});

export default connect(mapStateToProps)(MyMatches);
