import React from "react";
import { t } from "i18next";

const JoinMember = ({ props }) => {
  return (
    <>
      <div className="d-flex flex-wrap justify-content-xl-between">
        <div className="border-md-right flex-grow-1 p-3">
          <div className="col-lg-12">
            <table className="table tr-bordered">
              {props.join_position.length ? (
                // All Participate
                props.join_position.map((data, index) => (
                  <>
                    <tr>
                      <td key={index}>
                        {/* index + 1 start count from 1 */}
                        {index + 1}&nbsp; &nbsp; &nbsp;
                        {/* &nbsp; for white space */}
                      </td>
                      <td>
                        <strong className="pl-3">{data.pubg_id}</strong>
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                // if there is no any participates
                <p className="text-black text-center">
                  {t("text_no_joined_member")}
                </p>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinMember;
