import { Dashboard } from "../../../../store/Action/UserFatchData";
import { useEffect, React } from "react";
import { connect } from "react-redux";
import coin from "../../../../views/assets/images/coin.png";
import Spinner from "react-bootstrap/Spinner";

const HeadCoin = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Dashboard());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <p
          className="badge bg-white pull-right f-20 text-black d-inline"
          id="tot_wallet"
        >
          <img src={coin} className="coin" alt="img-coin" />
          {/* Total Balance */}
          {Number(
            parseInt(data.member.join_money) +
              parseInt(data.member.wallet_balance)
          ).toFixed(2)}
        </p>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.dashboard,
});

export default connect(mapStateToProps)(HeadCoin);
