import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { t } from "i18next";

const Carousel = ({ props }) => {
  const $ = window.$;
  //items shows in carousel on different screen sizes
  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  };

  // owl-carousel nav button border
  $(document).ready(function () {
    $(".owl-prev").click(function () {
      $(".owl-prev span").css({
        border: "2px solid black",
        borderRadius: "3px",
      });
      $(".owl-next span").css("border", "none");
    });
  });
  $(document).ready(function () {
    $(".owl-next").click(function () {
      $(".owl-prev span").css("border", "none");
      $(".owl-next span").css({
        border: "2px solid black",
        borderRadius: "3px",
      });
    });
  });

  // magnificPopup
  $(document).ready(function () {
    $(".popup").magnificPopup({
      type: "image",
      delegate: "a",
      gallery: { enabled: true },
    });
  });

  return (
    <>
      <section
        className="bm-section-padding bm-light-bg text-dark"
        id="screenshot"
      >
        <h6 className="text-center bm_section_title text-uppercase demo">
          {t("text_app_screenshots")}
        </h6>
        <p className="bm_section_subtitle text-center bm_mb30">
          {t("text_app_ss_desc")}
        </p>
        <div className="container">
          <div className="row popup">
            {/* carousel for screenshot */}
            <OwlCarousel
              className="owl-theme"
              margin={10}
              nav
              responsive={responsive}
            >
              {props.screenshots.map((data, index) => (
                <div className="item" key={index}>
                  <a href={data.screenshot} className="popup-link">
                    <img src={data.screenshot_thumb} alt="screenshot" />
                  </a>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
};

export default Carousel;
