import { React, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams, NavLink } from "react-router-dom";
import { GetCountry } from "../../../../store/Action/AdminData";
import { connect } from "react-redux";
import GameIdDetails from "./GameIdDetails";
import { t } from "i18next";

const MemberDetail = ({ props, props1, dispatch, res }) => {
  const params = useParams();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  //member data stored
  const [member, setMember] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    password: "",
    referral_no: "",
    email_id: "",
    country_code: "",
    mobile_no: "",
    dob: "",
    profile_image: "",
    gender: "",
  });
  useEffect(() => {
    setMember({
      first_name: "",
      user_name: props[0].user_name,
      last_name: "",
      password: "",
      email_id: props[0].email_id.replace(
        /(\w{2})(.*)(\w{1})@(.*)/,
        "$1******$3@$4"
      ),
      country_code: props[0].country_code,
      mobile_no: props[0].mobile_no.replace(/(\d{2})(.*)(\d{2})/, "$1******$3"),
      dob: props[0].dob,
      profile_image: props[0].profile_image,
      gender: props[0].gender,
    });
  }, [props]);
  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });
  //manage the image
  const handleInputFile = (e) => {
    const value = e.target.files[0];
    setMember({
      ...member,
      profile_image: value,
    });
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    // console.log(file);
    setMember({
      ...member,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(GetCountry());
  }, [dispatch]);
  if (res.loading) return false;
  const data = res.data.all_country;

  function handleFormValidation() {
    const {
      first_name,
      last_name,
      user_name,
      password,
      email_id,
      mobile_no,
      profile_image,
    } = member;
    console.log(password.length);
    let formErrors = {};
    let formIsValid = true;
    if (!first_name) {
      formIsValid = true;
      formErrors["firstNameErr"] = t("translation2:err_first_name_req");
    }
    if (!last_name) {
      formIsValid = true;
      formErrors["lastNameErr"] = t("translation2:err_last_name_req");
    }
    if (!user_name) {
      formIsValid = true;
      formErrors["userNameErr"] = t("translation2:err_user_name_req");
    }
    if (!password) {
      formIsValid = true;
      formErrors["passwordVErr"] = t("translation2:err_password_req");
    }
    if (password && password.length < 6) {
      formIsValid = true;
      formErrors["passwordErr"] = t("translation2:err_password_min");
    }
    if (!email_id) {
      formIsValid = true;
      formErrors["emailIdErr"] = t("translation2:err_email_id_req");
    }
    if (!mobile_no) {
      formIsValid = true;
      formErrors["mobileNoErr"] = t("translation2:err_mobile_no_req");
    }
    if (!profile_image) {
      formIsValid = true;
      formErrors["profileImageErr"] = t("translation2:err_game_image_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("first_name", member.first_name);
    formData.append("last_name", member.last_name);
    formData.append("user_name", member.user_name);
    formData.append("password", member.password);
    formData.append("referral_no", member.referral_no);
    formData.append("email_id", member.email_id);
    formData.append("country_code", member.country_code);
    formData.append("mobile_no", member.mobile_no);
    formData.append("dob", member.dob);
    formData.append("profile_image", member.profile_image);
    formData.append("gender", member.gender);
    formData.append("update", "Update");
    handleFormValidation();
    // update details
    var response = await fetch(`/api/member_update_details/${params.id}`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      history.push("/admin/all_users", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <div className="row mb-4" id="profile_setting">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            {/* card-header */}
            <div className="card-header">
              <strong>{t("text_member_detail")}</strong>
            </div>
            <div className="card-body">
              <div className="col-md-12">
                <form id="profile-form">
                  <div className="row m-3">
                    {/* First Name */}
                    <div className="form-group col-md-6 ">
                      <label htmlFor="first_name" className="form_label">
                        {t("text_first_name")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="first_name"
                        value={member.first_name}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.firstNameErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.firstNameErr}
                      </label>
                    </div>
                    {/* Last Name */}
                    <div className="form-group col-md-6 ">
                      <label htmlFor="last_name" className="form_label">
                        {t("text_last_name")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="last_name"
                        type="text"
                        className="form-control"
                        name="last_name"
                        value={member.last_name}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.lastNameErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.lastNameErr}
                      </label>
                    </div>

                    {/* User Name */}
                    <div className="form-group col-md-6 mt-2">
                      <label htmlFor="user_name" className="form_label">
                        {t("text_user_name")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={member.user_name}
                        onChange={handleInput}
                        name="user_name"
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.userNameErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.userNameErr}
                      </label>
                    </div>

                    {/* password */}
                    <div className="form-group col-md-6 mt-2">
                      <label htmlFor="password" className="form_label">
                        {t("text_password")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="password"
                        type="password"
                        className="form-control"
                        name="password"
                        value={member.password}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.passwordErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.passwordErr}
                      </label>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.passwordVErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.passwordVErr}
                      </label>
                    </div>
                  </div>

                  <div className="row m-3">
                    {/* referral no */}
                    <div className="form-group col-md-6 mt-2">
                      <label htmlFor="referral_no" className="form_label">
                        {t("text_referral_no")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="referral_no"
                        type="text"
                        // readonly=""
                        className="form-control"
                        name="referral_no"
                        value={member.referral_no}
                        onChange={handleInput}
                      />
                    </div>

                    {/* email */}
                    <div className="form-group col-md-6 mt-2">
                      <label htmlFor="email" className="form_label">
                        {t("text_email")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="email_id"
                        type="text"
                        className="form-control"
                        name="email_id"
                        value={member.email_id}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.emailIdErr ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.emailIdErr}
                      </label>
                    </div>
                  </div>

                  <div className="row m-3">
                    {/* country */}
                    <div className="form-group col-md-2 mt-2">
                      <label htmlFor="country_code" className="form_label">
                        {t("text_country")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <select
                        className="form-control"
                        name="country_code"
                        value={member.country_code}
                        onChange={handleInput}
                      >
                        <option>{t("text_select")}</option>
                        {data.map((country, index) => {
                          return (
                            <option
                              key={index}
                              defaultValue={country.p_code}
                              selected={
                                member.country_code == country.p_code
                                  ? "selected"
                                  : false
                              }
                            >
                              {country.p_code || ""}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {/* Mobile No */}
                    <div className="form-group col-md-4 mt-2">
                      <label htmlFor="mobile_no" className="form_label">
                        {t("text_mobile_no")}.
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="mobile_no"
                        value={member.mobile_no}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.mobileNoErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.mobileNoErr}
                      </label>
                    </div>

                    {/* Date of Birth */}
                    <div className="form-group col-md-6 mt-2">
                      <label htmlFor="dob" className="form_label">
                        {t("text_date_birth")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="datetimepicker1"
                        type="date"
                        className="form-control datepicker"
                        autocomplete="off"
                        name="dob"
                        value={member.dob}
                        onChange={handleInput}
                      />
                    </div>
                  </div>

                  <div className="row m-3">
                    {/* Profile Picture */}
                    <div className="form-group col-md-6 mt-2">
                      <label htmlFor="profile_image" className="form_label">
                        {t("text_profile_image")}
                      </label>
                      <input
                        type="file"
                        id="profile_image"
                        name="profile_image"
                        className="form-control"
                        onChange={handleInputFile}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.profileImageErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.profileImageErr}
                      </label>
                    </div>

                    {/* Gender */}
                    <div className="form-group col-md-6 mt-2">
                      <label htmlFor="gender" className="form_label">
                        {t("text_gender")}
                      </label>
                      {/* Male */}
                      <div className="form-group col-md-12">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="male"
                            name="gender"
                            type="radio"
                            value="0"
                            className="custom-control-input"
                            onChange={handleInput}
                            defaultChecked={props[0].gender === "0" && true}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="male"
                          >
                            &nbsp;&nbsp;{t("text_male")}&nbsp;&nbsp;
                          </label>
                        </div>
                        {/* Female */}
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="female"
                            name="gender"
                            type="radio"
                            value="1"
                            className="custom-control-input"
                            onChange={handleInput}
                            defaultChecked={props[0].gender === "1" && true}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="female"
                          >
                            &nbsp;&nbsp;{t("text_female")}&nbsp;&nbsp;
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {!props1[0] == "" && <GameIdDetails />} */}

                  {/* Update Profile button */}
                  <div className="form-group text-center d-flex justify-content-center">
                    {/* submit */}
                    <button
                      type="submit"
                      value="Update Profile"
                      name="profile_submit"
                      className={`btn btn-lightpink mt-3 ms-2 ${
                        demouser === "1" && "disabled"
                      }`}
                      onClick={demouser === "0" && handleSubmit}
                    >
                      {t("text_btn_update")}
                    </button>
                    {/* cancel */}
                    <NavLink
                      to="/admin/all_users"
                      className="btn btn-secondary mt-3 ms-2 "
                    >
                      {t("text_btn_cancel")}
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.GetCountry,
});

export default connect(mapStateToProps)(MemberDetail);
