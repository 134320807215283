import { React, useEffect } from "react";
import MainTheme from "../theme/MainTheme";
import { Lottery_data } from "../../../store/Action/UserFatchData";
import OngoingTab from "./luckydraw_detail/onGoingTab";
import Result from "./luckydraw_detail/result";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const LuckyDraw = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Lottery_data());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <MainTheme title={t("text_lottery")}>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-lg-12">
                <div className="card mt-3">
                  <div
                    className="card-body dashboard-tabs p-0 bg-lightgray"
                    id="tabs-1"
                  >
                    <ul className="nav nav-tabs" role="tablist">
                      {/* Ongoing Tab */}
                      <li className="nav-item">
                        <a
                          className="navlink active"
                          id="ongoing-tab"
                          data-toggle="tab"
                          href="#ongoing"
                          role="tab"
                          aria-controls="Upcoming"
                          aria-selected="false"
                        >
                          Ongoing
                        </a>
                      </li>
                      {/* Result Tab */}
                      <li className="nav-item">
                        <a
                          className="navlink"
                          id="results-tab"
                          data-toggle="tab"
                          href="#results"
                          role="tab"
                          aria-controls="Results"
                          aria-selected="false"
                        >
                          Results
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content py-0 px-0">
                      {/* OnGoing tab */}
                      <div
                        className="tab-pane fade show active"
                        id="ongoing"
                        role="tabpanel"
                        aria-labelledby="ongoing-tab"
                      >
                        <OngoingTab props={data} />
                      </div>
                      {/* Resule Tab */}
                      <div
                        className="tab-pane fade show "
                        id="results"
                        role="tabpanel"
                        aria-labelledby="results-tab"
                      >
                        <Result />
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.lottery,
});

export default connect(mapStateToProps)(LuckyDraw);
