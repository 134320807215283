import { React, useEffect } from "react";
import { razporpayApi } from "../../../../store/Action/FatchData";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { t } from "i18next";

const RazorPay = ({ dispatch }) => {
  const location = useLocation();
  console.log("location", location);
  var user = JSON.parse(localStorage.getItem("user"));
  const amount = 1000;

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  //fuction call on page load
  const displayRazorpay = async (amount) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert(t("translation1:text_offline"));
      return;
    }  
    const options = {
      key: "rzp_test_W41ayZaJZnENSo",
      currency: "INR",
      amount: amount,
      name: "Battlemania",
      description: "",
      image:
        "https://demowebapp.thebattlemania.com/uploads/company_image/thumb/189x40_202101191156431657827203__logo.png",
      handler: function (response) {
        const payment = {
          member_id: user.member_id,
          amount: amount,
          receipt: "battlemania",
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
          status: "true",
        };
        //Razorpay response api call
        // dispatch(razporpayApi(payment));
        console.log("razorpay success");
      },
      prefill: {
        name: "battlemania",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  useEffect(() => {
    displayRazorpay(amount);
  }, []);

  return <>hello</>;
};

//redux connect
const mapStateToProps = (state) => ({
  res: state.razporpay,
});

export default connect(mapStateToProps)(RazorPay);
