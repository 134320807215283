import { useEffect, React } from "react";
import { connect } from "react-redux";
import { GetMyMatch } from "../../../../store/Action/UserFatchData";
import MainTheme from "../../theme/MainTheme";
import Title from "../../../common/Helmet";
import OngoingTab from "../my_match_detail/ongoingTab";
import UpcomingTab from "../my_match_detail/upcomingTab";
import ResultTab from "../my_match_detail/resultTab";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

function MyMatch({ dispatch, res }) {
  useEffect(() => {
    dispatch(GetMyMatch());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_my_matches")} />
      <MainTheme title={t("text_my_matches")} backPath>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <div className="tab-section single-game mb-5">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                {/* ongoing tab */}
                <a
                  className="nav-link text-uppercase"
                  data-toggle="tab"
                  href="#ongoing"
                >
                  {t("text_ongoing")}
                </a>
              </li>
              {/* upcoming tab */}
              <li className="nav-item">
                <a
                  className="nav-link text-uppercase active"
                  data-toggle="tab"
                  href="#upcoming"
                >
                  {t("text_upcoming")}
                </a>
              </li>
              {/* result tab */}
              <li className="nav-item">
                <a
                  className="nav-link text-uppercase"
                  data-toggle="tab"
                  href="#results"
                >
                  {t("text_results")}
                </a>
              </li>
            </ul>
            <div className="tab-content">
              {/* ongoing */}
              <div id="ongoing" className="container tab-pane">
                <OngoingTab props={data} />
              </div>
              {/* upcoming */}
              <div id="upcoming" className="container tab-pane active">
                <UpcomingTab props={data} />
              </div>
              {/* result */}
              <div id="results" className="container tab-pane">
                <ResultTab props={data} />
              </div>
            </div>
          </div>
        )}
      </MainTheme>
    </>
  );
}

// redux connect
const mapStateToProps = (state) => ({
  res: state.get_my_match,
});

export default connect(mapStateToProps)(MyMatch);
