import { React, useEffect, useState } from "react";
import UserHeader from "../theme/userHeader";
import { GetLicense } from "../../../store/Action/AdminData";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Title from "../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";
import { t } from "i18next";

const License = ({ dispatch, res }) => {
  const history = useHistory();
  const location = useLocation();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  // manage state
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (location.state) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  useEffect(() => {
    dispatch(GetLicense());
  }, [dispatch]);
  const data = res.data;

  // delete
  const handleDelete = () => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>You won't be able to revert this?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var response = await fetch(`/api/remove_license`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
        });
        var res_data = await response.json();
        history.push("/admin/license/license_setting");
        setShow(true);
        setMessage(res_data.message);
      }
    });
  };

  return (
    <>
      <Title props={t("text_license")} />
      {/* Header */}
      <UserHeader title={t("text_license_info")} />
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <>
          {show && (
            <div
              className={
                (!location.state.status
                  ? "alert alert-danger "
                  : "alert alert-success ") +
                "alert-dismissible fade show " +
                (show ? "d-block" : "d-none")
              }
              role="alert"
            >
              {location.state.msg}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
          <div className="row text-dark">
            <div className="col-md-12">
              <div className="card bg-light text-dark">
                {/* card-header */}
                <div className="card-header">
                  <strong>{t("text_license_info")}</strong>
                </div>
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="row">
                      {/* purchase code */}
                      <div className="form-group col-md-2 mb-3">
                        <label htmlFor="purchase_code">
                          <strong>{t("purchase_code")} : </strong>
                        </label>
                      </div>
                      <div className="form-group col-md-6">
                        <span>
                          {data.get_licence_data.purchase_code.replace(
                            /(\w{5})(.*)(\w{5})/,
                            "$1**************$3"
                          )}
                        </span>
                      </div>
                    </div>
                    {/* purchase domain */}
                    <div className="row">
                      <div className="form-group col-md-2 mb-3">
                        <label htmlFor="purchase_code">
                          <strong>{t("text_installed_url")} : </strong>
                        </label>
                      </div>
                      <div className="form-group col-md-6">
                        <span>{data.get_licence_data.purchase_domain}</span>
                      </div>
                    </div>
                    {/* deactive */}
                    {demouser === "0" && (
                      <div className="row">
                        <div className="form-group col-md-6 offset-md-2 mb-3">
                          <div
                            onClick={handleDelete}
                            className="btn btn-lightpink"
                          >
                            {t("text_deactive")}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.GetLicense,
});

export default connect(mapStateToProps)(License);
