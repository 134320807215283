import { React, useEffect } from "react";
import { TotalWithdrawAmount } from "../../../store/Action/AdminData";
import { connect } from "react-redux";
import coin from "../../assets/images/coin.png";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const TotalWithdrawAmounts = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(TotalWithdrawAmount());
  }, [dispatch]);
  if (res.loading) return false;
  const data = res.data;

  return (
    <>
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="col-lg-3 col-md-6 dash-box pointer">
          <div className="bg-lightpink small-box card card-sm-3">
            <div className="card-icon ">
              <i className="fa fa-money"></i>
            </div>
            <div className="card-wrap">
              <div className="card-header">
                <h4>{t("text_withdraw")}</h4>
              </div>
              <div className="card-body">
                <img src={coin} alt="coin-img" className="coin" />
                &nbsp;
                {data.withdraw[0].withdraw}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.TotalWithdrawAmount,
});

export default connect(mapStateToProps)(TotalWithdrawAmounts);
