import { React, useEffect } from "react";
import Title from "../../common/Helmet";
import MainTheme from "../theme/MainTheme";
import { Earn_data } from "../../../store/Action/UserFatchData";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Earn = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Earn_data());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_earn")} />
      <MainTheme title={t("text_earn")}>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            {/* main */}
            <div className="row d-flex">
              {data.banner.map((data, index) => {
                return data.banner_link != "Watch and Earn" &&
                  data.banner_link != "My Rewards" ? (
                  <div
                    className="col-lg-4 col-md-6 text-center mb-sm-3 mb-xs-3"
                    key={index}
                  >
                    <div className=" box-shadow" style={{ height: "100%" }}>
                      {/* Link to sub-banner */}
                      <Link to={data.banner_link}>
                        <div className="card card-sm-3">
                          <img
                            src={data.banner_image}
                            alt="banner-img"
                            style={{ width: "100%" }}
                            title={data.banner_title}
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.earn,
});

export default connect(mapStateToProps)(Earn);
