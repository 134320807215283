import { React, useState } from "react";
import logo from "../assets/images/logo.png";
import { useHistory } from "react-router-dom";
import { t } from "i18next";

const ResetPsd = () => {
  const history = useHistory();
  const [state, setState] = useState({
    new_password: "",
    c_passowrd: "",
    password_update: "Update",
  });
  const [state1, setState1] = useState({
    formErrors: {},
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // form validation
  function handleFormValidation() {
    const { new_password, c_passowrd } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!new_password) {
      formIsValid = true;
      formErrors["new_password"] = t("translation2:err_password_req");
    }
    if (new_password && new_password.length < 6) {
      formIsValid = true;
      formErrors["new_password"] = t("translation2:err_password_min");
    }
    if (!c_passowrd) {
      formIsValid = true;
      formErrors["c_passowrd"] = t("translation2:err_password_req");
    }
    if (c_passowrd && c_passowrd.length < 6) {
      formIsValid = true;
      formErrors["c_passowrd"] = t("translation2:err_password_min");
    }
    if (
      new_password &&
      c_passowrd &&
      c_passowrd.length >= 6 &&
      new_password.length >= 6 &&
      new_password != c_passowrd
    ) {
      formIsValid = true;
      formErrors["c_passowrd"] = t("translation2:err_c_passowrd_equal");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    console.log(state);
    var response = await fetch(`/api/admin_for_pass`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    if (res_data) {
      history.push("/admin/login", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
    console.log(res_data);
  };

  return (
    <>
      <div className="body">
        <form className="form-signin text-center" id="validate-form">
          <img className="mb-4" src={logo} alt="logo" />
          <div className="text-left">
            <p style={{ textAlign: "initial", marginBottom: "0px" }}>
              {t("text_new_password")}
            </p>
            <input
              type="text"
              id="name"
              className="form-control"
              placeholder="User Name"
              name="new_password"
              value={state.new_password}
              onChange={handleInput}
            />
            <label
              htmlFor="new_password"
              generated="true"
              className={
                "error " +
                (state1.formErrors.new_password ? " d-block" : "d-none")
              }
            >
              {state1.formErrors.new_password}
            </label>
            <p
              style={{
                textAlign: "initial",
                marginBottom: "0px",
                marginTop: "10px",
              }}
            >
              {t("text_new_password")}
            </p>
            <input
              type="password"
              id="inputPassword"
              className="form-control "
              placeholder="Password"
              name="c_passowrd"
              value={state.c_passowrd}
              onChange={handleInput}
            />
            <label
              htmlFor="c_passowrd"
              generated="true"
              className={
                "error " +
                (state1.formErrors.c_passowrd ? " d-block" : "d-none")
              }
            >
              {state1.formErrors.c_passowrd}
            </label>
          </div>
          <button
            className="btn d-block fs-5 btn-lightpink  mt-3"
            name="submit"
            style={{ width: "inherit" }}
            onClick={handleSubmit}
          >
            {t("text_reset_now")}
          </button>
          <div className="mt-5 mb-3  text-white">
            <p>{t("text_copyright_text")}</p>
          </div>
        </form>
      </div>
    </>
  );
};

export default ResetPsd;
