import { React, useState, useEffect } from "react";
import UserHeader from "../../../theme/userHeader";
import { NavLink, useParams, useHistory } from "react-router-dom";
import Title from "../../../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const EditScreenshots = () => {
  //get the id from parameter
  const params = useParams();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  const [loading, setLoading] = useState(true);
  //store the data of api
  const [insertData, SetInsertData] = useState({});
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [state, setState] = useState({
    screenshot: "",
    dp_order: "",
  });

  // get insert screenshot
  const page = async () => {
    var response = await fetch(`/api/get_insert_screenshots/${params.id}`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    if (res_data) {
      setLoading(false);
    }
    SetInsertData(res_data.get_screenshot[0]);
    setState({
      screenshot: "",
      dp_order: res_data.get_screenshot[0].dp_order,
    });
  };

  //on page refresh api is call
  useEffect(() => {
    page();
  }, []);
  const handleChange = (e) => {
    const { name } = e.target;
    if (name == "screenshot") var value = e.target.files[0];
    else value = e.target.value;

    setState({
      ...state,
      [name]: value,
    });
  };

  // form validation
  function handleFormValidation() {
    const { screenshot, dp_order } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!screenshot) {
      formIsValid = true;
      formErrors["screenshotErr"] = t("translation2:err_image_req");
    }
    if (!dp_order) {
      formIsValid = true;
      formErrors["dpOrderErr"] = t("translation2:err_dp_order_req");
    }
    if (dp_order && !dp_order.match(/^[0-9]+$/)) {
      formIsValid = true;
      formErrors["dpNOrderErr"] = t("translation2:err_order_num_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("screenshot", state.screenshot);
    formData.append("dp_order", state.dp_order);
    handleFormValidation();
    // update screenshot
    var response = await fetch(`/api/update_screenshots/${params.id}`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      history.push("/admin/screenshots", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_screenshots")} />
      {/* Header */}
      <UserHeader title={t("text_screenshots")} />
      {loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card bg-light text-dark">
              {/* card-header */}
              <div className="card-header">
                <strong>{t("text_screenshots")}</strong>
              </div>
              <div className="card-body">
                <form method="POST" id="validate">
                  <div className="row">
                    {/* add screenshot */}
                    <div className="form-group mb-3 col-md-offset-1 col-md-6">
                      <label className="mb-3" htmlFor="screenshot">
                        {t("text_add_screenshot")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      <input
                        id="screenshot"
                        type="file"
                        className="file-input d-block"
                        name="screenshot"
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.screenshotErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.screenshotErr}
                      </label>
                      <p>
                        <b>{t("text_note")} : </b>&nbsp;
                        {t("text_image_note_270x500")}
                      </p>
                      <br />
                      <img src={insertData.screenshot} alt="img" />
                    </div>
                    {/* display order */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="mb-3" htmlFor="dp_order">
                          {t("text_display_order")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <br />
                        <input
                          id="dp_order"
                          type="text"
                          className="form-control"
                          name="dp_order"
                          value={state.dp_order}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="package_name"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.dpOrderErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.dpOrderErr}
                        </label>
                        <label
                          htmlFor="package_name"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.dpNOrderErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.dpNOrderErr}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* submit */}
                  <div className="form-group mb-3 d-flex justify-content-center mb-3">
                    <input
                      type="submit"
                      value="Submit"
                      name="submit"
                      className={`btn btn-lightpink ${
                        demouser === "1" && "disabled"
                      }`}
                      onClick={demouser === "0" && handleSubmit}
                    />
                    {/* cancel */}
                    <NavLink
                      className="btn btn-secondary ms-1 d-block"
                      to="/admin/screenshots"
                      name="cancel"
                    >
                      {t("text_btn_cancel")}
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditScreenshots;
