import { React, useState, useEffect } from "react";
import { Logout } from "../../../store/Action/FatchData";
import favicon from "../assets/images/favicon.png";
import logo from "../assets/images/logo.png";
import coin from "../assets/images/coin.png";
import { NavLink } from "react-router-dom";
import SlideBar from "./SlideBar";
import Footer from "./footer";
import { t } from "i18next";

const MainTheme = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  //manage state
  const [toggle, setToggle] = useState(true);
  const [state, setState] = useState();
  const [state1, setState1] = useState();
  const [state2, setState2] = useState(false);

  //handle slidebar hide and show
  const handleToggle = () => {
    setToggle(!toggle);
  };

  useEffect(async () => {
    var response = await fetch("/api/dashboard/" + user.member_id, {
      headers: {
        Authorization: "Bearer " + user.api_token,
      },
    });
    var res_data = await response.json();
    if (res_data) {
      setState2(true);
    }
    setState(res_data.member.join_money);
    setState1(res_data.member.wallet_balance);
  });

  return (
    <>
      <nav
        className="navbar col-lg-12 col-12 fixed-top d-flex flex-row"
        style={{ backgroundColor: "#363636", padding: "1px 1rem" }}
      >
        <div className="navbar-brand-wrapper d-flex justify-content-center">
          <div className="navbar-brand-inner-wrapper d-flex justify-content-between align-items-center w-100">
            {/* Logo Link to Home page */}
            <NavLink className="navbar-brand brand-logo" to="/">
              <img
                className="hide-on-mobile"
                src={toggle ? logo : favicon}
                id="logo"
                alt="logo"
              />
              <img
                className="show-on-mobile"
                style={{ display: "none" }}
                src={favicon}
                id="m-logo"
                alt="favicon"
              />
            </NavLink>
          </div>
          {/* toggle button */}
          <button
            className="btn"
            id="menu-toggle"
            type="button"
            onClick={handleToggle}
          >
            <span className="fa fa-align-left text-white"></span>
          </button>
        </div>
        {/* Wallet Balance */}
        <div className="dropdown d-flex justify-content-center">
          {state2 && (
            <small className="btn text-capitalize text-white" id="tot_wallet">
              <span>
                <img src={coin} alt="coin-img" className="coin" />
                &nbsp;
              </span>
              {Number(parseInt(state) + parseInt(state1)).toFixed(2)}
            </small>
          )}
          <button
            type="button"
            className="btn text-capitalize text-white dropdown-toggle"
            data-toggle="dropdown"
          >
            <i className="fa fa-user-circle-o"></i>
            <span className="hide-on-mobile"> {user.first_name}</span>
          </button>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{ marginTop: "2.5rem" }}
          >
            {/* My Profile */}
            <NavLink className="dropdown-item" to="/account/my_profile">
              <i className="fa fa-user"></i> {t("text_my_profile")}
            </NavLink>
            {/* Logout */}
            <span className="dropdown-item" onClick={Logout}>
              <i className="fa fa-key"></i> {t("text_logout")}
            </span>
          </div>
        </div>
      </nav>
      {/* Page-content */}
      <div className="d-flex" id="wrapper">
        {toggle && <SlideBar />}
        <div id="page-content-wrapper" className={!toggle && "page-content"}>
          <div className="container-fluid" style={{ marginBottom: "80px" }}>
            {/* sub header */}
            {props.title && (
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom text-dark">
                <h3 className="text-dark">{props.title}</h3>
              </div>
            )}
            {props.children}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default MainTheme;
