import { connect } from "react-redux";
import { React, useEffect, useState } from "react";
import { GameMatch } from "../../../../../../store/Action/UserFatchData";
import Title from "../../../../../../views/common/Helmet";
import MainTheme from "../../../../theme/MainTheme";
import { useParams, useLocation, NavLink } from "react-router-dom";
import { t } from "i18next";

const Position = ({ dispatch, res }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const member_id = user.member_id;
  const { id } = useParams();
  let location = useLocation();

  //game type
  let type = 1;
  if (location.state.type === "Duo") {
    type = 2;
  } else if (location.state.type === "Squad") {
    type = 4;
  } else if (location.state.type === "Squad5") {
    type = 5;
  } else {
    type = 1;
  }

  // manage state
  const [checkSelection, setCheckSelection] = useState([]);

  const handleChange = (e) => {
    e.preventDefault();
    if (e.currentTarget.checked) {
      setCheckSelection([...checkSelection, e.target.value]);
    } else {
      const newArr = checkSelection.filter((item) => item !== e.target.value);
      setCheckSelection(newArr);
    }
  };

  useEffect(() => {
    dispatch(GameMatch(id, member_id));
  }, [checkSelection, id, member_id, dispatch]);
  if (res.loading) return false;
  var joined_players = res.data.message.result;
  console.log("data", joined_players);

  return (
    <>
      <Title props={t("text_select_position")} />
      <MainTheme title={t("text_test")} backPath>
        <div className="bm-mdl-center bm-full-height overflow-auto">
          <div className="m-3">
            {checkSelection.length > type && (
              <p className="text-center text-red-500">
                Opps! You can select upto {type} seats. <br />
              </p>
            )}
            <div className="row">
              {/* type wise */}
              {joined_players
                .slice(0, joined_players.length / type)
                .map((data, index) => {
                  return type === 1 ? (
                    <>
                      <div
                        className="col-md-1 col-sm-1 col-xs-1 bordered position "
                        style={{ margin: "2px", color: "#000" }}
                      >
                        {index + 1}
                        {joined_players
                          .slice(index, index + type)
                          .map((data, index) => {
                            return (
                              <div key={index}>
                                {data.user_name ? (
                                  <input
                                    onChange={handleChange}
                                    className="w-4 h-4"
                                    type="checkbox"
                                    name="inputcheckbox"
                                    value={data.position}
                                  />
                                ) : (
                                  <input
                                    onChange={handleChange}
                                    className="w-4 h-4"
                                    type="checkbox"
                                    name="inputcheckbox"
                                    value={`${data.team}-${data.position}`}
                                    checked={
                                      checkSelection.includes(
                                        `${data.team}-${data.position}`
                                      ) && "checked"
                                    }
                                  />
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-lg-12 text-center text-dark">
                        <div
                          key={index}
                          className={`grid grid-cols-${type + 1}`}
                        >
                          <h1 className="font-semibold text-sm md:text-base my-2 text-dark">
                            {t("text_team")} {index + 1}
                          </h1>
                          {joined_players
                            .filter((data) => {
                              return data.team == index + 1;
                            })
                            .map((data, index) => {
                              return (
                                <div key={index}>
                                  {data.user_name ? (
                                    <div className="my-2">
                                      <input
                                        onChange={handleChange}
                                        className="w-4 h-4"
                                        type="checkbox"
                                        name="inputcheckbox"
                                        value={data.position}
                                        disabled
                                      />
                                    </div>
                                  ) : (
                                    <div className="my-2">
                                      <input
                                        onChange={handleChange}
                                        className="w-4 h-4"
                                        type="checkbox"
                                        name="inputcheckbox"
                                        value={`${data.team}-${data.position}`}
                                        checked={
                                          checkSelection.includes(
                                            `${data.team}-${data.position}`
                                          ) && "checked"
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </>
                  );
                })}
              {checkSelection.length > type ? (
                <p className="text-center text-red-500">
                  Opps! You can select upto {type} seats. <br />
                </p>
              ) : (
                <div className="flex items-center justify-center">
                  <NavLink
                    to={{
                      pathname: `/play/joining_position`,
                      state: {
                        selectedposition: checkSelection,
                        match_id: id,
                        match_fees: location.state.match_fees,
                        joined: location.state.joined,
                      },
                    }}
                  >
                    <button className="w-28 p-2 bg-blue-500 rounded my-5 font-semibold text-white hover:bg-blue-400">
                      {t("text_btn_join_now")}
                    </button>
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.game_match,
});

export default connect(mapStateToProps)(Position);
