import React from "react";
import coin from "../../../assets/images/coin.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";
import { t } from "i18next";

const OnGoing = ({ props }) => {
  return (
    <>
      <div className="d-flex flex-wrap justify-content-xl-between">
        <div className="border-md-right flex-grow-1 p-3 item">
          <div className="row">
            {props.my_match.length ? (
              props.my_match
                .filter((item) => item.match_status === "3")
                .map((data, index) => {
                  return (
                    <>
                      <div
                        className="col-lg-4 col-md-6 col-sm-12 mb-3"
                        key={index}
                      >
                        <div className="tour-card card br-5 overflow-hidden">
                          {/* <span className="pin-match">
                            <i
                              className="fa fa-thumb-tack text-primary"
                              aria-hidden="true"
                            ></i>
                          </span> */}
                          {/* match image */}
                          <Link
                            to={{
                              pathname: `/play/ongoing_match/${data.m_id}`,
                              state: data.match_banner,
                            }}
                          >
                            <img
                              src={data.match_banner}
                              alt="img"
                              className="img-fluid card-img-top"
                            />
                          </Link>
                          <div className="card-body">
                            {/* type */}
                            <span className="badge bg-lightpink p-2 text-white">
                              {data.type}&nbsp;
                            </span>
                            {/* map */}
                            <span className="badge bg-lightblue p-2 text-white">
                              {data.MAP}&nbsp;
                            </span>
                            <h6 className="card-title mt-3">
                              <i className="fa fa fa-bomb"></i>&nbsp;
                              {data.match_name} {t("text_for_macth_id")}
                              {data.no_of_player}
                            </h6>
                            {/* ProgressBar */}
                            <div className="row border-bottom">
                              <div className="col-8 m-auto">
                                <ProgressBar
                                  variant="primary"
                                  now={
                                    (data.no_of_player /
                                      data.number_of_position) *
                                    100
                                  }
                                />
                              </div>
                              <div className="col-4 text-center">
                                <div className="bm-card-info-item">
                                  <span className="text-secondary ">
                                    {data.no_of_player}/
                                    {data.number_of_position}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* match time */}
                            <div className="row border-bottom mb-3">
                              <div className="col-md-4">
                                <div className="bm-card-table-item text-center">
                                  <span className="bm-card-table-item-default text-lightgreen">
                                    {data.match_time}
                                  </span>
                                </div>
                              </div>
                              {/* prize pole */}
                              <div className="col-md-4 modal-div">
                                <div className="bm-card-table-item text-center pointer">
                                  <span className="bm-card-table-item-default text-primary text-uppercase">
                                    {t("text_prize_pool")}
                                  </span>
                                  <span className="bm-card-table-item-default text-primary">
                                    <i className="">
                                      <img
                                        src={coin}
                                        alt="coin-img"
                                        className="coin"
                                      />
                                    </i>
                                    &nbsp;
                                    {data.win_prize}
                                  </span>
                                </div>
                              </div>
                              {/* per kill */}
                              <div className="col-md-4">
                                <div className="bm-card-table-item text-center">
                                  <span className="bm-card-table-item-default text-lightblue text-uppercase">
                                    {t("text_per_kill")}
                                  </span>
                                  <br />
                                  <span className="bm-card-table-item-default text-lightblue">
                                    <img
                                      src={coin}
                                      className="coin"
                                      alt="img-coin"
                                    ></img>
                                    &nbsp;
                                    {data.per_kill}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* math url */}
                            <a
                              href={data.match_url}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-sm btn-block bg-lightpink text-white text-uppercase"
                            >
                              &nbsp;
                              <i className="text-white">
                                <img
                                  src={coin}
                                  alt="coin-img"
                                  className="coin"
                                />
                              </i>
                              &nbsp;
                              {data.entry_fee}
                              {t("text_Spactate")}&nbsp;
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
            ) : (
              // If there is no Any Live Match
              <div className="row">
                <div className="col-md-12 text-center text-dark">
                  <strong>No Live Match Fonud</strong>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OnGoing;
