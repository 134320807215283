import { t } from "i18next";
import { React, useState } from "react";

const ProfileSetting = ({ props }) => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [state1, setState1] = useState({
    formErrors: {},
  });
  // manage state
  const [show, setShow] = useState(false);
  //handle alert message
  const [message, setMessage] = useState({
    status: false,
    msg: "",
  });
  const [state, setState] = useState({
    username: props[0].name,
    useremail: props[0].email,
    profile_submit: "Submit",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };
  function handleFormValidation() {
    const { username, useremail } = state;
    let formErrors = {};
    let formIsValid = true;

    if (!username) {
      formIsValid = true;
      formErrors["NameErr"] = t("translation2:err_user_name_req");
    }

    if (!useremail) {
      formIsValid = true;
      formErrors["emailErr"] = t("translation2:err_email_req");
    }

    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    var response = await fetch(`/api/update_profile_data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    console.log(res_data);
    setShow(true);
    setMessage({ status: res_data.status, msg: res_data.message });
  };
  return (
    <>
      <div className="row mb-4" id="profile_setting">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            <div
              className={
                (!message.status
                  ? "alert alert-danger "
                  : "alert alert-success ") +
                "alert-dismissible fade show " +
                (show ? "d-block" : "d-none")
              }
              role="alert"
            >
              {message.msg}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
            <div className="card-header">
              <strong>{t("text_profile_setting")}</strong>
            </div>
            <div className="card-body">
              <div className="col-md-12">
                <form method="POST" id="profile-form">
                  <div className="row">
                    <div className="form-group pb-3 col-md-6">
                      <label htmlFor="username" className="pb-3">
                        {t("text_user_name")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="username"
                        value={state.username}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.NameErr ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.NameErr}
                      </label>
                    </div>
                    <div className="form-group pb-3 col-md-6">
                      <label htmlFor="useremail" className="pb-3">
                        {t("text_email")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="last_name"
                        type="text"
                        className="form-control"
                        name="useremail"
                        value={state.useremail}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.emailErr ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.emailErr}
                      </label>
                    </div>
                  </div>
                  <div className="form-group pb-3 text-center">
                    <input
                      type="submit"
                      value="Submit"
                      name="profile_submit"
                      className="btn btn-lightpink"
                      onClick={handleSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSetting;
