import React from "react";
import { NavLink } from "react-router-dom";
import { t } from "i18next";

const ContactUsSetting = () => {
  return (
    <>
      <div className="col-lg-3 col-md-6 dash-box pointer">
        <NavLink to="/admin/my_profile">
          <div className="bg-lightblue small-box card card-sm-3">
            <div className="card-icon ">
              <i className="fa fa-cog"></i>
            </div>
            <div className="card-wrap">
              <div className="card-header">
                <h4>{t("text_contact_us_setting")}</h4>
              </div>
              <div className="card-body"></div>
            </div>
          </div>
        </NavLink>
      </div>
    </>
  );
};

export default ContactUsSetting;
