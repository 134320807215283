import coin from "../../../assets/images/coin.png";
import { React, useEffect } from "react";
import UserHeader from "../../../theme/UserHeader";
import MainTheme from "../../../theme/MainTheme";
import { ResultMatch } from "../../../../../store/Action/UserFatchData";
import { useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const ResultDetail = ({ dispatch, res }) => {
  const location = useLocation();
  var { id } = useParams();

  useEffect(() => {
    dispatch(ResultMatch(id));
  }, [dispatch, id]);
  var data = res.data;

  return (
    <>
      <MainTheme>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <UserHeader props={data.match_deatils.match_name} />
            <div className="row">
              <div className="col-lg-12">
                {/* Game Image */}
                <img
                  src={location.state}
                  alt="img"
                  className="img-fluid1 img-responsive"
                />
                <div className="card my-3">
                  <div className="card-body dashboard-tabs p-0 bg-lightgray">
                    <div className="border-md-right flex-grow-1 p-3">
                      {/* Match Details */}
                      <h6 className="text-lightgreen">
                        {data.match_deatils.match_name}
                        {t("text_for_macth_id")}
                        {data.match_deatils.m_id}
                      </h6>
                      {/* match time */}
                      <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
                        {t("text_organised")}:&nbsp;
                        <strong> {data.match_deatils.match_time} </strong>
                      </span>
                      {/* Winning Prize */}
                      <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
                        {t("text_winning_prize")}:&nbsp;
                        <strong>
                          <i style={{ color: "#000" }}>
                            &nbsp;
                            <img src={coin} alt="coin-img" className="coin" />
                          </i>
                          &nbsp;
                          {data.match_deatils.win_prize}&nbsp;
                        </strong>
                      </span>
                      {/* Per kill */}
                      <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
                        {t("text_per_kill")} :&nbsp;
                        <strong>
                          &nbsp;
                          <i style={{ color: "#000" }}>
                            <img src={coin} alt="coin-img" className="coin" />
                          </i>
                          &nbsp;
                          {data.match_deatils.per_kill}&nbsp;
                        </strong>
                      </span>
                      {/* Entry Fees */}
                      <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
                        {t("text_entry_fee")} :&nbsp;
                        <strong>
                          &nbsp;
                          <i style={{ color: "#000" }}>
                            <img src={coin} alt="coin-img" className="coin" />
                          </i>
                          &nbsp;
                          {data.match_deatils.entry_fee}&nbsp;
                        </strong>
                      </span>
                    </div>
                    <div className="col-lg-12">
                      {/* table winner */}
                      <table className="table tr-bordered bg-white box-shadow">
                        <caption
                          className="bg-lightgreen text-white text-center"
                          style={{ captionSide: "top" }}
                        >
                          {t("text_winner")}
                        </caption>
                        <tbody>
                          <tr className="bg-black text-white">
                            <th>#</th>
                            <th>{t("text_player_name")}</th>
                            <th>{t("text_kills")}</th>
                            <th>{t("text_winner")}</th>
                          </tr>
                          {data.match_winner.map((data, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{data.user_name}</td>
                                <td>{data.killed}</td>
                                <td>{data.total_win}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {/* table full-result */}
                      <table className="table tr-bordered bg-white box-shadow">
                        <caption
                          className="bg-lightgreen text-white text-center"
                          style={{ captionSide: "top" }}
                        >
                          {t("text_full_result")}
                        </caption>
                        <thead>
                          <tr className="bg-black text-white">
                            <th>#</th>
                            <th>{t("text_player_name")}</th>
                            <th>{t("text_kills")}</th>
                            <th>{t("text_winner")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.full_result.map((data, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{data.user_name}</td>
                                <td>{data.killed}</td>
                                <td>{data.total_win}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.result_match,
});

export default connect(mapStateToProps)(ResultDetail);
