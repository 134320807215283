import { React, useEffect, useState } from "react";
import UserHeader from "../../../theme/userHeader";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import Title from "../../../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const EditHowToPlay = () => {
  const history = useHistory();
  const params = useParams();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    htp_content_title: "",
    htp_content_image: "",
    htp_order: "",
  });
  const [editor, setEditor] = useState({
    htp_content_text: "",
  });
  const [insertData, SetInsertData] = useState({});
  const [state1, setState1] = useState({
    formErrors: {},
  });

  //on page refresh data is get
  useEffect(async () => {
    var response = await fetch(`/api/get_insert_htp/${params.id}`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    if (res_data) {
      setLoading(false);
    }
    SetInsertData(res_data.get_feature[0]);
    setState((state) => ({
      htp_content_title: res_data.get_feature[0].htp_content_title,
      htp_content_image: "",
      htp_order: res_data.get_feature[0].htp_order,
    }));
  }, []);

  useEffect(() => {
    setEditor((editor) => ({
      htp_content_text: insertData.htp_content_text,
    }));
  }, [insertData]);
  const handleChange = (e) => {
    const { name } = e.target;
    if (name == "htp_content_image") var value = e.target.files[0];
    else value = e.target.value;

    setState({
      ...state,
      [name]: value,
    });
  };

  // form validation
  function handleFormValidation() {
    const { htp_content_title, htp_content_image, htp_order } = state;
    const { htp_content_text } = editor;
    let formErrors = {};
    let formIsValid = true;
    if (!htp_content_title) {
      formIsValid = true;
      formErrors["htpContentTitleErr"] = t(
        "translation2:err_htp_content_title_req"
      );
    }
    if (!htp_content_image) {
      formIsValid = true;
      formErrors["htpcontentImageErr"] = t("translation2:err_image_req");
    }
    if (!htp_order) {
      formIsValid = true;
      formErrors["htpOrderErr"] = t("translation2:download_image");
    }
    if (htp_order && !htp_order.match(/^[0-9]+$/)) {
      formIsValid = true;
      formErrors["htpNOrderErr"] = t("translation2:err_order_num_req");
    }
    if (!htp_content_text) {
      formIsValid = true;
      formErrors["htpContentTextErr"] = t(
        "translation2:err_htp_content_text_req"
      );
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  // submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      ...state,
      ...editor,
    };
    handleFormValidation();
    var formData = new FormData();
    formData.append("htp_content_title", state.htp_content_title);
    formData.append("htp_content_image", state.htp_content_image);
    formData.append("htp_order", state.htp_order);
    formData.append("htp_content_text", editor.htp_content_text);
    // update how to play
    var response = await fetch(`/api/update_howtoplay/${params.id}`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      history.push("/admin/how_to_play", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    } else {
    }
  };

  return (
    <>
      <Title props={t("text_howtoplay")} />
      {/* Header */}
      <UserHeader title={t("text_howtoplay")} />
      {loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card bg-light text-dark">
              {/* card-header */}
              <div className="card-header">
                <strong>{t("text_howtoplay")}</strong>
              </div>
              <div className="card-body px-3">
                <form method="POST" id="validate" action="">
                  <div className="row">
                    {/* edit htp content title */}
                    <div className="form-group col-md-6 mt-2">
                      <label htmlFor="htp_content_title">
                        {t("text_edit_htp_content_title")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      <input
                        id="htp_content_title"
                        type="text"
                        className="form-control mt-2"
                        name="htp_content_title"
                        value={state.htp_content_title}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.htpContentTitleErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.htpContentTitleErr}
                      </label>
                    </div>
                    {/* htp content image */}
                    <div className="form-group col-md-6 mt-2">
                      <label htmlFor="htp_content_image">
                        {t("text_edit_htp_content_image")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      <input
                        id="htp_content_image"
                        type="file"
                        className="file-input d-block mt-2 "
                        name="htp_content_image"
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.htpcontentImageErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.htpcontentImageErr}
                      </label>

                      <input
                        type="hidden"
                        id="file-input"
                        name="old_htp_content_image"
                        value="202001201329411580341981__moneyprize.jpg"
                        className="form-control-file mt-2"
                      />
                      <br />
                      <img src={insertData.htp_content_image} />
                    </div>
                    {/* htp content text */}
                    <div className="form-group col-12 mt-2">
                      <label htmlFor="htp_content_text">
                        {t("text_edit_htp_content_text")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      {/* ckeditor */}
                      <CKEditor
                        data={editor.htp_content_text}
                        onChange={(event, editor) => {
                          const data = event.editor.getData();
                          setEditor({
                            htp_content_text: data,
                          });
                        }}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.htpContentTextErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.htpContentTextErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    {/* htp order */}
                    <div className="form-group col-md-6 mt-2">
                      <label htmlFor="htp_order">
                        {t("text_edit_htp_content_order")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      <input
                        id="htp_order"
                        type="text"
                        className="form-control mt-2"
                        name="htp_order"
                        value={state.htp_order}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.htpOrderErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.htpOrderErr}
                      </label>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.htpNOrderErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.htpNOrderErr}
                      </label>
                    </div>
                  </div>
                  {/* submit */}
                  <div className="form-group d-flex justify-content-center mt-3">
                    <input
                      type="submit"
                      value="Submit"
                      name="submit"
                      className={`btn btn-lightpink ${
                        demouser === "1" && "disabled"
                      }`}
                      onClick={demouser === "0" && handleSubmit}
                    />
                    {/* cancel */}
                    <NavLink to="/admin/how_to_play">
                      <input
                        type="submit"
                        value="cancel"
                        name="cancel"
                        className=" btn btn-secondary ms-1"
                      />
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditHowToPlay;
