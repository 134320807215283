// statistics page
import React from "react";
import { useEffect } from "react";
import Title from "../../../common/Helmet";
import MainTheme from "../../theme/MainTheme";
import { connect } from "react-redux";
import { My_statistics_data } from "../../../../store/Action/UserFatchData";
import coin from "../../../assets/images/coin.png";
import Scrollbars from "react-custom-scrollbars";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const My_statistics = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(My_statistics_data());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_my_statistics")} />
      <MainTheme title={t("text_my_statistics")} backPath>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <Scrollbars>
            <div className="static-table">
              <table className="table table-responsive bg-white">
                <thead className="btn-green">
                  <tr>
                    <th style={{ width: "2%" }}>#</th>
                    <th style={{ width: "50%" }}>{t("text_match_info")}</th>
                    <th style={{ width: "20%" }}>{t("text_paid")}</th>
                    <th style={{ width: "20%" }}>{t("text_won")}</th>
                  </tr>
                </thead>
                <tbody className="text-black">
                  {data.my_statistics.map((data, index) => (
                    <tr key={index}>
                      <td style={{ width: "2%" }}>{index + 1}</td>
                      <td style={{ width: "50%" }}>
                        {data.match_name} {t("text_for_macth_id")}
                        {data.m_id}
                        <br />
                        {data.match_time}
                      </td>
                      <td style={{ width: "20%" }}>
                        <img src={coin} className="coin" alt="img-coin" />
                        {Number(data.paid).toFixed(2)}
                      </td>
                      <td style={{ width: "20%" }}>
                        <img src={coin} className="coin" alt="img-coin" />
                        {Number(data.won).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Scrollbars>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.my_statistics,
});

export default connect(mapStateToProps)(My_statistics);
