import React from "react";
import { t } from "i18next";

const AppUpload2 = () => {
  const $ = window.$;
  const user = JSON.parse(sessionStorage.getItem("user"));

  // table appupload
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#appupload2");
      var table1 = table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: "/api/get_app_setting",
          headers: {
            Authorization: user.api_token,
          },
          type: "POST",
          dataSrc: "get_app_upload_data",
          scrollX: false,
        },
        columns: [
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          {
            data: "app_upload",
            render: function (data, type, row) {
              return data.substring(data.lastIndexOf("/") + 1);
            },
          },
          { data: "app_version" },
          { data: "force_update" },
          { data: "force_logged_out" },
          {
            data: "app_upload",
            render: function (data, type, row) {
              return (
                "<a class='text-lightpink text-decoration-none' href=" +
                data +
                " target='_blank'>" +
                data +
                "</a>"
              );
            },
          },
          { data: "date_created" },
        ],
        Destroy: true,
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            <div className="card-header">
              <strong>{t("text_appupload")}</strong>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <div className="card card-primary">
                  <div className="card-header">
                    <h4>{t("text_appupload")}</h4>
                  </div>
                  <div className="card-body">
                    <table
                      id="appupload2"
                      className="display table table-bordered mb-2 "
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>{t("text_sr_no")}</th>
                          <th>{t("text_app")}</th>
                          <th>{t("text_version_code")}</th>
                          <th>{t("text_force_update")}</th>
                          <th>{t("text_logout")}</th>
                          <th>{t("text_link")}</th>
                          <th>{t("text_date")}</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                      <tfoot>
                        <tr>
                          <th>{t("text_sr_no")}</th>
                          <th>{t("text_app")}</th>
                          <th>{t("text_version_code")}</th>
                          <th>{t("text_force_update")}</th>
                          <th>{t("text_logout")}</th>
                          <th>{t("text_link")}</th>
                          <th>{t("text_date")}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppUpload2;
