import { React, useState, useEffect } from "react";
import { t } from "i18next";
import { CKEditor } from "ckeditor4-react";
import coin from "../../../assets/images/coin.png";
const OfflineP = ({ props, onMessage }) => {
  const [editor, setEditor] = useState("");
  const [state, setState] = useState({
    id: props.id,
    payment_name: props.payment_name,
    payment_description: props.payment_description,
    payment_status: props.payment_status,
    name: props.name,
    mid: props.mid,
    mkey: props.mkey,
    wname: props.wname,
    itype: props.itype,
    currency: props.currency,
    currency_point: props.currency_point,
    date: props.date,
    status: props.status,
    created_date: props.created_date,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
    // onMessage({
    //     ...state,
    //     [name]: value
    // })
  };
  useEffect(() => {
    const data = {
      ...state,
      ...editor,
    };
    onMessage(data);
  }, [state, editor]);
  return (
    <>
      <div className="row">
        <div className="form-group col-12 mb-3">
          <label className="mb-3" htmlFor="itype">
            {t("text_payment_desc")}
          </label>
          <CKEditor
            initData={<p>{state.payment_description}</p>}
            onChange={(event, editor) => {
              const data = event.editor.getData();
              setEditor({
                payment_description: data,
              });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6 mb-3">
          <label className="mb-3" htmlFor="currency">
            {t("text_currency")}
            <span className="required" aria-required="true">
              &nbsp;*&nbsp;
            </span>
          </label>
          <select
            name="currency"
            id="currency"
            className="form-control"
            onChange={handleChange}
          >
            <option value="">Select..</option>
            <option
              value=" 3"
              selected={state.currency == "3" ? "selected" : false}
            >
              &nbsp; India Rupee
            </option>
            <option
              value=" 5"
              selected={state.currency == "5" ? "selected" : false}
            >
              US Dollar
            </option>
            <option
              value=" 6"
              selected={state.currency == "6" ? "selected" : false}
            >
              Token
            </option>
            <option
              value=" 7"
              selected={state.currency == "7" ? "selected" : false}
            >
              BDT
            </option>
            <option
              value=" 8"
              selected={state.currency == "8" ? "selected" : false}
            >
              Taka
            </option>
          </select>
        </div>
        <div className="form-group col-md-6 mb-3">
          <label className="mb-3" htmlFor="currency_point">
            {t("text_point")} (<i className="fa fa-point"></i>
            <img src={coin} className="coin" alt="img-coin" />)
            <span className="required" aria-required="true">
              &nbsp;*&nbsp;
            </span>
          </label>
          <input
            id="currency_point"
            type="text"
            className="form-control"
            name="currency_point"
            onChange={handleChange}
            value={state.currency_point}
          />
        </div>
      </div>
    </>
  );
};

export default OfflineP;
