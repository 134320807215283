import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import { t } from "i18next";

const OtherSetting = ({ props, onShow, onMessage, time, users, languages }) => {
  const $ = window.$;
  const user = sessionStorage.getItem("user");

  const [selectedOption, setSeelectedOption] = useState("");
  const [state, setState] = useState({
    active_referral: props.active_referral,
    referral: props.referral,
    referral_level1: props.referral_level1,
    referral_min_paid_fee: props.referral_min_paid_fee,
    referandearn_description: props.referandearn_description,
    match_url: props.match_url,
    share_description: props.share_description,
    min_withdrawal: props.min_withdrawal,
    min_addmoney: props.min_addmoney,
    place_point_show: props.place_point_show,
    smtp_host: props.smtp_host,
    smtp_user: props.smtp_user,
    smtp_pass: props.smtp_pass,
    smtp_port: props.smtp_port,
    smtp_secure: props.smtp_secure,
    one_signal_notification: props.one_signal_notification,
    app_id: props.app_id,
    rest_api_key: props.rest_api_key,
    admin_user: props.admin_user,
    msg91_otp: props.msg91_otp,
    under_maintenance: props.under_maintenance,
    firebase_otp: props.firebase_otp,
    fb_login: props.fb_login,
    google_login: props.google_login,
    google_client_id: props.google_client_id,
    firebase_api_key: props.firebase_api_key,
    firebase_script: props.firebase_script,
    watch_earn_description: props.watch_earn_description,
    watch_earn_note: props.watch_earn_note,
    watch_ads_per_day: props.watch_ads_per_day,
    point_on_watch_ads: props.point_on_watch_ads,
    banner_ads_show: props.banner_ads_show,
  });
  //get the id from parameter
  const params = useParams();
  const history = useHistory();
  const [display, setDisplay] = useState(true);
  const [state1, setState1] = useState({
    formErrors: {},
  });

  const handleSelect = (e) => {
    setSeelectedOption(e.target.value);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "active_referral" && value == "0") {
      setDisplay(false);
    } else if (name == "active_referral" && value == "1") {
      setDisplay(true);
    }
    setState({
      ...state,
      [name]: value,
    });
  };
  function handleFormValidation() {
    const {
      active_referral,
      match_url,
      referral,
      referral_level1,
      referral_min_paid_fee,
      referandearn_description,
      share_description,
      min_withdrawal,
      min_addmoney,
      place_point_show,
      smtp_host,
      smtp_user,
      smtp_pass,
      smtp_port,
      smtp_secure,
      one_signal_notification,
      admin_user,
      msg91_otp,
      under_maintenance,
      firebase_otp,
      fb_login,
      google_login,
      watch_ads_per_day,
      point_on_watch_ads,
      banner_ads_show,
    } = state;

    let formErrors = {};
    let formIsValid = true;

    if (!active_referral) {
      formIsValid = true;
      formErrors["activeReferralErr"] = t(
        "translation2:err_active_referral_req"
      );
    }
    if (!referral) {
      formIsValid = true;
      formErrors["referralErr"] = t("translation2:err_referral_req");
    }
    if (!referral_level1) {
      formIsValid = true;
      formErrors["referralLevalErr"] = t(
        "translation2:err_referral_level1_req"
      );
    }
    if (!referral_min_paid_fee) {
      formIsValid = true;
      formErrors["referralMinPaidFeesErr"] = t(
        "translation2:err_referral_level1_req"
      );
    }
    if (!referandearn_description) {
      formIsValid = true;
      formErrors["referralEarnErr"] = t(
        "translation2:err_referandearn_desc_req"
      );
    }
    if (!match_url) {
      formIsValid = true;
      formErrors["matchUrlErr"] = t("translation2:err_match_url_req");
    }
    if (
      match_url &&
      !match_url.match(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$`"
      )
    ) {
      formIsValid = true;
      formErrors["matchMUrlErr"] = t("translation2:err_match_url_valid");
    }

    if (!share_description) {
      formIsValid = true;
      formErrors["shareDescErr"] = t("translation2:err_share_desc_req");
    }

    if (!min_withdrawal) {
      formIsValid = true;
      formErrors["minWithdrawErr"] = t("translation2:err_min_withdrawal_req");
    }
    if (min_withdrawal && !min_withdrawal.match(/^[0-9]+$/)) {
      formIsValid = true;
      formErrors["minNWithdrawErr"] = t("translation2:err_order_num_req");
    }
    if (!min_addmoney) {
      formIsValid = true;
      formErrors["minAddmoneyErr"] = t("translation2:err_min_addmoney_req");
    }
    if (min_addmoney && !min_addmoney.match(/^[0-9]+$/)) {
      formIsValid = true;
      formErrors["minNAddmoneyErr"] = t("translation2:err_order_num_req");
    }
    if (!place_point_show) {
      formIsValid = true;
      formErrors["placePointErr"] = t("translation2:err_place_point_req");
    }
    if (!smtp_host) {
      formIsValid = true;
      formErrors["smtpHostErr"] = t("translation2:err_smtp_host_req");
    }
    if (!smtp_user) {
      formIsValid = true;
      formErrors["smtpUserErr"] = t("translation2:err_smtp_user_req");
    }
    if (!smtp_pass) {
      formIsValid = true;
      formErrors["smtpPassErr"] = t("translation2:err_smtp_pass_req");
    }
    if (!smtp_port) {
      formIsValid = true;
      formErrors["smtpPortErr"] = t("translation2:err_smtp_port_req");
    }
    if (!smtp_secure) {
      formIsValid = true;
      formErrors["smtpSecureErr"] = t("translation2:err_smtp_secure_req");
    }
    if (!one_signal_notification) {
      formIsValid = true;
      formErrors["oneSignalNotiErr"] = t("translation2:err_one_signal_req");
    }
    if (!admin_user) {
      formIsValid = true;
      formErrors["adminUserErr"] = t("translation2:err_admin_user_req");
    }
    if (!msg91_otp) {
      formIsValid = true;
      formErrors["msg91OtpErr"] = t("translation2:err_msg91_otp_req");
    }
    if (!under_maintenance) {
      formIsValid = true;
      formErrors["underMainErr"] = t("translation2:err_under_maintenance_req");
    }
    if (!firebase_otp) {
      formIsValid = true;
      formErrors["firebaseOtpErr"] = t("translation2:err_firebase_otp_req");
    }
    if (!fb_login) {
      formIsValid = true;
      formErrors["fbLoginErr"] = t("translation2:err_fb_login_req");
    }
    if (!google_login) {
      formIsValid = true;
      formErrors["googleLoginErr"] = t("translation2:err_google_login_req");
    }
    if (!watch_ads_per_day) {
      formIsValid = true;
      formErrors["watchAdsErr"] = t("translation2:err_watch_ads_per_day_req");
    }
    if (!point_on_watch_ads) {
      formIsValid = true;
      formErrors["pointWatchErr"] = t(
        "translation2:err_point_on_watch_ads_req"
      );
    }
    if (point_on_watch_ads && !point_on_watch_ads.match(/^[0-9]+$/)) {
      formIsValid = true;
      formErrors["pointNWatchErr"] = t("translation2:err_order_num_req");
    }
    if (!banner_ads_show) {
      formIsValid = true;
      formErrors["bannerNShowErr"] = t("translation2:err_banner_ads_show_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    var response = await fetch(`/api/update_other_setting`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      window.scrollTo(0, 0);
      onShow(true);
      onMessage({ status: res_data.status, msg: res_data.message });
    }
  };
  $(document).ready(function () {
    $(".languages").multiselect({
      maxHeight: 200,
      nonSelectedText: "Select Language",
      buttonWidth: "100%",
      maxWidth: "100%",
      width: "100%",
      enableFiltering: true,
      enableCaseInsensitiveFiltering: true,
      buttonClass: "btn btn-outline-dark",
      templates: {
        filter:
          '<li class="multiselect-item multiselect-filter"><input class="form-control multiselect-search" type="text" /></li>',
      },
    });
  });

  return (
    <>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            <div className="card-header">
              <strong>{t("text_other_setting")}</strong>
            </div>
            <div className="card-body">
              <div className="col-md-12">
                <form className="needs-validation" id="app-form" method="POST">
                  <h6>
                    <b>{t("text_referral_setting")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="active_referral" className="mb-3">
                        {t("text_active_referral")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <div className="form-group col-md-12 d-flex">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="yes"
                            name="active_referral"
                            type="radio"
                            className="custom-control-input"
                            value="1"
                            onChange={handleChange}
                            defaultChecked={
                              props.active_referral === "1" && true
                            }
                          />
                          &nbsp;
                          <label className="custom-control-label" htmlFor="yes">
                            {t("text_yes")}
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="no"
                            name="active_referral"
                            type="radio"
                            className="custom-control-input"
                            value="0"
                            onChange={handleChange}
                            defaultChecked={
                              props.active_referral === "0" && true
                            }
                          />
                          &nbsp;
                          <label className="custom-control-label" htmlFor="no">
                            {t("text_no")}
                          </label>
                        </div>
                      </div>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.activeReferralErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.activeReferralErr}
                      </label>
                    </div>
                  </div>
                  <div
                    className={`row referral-div ${
                      display ? "visible" : "hidden"
                    }`}
                  >
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="referral" className="mb-3">
                        {t("text_main_user")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="referral"
                        type="text"
                        name="referral"
                        className="form-control"
                        value={state.referral}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.referralErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.referralErr}
                      </label>
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="referral_level1">
                        {t("text_referral_user")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="referral_level1"
                        type="text"
                        className="form-control"
                        name="referral_level1"
                        value={state.referral_level1}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.referralLevalErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.referralLevalErr}
                      </label>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6 mb-3">
                        <label htmlFor="referral_min_paid_fee" className="mb-3">
                          {t("text_referral_minimun_paid_fees")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          id="referral_min_paid_fee"
                          type="text"
                          className="form-control"
                          name="referral_min_paid_fee"
                          value={state.referral_min_paid_fee}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="referral_min_paid_fee"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.referralMinPaidFeesErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.referralMinPaidFeesErr}
                        </label>
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <label
                          htmlFor="referandearn_description  "
                          className="mb-3"
                        >
                          {t("text_refer_earn_desc")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <textarea
                          id="referandearn_description"
                          type="text"
                          className="form-control"
                          name="referandearn_description"
                          value={state.referandearn_description}
                          onChange={handleChange}
                        ></textarea>
                        <label
                          htmlFor="package_name"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.referralEarnErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.referralEarnErr}
                        </label>
                      </div>
                    </div>
                  </div>
                  <h6>
                    <b>{t("text_match_url_setting")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="form-group col-12 mb-3">
                      <label htmlFor="match_url" className="mb-3">
                        {t("text_match_url")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <textarea
                        id="match_url"
                        type="text"
                        className="form-control"
                        name="match_url"
                        value={state.match_url}
                        onChange={handleChange}
                      ></textarea>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.matchUrlErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.matchUrlErr}
                      </label>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.matchMUrlErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.matchMUrlErr}
                      </label>
                    </div>
                  </div>
                  <h6>
                    <b>{t("text_share_setting")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="share_description " className="mb-3">
                        {t("text_share_desc")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <textarea
                        id="share_description"
                        type="text"
                        name="share_description"
                        className="form-control"
                        value={state.share_description}
                        onChange={handleChange}
                      ></textarea>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.shareDescErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.shareDescErr}
                      </label>
                    </div>
                  </div>
                  <h6>
                    <b>{t("text_withdraw_setting")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="min_withdrawal ">
                        {t("text_min_withdrawal")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="min_withdrawal"
                        type="text"
                        name="min_withdrawal"
                        className="form-control"
                        value={state.min_withdrawal}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.minWithdrawErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.minWithdrawErr}
                      </label>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.minNWithdrawErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.minNWithdrawErr}
                      </label>
                    </div>
                  </div>
                  <h6>
                    <b>{t("text_deposit_setting")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="min_addmoney " className="mb-3">
                        {t("text_min_deposit")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="min_withdrawal"
                        type="text"
                        name="min_addmoney"
                        className="form-control"
                        value={state.min_addmoney}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.minAddmoneyErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.minAddmoneyErr}
                      </label>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.minNAddmoneyErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.minNAddmoneyErr}
                      </label>
                    </div>
                  </div>
                  <h6>
                    <b></b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="place_point_show" className="mb-3">
                        {t("text_place_point_show")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <div className="form-group col-md-12 d-flex">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="place_point_show_yes"
                            name="place_point_show"
                            type="radio"
                            className="custom-control-input"
                            value="yes"
                            onChange={handleChange}
                            defaultChecked={
                              props.place_point_show === "yes" && true
                            }
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="place_point_show_yes"
                          >
                            {t("text_yes")}
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="place_point_show_no"
                            name="place_point_show"
                            type="radio"
                            className="custom-control-input"
                            value="no"
                            onClick={handleChange}
                            defaultChecked={
                              props.place_point_show === "no" && true
                            }
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="place_point_show_no"
                          >
                            {t("text_no")}
                          </label>
                        </div>
                      </div>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.placePointErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.placePointErr}
                      </label>
                    </div>
                  </div>
                  <h6>
                    <b>{t("text_smtp_mail_setting")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="smtp_host" className="mb-3">
                        {t("text_smtp_host")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      <input
                        id="smtp_host"
                        type="text"
                        name="smtp_host"
                        className="form-control"
                        value={state.smtp_host}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.smtp_host ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.smtp_host}
                      </label>
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="smtp_user ">
                        {t("text_smtp_user")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="smtp_user"
                        type="text"
                        name="smtp_user"
                        className="form-control"
                        value={state.smtp_user}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.smtpUserErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.smtpUserErr}
                      </label>
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="smtp_pass" className="mb-3">
                        &nbsp;
                        {t("text_smtp_password")}&nbsp;
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="smtp_pass"
                        type="text"
                        name="smtp_pass"
                        className="form-control"
                        value={state.smtp_pass}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.smtpPassErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.smtpPassErr}
                      </label>
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="smtp_port" className="mb-3">
                        &nbsp;
                        {t("text_smtp_port")}&nbsp;
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="smtp_port"
                        type="text"
                        name="smtp_port"
                        className="form-control"
                        value={state.smtp_port}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.smtpPortErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.smtpPortErr}
                      </label>
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="smtp_secure " className="mb-3">
                        {t("text_smtp_secure")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <div className="form-group col-md-12 mb-3 d-flex">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="smtp_secure_tls"
                            name="smtp_secure"
                            type="radio"
                            className="custom-control-input"
                            value="tls"
                            onChange={handleChange}
                            defaultChecked={props.smtp_secure === "tls" && true}
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="smtp_secure_tls"
                          >
                            {t("text_tls")}
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="smtp_secure_ssl"
                            name="smtp_secure"
                            type="radio"
                            className="custom-control-input"
                            value="ssl"
                            onChange={handleChange}
                            defaultChecked={props.smtp_secure === "ssl" && true}
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="smtp_secure_ssl"
                          >
                            {t("text_ssl")}
                          </label>
                        </div>
                      </div>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.smtpSecureErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.smtpSecureErr}
                      </label>
                    </div>
                  </div>
                  <h6>
                    <b>{t("text_one_signal_notification_setting")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="one_signal_notification" className="mb-3">
                        {t("text_one_signal_notification")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      <div className="form-group col-md-12 d-flex">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="one_signal_notification_yes"
                            name="one_signal_notification"
                            type="radio"
                            className="custom-control-input"
                            value="1"
                            onChange={handleChange}
                            defaultChecked={
                              props.one_signal_notification === "1" && true
                            }
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="one_signal_notification_yes"
                          >
                            {t("text_yes")}
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="one_signal_notification_no"
                            name="one_signal_notification"
                            type="radio"
                            className="custom-control-input"
                            value="0"
                            onChange={handleChange}
                            defaultChecked={
                              props.one_signal_notification === "0" && true
                            }
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="one_signal_notification_no"
                          >
                            {t("text_no")}
                          </label>
                        </div>
                      </div>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.oneSignalNotiErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.oneSignalNotiErr}
                      </label>
                    </div>
                  </div>
                  <div className="row one_signal_notification-div ">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="app_id " className="mb-3">
                        &nbsp;
                        {t("text_app_id")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="app_id"
                        type="text"
                        name="app_id"
                        className="form-control"
                        value={state.app_id}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="rest_api_key " className="mb-3">
                        {t("text_rest_api_key")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="rest_api_key"
                        type="text"
                        name="rest_api_key"
                        className="form-control"
                        value={state.rest_api_key}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <h6>
                    <b>{t("text_admin_user_setting")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="admin_user " className="mb-3">
                        {t("text_admin_user")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                        <span
                          data-toggle="tooltip"
                          data-placement="right"
                          title="It is used for deduct match fee from this account when admin manually enter user from admin panel in any match."
                        >
                          <i className="fa fa-question-circle"></i>
                        </span>
                      </label>
                      <select
                        className="form-control"
                        name="admin_user"
                        onChange={handleChange}
                      >
                        {users.admin_user_name.map((data, index) => {
                          return (
                            <option value={`${data.member_id}`}>
                              {data.user_name}
                            </option>
                          );
                        })}
                      </select>
                      {/* <select
                        className="form-control"
                        name="admin_user"
                        onChange={handleChange}
                      >
                        <option value="">Select..</option>
                        <option value="1" selected="">
                          bm555
                        </option>
                      </select> */}
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.adminUserErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.adminUserErr}
                      </label>
                    </div>
                  </div>
                  <h6>
                    <b>{t("text_msg91_otp_setting")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="msg91_otp " className="mb-3">
                        {t("text_msg91_otp")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                        <span
                          data-toggle="tooltip"
                          data-placement="right"
                          title="It is used for forgot password with OTP, as firebase otp only used for login verification only."
                        >
                          <i className="fa fa-question-circle"></i>
                        </span>
                      </label>
                      <div className="form-group col-md-12 d-flex">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="msg91_otp_yes"
                            name="msg91_otp"
                            type="radio"
                            className="custom-control-input"
                            value="1"
                            onChange={handleChange}
                            defaultChecked={props.msg91_otp === "1" && true}
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="msg91_otp_yes"
                          >
                            {t("text_yes")}
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="msg91_otp_no"
                            name="msg91_otp"
                            type="radio"
                            className="custom-control-input"
                            value="0"
                            onChange={handleChange}
                            defaultChecked={props.msg91_otp === "0" && true}
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="msg91_otp_no"
                          >
                            {t("text_no")}
                          </label>
                        </div>
                      </div>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.msg91OtpErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.msg91OtpErr}
                      </label>
                    </div>
                  </div>
                  {/* <div className="row msg91_otp-div d-none">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="msg91_authkey " className="mb-3">
                        Msg91 Auth Key
                        <span className="required" aria-required="true">
                          &nbsp;
                        *&nbsp;
                        </span>
                      </label>
                      <input
                        id="msg91_authkey"
                        type="text"
                        name="msg91_authkey"
                        className="form-control"
                        value=""
                      />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="msg91_sender ">
                        Msg91 Sender
                        <span className="required" aria-required="true">
                          &nbsp;
                        *&nbsp;
                        </span>
                      </label>
                      <input
                        id="msg91_sender"
                        type="text"
                        name="msg91_sender"
                        className="form-control"
                        value=""
                      />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="msg91_route ">
                        Msg91 Routee
                        <span className="required" aria-required="true">
                          &nbsp;
                        *&nbsp;
                        </span>
                      </label>
                      <input
                        id="msg91_route"
                        type="text"
                        name="msg91_route"
                        className="form-control"
                        value=""
                      />
                    </div>
                  </div> */}
                  <h6>
                    <b>Under Maintenance Setting</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="under_maintenance " className="mb-3">
                        {t("text_under_maintenance_mode")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                        <span
                          data-toggle="tooltip"
                          data-placement="right"
                          title="It is used for show UNDER MAINTENANCE to user while update app or website"
                        >
                          <i className="fa fa-question-circle"></i>
                        </span>
                      </label>
                      <div className="form-group col-md-12 d-flex">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="under_maintenance_yes"
                            name="under_maintenance"
                            type="radio"
                            className="custom-control-input"
                            value="1"
                            onChange={handleChange}
                            defaultChecked={
                              props.under_maintenance === "1" && true
                            }
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="under_maintenance_yes"
                          >
                            {t("text_yes")}
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="under_maintenance_no"
                            name="under_maintenance"
                            type="radio"
                            className="custom-control-input"
                            value="0"
                            onChange={handleChange}
                            defaultChecked={
                              props.under_maintenance === "0" && true
                            }
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="under_maintenance_no"
                          >
                            {t("text_no")}
                          </label>
                        </div>
                      </div>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.underMainErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.underMainErr}
                      </label>
                    </div>
                  </div>
                  <h6>
                    <b>{t("text_login_setting")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="firebase_otp" className="mb-3">
                        {t("text_firebase_otp")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                        <a
                          href="https://youtu.be/dgJqXrRF6k8"
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "#000" }}
                        >
                          <i className="fa fa-question-circle "></i>
                        </a>
                      </label>
                      <div className="form-group col-md-12 mb-3 d-flex">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="firebase_otp_yes"
                            name="firebase_otp"
                            type="radio"
                            className="custom-control-input"
                            value="yes"
                            onChange={handleChange}
                            defaultChecked={
                              props.firebase_otp === "yes" && true
                            }
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="firebase_otp_yes"
                          >
                            {t("text_yes")}
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="firebase_otp_no"
                            name="firebase_otp"
                            type="radio"
                            className="custom-control-input"
                            value="no"
                            onChange={handleChange}
                            defaultChecked={props.firebase_otp === "no" && true}
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="firebase_otp_no"
                          >
                            {t("text_no")}
                          </label>
                        </div>
                      </div>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.firebaseOtpErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.firebaseOtpErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="fb_login " className="mb-3">
                        {t("text_fb_login")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                        <a
                          href="https://youtu.be/ud-V4-IAjlA"
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "#000" }}
                        >
                          <i className="fa fa-question-circle "></i>
                        </a>
                      </label>
                      <div className="form-group col-md-12 mb-3 d-flex">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="fb_login_yes"
                            name="fb_login"
                            type="radio"
                            className="custom-control-input"
                            value="yes"
                            onChange={handleChange}
                            defaultChecked={props.fb_login === "yes" && true}
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="fb_login_yes"
                          >
                            {t("text_yes")}
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="fb_login_no"
                            name="fb_login"
                            type="radio"
                            className="custom-control-input"
                            value="no"
                            onChange={handleChange}
                            defaultChecked={props.fb_login === "no" && true}
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="fb_login_no"
                          >
                            {t("text_no")}
                          </label>
                        </div>
                      </div>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.fbLoginErr ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.fbLoginErr}
                      </label>
                    </div>
                    {/* <div className="form-group col-md-6 fb_app_id_div d-none mb-3">
                      <label htmlFor="fb_app_id" className="mb-3">
                        Facebook App ID
                        <span className="required" aria-required="true">
                          &nbsp;
                        *&nbsp;
                        </span>
                      </label>
                      <input
                        id="fb_app_id"
                        type="text"
                        className="form-control"
                        name="fb_app_id"
                        value=""
                      />
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="google_login " className="mb-3">
                        {t("text_google_login")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                        <a
                          href="https://youtu.be/7qtytQk1m7c"
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "#000" }}
                        >
                          <i className="fa fa-question-circle "></i>
                        </a>
                      </label>
                      <div className="form-group col-md-12 mb-3 d-flex">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="google_login_yes"
                            name="google_login"
                            type="radio"
                            className="custom-control-input"
                            value="yes"
                            onChange={handleChange}
                            defaultChecked={
                              props.google_login === "yes" && true
                            }
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="google_login_yes"
                          >
                            {t("text_yes")}
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="google_login_no"
                            name="google_login"
                            type="radio"
                            className="custom-control-input"
                            value="no"
                            onChange={handleChange}
                            defaultChecked={props.google_login === "no" && true}
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="google_login_no"
                          >
                            {t("text_no")}
                          </label>
                        </div>
                      </div>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.googleLoginErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.googleLoginErr}
                      </label>
                    </div>
                    <div className="form-group col-md-6 google_client_id_div mb-3 ">
                      <label htmlFor="google_client_id  " className="mb-3">
                        {t("text_gogole_client_id")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="google_client_id"
                        type="text"
                        className="form-control"
                        name="google_client_id"
                        value={state.google_client_id}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="firebase_api_key_div ">
                    <div className="form-group col-md-12 mb-3">
                      <label htmlFor="firebase_api_key" className="mb-3">
                        {t("text_firebase_api_key")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="firebase_api_key"
                        type="text"
                        name="firebase_api_key"
                        className="form-control"
                        value={state.firebase_api_key}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col-md-12 mb-3">
                      <label htmlFor="firebase_script" className="mb-3">
                        {t("text_firebase_script")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <textarea
                        id="firebase_script"
                        type="text"
                        name="firebase_script"
                        className="form-control"
                        value={state.firebase_script}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <h6>
                    <b>{t("text_ads_setting")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="watch_earn_description" className="mb-3">
                        {t("text_watch_earn_description")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <textarea
                        id="watch_earn_description"
                        type="text"
                        name="watch_earn_description"
                        className="form-control"
                        value={state.watch_earn_description}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="watch_earn_note" className="mb-3">
                        {t("text_watch_earn_note")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <textarea
                        id="watch_earn_note"
                        type="text"
                        name="watch_earn_note"
                        className="form-control"
                        value={state.watch_earn_note}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="watch_ads_per_day" className="mb-3">
                        {t("text_watch_ads_per_day")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="watch_ads_per_day"
                        type="text"
                        name="watch_ads_per_day"
                        className="form-control"
                        value={state.watch_ads_per_day}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.watchAdsErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.watchAdsErr}
                      </label>
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="point_on_watch_ads" className="mb-3">
                        {t("text_point_on_watch_ads")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="point_on_watch_ads"
                        type="text"
                        name="point_on_watch_ads"
                        className="form-control"
                        value={state.point_on_watch_ads}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.pointWatchErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.pointWatchErr}
                      </label>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.pointNWatchErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.pointNWatchErr}
                      </label>
                    </div>
                  </div>
                  <h6>
                    <b>{t("text_banner_ads_setting")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="watch_earn_description" className="mb-3">
                        {t("text_banner_ads_show")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <div className="form-group col-md-12 d-flex">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="banner_ads_show_yes"
                            name="banner_ads_show"
                            type="radio"
                            className="custom-control-input"
                            value="yes"
                            onChange={handleChange}
                            defaultChecked={
                              props.banner_ads_show === "yes" && true
                            }
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="banner_ads_show_yes"
                          >
                            {t("text_yes")}
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="banner_ads_show_no"
                            name="banner_ads_show"
                            type="radio"
                            className="custom-control-input"
                            value="no"
                            onChange={handleChange}
                            defaultChecked={
                              props.banner_ads_show === "no" && true
                            }
                          />
                          &nbsp;
                          <label
                            className="custom-control-label"
                            htmlFor="banner_ads_show_no"
                          >
                            {t("text_no")}
                          </label>
                        </div>
                      </div>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.bannerNShowErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.bannerNShowErr}
                      </label>
                    </div>
                  </div>
                  <h6>
                    <b>{t("text_timezone_setting")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="timezone " className="mb-3">
                        {t("text_timezone")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <select
                        className="form-control"
                        name="timezone"
                        onChange={handleChange}
                      >
                        {time.map((data) => {
                          return <option value={`${data}`}>{data}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  <h6>
                    <b>{t("text_language_setting")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="language " className="mb-3">
                        {t("text_language")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <select
                        className="form-control languages"
                        name="languages"
                        onChange={handleChange}
                        multiselect
                      >
                        <option value="">Select One</option>
                        {Object.keys(languages).map((key, index) => {
                          return (
                            <option key={index} value={languages[key]}>
                              {languages[key]}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <h6>
                    <b>{t("text_script")}</b>
                  </h6>
                  <hr />
                  <div className="row">
                    <div className="form-group col-md-12 mb-3">
                      <label htmlFor="footer_script" className="mb-3">
                        {t("text_footer_script")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <textarea
                        id="footer_script"
                        type="text"
                        name="footer_script"
                        className="form-control"
                        value={state.footer_script}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="form-group d-flex justify-content-center mb-3">
                    <input
                      type="submit"
                      value="Submit"
                      name="other_submit"
                      className="btn btn-lightpink"
                      onClick={handleSubmit}
                    />
                    <NavLink
                      className="btn btn-secondary ms-1 d-block"
                      to="/admin/app_setting"
                      name="cancel"
                    >
                      {t("text_btn_cancel")}
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherSetting;
