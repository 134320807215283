import { React } from "react";
import Title from "../../common/Helmet";
import MainTheme from "../theme/MainTheme";
import coin from "../../assets/images/coin.png";
import { t } from "i18next";

const My_statistics = () => {
  const $ = window.$;
  var user = JSON.parse(localStorage.getItem("user"));

  // statistics table
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#statistics");
      table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: `/api/my_statistics/${user.member_id}`,
          headers: {
            Authorization: "Bearer " + user.api_token,
          },
          type: "POST",
          dataSrc: "my_statistics",
        },
        columns: [
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          {
            data: null,
            render: function (data, type, row) {
              var num = row.match_name + " - Match # " + row.m_id;
              return num;
            },
          },
          {
            data: "paid",
            render: function (data, type, row) {
              return Number(data).toFixed(2);
            },
          },
          {
            data: "won",
            render: function (data, type, row) {
              return Number(data).toFixed(2);
            },
          },
          { data: "match_time" },
        ],
        Destroy: true,
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    // error throw
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      <Title props={t("text_my_statistics")} />
      <MainTheme title={t("text_my_statistics")}>
        <div className="card bg-light text-dark">
          <div className="card-header">
            <strong>{t("text_my_statistics")}</strong>
          </div>
          {/* Table */}
          <div className="card-body">
            <table
              id="statistics"
              className="display table table-bordered mb-2"
            >
              {/* Table Head */}
              <thead>
                <tr>
                  <th>{t("text_sr_no")}</th>
                  <th>{t("text_match_info")}</th>
                  <th>
                    {t("text_paid")}
                    {"("}
                    <img src={coin} className="coin" alt="img-coin" />
                    {")"}
                  </th>
                  <th>
                    {t("text_won")}
                    {"("}
                    <img src={coin} className="coin" alt="img-coin" />
                    {")"}
                  </th>
                  <th>{t("text_date")}</th>
                </tr>
              </thead>
              {/* table Footer */}
              <tfoot>
                <tr>
                  <th>{t("text_sr_no")}</th>
                  <th>{t("text_match_info")}</th>
                  <th>
                    {t("text_paid")}
                    {"("}
                    <img src={coin} className="coin" alt="img-coin" />
                    {")"}
                  </th>
                  <th>
                    {t("text_won")}
                    {"("}
                    <img src={coin} className="coin" alt="img-coin" />
                    {")"}
                  </th>
                  <th>{t("text_date")}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </MainTheme>
    </>
  );
};

export default My_statistics;
