import { React, useState, useEffect } from "react";
import UserHeader from "../theme/userHeader";
import { CKEditor } from "ckeditor4-react";
import { NavLink, useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import Select2 from "../games/matches/Select2";
import Title from "../../../views/common/Helmet";
import { t } from "i18next";

const EditLottery = () => {
  //get the id from parameter
  const params = useParams();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  // manage state
  const [loading, setLoading] = useState(true);
  //store the data of api
  const [insertData, SetInsertData] = useState({});
  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [rule, setRule] = useState({
    lottery_rules: "",
  });
  const [state, setState] = useState({
    lottery_title: "",
    lottery_time: "",
    lottery_fees: "",
    lottery_prize: "",
    lottery_size: "",
    lottery_image: "",
    image_id: "",
  });

  // get lottery data
  useEffect(async () => {
    var response = await fetch(`/api/get_insert_lottery/${params.id}`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    if (res_data) {
      setLoading(false);
    }
    console.log(res_data);
    SetInsertData(res_data.get_lottery[0]);
    setState({
      lottery_title: res_data.get_lottery[0].lottery_title,
      lottery_time: res_data.get_lottery[0].lottery_time,
      lottery_fees: res_data.get_lottery[0].lottery_fees,
      lottery_prize: res_data.get_lottery[0].lottery_prize,
      lottery_size: res_data.get_lottery[0].lottery_size,
    });
  }, []);

  // for lottery rules
  useEffect(() => {
    setRule((rule) => ({
      lottery_rules: insertData.lottery_rules,
    }));
  }, [insertData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // lottery image
  const handleFile = (e) => {
    const file = e.target.files[0];
    setState({
      ...state,
      lottery_image: file,
    });
  };

  const handleData = (value) => {
    setState({
      ...state,
      image_id: value,
    });
  };

  // handle form validation
  function handleFormValidation() {
    const {
      lottery_title,
      lottery_time,
      lottery_fees,
      lottery_prize,
      lottery_size,
      lottery_image,
    } = state;
    const { lottery_rules } = rule;
    let formErrors = {};
    let formIsValid = true;
    if (!lottery_title) {
      formIsValid = true;
      formErrors["lotteryTitleErr"] = t("translation2:err_lottery_title_req");
    }
    if (!lottery_time) {
      formIsValid = true;
      formErrors["lotteryTimeErr"] = t("translation2:err_lottery_time_req");
    }
    if (!lottery_fees) {
      formIsValid = true;
      formErrors["lotteryFeesErr"] = t("translation2:err_lottery_fees_req");
    }
    if (!lottery_prize) {
      formIsValid = true;
      formErrors["lotetryPrizeErr"] = t("translation2:err_lottery_prize_req");
    }
    if (!lottery_size) {
      formIsValid = true;
      formErrors["lotterySizeErr"] = t("translation2:err_lottery_size_req");
    }
    if (!lottery_rules) {
      formIsValid = true;
      formErrors["lotteryRuleErr"] = t("translation2:err_lottery_rules_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(state);
    var formData = new FormData();
    formData.append("lottery_title", state.lottery_title);
    formData.append("lottery_time", state.lottery_time);
    formData.append("lottery_fees", state.lottery_fees);
    formData.append("lottery_prize", state.lottery_prize);
    formData.append("lottery_size", state.lottery_size);
    formData.append("lottery_image", state.lottery_image);
    formData.append("lottery_rules", rule.lottery_rules);
    formData.append("image_id", state.image_id);
    handleFormValidation();
    // update lottery
    var response = await fetch(`/api/update_lottery/${params.id}`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      history.push("/admin/lottery", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_lottery")} />
      {/* Header */}
      <UserHeader title={t("text_lottery")} />
      {loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card bg-light text-dark">
              {/* card-header */}
              <div className="card-header">
                <strong>{t("text_lottery")}</strong>
              </div>
              <div className="card-body">
                <div className="col-md-12">
                  <form
                    className="needs-validation"
                    id="validate"
                    method="POST"
                  >
                    <div className="row">
                      {/* lottery title */}
                      <div className="form-group col-md-6 mt-2">
                        <label htmlFor="lottery_title">
                          {t("text_title")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          name="lottery_title"
                          value={state.lottery_title}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="lottery_title"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.lotteryTitleErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.lotteryTitleErr}
                        </label>
                      </div>
                      {/* lottery time */}
                      <div className="form-group col-md-6 mt-2">
                        <label htmlFor="lottery_time">
                          {t("text_time")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          name="lottery_time"
                          id="datetimepicker1"
                          value={state.lottery_time}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="lottery_time"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.lotteryTimeErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.lotteryTimeErr}
                        </label>
                      </div>
                      {/* lottery fees */}
                      <div className="form-group col-md-6 mt-2">
                        <label htmlFor="lottery_fees">
                          {t("text_fees")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          name="lottery_fees"
                          value={state.lottery_fees}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="lottery_fees"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.lotteryFeesErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.lotteryFeesErr}
                        </label>
                      </div>
                      <div className="form-group col-md-6 mt-2">
                        <label htmlFor="lottery_prize">
                          {t("text_prize")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          name="lottery_prize"
                          value={state.lottery_prize}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="lottery_prize"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.lotetryPrizeErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.lotetryPrizeErr}
                        </label>
                      </div>
                      {/* lottery size */}
                      <div className="form-group col-md-6 mt-2">
                        <label htmlFor="lottery_size">
                          {t("text_size")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          name="lottery_size"
                          value={state.lottery_size}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="lottery_size"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.lotterySizeErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.lotterySizeErr}
                        </label>
                      </div>
                      <div className="row">
                        {/* lottery image */}
                        <div className="form-group col-md-5 mt-2">
                          <label htmlFor="lottery_image">
                            {t("text_browse_image")}
                            <span className="required" aria-required="true">
                              &nbsp;*&nbsp;
                            </span>
                          </label>
                          <br />
                          <input
                            id="lottery_image"
                            type="file"
                            className="file-input d-block mt-2   "
                            name="lottery_image"
                            onChange={handleFile}
                          />
                          <p>
                            <b>{t("text_note")} : </b>&nbsp;
                            {t("text_image_note_1000x500")}
                          </p>
                        </div>
                        <div className="form-group col-md-1 m-auto text-center">
                          <b>
                            <u>{t("text_or")}</u>
                          </b>
                        </div>
                        <div className="form-group col-md-6 mt-2">
                          <label htmlFor="image_id">
                            {t("text_select_image")}&nbsp;
                            <span className="required" aria-required="true">
                              &nbsp;*&nbsp;
                            </span>
                          </label>
                          <Select2 onHandle={handleData} />
                        </div>
                        {/* ckeditor for lottery rules */}
                        <div className="form-group col-12">
                          <label htmlFor="lottery_rules">
                            {t("text_rules")}
                          </label>
                          <CKEditor
                            initData={<p>{rule.lottery_rules}</p>}
                            onChange={(event, editor) => {
                              const data = event.editor.getData();
                              setRule({
                                lottery_rules: data,
                              });
                            }}
                          />
                        </div>
                        <label
                          htmlFor="lottery_rules"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.lotteryRuleErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.lotteryRuleErr}
                        </label>
                      </div>
                    </div>
                    {/* submit */}
                    <div className="form-group d-flex justify-content-center mt-3">
                      <input
                        type="submit"
                        value="Submit"
                        name="submit"
                        className={`btn btn-lightpink ${
                          demouser === "1" && "disabled"
                        }`}
                        onClick={demouser === "0" && handleSubmit}
                      />
                      {/* cancel */}
                      <NavLink to="/admin/lottery">
                        <input
                          type="submit"
                          value="cancel"
                          name="cancel"
                          className=" btn btn-secondary ms-1"
                        />
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditLottery;
