import React from "react";
import Title from "../../common/Helmet";
import MainTheme from "../theme/MainTheme";
import Announcement from "./play_detail/Announcement";
import Sliders from "./play_detail/slider";
import AllGames from "./play_detail/allGames";
import { t } from "i18next";

const Play = () => {
  return (
    <>
      <Title props={t("text_all_games")} />
      <MainTheme title={t("text_all_game")}>
        <Announcement />
        <Sliders />
        <AllGames />
      </MainTheme>
    </>
  );
};

export default Play;
