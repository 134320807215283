import { React, useEffect } from "react";
import Title from "../../common/Helmet";
import MainTheme from "../theme/MainTheme";
import { AppTutorialData } from "../../../store/Action/UserFatchData";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const AppTutorial = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(AppTutorialData());
  }, [dispatch]);
  var data = res.data.youtube_links;

  return (
    <>
      <Title props={t("text_app_tutorial")} />
      <MainTheme title={t("text_app_tutorial")}>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <div className="row">
            {data.map((data, index) => {
              return (
                <div className="col-4" key={index}>
                  <div className="card mt-3">
                    <div className="card-body  bg-lightgray">
                      <h6 className="text-dark">{data.youtube_link_title}</h6>
                      <iframe
                        src={data.youtube_link.replace("watch?v=", "embed/")}
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className="d-block"
                        style={{ width: "100%", height: "100%" }}
                      ></iframe>
                      <a
                        target="_blank"
                        href={data.youtube_link}
                        className="btn btn-green mt-2 text-uppercase"
                        style={{ width: "100%" }}
                      >
                        {t("text_play_in_youtube")}
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </MainTheme>
    </>
  );
};

//redux connect
const mapStateToProps = (state) => ({
  res: state.AppTutorialData,
});

export default connect(mapStateToProps)(AppTutorial);
