import React from "react";
import Title from "../../common/Helmet";
import MainTheme from "../theme/MainTheme";
import CountdownTimer from "react-component-countdown-timer";
import { t } from "i18next";

const WatchAndEarn = () => {
  return (
    <>
      <Title props={t("text_watch_more_to_earn_more")} />
      <MainTheme title={t("text_watch_more_to_earn_more")}>
        <div className="row">
          <div className="col-12 text-dark text-center">
            <h4 className="bm_text_lightgreen text-uppercase">
              {t("text_watch_more_to_earn_more")}
            </h4>
            <h6 className="bm_text_lightgreen text-uppercase mt-5">
              {t("text_complete_task")}
            </h6>
            {/* countdown timer  */}
            <CountdownTimer
              className="btn-lightgreen border-dashed px-2 m-auto"
              count={27396}
              border={false}
              showTitle={false}
              noPoints={false}
              responsive={true}
              hideDay={true}
              backgroundColor="#91e2c0"
            />
            <img src="" alt="Earn-img" className="p-5" />
            <div>
              <button className="btn btn-rounded btn-lightgreen text-uppercase text-white">
                {t("text_task_completed")}
              </button>
            </div>
          </div>
        </div>
      </MainTheme>
    </>
  );
};

export default WatchAndEarn;
