import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import Title from "../../../../../views/common/Helmet";
import { t } from "i18next";

const HowToPlays = ({ props }) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  // manage state
  const [state, setState] = useState({
    htp_title: props.htp_title,
    htp_text: props.htp_text,
  });
  const [message, setMessage] = useState({
    status: false,
    msg: "",
  });
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [show, setShow] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // handle Form Validation
  function handleFormValidation() {
    const { htp_title, htp_text } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!htp_title) {
      formIsValid = true;
      formErrors["htpTitleErr"] = t("translation2:err_htp_title_req");
    }
    if (!htp_text) {
      formIsValid = true;
      formErrors["htpTextErr"] = t("translation2:err_htp_text_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    // update how to play
    var response = await fetch(`/api/update_howto_play`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      setShow(true);
      setMessage({ status: res_data.status, msg: res_data.message });
    }
  };

  return (
    <>
      <Title props={t("text_howtoplay")} />
      {/* message */}
      <div
        className={
          (!message.status ? "alert alert-danger " : "alert alert-success ") +
          "alert-dismissible fade show " +
          (show ? "d-block" : "d-none")
        }
        role="alert"
      >
        {message.msg}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            {/* card-header */}
            <div className="card-header">
              <strong>{t("text_edit_howtoplay_setting")}</strong>
            </div>
            <div className="card-body">
              {/* htp title */}
              <div className="col-md-12 px-3">
                <form method="POST" id="validate">
                  <div className="form-group mt-2">
                    <label htmlFor="htp_title">{t("text_title")}</label>
                    <input
                      type="text"
                      id="htp_title"
                      name="htp_title"
                      value={state.htp_title}
                      onChange={handleChange}
                      className="form-control mt-2"
                    />
                    <label
                      htmlFor="package_name"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.htpTitleErr ? " d-block" : "d-none")
                      }
                    >
                      {state1.formErrors.htpTitleErr}
                    </label>
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="htp_text">{t("text_sub_title")}</label>
                    <textarea
                      id="htp_text"
                      name="htp_text"
                      rows="5"
                      value={state.htp_text}
                      onChange={handleChange}
                      className="form-control mt-2"
                    ></textarea>
                    <label
                      htmlFor="package_name"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.htpTextErr ? " d-block" : "d-none")
                      }
                    >
                      {state1.formErrors.htpTextErr}
                    </label>
                  </div>
                  {/* submit */}
                  <div className="form-group d-flex justify-content-center mt-3">
                    <input
                      type="submit"
                      value="Submit"
                      name="submit"
                      className={`btn btn-lightpink ${
                        demouser === "1" && "disabled"
                      }`}
                      onClick={demouser === "0" && handleSubmit}
                    />
                    {/* cancel */}
                    <NavLink to="/admin/how_to_play">
                      <input
                        type="submit"
                        value="cancel"
                        name="cancel"
                        className=" btn btn-secondary ms-1"
                      />
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowToPlays;
