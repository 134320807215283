import { React, useEffect } from "react";
import { CustomerSupportData } from "../../../../store/Action/UserFatchData";
import MainTheme from "../../theme/MainTheme";
import Spinner from "react-bootstrap/Spinner";
import Title from "../../../common/Helmet";
import { connect } from "react-redux";
import { t } from "i18next";

const CustomerSupport = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(CustomerSupportData());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_customer_supports")} />
      <MainTheme title={t("text_customer_supports")} backPath>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <div className="bm-content-listing">
            <div className="container support">
              <div className="row">
                <div className="col-12 text-dark">
                  <ul className="list-unstyled f-18">
                    {/* Address */}
                    <li className="py-3 align-middle border-bottom border-dark">
                      {t("text_address")}:
                    </li>
                    {/* Phone */}
                    <li className="py-3 align-middle border-bottom border-dark">
                      {t("text_phone")}:
                      <span className="bm_text_lightgreen float-end">
                        <a
                          onClick={() => window.open("tel:")}
                          className="support bm_text_lightgreen"
                          href="#phone"
                        >
                          <i className="fa fa-2x fa-phone"></i>
                        </a>
                        &nbsp;
                        <a
                          onClick={() =>
                            window.open("https://api.whatsapp.com/send?phone=")
                          }
                          className="support bm_text_lightgreen"
                          href="#whatsapp"
                        >
                          <i className="fa fa-2x fa-whatsapp ml-2"></i>
                        </a>
                      </span>
                    </li>

                    {/* email */}
                    <li className="py-3 align-middle border-bottom border-dark">
                      {t("text_email")}:&nbsp;
                      {data.customer_support.company_email}
                      <span className="float-end bm_text_lightgreen pointer">
                        <a
                          onClick={() =>
                            window.open(
                              `mailto:${data.customer_support.company_email}`
                            )
                          }
                          className="support bm_text_lightgreen"
                        >
                          <i className="fa fa-2x fa-envelope"></i>
                        </a>
                      </span>
                    </li>

                    {/* Instagram */}
                    <li className="py-3 align-middle border-bottom border-dark">
                      {t("text_instagram")}:
                      <span className="float-end bm_text_lightgreen">
                        <a
                          onClick={() =>
                            window.open("https://www.instagram.com/")
                          }
                          className="support bm_text_lightgreen"
                          href="#instagram"
                        >
                          <i className="fa fa-2x fa-instagram"></i>
                        </a>
                      </span>
                    </li>

                    {/* Address */}
                    <li className="py-3 align-middle border-bottom border-dark">
                      {t("text_street")}:
                    </li>

                    {/* support time */}
                    <li className="py-3 align-middle border-bottom border-dark">
                      {t("text_support_time")}:
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </MainTheme>
    </>
  );
};

//redux connect
const mapStateToProps = (state) => ({
  res: state.support,
});

export default connect(mapStateToProps)(CustomerSupport);
