import { React, useState } from "react";
import UserHeader from "../theme/userHeader";
import { useHistory, useLocation } from "react-router-dom";
import coin from "../../assets/images/coin.png";
import Title from "../../../views/common/Helmet";
import copy from "copy-to-clipboard";
import Swal from "sweetalert2";
import { t } from "i18next";

const WithdrawRequest = () => {
  const $ = window.$;
  const history = useHistory();
  const location = useLocation();
  const user = JSON.parse(sessionStorage.getItem("user"));

  // manage state
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  // Copy word
  const handleCopy = (copyword) => {
    copy(copyword);
    setShow(true);
    setMessage("copy to clipboard");
  };

  //change the order status
  const handleStatus = (id, status, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>Are you sure to change status?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = {
          note_id: status,
        };
        var response = await fetch(`/api/change_withdraw_status/${id}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
          body: JSON.stringify(result),
        });
        var res_data = await response.json();
        if (res_data) {
          setShow(true);
          setMessage(res_data.message);
          table1.ajax.reload(null, false);
        }
      }
    });
  };

  // user
  const handleUser = (id) => {
    history.push(`/admin/all_users/user_details/${id}`);
  };

  // table moneywithdraw
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#moneywithdraw");
      var table1 = table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: "/api/get_withdraw_request",
          headers: {
            Authorization: user.api_token,
          },
          type: "POST",
          dataSrc: "withdraw",
        },
        columns: [
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          {
            data: "user_name",
            render: function (data) {
              return (
                "<a class='text-lightpink pointer' id='user'>" + data + "</a>"
              );
            },
          },
          {
            data: "pyatmnumber",
            render: function (data) {
              return (
                "<span>" +
                data +
                "<i class='fa fa-copy ms-1 pointer' id='copy'></i></span>"
              );
            },
          },
          {
            data: null,
            render: function (data, type, row) {
              var withdraw = row.withdraw;
              var currency_decimal_place = row.currency_decimal_place;
              var currency_symbol = row.currency_symbol;
              var withdraw_method_currency_point =
                row.withdraw_method_currency_point;
              return (
                Number(withdraw).toFixed(currency_decimal_place) +
                " - " +
                currency_symbol +
                " " +
                Number(withdraw / withdraw_method_currency_point).toFixed(
                  currency_decimal_place
                )
              );
            },
          },
          { data: "withdraw_method" },
          {
            data: "note_id",
            render: function (data) {
              if (data == 9) {
                return `<select id='btn-status'><option value='9'>pending</option><option value='1'>complete</option></select>`;
              } else if (data == 1) {
                return '<span class="badge badge-success pointer">complete</span>';
              } else {
                return '<span class="badge bg-warning pointer">pending</span>';
              }
            },
          },
          { data: "accountstatement_dateCreated" },
        ],
        Destroy: true,
      });
      $("#moneywithdraw tbody").on("click", "#user", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().member_id;
        handleUser(id);
      });
      $("#moneywithdraw tbody").on("click", "#copy", function () {
        var row = $(this).parents("tr")[0];
        const copyword = table1.row(row).data().pyatmnumber;
        handleCopy(copyword);
      });
      $("#moneywithdraw tbody").on("change", "#btn-status", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().account_statement_id;
        const status = $("#btn-status option:selected").val();
        handleStatus(id, status, table1);
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      <Title props={t("text_withdraw_requests")} />
      <UserHeader title={t("text_withdraw_requests")} />
      <div className="row">
        <div className="col-md-12">
          <div
            className={
              "alert alert-success alert-dismissible fade show " +
              (show ? "d-block" : "d-none")
            }
            role="alert"
          >
            {location.state && !message ? (
              <span>{location.state.state.data}</span>
            ) : (
              <span></span>
            )}
            {!location.state && message ? (
              <span>{message}</span>
            ) : (
              <span></span>
            )}
            {location.state && message ? <span>{message}</span> : <span></span>}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <table
            id="moneywithdraw"
            className="display table table-bordered mb-2"
          >
            <thead className="mt-5">
              <tr>
                <th>{t("text_sr_no")}</th>
                <th>{t("text_user_name")}</th>
                <th>{t("text_mobile_no")}</th>
                <th>
                  {t("text_amount")}
                  {"("}
                  <img src={coin} className="coin" alt="img-coin" />
                  {")"}
                </th>
                <th>{t("text_withdraw_method")}</th>
                <th>{t("text_status")}</th>

                <th>{t("text_date")}</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>{t("text_sr_no")}</th>
                <th>{t("text_user_name")}</th>
                <th>{t("text_mobile_no")}</th>
                <th>
                  {t("text_amount")}
                  {"("}
                  <img src={coin} className="coin" alt="img-coin" />
                  {")"}
                </th>
                <th>{t("text_withdraw_method")}</th>
                <th>{t("text_status")}</th>
                <th>{t("text_date")}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default WithdrawRequest;
