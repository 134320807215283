import React, { useEffect, useState } from "react";
import UserHeader from "../../theme/userHeader";
import coin from "../../../assets/images/coin.png";
import { NavLink, useParams } from "react-router-dom";
import MatchDetails from "./MatchDetails";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { t } from "i18next";

const MatchJoin = () => {
  const $ = window.$;
  const params = useParams();
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [insertData, SetInsertData] = useState([{}]);
  const [state, setState] = useState({
    place: "",
    place_point: "",
    killed: "",
    win: "",
    win_prize: "",
    bonus: "",
    total_win: "",
    refund: "",
  });
  const [notification, setNotification] = useState({
    result_notification: "",
    add_result_notification: "",
  });

  // table postable
  $(document).ready(function () {
    $("#postable").DataTable({
      paging: false,
      ordering: false,
      info: false,
    });
    $.fn.dataTableExt.sErrMode = "throw";
  });

  useEffect(async () => {
    var response = await fetch(`/api/member_join_match/${params.id}`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    console.log(res_data);
    SetInsertData(res_data.join_member_details);
    res_data.join_member_details.map((data) => {
      setState({
        place: data.place,
        place_point: data.place_point,
        killed: data.killed,
        win: data.win,
        win_prize: data.win_prize,
        bonus: data.bonus,
        total_win: data.total_win,
        refund: data.refund,
      });
    });
    setNotification(res_data.result_notes[0].result_notification);
  }, []);

  //change the join detail
  const handleJoin = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSwal = () => {
    Swal.fire({
      icon: "success",
      html: "<h2>Success ?</h2>" + "<p>Records Updated</p>",
      confirmButtonText: "ok",
      confirmButtonColor: "#2778c4",
    });
  };
  const handleChange = async (e) => {
    e.preventDefault();
    console.log(state);
    var response = await fetch(`/api/update_member_join_match/${params.id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      handleSwal();
    } else {
    }
  };
  //update success then call function

  //notify when login success
  const notify = () =>
    toast.success(t("translation1:text_succ_success_update"));

  //update the result notification
  const handleResponse = async (e) => {
    e.preventDefault();
    var response = await fetch(`/api/update_member_join_match/${params.id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(notification),
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      console.log("duaksd");
      notify();
    }
  };
  console.log(insertData);

  // $(document).ready(function () {
  //   $("#myInput").on("keyup", function () {
  //     var value = $(this).val().toLowerCase();
  //     $("#myTable tbody tr").filter(function () {
  //       window
  //         .$(this)
  //         .toggle($(this).text().toLowerCase().indexOf(value) > -1);
  //     });
  //   });
  //   $.ajax({
  //     type: "GET",
  //     url: "<?php echo base_url() . $this->path_to_view_admin; ?>/matches/get_result_notification",
  //     data: {
  //       match_id: $("#notification_id").val(),
  //     },
  //     success: function funSuccess(response) {
  //       var data = JSON.parse(response);
  //       $("#result_notification").val(data["result_notification"]);
  //     },
  //   });
  // });

  function count_total_win() {
    var place_point = $(`#place_point`).val();
    console.log("asd", place_point);
    var win = $(`#win`).val();
    var win_prize = $(`#win_prize`).val();
    var bonus = $(`#bonus`).val();

    if (place_point == "") place_point = 0;
    if (win == "") win = 0;
    if (win_prize == "") win_prize = 0;
    if (bonus == "") bonus = 0;

    window
      .$("#total_win")
      .val(
        (
          parseFloat(place_point) +
          parseFloat(win) +
          parseFloat(win_prize) +
          parseFloat(bonus)
        ).toFixed(2)
      );

    console.log("win", $("#total_win").val());
  }

  function count_total_win_kill() {
    var per_kill = $("#per_kill").val();
    var no_of_player = $("#no_of_player").val();
    var entry_fee = $("#entry_fee").val();

    if (per_kill == "") per_kill = 0;

    if (per_kill > 0) {
      var result = $(".killed").get();

      window
        .$("#win")
        .val(
          (
            parseFloat(per_kill) +
            parseFloat(per_kill) +
            parseFloat(per_kill) +
            parseFloat(per_kill)
          ).toFixed(2)
        );
    }
  }

  return (
    <>
      {/* toastify */}
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: "#59daa4",
          color: "white",
          boxShadow: "0px 0px 15px  white",
        }}
      />
      <UserHeader title={t("text_for_macth_id") + params.id} />
      <MatchDetails />
      <div className="row">
        <div className="col-md-12">
          <form name="frmmatchlist" method="post">
            <div id="" className="handsontable">
              <div className="table-responsive">
                <table
                  style={{
                    display: "table",
                    width: "100%",
                    borderTop: "1px solid rgba(0, 0, 0, 0.3)",
                  }}
                  id="postable"
                  className="table table-striped table-bordered order-main-tbl"
                >
                  <colgroup>
                    <col style={{ width: "1%" }} />
                    <col style={{ width: "2%" }} />
                    <col style={{ width: "2%" }} />
                    <col style={{ width: "2%" }} />
                    <col style={{ width: "2%" }} />
                    <col style={{ width: "2%" }} />
                    <col style={{ width: "2%" }} />
                    <col style={{ width: "2%" }} />
                    <col style={{ width: "2%" }} />
                    <col style={{ width: "2%" }} />
                    <col style={{ width: "2%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>{t("text_sr_no")}</th>
                      <th> {t("text_game_id")}</th>
                      <th>{t("text_user_name")}</th>
                      <th>{t("text_place")}</th>
                      <th>{t("text_place_point")}</th>
                      <th>{t("text_killed")}</th>
                      <th>
                        {t("text_kill_win")}(
                        <img src={coin} className="coin" alt="coin-img" />)
                      </th>
                      <th>
                        {t("text_win_prize")}(
                        <img src={coin} className="coin" alt="coin-img" />)
                      </th>
                      <th>
                        {t("text_bonus")}(
                        <img src={coin} className="coin" alt="coin-img" />)
                      </th>
                      <th>
                        {t("text_total_win")}(
                        <img src={coin} className="coin" alt="coin-img" />)
                      </th>
                      <th>
                        {t("text_refund")}(
                        <img src={coin} className="coin" alt="coin-img" />)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {insertData.map((data, i) => {
                      return (
                        <tr key={i + 1}>
                          <td id="id1" className="match_member">
                            {i + 1}
                            <input
                              type="hidden"
                              id="match_join_member_id1"
                              value="96"
                            />
                            <input type="hidden" id="member_id1" value="21" />
                            <input type="hidden" id="match_id1" value="57" />
                          </td>
                          <td id="pubg_id1">{data.pubg_id}</td>
                          <td id="user_name1">
                            <NavLink
                              to={`/admin/all_users/user_details/${data.member_id}`}
                              className="text-lightpink text-decoration-none"
                            >
                              {data.user_name}
                            </NavLink>
                          </td>
                          <td>
                            <input
                              type="text"
                              id="place"
                              name="place"
                              className="numbers"
                              value={state.place}
                              onChange={handleJoin}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              id="place_point"
                              className="numbers"
                              name="place_point"
                              value={state.place_point}
                              onChange={handleJoin}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              id="killed"
                              className="numbers"
                              name="killed"
                              value={state.killed}
                              onChange={handleJoin}
                            />
                            <input type="hidden" id="per_kill1" value="0" />
                          </td>
                          <td>
                            <input
                              type="text"
                              id="win"
                              className="numbers"
                              name="win"
                              // value={state.win}
                              // onChange={handleJoin}
                              disabled
                              // onKeyUp={count_total_win_kill()}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              id="win_prize"
                              className="numbers"
                              name="win_prize"
                              value={state.win_prize}
                              onChange={handleJoin}
                              onKeyUp={count_total_win()}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              id="bonus"
                              className="numbers"
                              name="bonus"
                              value={state.bonus}
                              onChange={handleJoin}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              id="total_win"
                              readonly=""
                              className="numbers"
                              name="total_win"
                              // value={state.total_win}
                              // onChange={handleJoin}
                              disabled
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              id="refund"
                              className="numbers"
                              name="refund"
                              value={state.refund}
                              onChange={handleJoin}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="form-group text-center mt-3">
              <input
                type="button"
                value="Update"
                name="update"
                className="btn btn-lightpink "
                onClick={handleChange}
              />
              <a className="btn btn-secondary ms-1" href="#" name="cancel">
                {t("text_btn_cancel")}
              </a>
            </div>
          </form>
        </div>
        <div className="card-body">
          <div className="card card-primary">
            <div className="card-header">
              <h4 className="text-dark">{t("text_result_notes")}</h4>
            </div>
            <div className="card-body">
              <form id="result-notification" method="post">
                <input
                  type="hidden"
                  id="notification_id"
                  name="notification_id"
                  value="57"
                />
                <label htmlFor="result_notification" className="text-dark">
                  {t("text_result_notes")}
                </label>
                <textarea
                  className="form-control rounded-0"
                  id="result_notification"
                  name="result_notification"
                  rows="5"
                  value={notification.result_notification}
                  onChange={(e) =>
                    setNotification({
                      ...notification,
                      result_notification: e.target.value,
                    })
                  }
                ></textarea>
                <span className="error" style={{ display: "none" }}>
                  {t("text_result_notes_err")}
                </span>
                <div className="form-group text-center mt-3">
                  <input
                    type="button"
                    value="Send"
                    name="add_result_notification"
                    className="btn btn-lightpink "
                    onClick={handleResponse}
                  />
                  <a className="btn btn-secondary ms-1" href="#" name="cancel">
                    {t("text_btn_cancel")}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchJoin;
