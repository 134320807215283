import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Title from "../../../views/common/Helmet";
import MainTheme from "../theme/MainTheme";
import copy from "copy-to-clipboard";
import { Dashboard } from "../../../store/Action/UserFatchData";
import { connect } from "react-redux";
import ReferEarn from "../../assets/images/how_refer_earn.jpg";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Earndetails = ({ dispatch, res }) => {
  // manage state
  const [copyWord, setCopy] = useState("");

  useEffect(() => {
    dispatch(Dashboard());
  }, [dispatch]);

  // copy the clipboard
  const clipBoard = () => {
    copy(data.member.user_name);
    setCopy("copy the clipBoard");
    setTimeout(() => {
      setCopy("");
    }, 1000);
  };
  var data = res.data;

  return (
    <>
      <Title props={t("text_refer_earn")} />
      <MainTheme backPath popupMenu>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <Scrollbars>
            <div className="bm-content-listing">
              <div className="container support">
                <div className="row">
                  <div className="col-12 text-dark text-center">
                    <h4 className="bm_text_lightgreen text-uppercase">
                      &nbsp;
                      {t("text_refer_more_earn_more")}
                    </h4>
                    <p>{data.web_config.referandearn_description}</p>
                    <br />
                    <p className="bm_text_lightgreen mb-2 text-uppercase">
                      {t("text_your_referral_code")}
                    </p>
                    <h5
                      id="refer-code"
                      style={{ cursor: "pointer" }}
                      onClick={clipBoard}
                    >
                      {data.member.user_name} &nbsp;
                      <i className="fa fa-copy ml-3"></i>
                    </h5>
                    <br />
                    <span
                      className="copied  text-white bg-black rounded px-2"
                      style={{ position: "absolute", left: "35%" }}
                    >
                      &nbsp;
                      {copyWord}
                    </span>
                    <img
                      src={ReferEarn}
                      alt="Earn-img"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.dashboard,
});

export default connect(mapStateToProps)(Earndetails);
