import { useEffect, React } from "react";
import { connect } from "react-redux";
import { ResultMatch } from "../../../../store/Action/UserFatchData";
import MainTheme from "../../theme/MainTheme";
import UserHeader from "../../theme/header";
import coin from "../../../../views/assets/images/coin.png";
import { Scrollbars } from "react-custom-scrollbars";
import Title from "../../../../views/common/Helmet";
import { useParams, useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Result_Match = ({ dispatch, res }) => {
  var { id } = useParams();
  var location = useLocation();
  useEffect(() => {
    dispatch(ResultMatch(id));
  }, [dispatch, id]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_single_match")} />
      <MainTheme>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <UserHeader title={data.match_deatils.match_name} backPath />
            <Scrollbars>
              <div className="bm-mdl-center bm-full-height m-2">
                <div className="match-info">
                  <img
                    src={location.query}
                    alt="img"
                    className="img-fluid card-img-top"
                  />
                </div>
                <div className="match-result">
                  <h6 className="bm_text_lightgreen mt-3">
                    {/* match id  */}
                    {data.match_deatils.match_name}
                    {t("text_for_macth_id")}
                    {data.match_deatils.m_id}
                  </h6>
                  <span className="btn btn-sm btn-white shadow m-1">
                    {/* time  */}
                    {t("text_organised_on")} :&nbsp;
                    <strong> {data.match_deatils.match_time} </strong>
                  </span>
                  <span className="btn btn-sm btn-white shadow m-1">
                    {/* winning prize  */}
                    {t("text_winning_prize")} :&nbsp;
                    <strong>
                      <i>
                        <img src={coin} className="coin" alt="img-coin" />
                      </i>
                      &nbsp;
                      {data.match_deatils.win_prize}
                    </strong>
                  </span>
                  {/* per kill */}
                  <span className="btn btn-sm btn-white shadow m-1">
                    {t("text_per_kill")} :&nbsp;
                    <strong>
                      <i>
                        <img src={coin} className="coin" alt="img-coin" />
                      </i>
                      &nbsp;
                      {data.match_deatils.per_kill}
                    </strong>
                  </span>
                  {/* entry fees */}
                  <span className="btn btn-sm btn-white shadow m-1">
                    {t("text_entry_fee")} :&nbsp;
                    <strong>
                      <i>
                        <img src={coin} className="coin" alt="img-coin" />
                      </i>
                      &nbsp;
                      {data.match_deatils.entry_fee}
                    </strong>
                  </span>
                  {/* winner */}
                  <div className="winner mt-3 shadow-sm">
                    <span className="btn-green rounded-top p-10 text-center f-18 d-block">
                      {t("text_winner")}
                    </span>
                    <table className="table table-responsive">
                      <thead className="thead-dark bg-dark text-white">
                        <tr>
                          <th>#</th>
                          <th>{t("text_player_name")}</th>
                          <th>{t("text_kills")}</th>
                          <th>{t("text_winning")}</th>
                        </tr>
                      </thead>
                      <tbody className="text-black">
                        {data.match_winner.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.user_name}</td>
                            <td>{data.killed}</td>
                            <td>{data.total_win}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* result */}
                  <div className="winner mt-3 shadow-sm">
                    <span className="btn-green rounded-top p-10 text-center f-18 d-block">
                      {t("text_full_result")}
                    </span>
                    <table className="table table-responsive">
                      <thead className="thead-dark bg-dark text-white">
                        <tr>
                          <th>#</th>
                          <th>{t("text_player_name")}</th>
                          <th>{t("text_kills")}</th>
                          <th>{t("text_winning")}</th>
                        </tr>
                      </thead>
                      <tbody className="text-black">
                        {data.full_result.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.user_name}</td>
                            <td>{data.killed}</td>
                            <td>{data.total_win}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.result_match,
});

export default connect(mapStateToProps)(Result_Match);
