import React from "react";

const UserHeader = ({ props }) => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom text-dark">
        <h3 className="text-dark">{props}</h3>
      </div>
    </>
  );
};

export default UserHeader;
