import React from "react";
import BackPath from "./header_detail/backPath";
import HeadCoin from "./header_detail/headCoin";
import PopupMenu from "./header_detail/popupMenu";
import UserFooter from "./footer";

const MainTheme = (props) => {
  return (
    <main className="bm-full-width bm-full-height">
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-xl-4 col-left">
            <div className="bm-modal">
              {/* conditions for title backPath headCoin popupMenu */}
              {(props.title ||
                props.backPath ||
                props.headCoin ||
                props.popupMenu) && (
                <>
                  {/* Header */}
                  <div className="bm-mdl-header">
                    {props.backPath && <BackPath />}
                    {props.headCoin && <HeadCoin />}
                    {props.popupMenu && <PopupMenu />}
                    {/* Title on Header */}
                    {/* &nbsp; for white space */}
                    <h4 className="m-0 d-inline align-bottom">
                      &nbsp;&nbsp;{props.title}
                    </h4>
                  </div>
                </>
              )}
              {props.footer ? (
                // with footer
                <>
                  <div className="bm-mdl-center bm-full-height">
                    {props.children}
                  </div>
                  <UserFooter />
                </>
              ) : (
                // without footer
                <div className="bm-mdl-center bm-full-height pb-5">
                  {props.children}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MainTheme;
