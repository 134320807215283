import { React, useState } from "react";
import favicon from "../../assets/images/favicon.png";
import logo from "../../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import SlideBar from "./slidebar";
import Footer from "./footer";
import { t } from "i18next";

const MainTheme = (props) => {
  const admin_name = JSON.parse(sessionStorage.getItem("user"));

  //manage state
  const [toggle, setToggle] = useState(true);
  //handle slidebar hide and show
  const handleToggle = () => {
    setToggle(!toggle);
  };

  // function logout
  const Logout = () => {
    sessionStorage.removeItem("status");
    sessionStorage.removeItem("admin_user");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("demouser");
    window.location.href = "/admin";
  };

  return (
    <>
      <nav
        className="navbar col-lg-12 col-12 fixed-top d-flex flex-row"
        style={{ backgroundColor: "#363636", padding: "1px 1rem" }}
      >
        <div className="navbar-brand-wrapper d-flex justify-content-center">
          <div className="navbar-brand-inner-wrapper d-flex justify-content-between align-items-center w-100">
            {/* Logo Link to Home page */}
            <NavLink className="navbar-brand brand-logo" to="/">
              <img
                className="hide-on-mobile"
                src={toggle ? logo : favicon}
                id="logo"
                alt="logo"
              />
              <img
                className="show-on-mobile"
                style={{ display: "none" }}
                src={favicon}
                id="m-logo"
                alt="favicon"
              />
            </NavLink>
          </div>
          {/* toggle button */}
          <button
            className="btn"
            id="menu-toggle"
            type="button"
            onClick={handleToggle}
          >
            <span className="fa fa-align-left text-white"></span>
          </button>
        </div>
        {/* Admin */}
        <div className="dropdown d-flex justify-content-center">
          <button
            type="button"
            className="btn text-capitalize text-white dropdown-toggle"
            data-toggle="dropdown"
          >
            <i className="fa fa-user-circle-o"></i>
            <span className="hide-on-mobile">{admin_name.name}</span>
          </button>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{ marginTop: "2.5rem" }}
          >
            {/* My Profile */}
            <NavLink className="dropdown-item" to="/admin/my_profile">
              <i className="fa fa-user"></i> {t("text_my_profile")}
            </NavLink>
            {/* Logout */}
            <span className="dropdown-item pointer" onClick={Logout}>
              <i className="fa fa-key"></i> {t("text_logout")}
            </span>
          </div>
        </div>
      </nav>
      {/* Page-content */}
      <div className="d-flex" id="wrapper">
        {toggle && <SlideBar />}
        <div id="page-content-wrapper" className={!toggle && "page-content"}>
          <div className="container-fluid" style={{ marginBottom: "80px" }}>
            {props.children}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default MainTheme;
