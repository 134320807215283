import { React, useState } from "react";
import { useLocation } from "react-router-dom";
import Title from "../../common/Helmet";
import Header from "../theme/header";
import Footer from "../theme/footer";
import { t } from "i18next";

function Contact() {
  const location = useLocation();

  // manage state
  const [state, setState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [state1, setState1] = useState({
    formErrors: {},
  });

  // form validation
  function handleFormValidation() {
    const { name, email, subject, message } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!name) {
      formIsValid = true;
      formErrors["name"] = t("translation2:err_company_name_valid");
    }
    if (!email) {
      formIsValid = true;
      formErrors["email"] = t("translation2:err_email_req");
    }
    if (!subject) {
      formIsValid = true;
      formErrors["subject"] = t("translation2:err_subject_req");
    }
    if (!message) {
      formIsValid = true;
      formErrors["message"] = t("translation2:err_message_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //change the value of admin data is store in state
  const handleContact = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    var response = await fetch(`/api/contact_us`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      alert(res_data.message);
      setState({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    }
  };

  return (
    <>
      <Title props={t("text_contact_us")} />
      <Header subHeader path={location.pathname} />
      <section className="bm-section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-dark">
              <div className="card">
                <div className="card-body pb-5">
                  <h4>
                    <i className="fa-regular fa fa-envelope"></i>&nbsp;
                    {t("text_email")}
                  </h4>
                  <span
                    onClick={() =>
                      (window.location.href = "mailto:www.xyz@gmail.com")
                    }
                  >
                    <a
                      href="mailto:www.gmail.com"
                      style={{ textDecoration: "none" }}
                    >
                      xyz@gmail.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-dark">
              <div className="card">
                <div className="card-body">
                  <form id="login-form">
                    {/* name of user */}
                    <div className="form-group mb-3">
                      <label>
                        {t("text_full_name")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        value={state.name}
                        onChange={handleContact}
                      />
                      <label
                        htmlFor="name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.name ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.name}
                      </label>
                    </div>

                    {/* email */}
                    <div className="form-group mb-3">
                      <label>
                        {t("text_email")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        value={state.email}
                        onChange={handleContact}
                      />
                      <label
                        htmlFor="email"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.email ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.email}
                      </label>
                    </div>

                    {/* subject */}
                    <div className="form-group mb-3">
                      <label>
                        {t("text_subject")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        name="subject"
                        type="text"
                        className="form-control"
                        value={state.subject}
                        onChange={handleContact}
                      />
                      <label
                        htmlFor="subject"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.subject ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.subject}
                      </label>
                    </div>

                    {/* message */}
                    <div className="form-group mb-3">
                      <label>
                        {t("text_message")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <textarea
                        name="message"
                        type="text"
                        className="form-control"
                        rows="5"
                        value={state.message}
                        onChange={handleContact}
                      ></textarea>
                      <label
                        htmlFor="message"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.message ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.message}
                      </label>
                    </div>

                    {/* submit */}
                    <div className="form-group mb-3">
                      <input
                        className="btn btn-submit btn-block btn-lg btn-lightpink form-control"
                        type="submit"
                        onClick={handleSubmit}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
