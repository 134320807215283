import { React, useState } from "react";
import coin from "../../../../../views/assets/images/coin.png";
import { Scrollbars } from "react-custom-scrollbars";
import ReactHtmlParser from "react-html-parser";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { t } from "i18next";

const Description = (data) => {
  // get User Details from Local Storage
  var user = JSON.parse(localStorage.getItem("user"));
  var { id } = useParams();

  // manage state
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState({
    match_id: id,
    member_id: user.member_id,
    pubg_id: "",
    match_join_member_id: data.data.join_position.length
      ? data.data.join_position[0].match_join_member_id
      : "",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //update the data
  const handleUpdate = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setUpdate({
      ...update,
      [name]: value,
    });
  };
  const updateData = async (e) => {
    var response = await fetch(`/api/change_player_name`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(update),
    });
    var res_data = await response.json();
    if (res_data) {
      handleClose();
      window.location.reload();
    }
  };

  return (
    <>
      {/* modal for update username */}
      <Modal show={show} onHide={handleClose} className="mx-auto">
        <Modal.Header className="modal-header bg-modal text-center d-block text-dark">
          <Button
            className="btn "
            onClick={handleClose}
            style={{
              width: "10px",
              textAlign: "center",
              border: "1px solid gray",
              borderRadius: "50%",
              background: "transparent",
              color: "black",
              float: "right",
            }}
          >
            ×
          </Button>
          <h5 className="modal-title">{t("text_edit_player_name")}</h5>
        </Modal.Header>
        <Modal.Body className="mb-0 bg-white">
          <div className="modal-body text-black pb-0">
            <input
              type="text"
              id="pubg_id"
              name="pubg_id"
              className="form-control valid"
              value={update.pubg_id}
              onChange={handleUpdate}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-primary" onClick={updateData}>
            {t("text_btn_save")}
          </Button>
          <Button className="btn-gray" onClick={handleClose}>
            {t("text_btn_close")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Scrollbars>
        <div className="bm-content-listing tournaments">
          <div className=" bm-content-listing text-black">
            {/* match name */}
            <h6 className="bm_text_lightgreen">
              {data.data.match.match_name} {t("text_for_macth_id")}
              {data.data.match.m_id}
            </h6>
            {/* team */}
            <span className="btn btn-sm btn-white shadow m-1">
              {t("text_team")} : <strong>{data.data.match.type}</strong>&nbsp;
            </span>
            {/* entry fees */}
            <span className="btn btn-sm btn-white shadow m-1">
              {t("text_entry_fee")} :&nbsp;
              <strong>
                <i>
                  <img src={coin} className="coin" alt="img-coin" />
                </i>
                &nbsp;
                {data.data.match.entry_fee}
              </strong>
              &nbsp;
            </span>
            {/* match type */}
            <span className="btn btn-sm btn-white shadow m-1">
              {t("text_match_type")} : <strong>{t("text_free")}</strong>
            </span>
            {/* map */}
            <span className="btn btn-sm btn-white shadow m-1">
              {t("text_map")} : <strong>{data.data.match.Miramar}</strong>
            </span>
            {/* match schedule+ */}
            <span className="btn btn-sm btn-white shadow m-1">
              {t("text_match_schedule")} :&nbsp;
              <strong>{data.data.match.match_time}</strong>&nbsp;
            </span>
            {/* table */}
            <div className="f-result mt-3 shadow-sm rounded-0">
              <table className="table table-responsive">
                <thead className="btn-green">
                  <tr>
                    <th>{t("text_team")}</th>
                    <th>{t("text_position")}</th>
                    <th>{t("text_player_name")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.join_position.map((data, index) => (
                    <tr key={index}>
                      <td>
                        {t("text_team")}
                        {data.team}
                      </td>
                      <td>{data.position}</td>
                      <td>
                        <div
                          className="modal-div"
                          data-toggle="modal"
                          data-target="#myModal"
                          data-id="gopu"
                          style={{ cursor: "pointer" }}
                        >
                          {"   "}
                          {data.pubg_id}
                          <span onClick={handleShow} className="no-underline">
                            <i className="fa fa-pencil"></i>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* prize details */}
            <h6 className="bm_text_lightgreen mt-3">
              {t("text_prize_details")}
            </h6>
            {/* winning prize */}
            <span className="btn btn-sm btn-white shadow m-1">
              {t("text_winning_prize")} :&nbsp;
              <strong>
                <i>
                  <img src={coin} className="coin" alt="img-coin" />
                </i>
                &nbsp;
                {data.data.match.win_prize}
              </strong>
            </span>
            {/* per kill */}
            <span className="btn btn-sm btn-white shadow m-1">
              {t("text_per_kill")} :&nbsp;
              <strong>
                <i>
                  <img src={coin} className="coin" alt="img-coin" />
                </i>
                &nbsp;
                {data.data.match.per_kill}
              </strong>
            </span>
            {/* match description */}
            <h6 className="bm_text_lightgreen mt-3">{t("text_about_match")}</h6>
            {/* Parse JSX to HTML */}
            <span>{ReactHtmlParser(data.data.match.match_desc)}</span>
            <h6 className="bm_text_lightgreen mt-3">
              {t("text_match_private_description")}
              {/* match private Description shown only joined Player  */}
              {data.data.match.join_status && (
                <span className="text-dark">
                  {ReactHtmlParser(data.data.match.match_private_desc)}
                </span>
              )}
            </h6>
          </div>
        </div>
      </Scrollbars>
    </>
  );
};
export default Description;
