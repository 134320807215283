import { React, useEffect, useState } from "react";
import UserHeader from "../../theme/userHeader";
import { CKEditor } from "ckeditor4-react";
import { useParams, NavLink, useHistory } from "react-router-dom";
import { GetPackage } from "../../../../store/Action/AdminData";
import { connect } from "react-redux";
import Title from "../../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const EditAllGame = ({ dispatch, res }) => {
  const history = useHistory();
  const params = useParams();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  const [loading, setLoading] = useState(true);
  //manage the edit game state
  const [game, setGame] = useState({
    game_name: "",
    package_name: "",
    game_image: "",
    game_logo: "",
  });
  const [editor, setEditor] = useState({
    game_rules: "",
  });
  const [insertData, SetInsertData] = useState({});
  const [state, setState] = useState({
    formErrors: {},
  });

  //on page refresh data is get
  useEffect(async () => {
    var response = await fetch(`/api/get_game_inserted_data/${params.id}`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    if (res_data) {
      setLoading(false);
    }
    SetInsertData(res_data.get_game_data[0]);
    setGame((game) => ({
      ...game,
      game_name: res_data.get_game_data[0].game_name,
      package_name: res_data.get_game_data[0].package_name,
      game_image: "",
      game_logo: "",
    }));
  }, []);

  useEffect(() => {
    setEditor((editor) => ({
      game_rules: insertData.game_rules,
    }));
  }, [insertData]);

  //package url
  useEffect(() => {
    dispatch(GetPackage());
  }, [dispatch]);
  const data1 = res.data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGame({
      ...game,
      [name]: value,
    });
  };

  const handleFile = (e) => {
    setGame({
      ...game,
      game_image: e.target.files[0],
    });
  };
  const handleLogo = (e) => {
    setGame({
      ...game,
      game_logo: e.target.files[0],
    });
  };

  // form validation
  function handleFormValidation() {
    const { game_name, package_name } = game;
    const { game_rules } = editor;
    let formErrors = {};
    let formIsValid = true;
    if (!game_name) {
      formIsValid = true;
      formErrors["gameNameErr"] = t("translation2:err_game_name_req");
    }
    if (!package_name) {
      formIsValid = true;
      formErrors["packageNameErr"] = t("translation2:err_package_name_req");
    }
    if (!game_rules) {
      formIsValid = true;
      formErrors["gameRulesErr"] = t("translation2:err_game_rules_req");
    }
    setState({ formErrors: formErrors });
    return formIsValid;
  }

  // submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      ...game,
      ...editor,
    };
    handleFormValidation();
    var formData = new FormData();
    formData.append("game_name", game.game_name);
    formData.append("game_logo", game.game_logo);
    formData.append("game_image", game.game_image);
    formData.append("package_name", game.package_name);
    formData.append("game_rules", editor.game_rules);
    formData.append("id_prefix", 1);
    console.log(data);
    var response = await fetch(`/api/game_update_details/${params.id}`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    console.log("game", res_data);
    if (res_data.status === true) {
      history.push("/admin/all_game", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    } else {
    }
  };

  return (
    <>
      <Title props={t("text_game")} />
      <UserHeader title={t("text_game")} />
      {res.loading || loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card bg-light text-dark">
              <div className="card-header">
                <strong>{t("text_game")}</strong>
              </div>
              <div className="card-body">
                <div className="col-md-12">
                  <form
                    className="needs-validation"
                    id="validate"
                    method="POST"
                  >
                    <div className="row">
                      {/* game name */}
                      <div className="form-group mb-3 col-md-6">
                        <label htmlFor="game_name" className="mb-3">
                          {t("game_name")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="game_name"
                          value={game.game_name}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="game_name"
                          generated="true"
                          className={
                            "error " +
                            (state.formErrors.gameNameErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state.formErrors.gameNameErr}
                        </label>
                      </div>
                      {/* package name */}
                      <div className="form-group mb-3 col-md-6">
                        <label htmlFor="package_name" className="mb-3">
                          {t("text_package_name")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="package_name"
                          value={game.package_name}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="package_name"
                          generated="true"
                          className={
                            "error " +
                            (state.formErrors.packageNameErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state.formErrors.packageNameErr}
                        </label>
                        <p>
                          <b>{t("text_note")} : </b> {t("text_package_name")}
                          &nbsp;
                          {t("text_show")}&nbsp;
                          <a
                            href={data1.package_image}
                            target="_blank"
                            className="text-decoration-none text-lightpink pointer"
                          >
                            {t("text_here")}
                          </a>
                        </p>
                      </div>
                      {/* game image */}
                      <div className="form-group mb-3 col-md-6">
                        <label htmlFor="game_image" className="mb-3">
                          {t("text_image")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <br />
                        <input
                          id="game_image"
                          type="file"
                          className="file-input d-block"
                          name="game_image"
                          onChange={handleFile}
                          required
                        />
                        <label
                          htmlFor="game_image"
                          generated="true"
                          className={
                            "error " +
                            (state.formErrors.gameImageErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state.formErrors.gameImageErr}
                        </label>
                        <p>
                          <b>{t("text_note")} : </b>&nbsp;
                          {t("text_image_note_1000x500")}
                        </p>
                        <br />
                        <img src={insertData.game_image} alt="img" />
                      </div>
                      {/* game logo */}
                      <div className="form-group mb-3 col-md-6">
                        <label htmlFor="game_logo" className="mb-3">
                          {t("text_logo")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <br />
                        <input
                          id="game_logo"
                          type="file"
                          className="file-input d-block"
                          name="game_logo"
                          onChange={handleLogo}
                          required
                        />
                        <label
                          htmlFor="game_logo"
                          generated="true"
                          className={
                            "error " +
                            (state.formErrors.gameLogoErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state.formErrors.gameLogoErr}
                        </label>
                        <p>
                          <b>{t("text_note")} : </b>&nbsp;
                          {t("text_image_note_40x40")}
                        </p>
                        <br />
                        <img src={insertData.game_logo} alt="img" />
                      </div>
                      {/* game rules */}
                      <div className="form-group mb-3 col-12">
                        <label htmlFor="game_rules" className="mb-3">
                          {t("text_game_rules")}
                        </label>
                        <div>
                          &nbsp;
                          <CKEditor
                            data={editor.game_rules}
                            onChange={(event, editor) => {
                              const data = event.editor.getData();
                              setEditor({
                                game_rules: data,
                              });
                            }}
                          />
                        </div>
                        <label
                          htmlFor="game_rules"
                          generated="true"
                          className={
                            "error " +
                            (state.formErrors.gameRulesErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state.formErrors.gameRulesErr}
                        </label>
                      </div>
                    </div>
                    {/* submit */}
                    <div className="form-group mb-3  d-flex justify-content-center">
                      <input
                        type="submit"
                        value="Submit"
                        name="submit"
                        className={`btn btn-lightpink ${
                          demouser === "1" && "disabled"
                        }`}
                        onClick={demouser === "0" && handleSubmit}
                      />
                      {/* cancel */}
                      <NavLink
                        className="btn btn-secondary ms-1 d-block"
                        to="/admin/all_game"
                        name="cancel"
                      >
                        {t("text_btn_cancel")}
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.GetPackage,
});

export default connect(mapStateToProps)(EditAllGame);
