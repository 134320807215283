import React from "react";
import { useHistory } from "react-router-dom";

const BackPath = () => {
  // useHistory for going back
  const history = useHistory();

  return (
    <>
      <span className="text-white pointer" onClick={history.goBack}>
        <i className="fa fa-2x fa-long-arrow-left"></i>
      </span>
    </>
  );
};

export default BackPath;
