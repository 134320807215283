import { React } from "react";
import Title from "../../common/Helmet";
import UserHeader from "../theme/header";
import MainTheme from "../theme/MainTheme";
import { useLocation } from "react-router-dom";
import Description from "./lotteryTab/description";
import JoinedMember from "./lotteryTab/joinedMember";
import LotteryFooter from "./lotteryTab/lotteryFooter";
import { t } from "i18next";

const Lotterydetail = () => {
  const location = useLocation();
  const data = location.state;

  return (
    <>
      <Title props={t("text_lottery")} />
      <MainTheme>
        <UserHeader title={data.lottery_title} backPath />
        <div className="bm-mdl-center bm-full-height">
          <div className="bm-content-listing">
            {/* lottery image */}
            <div className="match-info">
              <img
                src={data.lottery_image}
                alt="match-banner"
                style={{ width: "100%" }}
              />
            </div>
            <div className="tab-section single-game mb-0">
              <ul className="nav nav-tabs">
                <li className="nav-item" style={{ width: "50%" }}>
                  {/* description tab  */}
                  <a
                    className="nav-link text-uppercase active"
                    data-toggle="tab"
                    href="#description"
                  >
                    {t("text_description")}
                  </a>
                </li>
                {/* joined member tab */}
                <li className="nav-item" style={{ width: "50%" }}>
                  <a
                    className="nav-link text-uppercase"
                    data-toggle="tab"
                    href="#join"
                  >
                    {t("text_joined_member")}
                  </a>
                </li>
              </ul>
              {/* description */}
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="description"
                  role="tabpanel"
                  aria-labelledby="description-tab"
                >
                  <Description data={data} />
                </div>
                {/* joined member  */}
                <div
                  className="tab-pane fade"
                  id="join"
                  role="tabpanel"
                  aria-labelledby="join-tab"
                >
                  <JoinedMember data={data.join_member} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <LotteryFooter data={data} />
      </MainTheme>
    </>
  );
};

export default Lotterydetail;
