import { React, useState, useEffect } from "react";
import UserHeader from "../../theme/userHeader";
import { NavLink, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Title from "../../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const EditCourier = () => {
  //get the id from parameter
  const params = useParams();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  const [loading, setLoading] = useState(true);
  //store the data of api
  const [insertData, SetInsertData] = useState({});
  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [state, setState] = useState({
    courier_name: "",
    courier_link: "",
  });
  useEffect(async () => {
    var response = await fetch(`/api/get_insert_courier/${params.id}`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    if (res_data) {
      setLoading(false);
    }
    SetInsertData(res_data.get_insert_courier_data[0]);
    setState({
      courier_name: res_data.get_insert_courier_data[0].courier_name,
      courier_link: res_data.get_insert_courier_data[0].courier_link,
    });
  }, []);
  const handleCourier = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };
  function handleFormValidation() {
    const { courier_name, courier_link } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!courier_name) {
      formIsValid = true;
      formErrors["courierNameErr"] = t("translation2:err_courier_name_req");
    }
    if (!courier_link) {
      formIsValid = true;
      formErrors["courierLinkErr"] = t("translation2:err_courier_link_req");
    }
    setState({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    var response = await fetch(`/api/update_courier/${params.id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      history.push("/admin/courier", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_courier")} />
      {/* Header */}
      <UserHeader title={t("text_add_courier")} />
      {loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card bg-light text-dark">
              {/* card-header */}
              <div className="card-header">
                <strong>{t("text_courier")}</strong>
              </div>
              <div className="card-body">
                <div className="col-md-12">
                  <form
                    className="needs-validation p-2"
                    id="validate"
                    method="POST"
                  >
                    <div className="row">
                      {/* courier name */}
                      <div className="form-group col-md-6">
                        <label htmlFor="courier_name">
                          {t("text_courier_name")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          name="courier_name"
                          value={state.courier_name}
                          onChange={handleCourier}
                        />
                        <label
                          htmlFor="package_name"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.courierNameErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.courierNameErr}
                        </label>
                      </div>
                      {/* courier link */}
                      <div className="form-group col-md-6">
                        <label htmlFor="courier_link">
                          {t("text_courier_link")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          name="courier_link"
                          value={state.courier_link}
                          onChange={handleCourier}
                        />
                        <label
                          htmlFor="package_name"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.courierLinkErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.courierLinkErr}
                        </label>
                      </div>
                    </div>
                    {/* submit */}
                    <div className="form-group d-flex justify-content-center mt-3">
                      <input
                        type="submit"
                        value="Submit"
                        name="submit"
                        className={`btn btn-lightpink ${
                          demouser === "1" && "disabled"
                        }`}
                        onClick={demouser === "0" && handleSubmit}
                      />
                      {/* cancel */}
                      <NavLink
                        className="btn btn-secondary ms-1 d-block"
                        to="/admin/courier"
                        name="cancel"
                      >
                        {t("text_btn_cancel")}
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditCourier;
