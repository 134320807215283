import { React, useState } from "react";
import UserHeader from "../../theme/userHeader";
import { CKEditor } from "ckeditor4-react";
import { NavLink, useHistory } from "react-router-dom";
import Title from "../../../../views/common/Helmet";
import { t } from "i18next";

const AddProduct = () => {
  //get the id from parameter
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [state, setState] = useState({
    product_name: "",
    product_image: "",
    product_actual_price: "",
    product_selling_price: "",
    product_short_description: "",
  });
  const [desc, setDesc] = useState({
    product_description: "",
  });

  //change the value of product data is store in state
  const handleProduct = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };
  //store the image in onchange method
  const handleImage = (e) => {
    const file = e.target.files[0];
    setState({
      ...state,
      product_image: file,
    });
  };

  // form validation
  function handleFormValidation() {
    const {
      product_name,
      product_image,
      product_actual_price,
      product_selling_price,
      product_short_description,
    } = state;
    const { product_description } = desc;
    let formErrors = {};
    let formIsValid = true;
    console.log("dsagdj");
    if (!product_name) {
      formIsValid = true;
      formErrors["productNameErr"] = t("translation2:err_product_name_req");
    }
    if (!product_image) {
      formIsValid = true;
      formErrors["productImageErr"] = t("translation2:err_game_image_req");
    }
    if (!product_actual_price) {
      formIsValid = true;
      formErrors["prosuctApriceErr"] = t("translation2:err_actual_price_req");
    }
    if (!product_selling_price) {
      formIsValid = true;
      formErrors["productSpriceErr"] = t("translation2:err_selling_price_req");
    }
    if (!product_short_description) {
      formIsValid = true;
      formErrors["productSDescErr"] = t("translation2:err_short_desc_req");
    }
    if (!product_description) {
      formIsValid = true;
      formErrors["productDescErr"] = t("translation2:err_desc_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(state);
    var formData = new FormData();
    formData.append("product_actual_price", state.product_actual_price);
    formData.append("product_name", state.product_name);
    formData.append("product_image", state.product_image);
    formData.append("product_selling_price", state.product_selling_price);
    formData.append(
      "product_short_description",
      state.product_short_description
    );
    formData.append("product_description", desc.product_description);
    handleFormValidation();
    // insert product
    var response = await fetch(`/api/insert_product`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      history.push("/admin/product", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_product")} />
      {/* Header */}
      <UserHeader title={t("text_product")} />
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            {/* card-header */}
            <div className="card-header">
              <strong>{t("text_product")}</strong>
            </div>
            <div className="card-body">
              <div className="col-md-12">
                <form className="needs-validation" id="validate" method="POST">
                  <div className="row">
                    {/* product name */}
                    <div className="form-group col-md-6">
                      <label htmlFor="product_name">
                        {t("text_product_name")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        name="product_name"
                        value={state.product_name}
                        onChange={handleProduct}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.productNameErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.productNameErr}
                      </label>
                    </div>
                    {/* product image */}
                    <div className="form-group col-md-6">
                      <label htmlFor="product_image">
                        {t("text_image")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      <input
                        id="product_image"
                        type="file"
                        className="file-input d-block mt-2"
                        name="product_image"
                        onChange={handleImage}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.productImageErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.productImageErr}
                      </label>

                      <p>
                        <b>{t("text_note")} : </b>&nbsp;
                        {t("text_image_note_1000x500")}.
                      </p>
                      <br />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="product_actual_price">
                        {t("text_product_actual_price")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        name="product_actual_price"
                        value={state.product_actual_price}
                        onChange={handleProduct}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.prosuctApriceErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.prosuctApriceErr}
                      </label>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="product_selling_price">
                        {t("text_product_selling_price")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        name="product_selling_price"
                        value={state.product_selling_price}
                        onChange={handleProduct}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.productSpriceErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.productSpriceErr}
                      </label>
                    </div>
                    <div className="form-group col-md-12 mt-2">
                      <label htmlFor="product_short_description">
                        {t("text_product_short_desc")}
                      </label>
                      <textarea
                        type="text"
                        className="form-control mt-2"
                        name="product_short_description"
                        value={state.product_short_description}
                        onChange={handleProduct}
                      ></textarea>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.productSDescErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.productSDescErr}
                      </label>
                    </div>
                    {/* ckeditor */}
                    <div className="form-group col-12 mt-2">
                      <label htmlFor="product_description">
                        {t("text_product_desc")}
                      </label>
                      <CKEditor
                        initData={<p>{desc.product_description}</p>}
                        onChange={(event, editor) => {
                          const data = event.editor.getData();
                          setDesc({
                            product_description: data,
                          });
                        }}
                      />
                      <label
                        htmlFor="product_description"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.productDescErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.productDescErr}
                      </label>
                    </div>
                  </div>
                  {/* submit */}
                  <div className="form-group text-center mt-3">
                    <input
                      type="submit"
                      value="Submit"
                      name="submit"
                      className={`btn btn-lightpink ${
                        demouser === "1" && "disabled"
                      }`}
                      onClick={demouser === "0" && handleSubmit}
                    />
                    {/* cancel */}
                    <NavLink
                      className="btn btn-secondary ms-1"
                      to="/admin/product"
                      name="cancel"
                    >
                      {t("text_btn_cancel")}
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
