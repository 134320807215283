import { React } from "react";
import Title from "../../common/Helmet";
import UserHeader from "../theme/UserHeader";
import MainTheme from "../theme/MainTheme";
import { useLocation } from "react-router-dom";
import Description from "./lottery_detail/description";
import JoinedMember from "./lottery_detail/joined_member";
import LotteryFooter from "./lottery_detail/lotteryFooter";
import { t } from "i18next";

const Lottery = () => {
  const location = useLocation();
  const data = location.state;

  return (
    <>
      <Title props={t("text_lottery")} />
      <MainTheme>
        <UserHeader props={data.lottery_title} />
        <div className="row">
          <div className="col-lg-12">
            <div className="card mt-3 ">
              <div
                className="card-body dashboard-tabs p-0 bg-lightgray"
                id="tabs-1"
              >
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    {/* description tab  */}
                    <a
                      className="navlink text-uppercase active"
                      data-toggle="tab"
                      href="#description"
                    >
                      {t("text_description")}
                    </a>
                  </li>
                  <li className="nav-item">
                    {/* joined member tab */}
                    <a
                      className="navlink text-uppercase"
                      data-toggle="tab"
                      href="#join"
                    >
                      {t("text_joined_member")}
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  {/* description */}
                  <div
                    className="tab-pane fade show active"
                    id="description"
                    role="tabpanel"
                    aria-labelledby="description-tab"
                  >
                    <Description data={data} />
                  </div>
                  {/* joined member  */}
                  <div
                    className="tab-pane fade"
                    id="join"
                    role="tabpanel"
                    aria-labelledby="join-tab"
                  >
                    <JoinedMember data={data.join_member} />
                  </div>
                </div>
              </div>
            </div>
            <LotteryFooter data={data} />
          </div>
        </div>
      </MainTheme>
    </>
  );
};

export default Lottery;
