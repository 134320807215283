import { React } from "react";
import { NavLink } from "react-router-dom";
import { t } from "i18next";

const SlideBar = () => {
  // Array for all NavLink in Sidebar
  const Acc = [
    {
      link: "/accounts",
      icon: "fa fa-user-circle",
      text: t("text_account"),
    },
    {
      link: "/play",
      icon: "fa fa-gamepad",
      text: t("text_play"),
    },
    {
      link: "/account/my_match",
      icon: "fa fa-gamepad",
      text: t("text_my_matches"),
    },
    {
      link: "/account/my_orders",
      icon: "fa fa-first-order",
      text: t("text_my_orders"),
    },
    {
      link: "/account/my_wallet",
      icon: "fa fa-money",
      text: t("text_my_wallet"),
    },

    {
      link: "/account/my_statistics",
      icon: "fa fa-bar-chart",
      text: t("text_my_statistics"),
    },
    {
      link: "/refer_earn",
      icon: "fa fa-product-hunt",
      text: t("text_earn"),
    },
    {
      link: "/account/announcement",
      icon: "fa fa-bullhorn",
      text: t("text_announcement"),
    },
    {
      link: "/account/my_referral",
      icon: "fa fa-users",
      text: t("text_my_referrals"),
    },

    {
      link: "/account/top_player",
      icon: "fa fa-star",
      text: t("text_top_players"),
    },
    {
      link: "/account/leader_board",
      icon: "fa fa-area-chart",
      text: t("text_leaderboard"),
    },
    {
      link: "/account/app_tutorial",
      icon: "fa fa-question-circle",
      text: t("text_app_tutorial"),
    },
    {
      link: "/account/about-us",
      icon: "fa fa-info-circle",
      text: t("text_about_us"),
    },
    {
      link: "/account/customer_support",
      icon: "fa fa-headphones",
      text: t("text_customer_supports"),
    },
    {
      link: "/account/terms & conditions",
      icon: "fa fa-file-text-o",
      text: t("text_terms_conditions"),
    },
  ];

  return (
    <>
      <div id="sidebar-wrapper" className="border-right ">
        {/* List of Sidebar */}
        <ul className="nav flex-column list-group list-group-flush">
          {Acc.map((data, index) => {
            return (
              <li className="nav-item" key={index}>
                {/* NavLink in Sidebar */}
                <NavLink className="navlink " to={data.link}>
                  <i className={data.icon}></i>
                  {data.text}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default SlideBar;
