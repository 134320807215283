import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import { t } from "i18next";

const Feature = ({ props }) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  //handle alert message
  const [message, setMessage] = useState({
    status: false,
    msg: "",
  });
  // handle state
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    features_title: props.features_title,
    features_text: props.features_text,
  });
  const [state1, setState1] = useState({
    formErrors: {},
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // form validation
  function handleFormValidation() {
    const { features_title, features_text } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!features_title) {
      formIsValid = true;
      formErrors["featureTitleErr"] = t("translation2:err_features_title_req");
    }
    if (!features_text) {
      formIsValid = true;
      formErrors["dpFeatureErr"] = t("translation2:err_features_text_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    // update Feature
    var response = await fetch(`/api/get_update_feature`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      setShow(true);
      setMessage({ status: res_data.status, msg: res_data.message });
    }
  };

  return (
    <>
      {/* message */}
      <div
        className={
          (!message.status ? "alert alert-danger " : "alert alert-success ") +
          "alert-dismissible fade show " +
          (show ? "d-block" : "d-none")
        }
        role="alert"
      >
        {message.msg}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            {/* card-header */}
            <div className="card-header">
              <strong>{t("text_features_setting")}</strong>
            </div>
            <div className="card-body">
              <div className="col-md-12">
                <form method="POST" id="validate">
                  {/* section title */}
                  <div className="form-group mb-3">
                    <label className="mb-3" htmlFor="features_title">
                      {t("text_section_title")}
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <input
                      type="text"
                      id="features_title"
                      name="features_title"
                      value={state.features_title}
                      onChange={handleChange}
                      className="form-control"
                    />
                    <label
                      htmlFor="package_name"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.featureTitleErr
                          ? " d-block"
                          : "d-none")
                      }
                    >
                      {state1.formErrors.featureTitleErr}
                    </label>
                  </div>
                  {/* section sub title */}
                  <div className="form-group mb-3">
                    <label className="mb-3" htmlFor="features_text">
                      {t("text_section_sub_title")}
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <textarea
                      id="features_text"
                      name="features_text"
                      rows="5"
                      value={state.features_text}
                      onChange={handleChange}
                      className="form-control"
                    ></textarea>
                    <label
                      htmlFor="package_name"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.dpFeatureErr ? " d-block" : "d-none")
                      }
                    >
                      {state1.formErrors.dpFeatureErr}
                    </label>
                  </div>
                  {/* submit */}
                  <div className="form-group d-flex justify-content-center mb-3">
                    <input
                      type="submit"
                      value="Submit"
                      name="submit"
                      className={`btn btn-lightpink ${
                        demouser === "1" && "disabled"
                      }`}
                      onClick={demouser === "0" && handleSubmit}
                    />
                    {/* cancel */}
                    <NavLink
                      className="btn btn-secondary ms-1 d-block"
                      to="/admin/features_tab"
                      name="cancel"
                    >
                      {t("text_btn_cancel")}
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feature;
