import { React, useEffect, useState } from "react";
import UserHeader from "../../../theme/userHeader";
import Feature from "./features_detail/feature";
import FeatureTab from "./features_detail/feature_tab";
import { connect } from "react-redux";
import { GetFeatures } from "../../../../../store/Action/AdminData";
import { useLocation } from "react-router-dom";
import Title from "../../../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const FeaturesTab = ({ dispatch, res }) => {
  // handle state
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const location = useLocation();

  useEffect(() => {
    dispatch(GetFeatures());
    if (location.state) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [dispatch]);
  const data = res.data;

  return (
    <>
      <Title props={t("text_features")} />
      {/* Header */}
      <UserHeader title={t("text_features")} />
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <>
          {/* message */}
          <div
            className={
              "alert alert-success alert-dismissible fade show " +
              (show ? "d-block" : "d-none")
            }
            role="alert"
          >
            {location.state && !message ? (
              <span>{location.state.state.data}</span>
            ) : (
              <span></span>
            )}
            {!location.state && message ? (
              <span>{message}</span>
            ) : (
              <span></span>
            )}
            {location.state && message ? <span>{message}</span> : <span></span>}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
          <Feature props={data.get_feature} />
          {/* datatable */}
          <FeatureTab />
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.GetFeatures,
});

export default connect(mapStateToProps)(FeaturesTab);
