// Admin Panel Routes

// dashboard
import Dashboard from "../admin-panel/pages/dashboard/dashboard";
//my profile
import MyProfile from "../admin-panel/pages/my_profile/my_profile";
// users
import AllUsers from "../admin-panel/pages/users/all_users";
import Member from "../admin-panel/pages/users/member/Member";
import RegisterReferrals from "../admin-panel/pages/users/register_referrals";
import JoinedReferral from "../admin-panel/pages/users/joined_referral";
// games
import AllGames from "../admin-panel/pages/games/all_game/all_game";
import AddAllGame from "../admin-panel/pages/games/all_game/add_all_game";
import EditAllGame from "../admin-panel/pages/games/all_game/edit_all_game";
// matches
import Matches from "../admin-panel/pages/games/matches/matches";
import MatcheEdit from "../admin-panel/pages/games/matches/matcheEdit";
// import MatchPosition from "../admin-panel/pages/games/matches/MatchPosition";
import Position from "../admin-panel/pages/games/matches/position";
import MatchJoin from "../admin-panel/pages/games/matches/MatchJoin";
import AddMatches from "../admin-panel/pages/games/matches/add_matches";
// challenges
import Challenges from "../admin-panel/pages/games/challenges/challenges";
import ChallengeDetail from "../admin-panel/pages/games/challenges/challenge_detail";
// images
import Images from "../admin-panel/pages/games/images/images";
import AddImages from "../admin-panel/pages/games/images/add_images";
import EditImage from "../admin-panel/pages/games/images/EditImage";
// product
import Product from "../admin-panel/pages/shop/product/product";
import AddProduct from "../admin-panel/pages/shop/product/add_product";
import EditProduct from "../admin-panel/pages/shop/product/EditProduct";
//order
import Order from "../admin-panel/pages/shop/order/order";
import ViewOrder from "../admin-panel/pages/shop/order/ViewOrder";
// courier
import Courier from "../admin-panel/pages/shop/courier/courier";
import EditCourier from "../admin-panel/pages/shop/courier/EditCourier";
import AddCourier from "../admin-panel/pages/shop/courier/add_courier";
// country
import Country from "../admin-panel/pages/country/country";
import EditCountry from "../admin-panel/pages/country/EditCountry";
import AddCountry from "../admin-panel/pages/country/add_country";
// money order
import MoneyOrder from "../admin-panel/pages/money/money_order";
// withdraw request
import WithdrawRequest from "../admin-panel/pages/money/withdraw_request";
// reports
import TopPlayers from "../admin-panel/pages/reports/top_players";
import LeaderBoard from "../admin-panel/pages/reports/leader_board";
// notification
import Notification from "../admin-panel/pages/notification/notification";
// announcement
import Announcement from "../admin-panel/pages/announcement/announcement";
import EditAnnouncement from "../admin-panel/pages/announcement/EditAnnouncement";
import AddAnnouncement from "../admin-panel/pages/announcement/add_announcement";
// lottery
import Lottery from "../admin-panel/pages/lottery/lottery";
import EditLottery from "../admin-panel/pages/lottery/EditLottery";
import ViewMember from "../admin-panel/pages/lottery/ViewMember";
import AddLottery from "../admin-panel/pages/lottery/add_lottery";
// website setting
// pages
import Page from "../admin-panel/pages/website_setting/page/page";
import AddPage from "../admin-panel/pages/website_setting/page/add_page";
import EditPages from "../admin-panel/pages/website_setting/page/edit_pages";
// main banner
import MainBanner from "../admin-panel/pages/website_setting/home_page_settings/main_banner";
// screenshot
import Screenshot from "../admin-panel/pages/website_setting/home_page_settings/screenshots/screenshots";
import AddScreenshot from "../admin-panel/pages/website_setting/home_page_settings/screenshots/add_screenshots";
import EditScreenshots from "../admin-panel/pages/website_setting/home_page_settings/screenshots/edit_screenshots";
// Features Tab
import FeaturesTab from "../admin-panel/pages/website_setting/home_page_settings/features_tab/features_tab";
import AddFeaturesTab from "../admin-panel/pages/website_setting/home_page_settings/features_tab/add_features_tab";
import EditFeaturesTab from "../admin-panel/pages/website_setting/home_page_settings/features_tab/edit_features_tab";
// Features
import Features from "../admin-panel/pages/website_setting/home_page_settings/features/features";
import AddFeatures from "../admin-panel/pages/website_setting/home_page_settings/features/add_features";
import EditFeatures from "../admin-panel/pages/website_setting/home_page_settings/features/edit_features";
// how to play
import HowToPlay from "../admin-panel/pages/website_setting/home_page_settings/how_to_play/how_to_play";
import EditHowToPlay from "../admin-panel/pages/website_setting/home_page_settings/how_to_play/EditHowToPlay";
import AddHowToPlay from "../admin-panel/pages/website_setting/home_page_settings/how_to_play/add_how_to_play";
// how to install
import HowToInstall from "../admin-panel/pages/website_setting/how_to_install/how_to_install";
import AddHowToInstall from "../admin-panel/pages/website_setting/how_to_install/add_how_to_install";
import EditHowToInstall from "../admin-panel/pages/website_setting/how_to_install/edit_how_to_install";
// app setting
import AppSetting from "../admin-panel/pages/app_setting/appSetting/app_setting";
// currency
import Currency from "../admin-panel/pages/app_setting/currency/currency";
import AddCurrency from "../admin-panel/pages/app_setting/currency/add_currency";
import EditCurrency from "../admin-panel/pages/app_setting/currency/edit_currency";
// withdraw method
import WithdrawMethod from "../admin-panel/pages/app_setting/withdraw_method/withdraw_method";
import AddWithdrawMethod from "../admin-panel/pages/app_setting/withdraw_method/add_withdraw_method";
import EditWithdrawMethod from "../admin-panel/pages/app_setting/withdraw_method/edit_withdraw_method";
// payment method
import PaymentMethod from "../admin-panel/pages/app_setting/payment_method/payment_method";
import EditPaymentMethod from "../admin-panel/pages/app_setting/payment_method/edit_payment_method";
// app tutorial
import AppTutorial from "../admin-panel/pages/app_setting/app_tutorial/app_tutorial";
import AddAppTutorial from "../admin-panel/pages/app_setting/app_tutorial/add_app_tutorial";
import EditAppTutorial from "../admin-panel/pages/app_setting/app_tutorial/edit_app_tutorial";
// slider
import Slider from "../admin-panel/pages/app_setting/slider/slider";
import AddSlider from "../admin-panel/pages/app_setting/slider/add_slider";
import EditSlider from "../admin-panel/pages/app_setting/slider/edit_slider";
// banner
import Banner from "../admin-panel/pages/app_setting/banner/banner";
import AddBanner from "../admin-panel/pages/app_setting/banner/add_banner";
import EditBanner from "../admin-panel/pages/app_setting/banner/edit_banner";
//admin
import Admin from "../admin-panel/pages/admin/admin";
import AddAdmin from "../admin-panel/pages/admin/add_admin";
import EditAdmin from "../admin-panel/pages/admin/edit_admin";
// license
import License from "../admin-panel/pages/license/license";
import LicenseSetting from "../admin-panel/pages/license/license_setting";
// error
// import Error from "../views/common/Error";

const AdminRoutes = [
  // dashboard
  { path: "/admin/dashboard", component: Dashboard },
  // my profile
  { path: "/admin/my_profile", component: MyProfile },
  // users
  { path: "/admin/all_users", component: AllUsers },
  { path: "/admin/all_users/user_details/:id", component: Member },
  { path: "/admin/register_referral", component: RegisterReferrals },
  { path: "/admin/joined_referral", component: JoinedReferral },
  // games
  { path: "/admin/all_game", component: AllGames },
  { path: "/admin/all_game/insert", component: AddAllGame },
  { path: "/admin/all_game/edit/:id", component: EditAllGame },
  // matches
  { path: "/admin/matches", component: Matches },
  { path: "/admin/matches/edit/:id", component: MatcheEdit },
  { path: "/admin/matches/member_join_match/:id", component: MatchJoin },
  // { path: "/admin/matches/member_position/:id", component: MatchPosition },
  { path: "/admin/matches/member_position/:id", component: Position },
  { path: "/admin/add_matches", component: AddMatches },
  // challenges
  { path: "/admin/challenges", component: Challenges },
  {
    path: "/admin/challenges/challenge_detail/:id",
    component: ChallengeDetail,
  },
  //images
  { path: "/admin/images", component: Images },
  { path: "/admin/images/edit/:id", component: EditImage },
  { path: "/admin/add_images", component: AddImages },
  // product
  { path: "/admin/product", component: Product },
  { path: "/admin/product/edit/:id", component: EditProduct },

  { path: "/admin/add_product", component: AddProduct },
  // order
  { path: "/admin/order", component: Order },
  { path: "/admin/order/order_detail/:id", component: ViewOrder },
  // courier
  { path: "/admin/courier", component: Courier },
  { path: "/admin/courier/edit/:id", component: EditCourier },
  { path: "/admin/add_courier", component: AddCourier },
  // country
  { path: "/admin/country", component: Country },
  { path: "/admin/country/edit/:id", component: EditCountry },
  { path: "/admin/add_country", component: AddCountry },
  //money order
  { path: "/admin/money_order", component: MoneyOrder },
  //withdraw request
  { path: "/admin/withdraw_request", component: WithdrawRequest },
  // top players
  { path: "/admin/top_players", component: TopPlayers },
  // leader board
  { path: "/admin/leader_board", component: LeaderBoard },
  // notification
  { path: "/admin/notification", component: Notification },
  //announcement
  { path: "/admin/announcement", component: Announcement },
  { path: "/admin/announcement/edit/:id", component: EditAnnouncement },
  { path: "/admin/add_announcement", component: AddAnnouncement },
  // lottery
  { path: "/admin/lottery", component: Lottery },
  { path: "/admin/lottery/edit/:id", component: EditLottery },
  { path: "/admin/lottery/viewmember/:id", component: ViewMember },
  { path: "/admin/add_lottery", component: AddLottery },
  // page
  { path: "/admin/pages", component: Page },
  { path: "/admin/pages/insert", component: AddPage },
  { path: "/admin/pages/edit/:id", component: EditPages },
  // main banner
  { path: "/admin/main_banner", component: MainBanner },
  // screenshot
  { path: "/admin/screenshots", component: Screenshot },
  { path: "/admin/screenshots/insert", component: AddScreenshot },
  { path: "/admin/screenshots/edit/:id", component: EditScreenshots },
  // Features Tab
  { path: "/admin/features_tab", component: FeaturesTab },
  { path: "/admin/features_tab/insert", component: AddFeaturesTab },
  { path: "/admin/features_tab/edit/:id", component: EditFeaturesTab },
  // Features
  { path: "/admin/features", component: Features },
  { path: "/admin/features/insert", component: AddFeatures },
  { path: "/admin/features/edit/:id", component: EditFeatures },
  //how to play
  { path: "/admin/how_to_play", component: HowToPlay },
  { path: "/admin/how_to_play/edit/:id", component: EditHowToPlay },
  { path: "/admin/add_how_to_play", component: AddHowToPlay },
  // how to install
  { path: "/admin/how_to_install", component: HowToInstall },
  { path: "/admin/how_to_install/insert", component: AddHowToInstall },
  { path: "/admin/how_to_install/edit/:id", component: EditHowToInstall },
  // app setting
  { path: "/admin/app_setting", component: AppSetting },
  //currency
  { path: "/admin/currency", component: Currency },
  { path: "/admin/currency/insert", component: AddCurrency },
  { path: "/admin/currency/edit/:id", component: EditCurrency },
  // withdraw method
  { path: "/admin/withdraw_method", component: WithdrawMethod },
  { path: "/admin/withdraw_method/insert", component: AddWithdrawMethod },
  { path: "/admin/withdraw_method/edit/:id", component: EditWithdrawMethod },
  // payment method
  { path: "/admin/payment_method", component: PaymentMethod },
  { path: "/admin/payment_method/edit/:id", component: EditPaymentMethod },
  // app tutorial
  { path: "/admin/app_tutorial", component: AppTutorial },
  { path: "/admin/app_tutorial/insert", component: AddAppTutorial },
  { path: "/admin/app_tutorial/edit/:id", component: EditAppTutorial },
  // slider
  { path: "/admin/slider", component: Slider },
  { path: "/admin/slider/insert", component: AddSlider },
  { path: "/admin/slider/edit/:id", component: EditSlider },
  // banner
  { path: "/admin/banner", component: Banner },
  { path: "/admin/banner/insert", component: AddBanner },
  { path: "/admin/banner/edit/:id", component: EditBanner },
  // admin
  { path: "/admin/admin", component: Admin },
  { path: "/admin/admin/insert", component: AddAdmin },
  { path: "/admin/admin/edit/:id", component: EditAdmin },
  // license
  { path: "/admin/license", component: License },
  { path: "/admin/license/license_setting", component: LicenseSetting },
  // error
  // { path: "*", component: Error },
];

export default AdminRoutes;
