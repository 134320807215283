import { React, useEffect, useState } from "react";
import favicon from "../../../views/assets/images/favicon.png";
import { Payment } from "../../../store/Action/UserFatchData";
import coin from "../../../views/assets/images/coin.png";
import { ToastContainer, toast } from "react-toastify";
import Title from "../../../views/common/Helmet";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import MainTheme from "../theme/MainTheme";
import { connect } from "react-redux";
import { t } from "i18next";

const Add = ({ dispatch, res }) => {
  console.log("completed: paypal,paystack,cashfree,razorpay,offline,tron");
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));

  // manage state
  const [payment, getPayment] = useState({
    CUST_ID: user.member_id,
    payment_name: "",
    TXN_AMOUNT: "",
    amount: "",
    data:{},
  });

  const [message, setMessage] = useState(false);
  const [form, setForm] = useState({});
  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });

  const handleForm = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setMessage(true);
  };

  //Payment api call on page load
  useEffect(() => {
    dispatch(Payment());
  }, [dispatch]);
  var data = res.data;

  // res
  const key =
    data.payment &&
    data.payment
      .filter((res) => res.payment_name === form.payment_name)
      .map((res) => {
        return res;
      });
  const prop = key && key[0];

  //  total amount
  const amount =
    data.payment &&
    data.payment
      .filter((res) => res.payment_name === form.payment_name)
      .map((res) => {
        return form.txnAmount / res.currency_point;
      });

  useEffect(() => {
    if (form.payment_name == "Razorpay") {
      getPayment({
        ...payment,
        payment_name: form.payment_name,
        TXN_AMOUNT: form.txnAmount * 100,
        data : prop && prop,
      });
    } else {
      getPayment({
        ...payment,
        payment_name: form.payment_name,
        TXN_AMOUNT: amount && amount[0],
        // TXN_AMOUNT: form.txnAmount,
        data : prop && prop,
      });
    }
  }, [form]);

  // success notification
  const notifyS = (data) => toast.success(data);
  const notifyE = (data) => toast.error(data);

  // form validation
  function handleFormValidation() {
    const { txnAmount, payment_name } = form;
    let formErrors = {};
    let formIsValid = true;
    if (!txnAmount) {
      formIsValid = true;
      formErrors["txnAmountErr"] = t("translation2:err_amount_req");
    }
    if (txnAmount && payment_name && amount < 10) {
      formIsValid = true;
      formErrors["txnAmountErr"] = t(
        "translation2:err_number_of_position_min10"
      );
    }
    if (!payment_name) {
      formIsValid = true;
      formErrors["paymentNameErr"] = t("translation2:err_payment_method_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  // add money api call
  const add_money = async (e) => {
    e.preventDefault();
    handleFormValidation();
    var response = await fetch(`/api/add_money`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(payment),
    });
    var res_data = await response.json();
    console.log("response", res_data);
    if (res_data.status === true) {

      // offline
      if (payment.payment_name === "Offline") {
        notifyS(res_data.message);
        setTimeout(() => {
          history.push("/account/my_wallet");
        }, 3000);
      }

      // paytm
      else if (payment.payment_name === "PayTm") {
        console.log("payment" , payment);
        console.log("res_data", res_data);

        // history.push(`/account/my_wallet/add/${payment.payment_name}`, {
        //   state: {
        //     data: payment,
        //     message: res_data.message,
        //   },
        // });

        const PaytmChecksum = res_data.message.CHECKSUMHASH;
        console.log(PaytmChecksum);
        const data = {
          // head: {
          //   signature: PaytmChecksum,
          // },
          // body: {
            // requestType: "payment",
            mid: res_data.message.MID,  
            // websiteName: res_data.message.WEBSITE,
            orderId: res_data.message.ORDER_ID,
            // txnAmount: {
            //   value: payment.TXN_AMOUNT,
            //   currency: payment.data.currency_code,
            // },
            // userInfo: {
            //   custId: payment.CUST_ID,
            // },
            // callbackUrl:
            //   "https://securegw.paytm.in/merchant-status/getTxnStatus",
          // },
        };

        const params = {
          mid: res_data.message.MID,  
          orderId: res_data.message.ORDER_ID,
        }
        console.log("data", data);
        var response = await fetch(
          `https://securegw-stage.paytm.in/v3/order/status`,
          {
            params: data,
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        var resPayTm = await response.json();
        console.log("response1", resPayTm);
        // if (resPayTm.body.resultInfo.resultCode == "0000") {
        //   notifyS(resPayTm.body.resultInfo.resultMsg);
        //   setTimeout(() => {
        //     history.goBack();
        //   }, 2000);
        // } else {
        //   notifyE(resPayTm.body.resultInfo.resultMsg);
        // }
      }

      // paypal
      else if (payment.payment_name === "PayPal") {
        console.log("payPal");
        history.push(`/account/my_wallet/add/${payment.payment_name}`, {
          state: {
            data: payment,
            message: res_data.message,
          },
        });
      }

      // paystack
      else if (payment.payment_name === "PayStack") {
        history.push(`/account/my_wallet/add/${payment.payment_name}`, {
          state: {
            data: payment,
            message: res_data.message,
          },
        });
      }

      // Instamojo
      else if (payment.payment_name === "Instamojo") {
        console.log("payment",payment);
        const data = {
          amount: 100,
          purpose: "true",
        };
        console.log("data", data);
        var response = await fetch(
          `https://www.instamojo.com/api/1.1/payment-requests/`,
          {
            method: "POST",
            // mode: "no-cors",
            headers: {
              "X-Api-Key":payment.data.client_id,
              "X-Auth-Token":payment.data.client_key,
            },
            body: JSON.stringify(data),
          }
        );
        var resInstamojo = await response.json();
        console.log("resInstamojo", resInstamojo);
      }

      // cashfree
      else if (payment.payment_name === "Cashfree") {
        console.log("payment",payment);

        // history.push(`/account/my_wallet/add/${payment.payment_name}`, {
        //   state: {
        //     data: payment,
        //     message: res_data.message,
        //   },
        // });

        // const data = {
        //   "order_id": "order_1626945143520",
        //   "order_amount": 10.12,
        //   "order_currency": "INR",
        //   "order_note": "Additional order info",
        //   "customer_details": {
        //     "customer_id": "12345",
        //     "customer_name": "name",
        //     "customer_email": "care@cashfree.com",
        //     "customer_phone": "9816512345"
        //   }
        // };

        const data = {
            "order_id": res_data.message.order_id,
            "order_amount": 10.12,
            "order_currency": "INR",
            "order_note": "Additional order info",
            "customer_details": {
              "customer_id": "21",
              "customer_name": "demo",
              "customer_email": "care@cashfree.com",
              "customer_phone": "9816512345"
            }
        }
        console.log(data);
        console.log("res", res_data.message.cftoken);
        var response = await fetch(`https://sandbox.cashfree.com/pg/orders`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-version": "2022-01-01",
            "x-client-id": key[0].app_id,
            "x-client-secret":key[0].secret_key,
          },
          // mode:"no-cors", 
          body: JSON.stringify(data),
        });
        var resCashfree = await response.json();
        console.log("resCashfree", resCashfree);
      }

      // Razorpay   
      else if (payment.payment_name === "Razorpay") {
        const loadScript = (src) => {
          return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
              resolve(true);
            };
            script.onerror = () => {
              resolve(false);
            };
            document.body.appendChild(script);
          });
        };
        //fuction call on page load
        const displayRazorpay = async (amount) => {
          const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
          );
          if (!res) {
            alert(t("translation1:text_offline"));
            return;
          }
          const options = {
            key: payment.data.key_id,
            currency:payment.data.currency_code,
            amount: payment.TXN_AMOUNT,
            name: "Battlemania",
            description: "Battlemania - Add Money",
            image: favicon,
            order_id: res_data.order_id,
            handler: async function (response) {
              // post data for razorpay_response
              const data = {
                orderCreationId: res_data.order_id,
                member_id: user.member_id,
                amount: amount[0],
                receipt: res_data.receipt,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                status: "true",
              };
              //Razorpay response api call
              var response = await fetch(`/api/razorpay_response`, {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify(data),
              });
              var res_razorpay = await response.json();
              if (res_razorpay.status === true) {
                notifyS(res_razorpay.message);
                setTimeout(() => {
                  history.push("/account/my_wallet");
                }, 3000);
              } else {
                notifyE(res_razorpay.message);
                setTimeout(() => {
                  history.push("/account/my_wallet");
                }, 3000);
              }
            },
            prefill: {
              name: "battlemania",
              email: "battlemania@gmail.com",
              contact: "1234567890",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        };
        displayRazorpay(amount);
      }

      // tron
      else if (payment.payment_name === "Tron") {
        history.push("/account/my_wallet/add/tron", { state: payment });
      }

      // payU
      else if (payment.payment_name === "PayU") {
        e.preventDefault();
        console.log("payu");

        // let hashkey = HashGenerator({
        //   key: "QylhKRVd",
        //   amount: payment.TXN_AMOUNT,
        //   email: user.email_id,
        //   txnId: res_data.transaction_id,
        //   productName: "di",
        //   firstName: this.state.user_data.name,
        //   salt: "seVTUgzrgE",
        // });
        // console.log(hashkey);

        const data = {
          key: "QylhKRVd",
          txnid: res_data.transaction_id,
          amount: payment.TXN_AMOUNT,
          productinfo: "di",
          firstname: user.first_name,
          email: user.email_id,
          phone: user.mobile_no,
          surl: "https://demowebappreact.thebattlemania.com/api/payu_response",
          furl: "https://demowebappreact.thebattlemania.com/api/payu_succ_fail",
          hash: "hashkey",
        };
        console.log("data", data);
        var response = await fetch(`https://test.payu.in/_payment`, {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: JSON.stringify(data),
        });

        var res_payu = await response.json();
        console.log("res_payu", res_payu);
      }

      // error
      else {
        console.log("error");
      }
    }
  };

  return (
    <>
      <Title props={t("text_add_money")} />
      {/* toastify */}
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <MainTheme title={t("text_add_money")} backPath>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <div className="bm-content-listing pt-3">
            <div className="profile-content text-black">
              <form method="POST" className="container mt-2" id="addmoney-form">
                {/* txn amount */}
                <div className="form-group row profile-form">
                  <label htmlFor="txnAmount">
                    {t("text_amount")}
                    {"("}
                    <img src={coin} className="coin" alt="coin" />
                    {")"}
                  </label>
                  <input
                    type="text"
                    id="txnAmount"
                    name="txnAmount"
                    className="form-control border-bottom rounded-0"
                    value={form.txnAmount}
                    onChange={handleForm}
                  />
                  <label
                    htmlFor="txnAmount"
                    generated="true"
                    className={
                      "error " +
                      (state1.formErrors.txnAmountErr ? " d-block" : "d-none")
                    }
                  >
                    {state1.formErrors.txnAmountErr}
                  </label>
                </div>
                {/* payment name */}
                <div className="form-group row pt-3">
                  <label htmlFor="payment_name">
                    {t("text_add_money")} :&nbsp;
                  </label>
                  {data.payment &&
                    data.payment.map((data, index) => {
                      return (
                        <div className="ms-3" key={index}>
                          <div className="custom-control d-inline">
                            <input
                              id="payment_name"
                              name="payment_name"
                              type="radio"
                              className="custom-control-input"
                              value={data.payment_name}
                              onChange={handleForm}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="payment_name"
                            >
                              {data.payment_name}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  <label
                    htmlFor="payment_name"
                    generated="true"
                    className={
                      "error " +
                      (state1.formErrors.paymentNameErr ? " d-block" : "d-none")
                    }
                  >
                    {state1.formErrors.paymentNameErr}
                  </label>
                </div>
                {/* coin convert to currency */}
                {message &&
                  data.payment
                    .filter((res) => res.payment_name === form.payment_name)
                    .map((res, index) => (
                      <div
                        className="form-group bm_text_lightgreen getcurrency_div"
                        key={index}
                      >
                        You will get&nbsp;
                        <span className="getcurrency">
                          {res.currency_symbol == "₹" ? (
                            <>
                              <span>{res.currency_symbol}</span>
                              &nbsp;&nbsp;&nbsp;
                              <span>{form.txnAmount / res.currency_point}</span>
                            </>
                          ) : (
                            <>
                              <span>{res.currency_symbol}</span>
                              <span>{form.txnAmount / res.currency_point}</span>
                            </>
                          )}
                        </span>
                      </div>
                    ))}
                {/* add money submit button */}
                <button
                  type="button"
                  value="Add Money"
                  name="add_money"
                  className="btn btn-block btn-lightpink mt-3 text-decoration-none"
                  onClick={add_money}
                >
                  {t("text_add_money")}
                </button>
              </form>
            </div>
          </div>
        )}
      </MainTheme>
    </>
  );
};

//redux connect
const mapStateToProps = (state) => ({
  res: state.payment,
});

export default connect(mapStateToProps)(Add);

// completed
//-----------
// paypal
// paystack
// razorpay
// cashfree
// offline
// tron

// paytm
// instamojo

// 6*5 = 30
