// Combine Reducer

import { combineReducers } from "redux";
//user
import {
  DemoUserReducer,
  HomeReducer,
  PageReducer,
  FooterReducer,
  HowToInstallReducer,
  GetCountryDataReducer,
  RegistrationReducer,
  LoginReducer,
  DashboardReducer,
  MyProfileDataReducer,
  UpdateMyProfileDataReducer,
  ChangePasswordReducer,
  GetMyMatchReducer,
  AllgameReducer,
  SliderReducer,
  EarnReducer,
  LotteryReducer,
  LotteryResultReducer,
  ProductReducer,
  ReferralReducer,
  AnnouncementReducer,
  TopplayerReducer,
  AllongoingmatchReducer,
  AllplaymatchReducer,
  AllgameresultReducer,
  ResultMatchReducer,
  GameMatchReducer,
  ParticipateReducer,
  MyStatisticsReducer,
  AppTutorialReducer,
  MyLeaderBoardReducer,
  TermsReducer,
  AboutUsReducer,
  CustomerSupportReducer,
  MyorderReducer,
  TransactionReducer,
  SinglematchReducer,
  ChangeplayernameReducer,
  Join_Match_Reducer,
  Product_buy_data_Reducer,
  Watch_Earn_Reducer,
  GoogleLoginReducer,
  Withdraw_method_Reducer,
  Withdraw_Reducer,
  Payment_Reducer,
  Add_money_Reducer,
  Verify_check_sum_Reducer,
  Paystack_Reducer,
  Razporpay_Reducer,
  Paypal_Reducer,
  Instamojo_Reducer,
  Cashfree_Reducer,
  all_language_Reducer,
  Cash_Reducer,
} from "./reducer/FatchReducer";

//admin
import {
  Admin_LoginReducer,
  PermissionReducer,
  TotalUserReducer,
  TotalMatchReducer,
  TotalReceivedAmountReducer,
  TotalWithdrawAmountReducer,
  AdminSendOtpReducer,
  GetPackageReducer,
  GetCountryReducer,
  GetTopPlayersReducer,
  GetGameNameReducer,
  GetMemberPositionReducer,
  GetChallengeDetailReducer,
  GameUpdateReducer,
  GetLeaderBoardReducer,
  GetInsertLotteryReducer,
  GetFeaturesReducer,
  GetHowToPlayReducer,
  GetAppUploadReducer,
  GetWithdrawMethodReducer,
  GetPaymentMethodReducer,
  GetAdminReducer,
  CheckAllPermissionReducer,
  CheckPermissionReducer,
  GetLicenseReducer,
  CheckLicenseReducer,
  GameAddReducer,
  GetGameIdReducer,
  RoomUpdateReducer,
  SpecificMatchReducer,
  SpecificOrderReducer,
  courierReducer,
  orderStatusReducer,
  lotteryMemberReducer,
  updateLotteryMemberReducer,
  getMemberReducer,
  getMemberJoinReducer,
  withdrawReducer,
  topPlayerReducer,
  GameReducer,
  GetCurrencyReducer,
  GetImageReducer,
  ProfileDataReducer,
  GetMemberListReducer,
  DownloadResultReducer,
  MemberEditReducer,
  GetGameMatchReducer,
  GetAllUserReducer,
} from "./reducer/AdminReducer";

const rootReducer = combineReducers({
  //user
  DemoUser: DemoUserReducer,
  home: HomeReducer,
  PageData: PageReducer,
  footer: FooterReducer,
  how_to_install: HowToInstallReducer,
  all_language: all_language_Reducer,
  get_all_country: GetCountryDataReducer,
  registration: RegistrationReducer,
  login: LoginReducer,
  dashboard: DashboardReducer,
  my_profile: MyProfileDataReducer,
  update_my_profile: UpdateMyProfileDataReducer,
  change_password: ChangePasswordReducer,
  get_my_match: GetMyMatchReducer,
  earn: EarnReducer,
  lottery: LotteryReducer,
  LotteryResult: LotteryResultReducer,
  product: ProductReducer,
  referral: ReferralReducer,
  announcement: AnnouncementReducer,
  top_player: TopplayerReducer,
  All_game: AllgameReducer,
  Slider: SliderReducer,
  All_ongoing_match: AllongoingmatchReducer,
  All_play_match: AllplaymatchReducer,
  All_game_result: AllgameresultReducer,
  result_match: ResultMatchReducer,
  game_match: GameMatchReducer,
  participate: ParticipateReducer,
  my_statistics: MyStatisticsReducer,
  AppTutorialData: AppTutorialReducer,
  my_leaderboard: MyLeaderBoardReducer,
  terms: TermsReducer,
  about: AboutUsReducer,
  support: CustomerSupportReducer,
  order: MyorderReducer,
  transaction: TransactionReducer,
  singlematch: SinglematchReducer,
  changename: ChangeplayernameReducer,
  join_match: Join_Match_Reducer,
  product_buy_data: Product_buy_data_Reducer,
  watch_earn: Watch_Earn_Reducer,
  googlelogin: GoogleLoginReducer,
  payment: Payment_Reducer,
  withdrawmethod: Withdraw_method_Reducer,
  withdraw: Withdraw_Reducer,
  addmoney: Add_money_Reducer,
  verfiychecksum: Verify_check_sum_Reducer,
  paystack: Paystack_Reducer,
  razporpay: Razporpay_Reducer,
  paypal: Paypal_Reducer,
  instamojo: Instamojo_Reducer,
  Cashfree: Cashfree_Reducer,
  Cash: Cash_Reducer,
  //admin
  Admin: Admin_LoginReducer,
  SendOtp: AdminSendOtpReducer,
  Permission: PermissionReducer,
  TotalUser: TotalUserReducer,
  TotalMatch: TotalMatchReducer,
  TotalReceivedAmount: TotalReceivedAmountReducer,
  TotalWithdrawAmount: TotalWithdrawAmountReducer,
  GetPackage: GetPackageReducer,
  GetCountry: GetCountryReducer,
  GetTopPlayers: GetTopPlayersReducer,
  GetGameName: GetGameNameReducer,
  GetMemberPosition: GetMemberPositionReducer,
  GetChallengeDetail: GetChallengeDetailReducer,
  GameUpdate: GameUpdateReducer,
  GetLeaderBoard: GetLeaderBoardReducer,
  GetInsertLottery: GetInsertLotteryReducer,
  GetFeatures: GetFeaturesReducer,
  GetHowToPlay: GetHowToPlayReducer,
  GetAppUpload: GetAppUploadReducer,
  GetWithdrawMethod: GetWithdrawMethodReducer,
  GetPaymentMethod: GetPaymentMethodReducer,
  GetAdmin: GetAdminReducer,
  CheckAllPermission: CheckAllPermissionReducer,
  CheckPermission: CheckPermissionReducer,
  GetLicense: GetLicenseReducer,
  CheckLicense: CheckLicenseReducer,
  GameAdd: GameAddReducer,
  GameData: GetGameIdReducer,
  RoomUpdate: RoomUpdateReducer,
  Match: SpecificMatchReducer,
  Order: SpecificOrderReducer,
  courier: courierReducer,
  orderStatus: orderStatusReducer,
  lotteryMember: lotteryMemberReducer,
  updateLotteryMember: updateLotteryMemberReducer,
  member: getMemberReducer,
  memberJoin: getMemberJoinReducer,
  withdrawRequest: withdrawReducer,
  topPlayer: topPlayerReducer,
  Game: GameReducer,
  currencydata: GetCurrencyReducer,
  getImage: GetImageReducer,
  profile: ProfileDataReducer,
  GetMemberList: GetMemberListReducer,
  DownloadResult: DownloadResultReducer,
  MemberEditPosition: MemberEditReducer,
  GetGameMatch: GetGameMatchReducer,
  GetAllUser: GetAllUserReducer,
});

export default rootReducer;
