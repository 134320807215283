// referral page
import { React, useEffect } from "react";
import Title from "../../../../views/common/Helmet";
import { connect } from "react-redux";
import { Referral_data } from "../../../../store/Action/UserFatchData";
import MainTheme from "../../theme/MainTheme";
import coin from "../../../assets/images/coin.png";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const My_referral = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Referral_data());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_my_referrals")} />
      <MainTheme title={t("text_my_referrals")} backPath>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <div className="bm-content-listing">
            <div className="sumery bg-white rounded-bottam  shadow-sm ">
              <span
                className="btn-green rounded-top p-10 text-center f-18 text-uppercase"
                style={{
                  cursor: "auto",
                  display: "block",
                  width: "inherit",
                }}
              >
                {t("text_my_referral_summary")}
              </span>
              <div className="container row  text-black p-3  text-center">
                <div className="col-6">
                  {t("text_referrals")} <br />
                  &nbsp;
                  {data.tot_referrals.total_ref}
                </div>
                <div className="col-6">
                  {t("text_earnings")} <br />
                  <span>
                    <img src={coin} className="coin" alt="img-coin" />
                  </span>
                  {/* convert number  */}
                  {Number(data.tot_earnings.total_earning).toFixed(2)}
                </div>
              </div>
            </div>
            <div className="sumery bg-white rounded-bottam  shadow-sm mt-3">
              <span
                className="btn-green rounded-top p-10 text-center f-18  text-uppercase"
                style={{
                  cursor: "auto",
                  display: "block",
                  width: "inherit",
                }}
              >
                {t("text_my_referral_list")}
              </span>
              <table className="table table-responsive text-center">
                <thead className="bg-dark text-white ">
                  <tr>
                    <th>{t("text_date")}</th>
                    <th>{t("text_player_name")} </th>
                    <th>{t("text_status")} </th>
                  </tr>
                </thead>
                <tbody className="text-dark">
                  <tr className="text-center">
                    {data.length ? (
                      <>
                        <td>{data.date}</td>
                        <td>{data.user_name}</td>
                        <td>{data.status}</td>
                      </>
                    ) : (
                      <td>{t("text_no_referral_found")}</td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.referral,
});

export default connect(mapStateToProps)(My_referral);
