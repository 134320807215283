import { React, useEffect, useState } from "react";
import { All_game_result } from "../../../store/Action/UserFatchData";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import coin from "../../../views/assets/images/coin.png";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactHtmlParser from "react-html-parser";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const ResultTab = ({ dispatch, res }) => {
  var { id } = useParams();

  // modal manage state
  const [index, setIndex] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (data1, data2, data3) => {
    setShow(true);
    setIndex({
      match_name: data1,
      m_id: data2,
      prize_description: data3,
    });
  };

  useEffect(() => {
    dispatch(All_game_result(id));
  }, [dispatch, id]);
  var data = res.data;

  return (
    <>
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <>
          {/* modal for result pize description */}
          <Modal
            show={show}
            onHide={handleClose}
            className="mx-auto result-modal"
          >
            <Modal.Header className="modal-header btn-green text-center d-block">
              <Button
                className="btn"
                onClick={handleClose}
                style={{
                  width: "10px",
                  textAlign: "center",
                  border: "1px solid gray",
                  borderRadius: "50%",
                  background: "transparent",
                  color: "black",
                  float: "right",
                }}
              >
                <i className="fa fa-close"></i>
              </Button>
              <h5 className="modal-title text-uppercase">
                {t("text_prize_pool")}
              </h5>
              <p>
                {index.match_name}
                {t("text_for_macth_id")}
                {index.m_id}
              </p>
            </Modal.Header>
            <Modal.Body className="mb-0 bg-white">
              <div className="modal-body text-black pb-0">
                {ReactHtmlParser(index.prize_description)}
              </div>
            </Modal.Body>
          </Modal>
          {data.all_game_result.length ? (
            <div className="bm-content-listing tournaments">
              {data.all_game_result.map((data, key) => (
                <div className="card br-5 hide-over mb-3" key={key}>
                  <Link
                    to={{
                      pathname: `/play/result_match/${data.m_id}`,
                      query: data.match_banner,
                    }}
                  >
                    <img
                      src={data.match_banner}
                      className="img-fluid card-img-top mCS_img_loaded"
                      alt="img-banner"
                    />
                  </Link>
                  <div className="card-body">
                    {/* type */}
                    <span className="btn btn-sm btn-lightpink">
                      {data.type}
                    </span>
                    {/* map */}
                    <span className="btn btn-sm btn-primary ms-1">
                      {data.MAP}
                    </span>
                    <h6 className="card-title mt-2">
                      <i className="fa fa-bomb"></i>
                      {/* match name  */}
                      <span className="text-dark no-underline">
                        {data.match_name}
                        {t("text_for_macth_id")}
                        {data.m_id}
                      </span>
                    </h6>
                    {/* table */}
                    <table className="card-table table text-center mt-3">
                      <tbody>
                        <tr>
                          <td>
                            {/* match time  */}
                            <span className="bm_text_lightpink">
                              {data.match_time}
                            </span>
                          </td>
                          <td>
                            {/* winning prize and description */}
                            <span
                              onClick={
                                data.prize_description &&
                                (() =>
                                  handleShow(
                                    data.match_name,
                                    data.m_id,
                                    data.prize_description
                                  ))
                              }
                              className={`${
                                data.prize_description && "pointer"
                              }`}
                            >
                              {/* prize pole */}
                              <span className="bm_text_lightgreen text-uppercase">
                                {t("text_prize_pool")}
                                <br />
                                <img
                                  src={coin}
                                  className="coin"
                                  alt="img-coin"
                                />
                                &nbsp;
                                {data.win_prize}
                                {data.prize_description && (
                                  <i
                                    className="fa fa-angle-down bm_text_lightgreen"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </span>
                            </span>
                          </td>
                          <td>
                            {/* per kill  */}
                            <span className="text-primary text-uppercase">
                              {t("text_per_kill")}
                              <br />
                              <img src={coin} className="coin" alt="img-coin" />
                              &nbsp;
                              {data.per_kill}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="row">
                      {/* watch match */}
                      <div className="col-6">
                        <a
                          href={data.match_url}
                          target="_blank"
                          className="btn btn-sm d-block btn-lightpink text-uppercase"
                        >
                          {t("text_watch_macth")}
                        </a>
                      </div>
                      {/* join */}
                      <div className="col-6">
                        <span
                          className={`btn btn-sm d-block btn-lightgreen ${
                            data.join_status ? "text-white" : "text-red-500"
                          } `}
                        >
                          <img src={coin} className="coin" alt="img-coin" />
                          &nbsp;
                          <span className="text-xs text-uppercase md:text-base">
                            {data.entry_fee}&nbsp;
                            {data.join_status
                              ? t("text_btn_joined")
                              : t("text_btn_missed")}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            // if there is no complete match
            <div className="bm-content-listing tournaments">
              <div className="col-md-12 text-center text-black">
                <strong>{t("text_no_complete_macth")}</strong>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.All_game_result,
});

export default connect(mapStateToProps)(ResultTab);
