import React from "react";
import { useHistory } from "react-router-dom";
import UserHeader from "../theme/userHeader";
import coin from "../../assets/images/coin.png";
import Title from "../../../views/common/Helmet";
import { t } from "i18next";

const RegisterReferrals = () => {
  const $ = window.$;
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));

  // user
  const handleUser = (id) => {
    history.push(`/admin/all_users/user_details/${id}`);
  };

  // Member
  const handleMember = (id) => {
    history.push(`/admin/all_users/user_details/${id}`);
  };

  // table register_referral
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#regref");
      var table1 = table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: "/api/register_referral",
          headers: {
            Authorization: user.api_token,
          },
          type: "POST",
          dataSrc: "register_referral",
        },
        columns: [
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          {
            data: "user_name",
            render: function (data, type, row) {
              return (
                "<a id='user' class='text-lightpink pointer'>" + data + "</a>"
              );
            },
          },
          {
            data: "from_member_name",
            render: function (data, type, row) {
              return (
                "<a id='member'class='text-lightpink pointer'>" + data + "</a>"
              );
            },
          },
          {
            data: "referral_amount",
            render: function (data, type, row) {
              return Number(data).toFixed(2);
            },
          },
          { data: "referral_dateCreated" },
        ],
        Destroy: true,
      });
      // user
      $("#regref tbody").on("click", "#user", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().member_id;
        handleUser(id);
      });
      // member
      $("#regref tbody").on("click", "#member", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().from_mem_id;
        handleMember(id);
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    // error throw
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      <Title props={t("text_register_referral")} />
      {/* Header */}
      <UserHeader title={t("text_register_referral")} />
      <div className="row">
        <div className="col-md-12"></div>
      </div>
      {/* datatable */}
      <div className="card-body">
        <table id="regref" className="display table table-bordered mb-2">
          <thead className="mt-5">
            <tr>
              <th>{t("text_sr_no")}</th>
              <th>{t("text_user_name")}</th>
              <th>{t("text_from_user_name")}</th>
              <th>
                {t("text_amount")}
                {"("}
                <img src={coin} className="coin" alt="img-coin" />
                {")"}
              </th>
              <th>{t("text_date")}</th>
            </tr>
          </thead>
          <tbody></tbody>
          <tfoot>
            <tr>
              <th>{t("text_sr_no")}</th>
              <th>{t("text_user_name")}</th>
              <th>{t("text_from_user_name")}</th>
              <th>
                {t("text_amount")}
                {"("}
                <img src={coin} className="coin" alt="img-coin" />
                {")"}
              </th>
              <th>{t("text_date")}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};
export default RegisterReferrals;
