import Header from "../theme/header";
import Footer from "../theme/footer";
import {
  LoginApi,
  GetCountry,
  DemoUser,
} from "../../../store/Action/FatchData";
import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import Title from "../../common/Helmet";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { provider, auth } from "../Firebase/firebase";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

function Login({ dispatch, country_data, login_data, res_demouser }) {
  const { t } = useTranslation(["translation", "translation1", "translation2"]);

  // get user detail from local storage
  const history = useHistory();

  const [show, setShow] = useState(false);
  const [otp, getotp] = useState({
    CountryCode: "",
    mobile_no: "",
    referralcode: "",
  });

  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [state, setState] = useState({
    msg91_otp: "0",
    email_mobile: "",
  });
  const handleInputOTP = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  function handleFormValidation() {
    const { UserName, Password } = input;
    let formErrors = {};
    let formIsValid = true;
    if (!UserName) {
      formIsValid = true;
      formErrors["userNameErr"] = t("translation2:err_user_name_req");
    }
    if (!Password) {
      formIsValid = true;
      formErrors["passwordErr"] = t("translation2:err_password_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //otp response
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [input, setInput] = useState({
    UserName: "demouser",
    Password: "password",
  });

  // demouser
  useEffect(() => {
    dispatch(DemoUser());
  }, [dispatch]);
  const demouser = res_demouser.data.demo_user;

  useEffect(() => {
    if (demouser == 1) {
      setInput({ UserName: "demouser", Password: "password" });
    } else {
      setInput({ UserName: "", Password: "" });
    }
  }, [demouser]);

  //google login modal
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  //signinwithgoogle
  const SignInWithGoogle = (e) => {
    e.preventDefault();
    signInWithPopup(auth, provider)
      .then(async (res) => {
        const user = {
          submit: "google_login",
          email_id: res.user.email,
          g_id: res.user.uid,
        };
        var response = await fetch("/api/registerGoogle", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        });
        var res_data = await response.json();
        if (res_data.status === true) {
          handleShow1();
          localStorage.setItem("data", JSON.stringify(res_data));
          localStorage.setItem("login_status", JSON.stringify(res_data.status));
          localStorage.setItem("view_status", 0);
          localStorage.setItem("user", JSON.stringify(res_data.message));
          localStorage.setItem(
            "api_token",
            JSON.stringify(res_data.message.api_token).replace(/['"]+/g, "")
          );
        } else {
          notify(res_data.message);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  // handle input
  const handleInput = (event) => {
    var name = event.target.name;
    var value = event.target.value;
    setInput((values) => ({ ...values, [name]: value }));
  };

  // handle otp
  const handleotp = (e) => {
    const { name, value } = e.target;
    getotp({
      ...otp,
      [name]: value,
    });
  };
  console.log("otp", otp);

  //get countryData
  useEffect(() => {
    dispatch(GetCountry());
  }, [dispatch]);

  //login function
  const handleSubmit = (e) => {
    e.preventDefault();
    handleFormValidation();
    let login_params = {
      user_name: input.UserName ? input.UserName : "",
      password: input.Password ? input.Password : "",
    };
    dispatch(LoginApi(login_params));
  };

  // recaptcha
  const recaptcha = (number) => {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  };

  // verify mobile
  const sendtheotp = async (e) => {
    e.preventDefault();
    const number = otp.CountryCode + otp.mobile_no;
    var response = await fetch(`/api/checkMobileNumber`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(otp),
    });
    var res_data = await response.json();
    console.log("res_data", res_data);
    if (res_data.status === true) {
      notify();
      history.push("/accounts ");
    } else {
      notify(res_data.message);
    }
    // console.log("otp", otp);
    // if (otp != null && res_data.status == false) {
    //   notify(res_data.message);
    // }
    // try {
    //   const response = await recaptcha(number);
    //   history.push("/sendotp", { state: response });
    //   console.log("resr", response);
    // } catch (err) {
    //   console.log("err", err);
    // }
  };

  const allCountryData = country_data.data;

  // for otp false status
  const notify = (data) => toast.error(data);

  const handleOTP = async (e) => {
    e.preventDefault();
    console.log(state);
    var response = await fetch(`/api/sendOTP`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      toast.success(res_data.message);
      setTimeout(() => {
        history.push("/sendotp", res_data);
      }, 2000);
    } else {
      notify(res_data.message);
    }
  };

  return (
    <>
      <Title props={t("text_login")} />
      {/* toastify */}
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {country_data.loading ? (
        <div className="loader">
          <Spinner />
        </div>
      ) : (
        <>
          {/* {/ google login modal /} */}
          <Modal show={show1} onHide={handleClose1} animation={false}>
            <Modal.Header>
              <h6 className="text-dark">{t("text_enter_mobile_no")}</h6>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-3">
                  <span>
                    <label className="text-dark">
                      {t("text_country")}
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <select
                      name="CountryCode"
                      className="form-control dropdown-toggle b-bottom"
                      value={otp.CountryCode || ""}
                      onChange={handleotp}
                    >
                      {allCountryData.all_country.map((country, index) => {
                        return (
                          <option value={country.p_code} key={index}>
                            {country.p_code +
                              " (" +
                              country.country_name +
                              ")" || ""}
                          </option>
                        );
                      })}
                    </select>
                  </span>
                </div>
                <div className="col-9">
                  <span>
                    <label className="text-dark">
                      {t("text_mobile_no")}
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <input
                      name="mobile_no"
                      type="text"
                      className="form-control b-bottom m-0"
                      value={otp.mobile_no}
                      onChange={handleotp}
                    />
                  </span>
                </div>
                {/* <div id="recaptcha-container" /> */}
              </div>
              {/* <span className="required" aria-required="true">
                &nbsp;*&nbsp;
              </span>
              <input
                name="referralcode"
                type="text"
                className="form-control b-bottom"
                value={otp.referralcode}
                onChange={handleotp}
                placeholder="Referral code"
              /> */}
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-lightgray" onClick={handleClose1}>
                {t("text_btn_close")}
              </Button>
              <Button className="btn-lightpink" onClick={sendtheotp}>
                {t("text_verify")}
              </Button>
            </Modal.Footer>
          </Modal>
          {/* reset password */}
          <Modal show={show}>
            <Modal.Header className="text-dark">
              <h4>{t("text_reset_password")}</h4>
            </Modal.Header>
            <Modal.Body>
              <label htmlFor="email" className="text-dark">
                {t("translation2:err_email_req")}&nbsp;
              </label>
              <input
                type="email"
                id="email"
                name="email_mobile"
                placeholder="email address"
                value={state.email}
                onChange={handleInputOTP}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose} className="btn-gray">
                {t("text_btn_close")}
              </Button>
              <Button className="btn-lightpink" onClick={handleOTP}>
                {t("text_send_otp")}
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Header */}
          <Header />
          <div className="page-header d-flex">
            <div className="black-overlay"></div>
            <div className="container m-auto">
              <div className="row align-items-center">
                <div className="col-md-12 text-center">
                  <h1 className=" text-dark">{t("text_login")}</h1>
                </div>
              </div>
            </div>
          </div>
          <section className="bm-light-bg text-dark">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col_right mx-auto">
                  <div className="card cnt-card my-5">
                    <div className="card-body m-2">
                      {/* login form start */}
                      <form id="login-form">
                        {/* username */}
                        <div className="form-group mb-3">
                          <label className="mb-2">
                            {t("text_user_name")}
                            <span className="required" aria-required="true">
                              &nbsp;*&nbsp;
                            </span>
                          </label>
                          <input
                            name="UserName"
                            type="text"
                            className="form-control"
                            value={input.UserName}
                            onChange={handleInput}
                          />
                          <label
                            htmlFor="UserName"
                            generated="true"
                            className={
                              "error " +
                              (state1.formErrors.userNameErr
                                ? " d-block"
                                : "d-none")
                            }
                          >
                            {state1.formErrors.userNameErr}
                          </label>
                        </div>

                        {/* password */}
                        <div className="form-group mb-3">
                          <label className="mb-2">
                            {t("text_password")}
                            <span className="required" aria-required="true">
                              &nbsp;*&nbsp;
                            </span>
                          </label>
                          <input
                            name="Password"
                            type="password"
                            className="form-control"
                            value={input.Password}
                            onChange={handleInput}
                          />
                          <label
                            htmlFor="Password"
                            generated="true"
                            className={
                              "error " +
                              (state1.formErrors.passwordErr
                                ? " d-block"
                                : "d-none")
                            }
                          >
                            {state1.formErrors.passwordErr}
                          </label>
                        </div>

                        {/* forgot password */}
                        <div className="pt-2 mb-3 d-flex">
                          {t("text_forgot_password")}&nbsp;
                          <div
                            className="bm_link_lightpink pointer"
                            onClick={handleShow}
                          >
                            {t("text_reset_now")}
                          </div>
                        </div>

                        {/* login-submit */}
                        <div className="form-group mb-3">
                          <input
                            className="btn btn-submit btn-block btn-lg btn-lightpink mb-2 form-control"
                            type="submit"
                            onClick={handleSubmit}
                          />
                        </div>

                        {/* sign in with Google */}
                        <div className="form-group mb-3">
                          <button
                            className="btn btn-block btn-submit mb-3 form-control btn-custom"
                            style={{ padding: "0px" }}
                            onClick={SignInWithGoogle}
                          >
                            <span className="google-icon"></span>
                            <span className="fs-6 fw-bold">
                              {t("text_google_login")}
                            </span>
                          </button>
                        </div>

                        {/* not have account? */}
                        <div className="pt-2 text-center">
                          {t("text_not_acc")}&nbsp;
                          <Link
                            to="/registration"
                            className="bm_link_lightpink"
                          >
                            {t("text_sign_up")}
                          </Link>
                        </div>
                      </form>
                      {/* login form end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
}

// redux connect
const mapStateToProps = (state) => ({
  login_data: state.login,
  country_data: state.get_all_country,
  google_login: state.googlelogin,
  res_demouser: state.DemoUser,
});

export default connect(mapStateToProps)(Login);
