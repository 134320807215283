import { React, useState, useEffect } from "react";
import UserHeader from "../theme/userHeader";
import { GetLicense } from "../../../store/Action/AdminData";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Title from "../../../views/common/Helmet";
import License from "./license";
import { t } from "i18next";

const LicenseSetting = ({ dispatch, res }) => {
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  // manage state
  const [state, setState] = useState({
    purchase_code: "",
    domain_name: "",
  });
  // handle validation
  const [state1, setState1] = useState({
    formErrors: {},
  });

  // get license data
  useEffect(() => {
    dispatch(GetLicense());
  }, [dispatch]);
  const data = res.data;

  // license
  const handleLicense = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // form validation
  function handleFormValidation() {
    const { purchase_code } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!purchase_code) {
      formIsValid = true;
      formErrors["purchaseCodeErr"] = t("translation2:err_purchase_code_req");
    }
    if (
      purchase_code &&
      !purchase_code.match(
        "/^([a-f0-9]{8})-(([a-f0-9]{4})-){3}([a-f0-9]{12})$/i"
      )
    ) {
      formIsValid = true;
      formErrors["purchaseCodeErr"] = t(
        "translation2:err_purchase_code_invalid"
      );
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  // submit
  const handleSubmit = async (e) => {
    var formData = new FormData();
    e.preventDefault();
    formData.append("purchase_code", state.purchase_code);
    handleFormValidation();
    var response = await fetch(`/api/add_license`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      history.push("/admin/license", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_license")} />
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <>
          {data.get_licence_data.purchase_code ? (
            <License />
          ) : (
            <>
              {/* Header */}
              <UserHeader title={t("text_license_setting")} />
              <div className="row">
                <div className="col-md-12">
                  <div className="card bg-light text-dark">
                    {/* card-header */}
                    <div className="card-header">
                      <strong>{t("text_license_setting")}</strong>
                    </div>
                    <div className="card-body">
                      <div className="col-md-12">
                        <form
                          className="needs-validation"
                          id="validate"
                          method="POST"
                        >
                          <div className="row">
                            {/* purchase code */}
                            <div className="form-group col-12 mb-3">
                              <label htmlFor="purchase_code" className="mb-3">
                                {t("text_purchasecode")}
                                <span className="required" aria-required="true">
                                  &nbsp;*&nbsp;
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="purchase_code"
                                value={state.purchase_code}
                                onChange={handleLicense}
                              />
                              <label
                                htmlFor="purchase_code"
                                generated="true"
                                className={
                                  "error " +
                                  (state1.formErrors.purchaseCodeErr
                                    ? " d-block"
                                    : "d-none")
                                }
                              >
                                {state1.formErrors.purchaseCodeErr}
                              </label>
                            </div>
                          </div>
                          {/* submit */}
                          <div className="form-group d-flex justify-content-center my-3">
                            <input
                              type="submit"
                              value="Submit"
                              name="submit"
                              className={`btn btn-lightpink ${
                                demouser === "1" && "disabled"
                              }`}
                              onClick={demouser === "0" && handleSubmit}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.GetLicense,
});

export default connect(mapStateToProps)(LicenseSetting);
