import React from "react";
import { Link, NavLink } from "react-router-dom";
import coin from "../../../assets/images/coin.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import { t } from "i18next";

const OnGoingTab = ({ props }) => {
  return (
    <>
      <div className="d-flex flex-wrap justify-content-xl-between">
        <div className="border-md-right flex-grow-1 p-3 item">
          <div className="row">
            {props.ongoing.map((data, index) => (
              <div className="col-lg-4 col-md-6 col-sm-12 mb-3" key={index}>
                <div className="tour-card card br-5 overflow-hidden">
                  <span className="join-lottery">
                    <i style={{ color: "#000" }}>
                      <img src={coin} className="coin" alt="coin-img" />
                    </i>
                    &nbsp;
                    {data.lottery_fees}
                  </span>
                  <Link
                    to={{
                      pathname: `/Luckey Draw/lotterydetail/${data.lottery_id}`,
                      state: data,
                    }}
                  >
                    <img
                      src={data.lottery_image}
                      className="img-fluid card-img-top"
                      alt="images"
                    />
                  </Link>
                  <div className="card-body text-dark">
                    <h6 className="card-title">
                      {data.lottery_title} {t("text_for_lottery_id")}
                      {data.lottery_id}&nbsp;
                    </h6>
                    <div className="mb-1">{data.lottery_time}</div>
                    {/* progressbar */}
                    <div className="row">
                      <div className="col-8 my-2">
                        <ProgressBar
                          variant=""
                          now={(data.total_joined / data.lottery_size) * 100}
                          style={{ height: "5px" }}
                        />
                      </div>
                      <div className="col-4 text-center">
                        &nbsp;
                        <span className="text-primary">
                          {data.total_joined}/{data.lottery_size}&nbsp;
                        </span>
                      </div>
                    </div>
                    {data.join_status == false ? (
                      <NavLink
                        className="btn btn-lightpink text-white text-uppercase"
                        to={{
                          pathname: "/Luckey Draw/register",
                          state: {
                            lottery_id: data.lottery_id,
                            lottery_prize: data.lottery_prize,
                          },
                        }}
                      >
                        <img src={coin} alt="coin-img" className="coin" />
                        &nbsp;
                        {data.lottery_fees} {t("text_registered")}
                        <i className="fa fa-angle-down text-white"></i>
                      </NavLink>
                    ) : (
                      <button className="btn btn-block btn-lightgreen text-white">
                        &nbsp;
                        <i className="text-white">
                          <img src={coin} alt="coin-img" className="coin" />
                        </i>
                        &nbsp;
                        {data.lottery_fees} {t("text_registered")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OnGoingTab;
