import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import HomeRoutes from "./routes/homeroutes";
import PrivateRoutes from "./routes/privateroutes";
import AdminRoutes from "./routes/adminPanelRoutes";
import PrivateAdminRoutes from "./routes/privateadminroutes";
//private
import PrivateRoute from "./routes/private/PrivateRoute";
import AdminPrivateRoute from "./routes/private/AdminPrivateRoute";
import MainTheme from "./admin-panel/pages/theme/mainTheme";
import { useTranslation } from "react-i18next";
import ScrollToTop from "react-router-scroll-top";
import "./views/assets/css/style.css";

function App() {
  const view_status = localStorage.getItem("view_status");

  // home routes
  const Home_Route = HomeRoutes.map(({ path, component }, key) => (
    <Route exact path={path} component={component} key={key} />
  ));

  // App view
  const Private_Route = PrivateRoutes.map(({ path, component }, key) => (
    <PrivateRoute exact path={path} component={component} key={key} />
  ));

  // Admin view
  const PrivateAdmin_Route = PrivateAdminRoutes.map(
    ({ path, component }, key) => (
      <PrivateRoute exact path={path} component={component} key={key} />
    )
  );

  // admin panel
  const Admin_Route = AdminRoutes.map(({ path, component }, key) => (
    <AdminPrivateRoute exact path={path} component={component} key={key} />
  ));

  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();

  return (
    <>
      <Router>
        <ScrollToTop>
          <Switch>
            {/* home */}
            {Home_Route}
            {/* app and admin view */}
            {view_status == 1 ? PrivateAdmin_Route : Private_Route}
            {/* admin panel */}
            {sessionStorage.getItem("status") ? (
              <MainTheme>{Admin_Route}</MainTheme>
            ) : (
              <Redirect to="/admin" />
            )}
          </Switch>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
// git init
// git add .
// git commit -m "8august"
// git push origin mania

// username : mbavishi
// password : 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
