import React, { useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Title from "../../../views/common/Helmet";
import MainTheme from "../theme/MainTheme";
import { Watch_Earn } from "../../../store/Action/UserFatchData";
import { connect } from "react-redux";
import CountdownTimer from "react-component-countdown-timer";
import { t } from "i18next";

const WatchAndEarn = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Watch_Earn());
  }, [dispatch]);
  // var data = res.data;

  return (
    <>
      <Title props={t("text_refer_earn")} />
      <MainTheme backPath popupMenu>
        <Scrollbars>
          <div className="bm-content-listing">
            <div className="container support">
              <div className="row">
                <div className="col-12 text-dark text-center">
                  <h4 className="bm_text_lightgreen text-uppercase">
                    {t("text_watch_more_to_earn_more")}
                  </h4>
                  <h6 className="bm_text_lightgreen text-uppercase mt-5">
                    {t("text_complete_task")}
                  </h6>
                  {/* countdown timer  */}
                  <CountdownTimer
                    className="btn-lightgreen border-dashed px-2 m-auto"
                    count={27396}
                    border={false}
                    showTitle={false}
                    noPoints={false}
                    responsive={true}
                    hideDay={true}
                    backgroundColor="#91e2c0"
                  />
                  <img src="" alt="Earn-img" className="p-5" />
                  <div className="row">
                    <button className="btn btn-rounded btn-lightgreen text-uppercase d-block text-white">
                      {t("text_task_completed")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.watch_earn,
});

export default connect(mapStateToProps)(WatchAndEarn);
