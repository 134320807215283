import { React, useState, useEffect } from "react";
import UserHeader from "../../theme/userHeader";
import { useHistory, useParams, NavLink } from "react-router-dom";
import { t } from "i18next";
import { GetGameData } from "../../../../store/Action/AdminData";
import Title from "../../../../views/common/Helmet";
import { connect } from "react-redux";

const AddBanner = ({ dispatch, res }) => {
  //get the id from parameter
  const params = useParams();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  // manage state
  const [insertData, SetInsertData] = useState({});
  const [display, setDisplay] = useState({
    app: false,
    web: false,
  });
  const [visible, setVisible] = useState(false);
  // handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [state, setState] = useState({
    banner_title: "",
    banner_image: "",
    banner_link_type: "",
    app_banner_link: "",
    web_banner_link: "",
    game_id: "",
  });

  useEffect(() => {
    dispatch(GetGameData());
  }, []);
  if (res.loading) return false;
  var gameData = res.data.game_name;

  // banner
  const handleBanner = (e) => {
    const { name, value } = e.target;
    if (name == "app_banner_link") {
      if (value == "Game") {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
    if (name == "banner_link_type") {
      if (value == "app") {
        setDisplay({ app: true });
      } else {
        setDisplay({ web: true });
        setVisible(false);
      }
    }
    setState({
      ...state,
      [name]: value,
    });
  };
  const handleFile = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.files[0],
    });
  };

  // form validation
  function handleFormValidation() {
    const { banner_title, banner_image, banner_link_type } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!banner_title) {
      formIsValid = true;
      formErrors["bannerTitleErr"] = t("translation2:err_banner_title_req");
    }
    if (!banner_image) {
      formIsValid = true;
      formErrors["bannerImageErr"] = t("translation2:err_image_req");
    }
    if (!banner_link_type) {
      formIsValid = true;
      formErrors["bannerLinkTypeErr"] = t(
        "translation2:err_slider_link_type_req"
      );
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    var formData = new FormData();
    e.preventDefault();
    formData.append("banner_title", state.banner_title);
    formData.append("banner_image", state.banner_image);
    formData.append("banner_link_type", state.banner_link_type);
    formData.append("app_banner_link", state.app_banner_link);
    formData.append("web_banner_link", state.web_banner_link);
    formData.append("game_id", state.game_id);
    handleFormValidation();
    // inser banner
    var response = await fetch(`/api/insert_banner`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      history.push("/admin/banner", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_banner")} />
      {/* Header */}
      <UserHeader
        title={t("text_banner")}
        path={"/admin/banner"}
        icon={<i className="fa fa-eye"></i>}
        btnText={t("text_view_banner")}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="card bg-light text-dark">
                {/* card-header */}
                <div className="card-header">
                  <strong>{t("text_banner")}</strong>
                </div>
                <div className="card-body">
                  <form method="POST" id="validate">
                    <div className="row">
                      {/* title */}
                      <div className="form-group col-md-6 mb-3">
                        <label htmlhtmlFor="banner_title" className="mb-3">
                          {t("text_title")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="banner_title"
                          value={state.banner_title}
                          onChange={handleBanner}
                        />
                        <label
                          htmlhtmlFor="banner_title"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.bannerTitleErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.bannerTitleErr}
                        </label>
                      </div>
                      {/* image */}
                      <div className="form-group col-md-offset-1 col-md-6">
                        <label htmlhtmlFor="banner" className="mb-3">
                          {t("text_image")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <br />
                        <input
                          id="banner_image"
                          type="file"
                          className="file-input d-block"
                          name="banner_image"
                          onChange={handleFile}
                          required
                        />
                        <label
                          htmlhtmlFor="banner_image"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.bannerImageErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.bannerImageErr}
                        </label>
                        <p>
                          <b>{t("text_note")} : </b>&nbsp;
                          {t("text_image_note_270x500")}
                        </p>
                      </div>
                      {/* banner link type */}
                      <div className="col-md-6 mt-3">
                        <label htmlhtmlFor="banner_link_type" className="mb-3">
                          {t("text_link_type")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <div className="d-flex">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              id="app"
                              name="banner_link_type"
                              type="radio"
                              className="custom-control-input"
                              value="app"
                              onChange={handleBanner}
                            />
                            &nbsp;
                            <label
                              className="custom-control-label"
                              htmlhtmlFor="app"
                            >
                              {t("text_app")}
                            </label>
                          </div>
                          {/* banner link type */}
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              id="web"
                              name="banner_link_type"
                              type="radio"
                              className="custom-control-input"
                              value="web"
                              onChange={handleBanner}
                            />
                            &nbsp;
                            <label
                              className="custom-control-label"
                              htmlhtmlFor="web"
                            >
                              {t("text_web")}
                            </label>
                          </div>
                        </div>
                        <label
                          htmlhtmlFor="banner_link_type"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.bannerLinkTypeErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.bannerLinkTypeErr}
                        </label>
                      </div>
                      {/* link type options */}
                      <div
                        className={
                          "col-md-6 mt-3 " +
                          (display.app ? "d-block" : "d-none")
                        }
                        id="app-div"
                      >
                        <div className="form-group">
                          <label htmlFor="app_banner_link">
                            {t("text_link")}
                            <span className="required" aria-required="true">
                              &nbsp;*&nbsp;
                            </span>
                          </label>
                          <div>
                            <div className="custom-control custom-radio">
                              <input
                                id="Refer and Earn"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="Refer and Earn"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="Refer and Earn"
                              >
                                {t("text_refer_earn")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="Luckey Draw"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="Luckey Draw"
                                checked=""
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="Luckey Draw"
                              >
                                {t("text_luckey_draw")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="Buy Product"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="Buy Product"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="Buy Product"
                              >
                                {t("text_buy_product")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="Watch and Earn"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="Watch and Earn"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="Watch and Earn"
                              >
                                {t("text_watch_earn")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="My Profile"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="My Profile"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="My Profile"
                              >
                                {t("text_my_profile")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="My Wallet"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="My Wallet"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="My Wallet"
                              >
                                {t("text_my_wallet")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="My Matches"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="My Matches"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="My Matches"
                              >
                                {t("text_my_matches")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="My Statics"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="My Statics"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="My Statics"
                              >
                                {t("text_my_statistics")}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                id="My Referral"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="My Referral"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="My Referral"
                              >
                                {t("text_my_referrals")}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                id="My Rewards"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="My Rewards"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="My Rewards"
                              >
                                {t("text_my_rewards")}
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              <input
                                id="Announcement"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="Announcement"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="Announcement"
                              >
                                {t("text_announcement")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="Top Players"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="Top Players"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="Top Players"
                              >
                                {t("text_top_players")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="Leaderboard"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="Leaderboard"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="Leaderboard"
                              >
                                {t("text_leaderboard")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="App Tutorials"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="App Tutorials"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="App Tutorials"
                              >
                                {t("text_app_tutorial")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="About us"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="About us"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="About us"
                              >
                                {t("text_about_us")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="Customer Support"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="Customer Support"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="Customer Support"
                              >
                                {t("text_customer_supports")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="Terms and Condition"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="Terms and Condition"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="Terms and Condition"
                              >
                                {t("text_terms_conditions")}
                              </label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input
                                id="Game"
                                name="app_banner_link"
                                type="radio"
                                className="custom-control-input"
                                value="Game"
                                onChange={handleBanner}
                              />
                              &nbsp;
                              <label
                                className="custom-control-label"
                                htmlFor="Game"
                              >
                                {t("text_game")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* link */}
                      <div
                        className={
                          "col-md-6 mt-3 " +
                          (display.web ? "d-block" : "d-none")
                        }
                        id="web-div"
                      >
                        <div className="form-group">
                          <label htmlFor="web_banner_link">
                            {t("text_link")}
                            <span className="required" aria-required="true">
                              &nbsp;*&nbsp;
                            </span>
                          </label>
                          <br />
                          <input
                            type="text"
                            className="form-control"
                            name="web_banner_link"
                            value={state.web_banner_link}
                            onChange={handleBanner}
                          />
                        </div>
                      </div>
                    </div>
                    {/* banner options */}
                    <div
                      className={
                        "form-group col-md-6 " +
                        (visible ? "d-block" : "d-none")
                      }
                      id="game-div"
                    >
                      <label htmlFor="game_id">
                        {t("text_game_id")}&nbsp;
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <select
                        className="form-control"
                        name="game_id"
                        onChange={handleBanner}
                      >
                        <option value="">Select..</option>
                        {gameData.map((data, index) => {
                          return (
                            <option value={data.game_id}>
                              {data.game_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {/* submit */}
                    <div className="form-group d-flex justify-content-center my-3">
                      <input
                        type="submit"
                        value="Submit"
                        name="submit"
                        className={`btn btn-lightpink ${
                          demouser === "1" && "disabled"
                        }`}
                        onClick={demouser === "0" && handleSubmit}
                      />
                      {/* cancel */}
                      <NavLink
                        className="btn btn-secondary ms-1 d-block"
                        to="/admin/banner"
                        name="cancel"
                      >
                        {t("text_btn_cancel")}
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.Game,
});

export default connect(mapStateToProps)(AddBanner);
