import { React, useEffect, useState } from "react";
import UserHeader from "../theme/userHeader";
import { useLocation, useHistory } from "react-router-dom";
import Title from "../../../views/common/Helmet";
import Swal from "sweetalert2";
import { t } from "i18next";

const Lottery = () => {
  const $ = window.$;
  const history = useHistory();
  const location = useLocation();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  // manage state
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [danger, setDanger] = useState(false);
  const [selected, setSelected] = useState("");
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (location.state) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  // delete
  const handleDelete = (id, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>You won't be able to revert this?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var response = await fetch(`/api/delete_lottery/${id}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
        });
        var res_data = await response.json();
        table1.ajax.reload(null, false);
        setShow(true);
        setMessage(res_data.message);
        setDanger(false);
      }
    });
  };

  //change the match status
  const handleStatus = (id, status, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>Are you sure to change status?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const value = {
          lottery_status: status,
        };
        var response = await fetch(`/api/change_lottery_status/${id}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
          body: JSON.stringify(value),
        });
        var res_data = await response.json();
        table1.ajax.reload(null, false);
        setShow(true);
        setMessage(res_data.message);
        setDanger(false);
      }
    });
  };

  // delete multi game
  const handleMultiDelete = (id, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>You won't be able to revert this?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const value = {
          action: "delete",
          lottery_id: id,
        };
        var response = await fetch(`/api/change_lottery_multi_status`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
          body: JSON.stringify(value),
        });
        var res_data = await response.json();
        table1.ajax.reload(null, false);
        setShow(true);
        setMessage(res_data.message);
        setDanger(false);
        setSelected("");
        setCheck(false);
      } else if (result.dismiss == "cancel") {
        table1.ajax.reload(null, false);
        setSelected("");
        setCheck(false);
      }
    });
  };

  // user
  const handleUser = (id) => {
    history.push(`/admin/lottery/viewmember/${id}`);
  };

  // edit
  const handleEdit = (id) => {
    history.push(`/admin/lottery/edit/${id}`);
  };

  // table lottery
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#lottery");
      var table1 = table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: "/api/get_lottery",
          headers: {
            Authorization: user.api_token,
          },
          type: "POST",
          dataSrc: "get_lottery_data",
        },
        columns: [
          { data: null, sortable: false },
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          { data: "lottery_title" },
          { data: "lottery_time" },
          {
            data: "lottery_fees",
            render: function (data, type, row) {
              return Number(data).toFixed(2);
            },
          },
          {
            data: "lottery_prize",
            render: function (data, type, row) {
              return Number(data).toFixed(2);
            },
          },
          { data: "lottery_size" },
          { data: "total_joined" },
          { data: "date_created" },
          {
            data: "lottery_status",
            render: function (data, type, row) {
              if (data == "0") var a = "selected";
              if (data == "1") var b = "selected";
              if (data == "2") var c = "selected";

              if (data == 2) {
                return '<span class="badge badge-success">Completed</span>';
              } else {
                return `<select id='btn-status'><option value='0' ${a}>Deactive</option><option value='1' ${b}>Ongoing</option><option value='2' ${c}>Completed</option></select>`;
              }
            },
          },
          {
            data: "lottery_id",
            render: function (data, type, row) {
              if (demouser === "1" && data <= 2) {
                return (
                  '<i class="fa fa-user text-lightpink fa-lg pointer" id="user-btn"></i>' +
                  '<i class="fa fa-edit text-lightpink fa-lg pointer ps-1" id="edit-btn"></i>' +
                  '<i class="fa fa-trash-o text-lightblue fa-lg ps-1 disabled"></i>'
                );
              } else {
                return (
                  '<i class="fa fa-user text-lightpink fa-lg pointer" id="user-btn"></i>' +
                  '<i class="fa fa-edit text-lightpink fa-lg ps-1 pointer" id="edit-btn"></i>' +
                  '<i class="fa fa-trash-o text-lightblue fa-lg ps-1 pointer" id="delete-btn"></i>'
                );
              }
            },
          },
        ],
        columnDefs: [
          {
            orderable: false,
            className: "select-checkbox",
            checkboxes: true,
            targets: 0,
            render: function (data, type, full, meta) {
              return (
                '<input type="checkbox"  value="' +
                $("<div/>").text(data.lottery_id).html() +
                '">'
              );
            },
          },
        ],
        select: {
          style: "multi",
          selector: "td:first-child",
        },
        // order: [[1, "asc"]],
        Destroy: true,
      });
      // user
      $("#lottery tbody").on("click", "#user-btn", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().lottery_id;
        handleUser(id);
      });
      // edit
      $("#lottery tbody").on("click", "#edit-btn", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().lottery_id;
        handleEdit(id);
      });
      // status
      $("#lottery tbody").on("change", "#btn-status", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().lottery_id;
        const status = $("#btn-status option:selected").val();
        handleStatus(id, status, table1);
      });
      // delete
      $("#lottery tbody").on("click", "#delete-btn", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().lottery_id;
        handleDelete(id, table1);
      });
      // Handle click on "Select all" control
      $("#checkall").on("click", function () {
        // Check/uncheck all checkboxes in the table
        var rows = table1.rows().nodes();
        $('input[type="checkbox"]', rows).prop("checked", this.checked);
      });
      var arr = [];
      $("#multi_action").on("change", function () {
        table1.$('input[type="checkbox"]').each(function () {
          // If checkbox doesn't exist in DOM
          var val = $("#multi_action option:selected").text();
          if ($.contains(document, this)) {
            // If checkbox is checked
            if (this.checked) {
              arr.push(this.value);
            }
          }
          if (val == "Delete") {
            if (arr.length === 0) {
              setShow(true);
              setMessage("please do select records");
              setDanger(true);
            } else {
              handleMultiDelete(arr, table1);
            }
          }
        });
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    // error throw
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      <Title props={t("text_lottery")} />
      {/* Header */}
      <UserHeader
        title={t("text_lottery")}
        path={"/admin/add_lottery"}
        icon={<i className="fa fa-plus"></i>}
        btnText={t("text_add_lottery")}
      />
      {/* Message */}
      <div
        class={
          "alert alert-dismissible fade show " +
          (show ? "d-block " : "d-none ") +
          (danger ? "alert-danger" : "alert-success")
        }
        role="alert"
      >
        {location.state && !message ? (
          <span>{location.state.state.data}</span>
        ) : (
          <span></span>
        )}
        {!location.state && message ? <span>{message}</span> : <span></span>}
        {location.state && message ? <span>{message}</span> : <span></span>}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
      {/* datatable */}
      <div className="row">
        <div className="col-md-12">
          <table id="lottery" className="display table table-bordered mb-2">
            <thead className="mt-5">
              <tr>
                <th colSpan={11}>
                  <label htmlFor="action">
                    {t("text_actions_performed")} :&nbsp;
                  </label>
                  <select
                    name="action"
                    id="multi_action"
                    value={selected}
                    onChange={(e) => setSelected(e.target.value)}
                  >
                    <option value="">{t("text_select")}</option>
                    <option value="Delete">{t("text_delete")}</option>
                  </select>
                </th>
              </tr>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    className="checkall"
                    id="checkall"
                    checked={check}
                    onChange={(e) => setCheck(e.target.value)}
                  />
                </th>
                <th>{t("text_sr_no")}</th>
                <th>{t("text_title")}</th>
                <th>{t("text_time")}</th>
                <th>{t("text_fees")}</th>
                <th>{t("text_prize")}</th>
                <th>{t("text_size")}</th>
                <th>{t("text_total_joined")}</th>
                <th>{t("text_date")}</th>
                <th>{t("text_actions")}</th>
                <th>{t("text_view")}</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th></th>
                <th>{t("text_sr_no")}</th>
                <th>{t("text_title")}</th>
                <th>{t("text_time")}</th>
                <th>{t("text_fees")}</th>
                <th>{t("text_prize")}</th>
                <th>{t("text_size")}</th>
                <th>{t("text_total_joined")}</th>
                <th>{t("text_date")}</th>
                <th>{t("text_actions")}</th>
                <th>{t("text_view")}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default Lottery;
