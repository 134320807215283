// order page
import { React, useEffect } from "react";
import { my_order_data } from "../../../../store/Action/UserFatchData";
import coin from "../../../assets/images/coin.png";
import { Scrollbars } from "react-custom-scrollbars";
import Title from "../../../common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import MainTheme from "../../theme/MainTheme";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { t } from "i18next";

const My_orders = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(my_order_data());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_my_orders")} />
      <MainTheme title={t("text_my_orders")} backPath headCoin>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <Scrollbars>
            {data.my_orders.length ? (
              data.my_orders.map((data, key) => (
                <div className="bm-content-listing tournaments" key={key}>
                  <div className="col-md-12 text-center text-black">
                    <div
                      className="card br-5 hide-over mb-3 p-2"
                      style={{ minHeight: "unset" }}
                    >
                      {/* product image and link */}
                      <NavLink
                        className="row no-underline"
                        to={`/account/my_orders/order_details/${data.orders_id}`}
                      >
                        <div
                          className="col-md-5 m-auto"
                          style={{ height: "100%" }}
                        >
                          <img
                            src={data.product_image}
                            className="img-fluid card-img-top"
                            alt="img"
                          />
                        </div>
                        <div className="col-md-7">
                          {/* order number */}
                          <h6 className="mt-2 mb-0 text-black">
                            {data.order_no}
                          </h6>
                          {/* product price */}
                          <div className="text-black mb-1 ">
                            {t("text_price")}:
                            <img src={coin} className="coin" alt="img-coin" />
                            {data.product_price}
                          </div>
                          {/* order date */}
                          <div className="text-black mb-1">
                            {t("text_order_date")} : {data.created_date}
                          </div>
                          {/* order status */}
                          <div className="text-black mb-1">
                            {t("text_status")}: {data.order_status}
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              // if there is no orders
              <div className="bm-content-listing tournaments">
                <div className="col-md-12 text-center text-black">
                  <strong>{t("text_order_empty")}</strong>
                </div>
              </div>
            )}
          </Scrollbars>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.order,
});

export default connect(mapStateToProps)(My_orders);
