import { React, useState } from "react";
import coin from "../../../../../views/assets/images/coin.png";
import { Scrollbars } from "react-custom-scrollbars";
import ReactHtmlParser from "react-html-parser";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { t } from "i18next";

const Description = (data) => {
  var user = JSON.parse(localStorage.getItem("user"));
  var { id } = useParams();

  // manage state
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState({
    match_id: id,
    member_id: user.member_id,
    pubg_id: "",
    match_join_member_id: data.data.join_position.length
      ? data.data.join_position[0].match_join_member_id
      : "",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //update the data
  const handleUpdate = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setUpdate({
      ...update,
      [name]: value,
    });
  };

  const updateData = async (e) => {
    var response = await fetch(`/api/change_player_name`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(update),
    });
    var res_data = await response.json();
    if (res_data) {
      handleClose();
      window.location.reload();
    }
  };

  return (
    <>
      {/* modal for player name change */}
      <Modal show={show} onHide={handleClose} className="mx-auto">
        <Modal.Header className="modal-header bg-modal text-center d-block text-dark">
          <Button
            className="btn "
            onClick={handleClose}
            style={{
              width: "10px",
              textAlign: "center",
              border: "1px solid gray",
              borderRadius: "50%",
              background: "transparent",
              color: "black",
              float: "right",
            }}
          >
            ×
          </Button>
          <h5 className="modal-title">{t("text_edit_player_name")}</h5>
        </Modal.Header>
        <Modal.Body className="mb-0 bg-white">
          <div className="modal-body text-black pb-0">
            <input
              type="text"
              id="pubg_id"
              name="pubg_id"
              className="form-control valid"
              value={update.pubg_id}
              onChange={handleUpdate}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-primary" onClick={updateData}>
            {t("text_btn_save")}
          </Button>
          <Button className="btn-gray" onClick={handleClose}>
            {t("text_btn_close")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Scrollbars>
        <div className="bm-content-listing tournaments">
          <div className=" bm-content-listing text-black">
            {/* match name */}
            <h6 className="bm_text_lightgreen">
              {data.data.match.match_name} {t("text_for_macth_id")}
              {data.data.match.m_id}
            </h6>
            {/* team */}
            <span className="btn btn-sm btn-white shadow m-1">
              {t("text_team")} : <strong>{data.data.match.type}</strong>&nbsp;
            </span>
            {/* entry fee */}
            <span className="btn btn-sm btn-white shadow m-1">
              {t("text_entry_fee")} :&nbsp;
              <strong>
                <i>
                  <img src={coin} className="coin" alt="img-coin" />
                </i>
                &nbsp;
                {data.data.match.entry_fee}
              </strong>
              &nbsp;
            </span>
            {/* match type */}
            <span className="btn btn-sm btn-white shadow m-1">
              {t("text_match_type")} : <strong>{t("text_free")}</strong>&nbsp;
            </span>
            {/* map */}
            <span className="btn btn-sm btn-white shadow m-1">
              {t("text_map")} : <strong>{data.data.match.Miramar}</strong>&nbsp;
            </span>
            {/* match schedule */}
            <span className="btn btn-sm btn-white shadow m-1">
              {t("text_match_schedule")} :&nbsp;
              <strong>{data.data.match.match_time}</strong>&nbsp;
            </span>
            {/* table */}
            <div className="f-result mt-3 shadow-sm rounded-0">
              {data.data.join_position.length ? (
                <table className="table table-responsive">
                  <thead className="btn-green">
                    <tr>
                      <th>{t("text_team")}</th>
                      <th>{t("text_position")}</th>
                      <th>{t("text_player_name")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.data.join_position.map((data, index) => (
                      <tr key={index}>
                        {/* team */}
                        <td>
                          {t("text_team")}
                          {data.team}
                        </td>
                        {/* position */}
                        <td>{data.position}</td>
                        <td>
                          <div
                            className="modal-div"
                            data-toggle="modal"
                            data-target="#myModal"
                            data-id={data.pubg_id}
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={handleShow}
                          >
                            {"   "}
                            {data.pubg_id}
                            <span>
                              <i
                                style={{
                                  paddingLeft: "15px",
                                  color: "#000",
                                }}
                                className="fa fa-pencil"
                              ></i>
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </div>
            {/* prize details */}
            <h6 className="bm_text_lightgreen mt-3">
              {t("text_prize_details")}
            </h6>
            {/* win prize */}
            <span className="btn btn-sm btn-white shadow m-1">
              {t("text_winning_prize")} :&nbsp;
              <strong>
                <i>
                  <img src={coin} className="coin" alt="img-coin" />
                </i>
                &nbsp;
                {data.data.match.win_prize}
              </strong>
            </span>
            {/* per kill */}
            <span className="btn btn-sm btn-white shadow m-1">
              {t("text_per_kill")} :&nbsp;
              <strong>
                <i>
                  <img src={coin} className="coin" alt="img-coin" />
                </i>
                &nbsp;
                {data.data.match.per_kill}
              </strong>
            </span>
            {/* match descriptions */}
            <h6 className="bm_text_lightgreen mt-3">{t("text_about_match")}</h6>
            <span>{ReactHtmlParser(data.data.match.match_desc)}</span>
            <h6 className="bm_text_lightgreen mt-3">
              {t("text_match_private_description")}
              {data.data.match.join_status && (
                <span className="text-dark">
                  {/* convert jsx to html */}
                  {ReactHtmlParser(data.data.match.match_private_desc)}
                </span>
              )}
            </h6>
          </div>
        </div>
      </Scrollbars>
    </>
  );
};
export default Description;
