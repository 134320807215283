import { React } from "react";
import BackPath from "./header_detail/backPath";
import HeadCoin from "./header_detail/headCoin";
import PopupMenu from "./header_detail/popupMenu";

function UserHeader({ backPath, headCoin, popupMenu, title }) {
  return (
    <div className="bm-mdl-header">
      {backPath && <BackPath />}
      {headCoin && <HeadCoin />}
      {popupMenu && <PopupMenu />}
      {/* Title on Header */}
      {/* &nbsp; for white space */}
      <h4 className="m-0 d-inline align-bottom">&nbsp;&nbsp;{title}</h4>
    </div>
  );
}

export default UserHeader;
