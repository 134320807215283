import React from "react";
import coin from "../../assets/images/coin.png";
import { t } from "i18next";

const MyWalletTable = () => {
  const $ = window.$;
  var user = JSON.parse(localStorage.getItem("user"));

  // wallet table
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#wallet");
      table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: "/api/transaction",
          headers: {
            Authorization: "Bearer " + user.api_token,
          },
          type: "POST",
          dataSrc: "transaction",
        },
        columns: [
          { data: "transaction_id", visible: false },
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          {
            data: null,
            render: function (data, type, row, meta) {
              var num = Number(parseInt(row.deposit - row.withdraw));
              var tot = num.toFixed(2);
              if (tot > 0) {
                return '<span class="text-lightgreen">Credit</span>';
              } else {
                return '<span class="text-primary">Debit</span>';
              }
            },
          },
          { data: "note" },
          {
            data: null,
            render: function (data, type, row, meta) {
              var num = Number(parseInt(row.deposit - row.withdraw));
              var tot = num.toFixed(2);
              if (tot > 0) {
                return '<span class="text-lightgreen">+' + tot + "</span>";
              } else {
                return '<span class="text-primary">' + tot + "</span>";
              }
            },
          },
          {
            data: "join_money",
            render: function (data, type, row, meta) {
              return Number(data).toFixed(2);
            },
          },
          {
            data: "win_money",
            render: function (data, type, row, meta) {
              return Number(data).toFixed(2);
            },
          },
          { data: "date" },
        ],
        order: [[0, "desc"]],
        Destroy: true,
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    // error throw
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      {/* wallet table */}
      <table id="wallet" className="display table table-bordered mb-2">
        {/* table head */}
        <thead className="mt-5">
          <tr>
            <th>{t("text_transaction_id")}</th>
            <th>{t("text_sr_no")}</th>
            <th> {t("text_transaction_no")} </th>
            <th> {t("text_image_note")}</th>
            <th>
              {t("text_amount")}
              {"("}
              <img src={coin} className="coin" alt="img-coin" />
              {")"}
            </th>
            <th>
              {t("text_join_money")}
              {"("}
              <img src={coin} className="coin" alt="img-coin" />
              {")"}
            </th>
            <th>
              {t("text_win_money")}
              {"("}
              <img src={coin} className="coin" alt="img-coin" />
              {")"}
            </th>
            <th>{t("text_date")}</th>
          </tr>
        </thead>
        {/* table body */}
        <tbody></tbody>
        {/* table foot */}
        <tfoot>
          <tr>
            <th>{t("text_transaction_id")}</th>
            <th>{t("text_sr_no")}</th>
            <th> {t("text_transaction_no")} </th>
            <th> {t("text_image_note")}</th>
            <th>
              {t("text_amount")}
              {"("}
              <img src={coin} className="coin" alt="img-coin" />
              {")"}
            </th>
            <th>
              {t("text_join_money")}
              {"("}
              <img src={coin} className="coin" alt="img-coin" />
              {")"}
            </th>
            <th>
              {t("text_win_money")}
              {"("}
              <img src={coin} className="coin" alt="img-coin" />
              {")"}
            </th>
            <th>{t("text_date")}</th>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default MyWalletTable;
