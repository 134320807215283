import { React, useState } from "react";
import { CKEditor } from "ckeditor4-react";
import { t } from "i18next";
import { NavLink } from "react-router-dom";

const AppUpload1 = (props) => {
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [message, setMessage] = useState({
    status: false,
    msg: "",
  });

  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    app_upload: "",
    app_version: "",
    force_update: "",
  });
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [editor, setEditor] = useState({
    app_description: "",
  });
  const handleChange = (e) => {
    const { name } = e.target;
    if (name == "app_upload") var value = e.target.files[0];
    else value = e.target.value;

    setState({
      ...state,
      [name]: value,
    });
  };
  function handleFormValidation() {
    const { app_upload, app_version, force_update } = state;
    const { app_description } = editor;
    let formErrors = {};
    let formIsValid = true;
    let path = /(\.apk)$/i;

    if (!app_upload) {
      formIsValid = true;
      formErrors["appUploadErr"] = t("translation2:err_app_upload_req");
    }
    if (app_upload && !app_upload.name.split(".").pop() == "apk") {
      formIsValid = true;
      formErrors["appVUploadErr"] = t("translation2:err_app_upload_valid");
    }
    if (!app_version) {
      formIsValid = true;
      formErrors["err_force_update_req"] = t(
        "translation2:err_app_version_req"
      );
    }
    if (!force_update) {
      formIsValid = true;
      formErrors["forseUpdatehtpOrderErr"] = t(
        "translation2:err_force_update_req"
      );
    }
    if (!app_description) {
      formIsValid = true;
      formErrors["appDescErr"] = t("translation2:err_app_desc_req");
    }

    setState1({ formErrors: formErrors });
    return formIsValid;
  }
  console.log(state1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      ...state,
      ...editor,
    };
    handleFormValidation();

    var response = await fetch(`/api/insert_app_setting`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      props.onShow(true);

      props.onMessage({ status: res_data.status, msg: res_data.message });
    }
  };
  return (
    <>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            <div className="card-header">
              <strong>{t("text_appupload")}</strong>
            </div>
            <div className="card-body">
              <div className="col-md-12">
                <form
                  className="needs-validation"
                  id="app-upload-form"
                  method="POST"
                >
                  <div className="row">
                    <div className="form-group col-md-3 mb-3">
                      <label htmlFor="app_upload">
                        {t("text_app")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      <input
                        id="app_upload"
                        type="file"
                        className="file-input mt-3"
                        name="app_upload"
                        accept=".apk"
                        onChange={handleChange}
                      />
                      &nbsp;
                      <label
                        htmlFor="app_upload"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.appUploadErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.appUploadErr}
                      </label>
                      <label
                        htmlFor="app_upload"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.appVUploadErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.appVUploadErr}
                      </label>
                      <br />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="app_version">
                        {t("text_version_code")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="app_version"
                        type="text"
                        className="form-control mt-3"
                        name="app_version"
                        value={state.app_version}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="app_version"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.err_force_update_req
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.err_force_update_req}
                      </label>
                    </div>
                    <div className=" form-group col-md-3 mb-3">
                      <label htmlFor="force_update">
                        {t("text_force_update")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <div className="form-group col-md-12 mt-3 d-flex">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="force_update_yes"
                            name="force_update"
                            type="radio"
                            className="custom-control-input"
                            value="Yes"
                            onChange={handleChange}
                          />
                          &nbsp;
                          <label
                            className="custom-control-label ps-1"
                            htmlFor="force_update_yes"
                          >
                            {t("text_yes")}
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            id="force_update_no"
                            name="force_update"
                            type="radio"
                            className="custom-control-input"
                            value="No"
                            onChange={handleChange}
                          />
                          &nbsp;
                          <label
                            className="custom-control-label ps-1"
                            htmlFor="force_update_no"
                          >
                            {t("text_no")}
                          </label>
                        </div>
                      </div>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.forseUpdatehtpOrderErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.forseUpdatehtpOrderErr}
                      </label>
                    </div>
                    <div className=" form-group col-md-2 mb-3">
                      <div className="custom-control custom-checkbox">
                        <input
                          id="force_logged_out"
                          name="force_logged_out"
                          type="checkbox"
                          className="custom-control-input"
                          value="Yes"
                        />
                        &nbsp;
                        <label
                          className="custom-control-label"
                          htmlFor="force_logged_out"
                        >
                          {t("text_logout_user")}
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-12 mb-3">
                      <label htmlFor="app_description">
                        {t("text_app_desc")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <CKEditor
                        initData={<p></p>}
                        onChange={(event, editor) => {
                          const data = event.editor.getData();
                          setEditor({
                            app_description: data,
                          });
                        }}
                      />
                      <label
                        htmlFor="app_description"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.appDescErr ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.appDescErr}
                      </label>
                    </div>
                  </div>
                  <div className="form-group d-flex justify-content-center mb-3">
                    <input
                      type="submit"
                      value="Submit"
                      name="submit"
                      className={`btn btn-lightpink ${
                        demouser === "1" && "disabled"
                      }`}
                      onClick={demouser === "0" && handleSubmit}
                    />
                    <NavLink
                      className="btn btn-secondary ms-1 d-block"
                      to="/admin/app_setting"
                      name="cancel"
                    >
                      {t("text_btn_cancel")}
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppUpload1;
