import React from "react";
// import { Fade } from "react-awesome-reveal";
import Fade from "react-reveal/Fade";
import { t } from "i18next";

const Tab = ({ props }) => {
  return (
    <>
      <section id="tabs" className="bm-dark-bg bm-section-padding">
        <div className="container">
          <h6 className="section-title bm_section_title">
            {t("text_features")}
          </h6>
          <p className="bm_section_subtitle text-center bm_mb30">
            Battlemania Application will give you stage to play eSports on
            <br />
            your preferred portable games.
          </p>
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <nav>
                <div
                  className="nav nav-tabs nav-fill"
                  id="nav-tab"
                  role="tablist"
                >
                  {props.features_tabs.map((data, index) => {
                    var active = "";
                    if (index === 0) {
                      active = "active";
                    }
                    return (
                      <a
                        className={`nav-item nav-link ${active}`}
                        id={`nav-${data.f_tab_name.replace(" ", "-")}-tab`}
                        data-toggle="tab"
                        href={`#nav-${data.f_tab_name.replace(" ", "-")}`}
                        role="tab"
                        aria-selected="true"
                        key={index}
                      >
                        {data.f_tab_name}
                      </a>
                    );
                  })}
                </div>
              </nav>
              <div
                className="tab-content py-5 px-3 px-sm-0"
                id="nav-tabContent"
              >
                {props.features_tabs.map((features_tab, index) => {
                  var active_tab = "";
                  if (index === 0) {
                    active_tab = "active";
                  }
                  // center tab
                  if (features_tab.f_tab_img_position === "center") {
                    return (
                      <Fade key={index}>
                        <div
                          className={`center-tab tab-pane fade show ${active_tab}`}
                          key={index}
                          id={`nav-${features_tab.f_tab_name.replace(
                            " ",
                            "-"
                          )}`}
                          role="tabpanel"
                          aria-labelledby={`nav-${features_tab.f_tab_name.replace(
                            " ",
                            "-"
                          )}-tab`}
                        >
                          <div className="container">
                            <div className="row">
                              <div className="bm-knowtext col-md-12 text-center">
                                <h3>{features_tab.f_tab_title}</h3>
                                <p>{features_tab.f_tab_text}</p>
                              </div>
                              <div className="col-lg-4">
                                {props.features_tab_contents
                                  .filter(
                                    (item) =>
                                      item.features_tab_id === features_tab.f_id
                                  )
                                  .map((features_tab_content, index1) => {
                                    if (index1 > 2) {
                                      return null;
                                    }
                                    var card_className = "";
                                    if (index1 === 0) {
                                      card_className = "first-card";
                                    }
                                    return (
                                      <div key={index1}>
                                        <div
                                          className={`card bm_featurecard left-pane ${card_className}`}
                                        >
                                          <div className="card-body">
                                            <div className="bm_featuretext">
                                              <h4>
                                                {
                                                  features_tab_content.content_title
                                                }
                                              </h4>
                                              <p>
                                                {
                                                  features_tab_content.content_text
                                                }
                                              </p>
                                            </div>
                                            <div className="bm_featureicon">
                                              <i
                                                className={
                                                  features_tab_content.content_icon
                                                }
                                              ></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                              <div className="col-lg-4 text-center">
                                <img
                                  src={features_tab.f_tab_image}
                                  alt="img"
                                  className="img-fluid animated fadeIn"
                                />
                              </div>
                              <div className="col-lg-4">
                                {props.features_tab_contents
                                  .filter(
                                    (item) =>
                                      item.features_tab_id === features_tab.f_id
                                  )
                                  .map((features_tab_content, key) => {
                                    if (key >= 3) {
                                      if (key > 5) {
                                        return null;
                                      }
                                      var card_className = "";
                                      if (key === 3) {
                                        card_className = "first-card";
                                      }
                                      return (
                                        <div key={key}>
                                          <div
                                            className={`card bm_featurecard right-pane ${card_className}`}
                                          >
                                            <div className="card-body">
                                              <div className="bm_featureicon">
                                                <i
                                                  className={
                                                    features_tab_content.content_icon
                                                  }
                                                ></i>
                                              </div>
                                              <div className="bm_featuretext">
                                                <h4>
                                                  {
                                                    features_tab_content.content_title
                                                  }
                                                </h4>
                                                <p>
                                                  {
                                                    features_tab_content.content_text
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                    return null;
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fade>
                    );
                  }
                  // left tab
                  else if (features_tab.f_tab_img_position === "left") {
                    return (
                      <Fade key={index}>
                        <div
                          className={`left-tab tab-pane fade show ${active_tab}`}
                          key={index}
                          id={`nav-${features_tab.f_tab_name.replace(
                            " ",
                            "-"
                          )}`}
                          role="tabpanel"
                          aria-labelledby={`nav-${features_tab.f_tab_name.replace(
                            " ",
                            "-"
                          )}-tab`}
                        >
                          <div className="container">
                            <div className="row">
                              <div className="bm-knowtext col-md-12 text-center">
                                <h3>{features_tab.f_tab_title}</h3>
                                <p>{features_tab.f_tab_text}</p>
                              </div>
                              <div className="col-lg-4 text-center">
                                <img
                                  src={features_tab.f_tab_image}
                                  className="img-fluid animated fadeIn"
                                  alt="img"
                                />
                              </div>
                              <div className="col-lg-4">
                                <div className="bm-iconsarea">
                                  {props.features_tab_contents
                                    .filter(
                                      (item, index) =>
                                        item.features_tab_id ===
                                        features_tab.f_id
                                    )
                                    .map((features_tab_content, key) => {
                                      if (key > 2) {
                                        return null;
                                      }
                                      var card_className = "";
                                      if (key === 0) {
                                        card_className = "first-card";
                                      }
                                      return (
                                        <div key={key}>
                                          <div
                                            className={`card bm_featurecard right-pane ${card_className}`}
                                          >
                                            <div className="card-body">
                                              <div className="bm_featureicon">
                                                <i
                                                  className={
                                                    features_tab_content.content_icon
                                                  }
                                                ></i>
                                              </div>
                                              <div className="bm_featuretext">
                                                <h4>
                                                  {
                                                    features_tab_content.content_title
                                                  }
                                                </h4>
                                                <p>
                                                  {
                                                    features_tab_content.content_text
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="bm-iconsarea">
                                  {props.features_tab_contents
                                    .filter(
                                      (item, index) =>
                                        item.features_tab_id ===
                                        features_tab.f_id
                                    )
                                    .map((features_tab_content, key) => {
                                      if (key >= 3) {
                                        if (key > 5) {
                                          return null;
                                        }
                                        var card_className = "";
                                        if (key === 3) {
                                          card_className = "first-card";
                                        }
                                        return (
                                          <div key={key}>
                                            <div
                                              className={`card bm_featurecard right-pane ${card_className}`}
                                            >
                                              <div className="card-body">
                                                <div className="bm_featureicon">
                                                  <i
                                                    className={
                                                      features_tab_content.content_icon
                                                    }
                                                  ></i>
                                                </div>
                                                <div className="bm_featuretext">
                                                  <h4>
                                                    {
                                                      features_tab_content.content_title
                                                    }
                                                  </h4>
                                                  <p>
                                                    {
                                                      features_tab_content.content_text
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fade>
                    );
                  }
                  // right tab
                  else if (features_tab.f_tab_img_position === "right") {
                    return (
                      <Fade key={index}>
                        <div
                          className={`right-tab tab-pane fade show ${active_tab}`}
                          key={index}
                          id={`nav-${features_tab.f_tab_name.replace(
                            " ",
                            "-"
                          )}`}
                          role="tabpanel"
                          aria-labelledby={`nav-${features_tab.f_tab_name.replace(
                            " ",
                            "-"
                          )}-tab`}
                        >
                          <div className="container">
                            <div className="row">
                              <div className="bm-knowtext col-md-12 text-center">
                                <h3>{features_tab.f_tab_title}</h3>
                                <p>{features_tab.f_tab_text}</p>
                              </div>
                              <div className="col-lg-4">
                                <div className="bm-iconsarea">
                                  {props.features_tab_contents
                                    .filter(
                                      (item, index) =>
                                        item.features_tab_id ===
                                        features_tab.f_id
                                    )
                                    .map((features_tab_content, key) => {
                                      if (key > 2) {
                                        return null;
                                      }
                                      var card_className = "";
                                      if (key === 0) {
                                        card_className = "first-card";
                                      }
                                      return (
                                        <div key={key}>
                                          <div
                                            className={`card bm_featurecard right-pane ${card_className}`}
                                          >
                                            <div className="card-body">
                                              <div className="bm_featureicon">
                                                <i
                                                  className={
                                                    features_tab_content.content_icon
                                                  }
                                                ></i>
                                              </div>
                                              <div className="bm_featuretext">
                                                <h4>
                                                  {
                                                    features_tab_content.content_title
                                                  }
                                                </h4>
                                                <p>
                                                  {
                                                    features_tab_content.content_text
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="bm-iconsarea">
                                  {props.features_tab_contents
                                    .filter(
                                      (item, index) =>
                                        item.features_tab_id ===
                                        features_tab.f_id
                                    )
                                    .map((features_tab_content, key) => {
                                      if (key >= 3) {
                                        if (key > 5) {
                                          return null;
                                        }
                                        var card_className = "";
                                        if (key === 3) {
                                          card_className = "first-card";
                                        }
                                        return (
                                          <div key={key}>
                                            <div
                                              className={`card bm_featurecard right-pane ${card_className}`}
                                            >
                                              <div className="card-body">
                                                <div className="bm_featureicon">
                                                  <i
                                                    className={
                                                      features_tab_content.content_icon
                                                    }
                                                  ></i>
                                                </div>
                                                <div className="bm_featuretext">
                                                  <h4>
                                                    {
                                                      features_tab_content.content_title
                                                    }
                                                  </h4>
                                                  <p>
                                                    {
                                                      features_tab_content.content_text
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                </div>
                              </div>
                              <div className="col-lg-4 text-center">
                                <img
                                  src={features_tab.f_tab_image}
                                  alt="img"
                                  className="img-fluid animated fadeIn"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fade>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tab;
