import React from "react";
import { useParams } from "react-router-dom";
import coin from "../../../assets/images/coin.png";
import { t } from "i18next";

const MyStasticsTable = () => {
  const $ = window.$;
  const params = useParams();
  const user = JSON.parse(sessionStorage.getItem("user"));

  // table stastics
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#stastics");
      var table1 = table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: `/api/get_statistics/${params.id}`,
          headers: {
            Authorization: user.api_token,
          },
          type: "POST",
          dataSrc: "match_statistics",
        },
        columns: [
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          { data: "match_name" },
          {
            data: "entry_fee",
            render: function (data, type, row) {
              return Number(data).toFixed(2);
            },
          },
          {
            data: "total_win",
            render: function (data, type, row) {
              return Number(data).toFixed(2);
            },
          },
          { data: "date_craeted" },
        ],
        Destroy: true,
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      <div className="row mb-4" id="profile_setting">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            {/* card-header */}
            <div className="card-header">
              <strong>{t("text_my_statistics")}</strong>
            </div>
            {/* datatable */}
            <div className="card-body">
              <table
                id="stastics"
                className="display table table-bordered mb-2"
              >
                <thead className="mt-5">
                  <tr>
                    <th>{t("text_sr_no")}</th>
                    <th>{t("text_match_info")}</th>
                    <th>
                      {t("text_paid")}
                      {"("}
                      <img src={coin} className="coin" alt="img-coin" />
                      {")"}
                    </th>
                    <th>
                      {t("text_won")}
                      {"("}
                      <img src={coin} className="coin" alt="img-coin" />
                      {")"}
                    </th>
                    <th>{t("text_note")}</th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <th>{t("text_sr_no")}</th>
                    <th>{t("text_match_info")}</th>
                    <th>
                      {t("text_paid")}
                      {"("}
                      <img src={coin} className="coin" alt="img-coin" />
                      {")"}
                    </th>
                    <th>
                      {t("text_won")}
                      {"("}
                      <img src={coin} className="coin" alt="img-coin" />
                      {")"}
                    </th>
                    <th>{t("text_note")}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyStasticsTable;
