// order page
import { React, useEffect } from "react";
import { my_order_data } from "../../../store/Action/UserFatchData";
import coin from "../../assets/images/coin.png";
import Spinner from "react-bootstrap/Spinner";
import MainTheme from "../theme/MainTheme";
import { Link } from "react-router-dom";
import Title from "../../common/Helmet";
import { connect } from "react-redux";
import { t } from "i18next";

const MyOrders = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(my_order_data());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_order")} />
      <MainTheme title={t("text_order")}>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="row">
              {data.my_orders.length ? (
                data.my_orders.map((data, index) => (
                  <div className="col-lg-6 col-12">
                    <div className="bm-content-listing tournaments" key={index}>
                      <div className="col-md-12 text-center text-black">
                        <div
                          className="card br-5 hide-over mb-3 p-2"
                          style={{ minHeight: "unset" }}
                        >
                          {/* Link to single order */}
                          <Link
                            className="row no-underline"
                            to={`/account/my_orders/order_details/${data.orders_id}`}
                          >
                            <div
                              className="col-md-5 m-auto"
                              style={{ height: "100%" }}
                            >
                              {/* Product Image */}
                              <img
                                src={data.product_image}
                                className="img-fluid card-img-top"
                                alt="img"
                              />
                            </div>
                            <div className="col-md-7">
                              {/* Order no */}
                              <h6 className="mt-2 mb-0 text-black">
                                {data.order_no}
                              </h6>
                              {/* Price */}
                              <div className="text-black mb-1 ">
                                {t("text_price")}:
                                <img
                                  src={coin}
                                  className="coin"
                                  alt="img-coin"
                                />
                                {data.product_price}
                              </div>
                              {/* Order Date */}
                              <div className="text-black mb-1">
                                {t("text_order_date")} : {data.created_date}
                              </div>
                              {/* Order Status */}
                              <div className="text-black mb-1">
                                &nbsp;
                                {t("text_status")}: {data.order_status}
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                // If there is no Any Order
                <strong className="text-dark text-center">
                  {t("text_order_empty")}
                </strong>
              )}
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.order,
});

export default connect(mapStateToProps)(MyOrders);
