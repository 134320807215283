import React from "react";
import { NavLink } from "react-router-dom";
import { t } from "i18next";

const LotteryFooter = ({ data }) => {
  return (
    <>
      <div className="py-3">
        {data.join_status == true ? (
          <a
            style={{
              cursor: "auto",
            }}
            className="btn btn-sm f-18 btn-lightpink text-uppercase"
          >
            {t("text_already_registered")}
          </a>
        ) : (
          <NavLink
            to={{
              pathname: "/Luckey Draw/register",
              state: {
                lottery_id: data.lottery_id,
                lottery_prize: data.lottery_prize,
              },
            }}
            className="btn btn-sm f-18 btn-lightgreen text-uppercase"
          >
            {t("text_register")}
          </NavLink>
        )}
      </div>
    </>
  );
};

export default LotteryFooter;
