import { React, useEffect } from "react";
import MainTheme from "../../../theme/MainTheme";
import UserHeader from "../../../theme/UserHeader";
import { Single_match_data } from "../../../../../store/Action/UserFatchData";
import Description from "./description";
import JoinMember from "./JoinMember";
import { useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const OnGoingDetail = ({ dispatch, res }) => {
  const location = useLocation();
  var { id } = useParams();
  useEffect(() => {
    dispatch(Single_match_data(id));
  }, [dispatch, id]);
  var data = res.data;

  return (
    <>
      <MainTheme>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <UserHeader props={data.match.match_name} />
            <div className="row">
              <div className="col-lg-12">
                {/* Game Image */}
                <img
                  src={location.state}
                  alt="img"
                  className="img-fluid1 img-responsive"
                />
                <div className="card my-3">
                  <div
                    className="card-body dashboard-tabs p-0 bg-lightgray"
                    id="tabs-1"
                  >
                    <ul className="nav nav-tabs" role="tablist">
                      {/* Description Tab */}
                      <li className="nav-item">
                        <a
                          className="navlink active"
                          id="description-tab"
                          data-toggle="tab"
                          href="#description"
                          role="tab"
                          aria-controls="Description"
                          aria-selected="true"
                        >
                          {t("text_description")}
                        </a>
                      </li>
                      {/* Joined Member Tab */}
                      <li className="nav-item">
                        <a
                          className="navlink"
                          id="join_member-tab"
                          data-toggle="tab"
                          href="#join_member"
                          role="tab"
                          aria-controls="join_member"
                          aria-selected="false"
                        >
                          {t("text_joined_member")}
                        </a>
                      </li>
                    </ul>
                    {/* Tab Content */}
                    <div className="tab-content py-0 px-0">
                      {/* Description Tab */}
                      <div
                        className="tab-pane fade show active"
                        id="description"
                        role="tabpanel"
                        aria-labelledby="description-tab"
                      >
                        <Description data={data} />
                      </div>
                      {/* Joined Member Tab */}
                      <div
                        className="tab-pane fade show "
                        id="join_member"
                        role="tabpanel"
                        aria-labelledby="join_member-tab"
                      >
                        <JoinMember data={data} />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Spactate Button */}
                <div className="row">
                  <div className="col-md-6">
                    <a
                      href={data.match_url}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-lightpink"
                    >
                      {t("text_Spactate")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.singlematch,
});

export default connect(mapStateToProps)(OnGoingDetail);
