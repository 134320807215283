// PrivateRoutes are only accessible for the user who is already logged in

import { React, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { CheckLicense } from "../../store/Action/AdminData";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";

function AdminPrivateRoute({ dispatch, res, component: Component, ...rest }) {
  useEffect(() => {
    dispatch(CheckLicense());
  }, [dispatch]);
  if (res.loading) return false;
  const data = res.data;

  return (
    <>
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <Route
          {...rest}
          render={(props) =>
            // if login_status true then user can access all other component else Redirect to login page
            data.status == true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/admin/license/license_setting" />
            )
          }
        />
      )}
    </>
  );
}

// redux connect
const mapStateToProps = (state) => ({
  res: state.CheckLicense,
});

export default connect(mapStateToProps)(AdminPrivateRoute);
