import Header from "../theme/header";
import Footer from "../theme/footer";
import { HomeFatch, DemoUser } from "../../../store/Action/FatchData";
import { useEffect, React, useState } from "react";
import { connect } from "react-redux";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Title from "../../../views/common/Helmet";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Banner from "./banner";
import Carousel from "./carousel";
import Tab from "./tab";
import HowToPlay from "./how_to_play";
import Spinner from "react-bootstrap/Spinner";
import Download from "./download";
import { t } from "i18next";

function Home({ dispatch, res, res_demouser }) {
  // handle states for modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    handleShow();
  }, []);

  useEffect(() => {
    dispatch(HomeFatch());
    dispatch(DemoUser());
  }, [dispatch]);
  const data = res.data;
  const demouser = res_demouser.data.demo_user;

  return (
    <>
      <Title props={t("text_home")} />
      <Header />
      {res.loading || res_demouser.loading ? (
        <div className="loader">
          <Spinner />
        </div>
      ) : (
        <>
          {demouser == 1 && (
            <>
              {/* modal for home page */}
              <Modal show={show}>
                <Modal.Header
                  closeButton
                  onClick={handleClose}
                  style={{ borderBottom: "none" }}
                ></Modal.Header>
                <Modal.Body
                  style={{
                    color: "gray",
                    textAlign: "center",
                    paddingBottom: "4rem",
                  }}
                >
                  If you purchased BattleMania code other than
                  <span
                    className="bm_text_lightpink"
                    onClick={() => window.open("https://codecanyon.net/")}
                  >
                    https://codecanyon.net/
                  </span>
                  website or buy from any third-party or buy nulled code, we are
                  not responsible for any kind of support, setup, and
                  customization.
                </Modal.Body>
                <Modal.Footer>
                  <Button className="btn-lightpink" onClick={handleClose}>
                    ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
          <Banner props={data} />
          <Carousel props={data} />
          <Tab props={data} />
          <HowToPlay props={data} />
          <Download props={data} />
          <Footer />
        </>
      )}
    </>
  );
}

// redux connect
const mapStateToProps = (state) => ({
  res: state.home,
  res_demouser: state.DemoUser,
});

export default connect(mapStateToProps)(Home);
