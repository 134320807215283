import { t } from "i18next";
import { AdminLogin, AdminSendOtp } from "../../store/Action/AdminData";
import { React, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";

const Login = ({ dispatch, res, res_sendOtp }) => {
  const status = sessionStorage.getItem("status");
  const location = useLocation();

  //manage alert box
  const [alertMessage, setAlertMessage] = useState(false);
  const [alertMessage1, setAlertMessage1] = useState(false);
  const [message, setMessage] = useState("");
  const [state, setState] = useState("");

  //handle email address for reset
  const [email, setEmail] = useState({
    email_mobile: "",
  });
  const handleEmail = (e) => {
    setEmail({
      email_mobile: e.target.value,
    });
  };

  //store login data
  const [login, setLogin] = useState({
    name: "admin",
    password: "password",
  });

  //handle modal
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  //handle login data
  const handleInput = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setLogin({
      ...login,
      [name]: value,
    });
  };

  //handle otp
  const handleOtp = (e) => {
    setState(e.target.value);
  };

  //modal
  const handleSendOtp = (e) => {
    e.preventDefault();
    dispatch(AdminSendOtp(email));
  };
  console.log(res_sendOtp.data);

  useEffect(() => {
    if (res_sendOtp.data.status == false) {
      setAlertMessage(false);
      setAlertMessage1(true);
      handleClose();
    } else if (res_sendOtp.data.status == true) {
      handleClose();
      handleShow2();
    }
  }, [res_sendOtp]);

  //submit the data
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(AdminLogin(login));
  };
  const data = res.data;

  useEffect(() => {
    if (location.state) {
      setShow1(true);
    }
  }, []);

  //change the res call
  useEffect(() => {
    if (res.data.status == false) {
      setAlertMessage(true);
    }
  }, [res]);

  //verify otp
  const handleVerify = () => {
    if (state == res_sendOtp.data.otp) {
      window.location.href = "/admin/reset";
    } else {
      setAlertMessage(true);
      handleClose2();
      setMessage("please enter correct otp");
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header>
          <h6 className="text-dark">{t("text_reset_password")}</h6>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <span>
                <label className="text-dark">
                  {t("text_email_address_enter")}
                  <span className="required" aria-required="true">
                    &nbsp;*&nbsp;
                  </span>
                  -
                </label>
                <input
                  name="MobileNo"
                  type="text"
                  className="form-control b-bottom m-0"
                  placeholder="email address"
                  onChange={handleEmail}
                  value={email.email_mobile}
                />
              </span>
            </div>
          </div>
          <div id="recaptcha-container" />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-lightgray" onClick={handleClose}>
            {t("text_btn_close")}
          </Button>
          <Button className="btn-lightpink" onClick={handleSendOtp}>
            {t("text_send_otp")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose} animation={true}>
        <Modal.Header>
          <h6 className="text-dark">{t("text_reset_password")}</h6>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <span>
                <label className="text-dark">
                  {t("translation2:err_otp_req")}
                  <span className="required" aria-required="true">
                    &nbsp;*&nbsp;
                  </span>
                  -
                </label>
                <input
                  name="otp"
                  type="text"
                  className="form-control b-bottom m-0"
                  placeholder="otp"
                  onChange={handleOtp}
                  value={state}
                />
              </span>
            </div>
          </div>
          <div id="recaptcha-container" />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-lightgray" onClick={handleClose2}>
            {t("text_btn_close")}
          </Button>
          <Button className="btn-lightpink" onClick={handleVerify}>
            {t("text_verify")}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="body">
        <form className="form-signin text-center" id="validate-form">
          <img className="mb-4" src={logo} alt="logo" />
          <div
            className={
              (status == 1 ? "alert alert-success " : "alert  alert-danger ") +
              (alertMessage ? "d-block" : "d-none")
            }
          >
            {status == 0 && !message && t("translation1:text_err_login")}
            {message && message}
            <button
              type="button"
              className="close "
              data-dismiss="alert"
              aria-label="Close"
              style={{
                background: "transparent",
                border: "none",
                marginLeft: "36%",
              }}
            >
              <i className="fa fa-close ms-auto"></i>
            </button>
          </div>
          <div
            className={"alert alert-success " + (show1 ? "d-block" : "d-none")}
          >
            {location.state && location.state.state.data}
            <button
              type="button"
              className="close "
              data-dismiss="alert"
              aria-label="Close"
              style={{
                background: "transparent",
                border: "none",
              }}
            >
              <i className="fa fa-close ms-auto"></i>
            </button>
          </div>

          <div
            className={
              (!res_sendOtp.data.status && "alert  alert-danger ") +
              (alertMessage1 ? " d-block" : " d-none")
            }
          >
            {t("translation2:err_email_mobile_not_exist")}
            <button
              type="button"
              className="close "
              data-dismiss="alert"
              aria-label="Close"
              style={{ background: "transparent", border: "none" }}
            >
              <i className="fa fa-close ms-auto"></i>
            </button>
          </div>
          <h1 className="h3 mb-3 font-weight-normal text-white">
            {t("text_please_sign_in")}
          </h1>
          <div className="text-left">
            <input
              type="text"
              id="name"
              className="form-control"
              placeholder="User Name"
              name="name"
              value={login.name}
              onChange={handleInput}
            />
            <input
              type="password"
              id="inputPassword"
              className="form-control mt-3"
              placeholder="Password"
              name="password"
              value={login.password}
              onChange={handleInput}
            />
          </div>
          <div className="form-group text-white mt-2 d-flex justify-content-center">
            {t("text_forgot_password")}&nbsp;
            <span
              id="forgot-modal"
              onClick={handleShow}
              className="text-lightpink text-decoration-none ms-1 pointer"
            >
              {t("text_reset_now")}
            </span>
          </div>
          <button
            className="btn d-block fs-5 btn-lightpink  mt-3"
            name="submit"
            style={{ width: "inherit" }}
            onClick={handleLogin}
          >
            {t("text_sign_in")}
          </button>
          <div className="mt-5 mb-3  text-white">
            <p>{t("text_copyright_text")}</p>
          </div>
        </form>
      </div>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.Admin,
  res_sendOtp: state.SendOtp,
});

export default connect(mapStateToProps)(Login);
