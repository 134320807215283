// Redux-Reducer

export const initialState = {
  data: {},
  loading: true,
};

// demouser
export function DemoUserReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_DEMOUSER":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Home
export function HomeReducer(state = initialState, action) {
  switch (action.type) {
    case "HOME_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// page
export function PageReducer(state = initialState, action) {
  switch (action.type) {
    case "PAGE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Footer
export function FooterReducer(state = initialState, action) {
  switch (action.type) {
    case "FOOTER_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// How to Install
export function HowToInstallReducer(state = initialState, action) {
  switch (action.type) {
    case "HOW_TO_INSTALL":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Language
export function all_language_Reducer(state = initialState, action) {
  switch (action.type) {
    case "All_LANGUAGE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Country
export function GetCountryDataReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_COUNTRY_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Registration
export function RegistrationReducer(state = initialState, action) {
  switch (action.type) {
    case "REGISTRATION_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Login
export function LoginReducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Google Login
export function GoogleLoginReducer(state = initialState, action) {
  switch (action.type) {
    case "GOOGLE_LOGIN_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

/// Dashboard
export function DashboardReducer(state = initialState, action) {
  switch (action.type) {
    case "DASHBOARD_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// My Profile
export function MyProfileDataReducer(state = initialState, action) {
  switch (action.type) {
    case "MY_PROFILE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Update My Profile
export function UpdateMyProfileDataReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_MY_PROFILE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Change Password
export function ChangePasswordReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_PASSWORD_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// My Match
export function GetMyMatchReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_MY_MATCH":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// All Games
export function AllgameReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_GAME_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Ongoing Match
export function AllongoingmatchReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_ONGOING_MATCH_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// All Play Match
export function AllplaymatchReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_PLAY_MATCH_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Result Match
export function ResultMatchReducer(state = initialState, action) {
  switch (action.type) {
    case "RESULT_MATCH_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Game Match
export function GameMatchReducer(state = initialState, action) {
  switch (action.type) {
    case "GAME_MATCH_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// All Games Result
export function AllgameresultReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_GAME_RESULT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Participates
export function ParticipateReducer(state = initialState, action) {
  switch (action.type) {
    case "PARTICIPATE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Earn
export function EarnReducer(state = initialState, action) {
  switch (action.type) {
    case "EARN_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Slider
export function SliderReducer(state = initialState, action) {
  switch (action.type) {
    case "SLIDER_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Lottery
export function LotteryReducer(state = initialState, action) {
  switch (action.type) {
    case "LOTTERY_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
// Lottery
export function LotteryResultReducer(state = initialState, action) {
  switch (action.type) {
    case "LOTTERY_RESULT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
// Product
export function ProductReducer(state = initialState, action) {
  switch (action.type) {
    case "PRODUCT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// My Statistics
export function MyStatisticsReducer(state = initialState, action) {
  switch (action.type) {
    case "MY_STATISTICS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// My Order
export function MyorderReducer(state = initialState, action) {
  switch (action.type) {
    case "MY_ORDER_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// My Referral
export function ReferralReducer(state = initialState, action) {
  switch (action.type) {
    case "REFERRAL_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
// My youtube
export function AppTutorialReducer(state = initialState, action) {
  switch (action.type) {
    case "APP_TUTORIAL":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
// My LeaderBoard
export function MyLeaderBoardReducer(state = initialState, action) {
  switch (action.type) {
    case "MY_LEADER_BOARD_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Top Players
export function TopplayerReducer(state = initialState, action) {
  switch (action.type) {
    case "TOP_PLAYER_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Announcement
export function AnnouncementReducer(state = initialState, action) {
  switch (action.type) {
    case "ANNOUNCEMENT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Terms
export function TermsReducer(state = initialState, action) {
  switch (action.type) {
    case "TERMS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// About Us
export function AboutUsReducer(state = initialState, action) {
  switch (action.type) {
    case "ABOUT_US_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Customer Support
export function CustomerSupportReducer(state = initialState, action) {
  switch (action.type) {
    case "CUSTOMER_SUPPORT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Transaction
export function TransactionReducer(state = initialState, action) {
  switch (action.type) {
    case "TRANSACTION_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Single Match
export function SinglematchReducer(state = initialState, action) {
  switch (action.type) {
    case "SINGLE_MATCH_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Change Player Name
export function ChangeplayernameReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_PLAYER_NAME_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Join Match
export function Join_Match_Reducer(state = initialState, action) {
  switch (action.type) {
    case "JOIN_MATCH":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Product Details
export function Product_buy_data_Reducer(state = initialState, action) {
  switch (action.type) {
    case "PRODUCT_BUY_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Watch_Earn_Reducer(state = initialState, action) {
  switch (action.type) {
    case "WATCH_AND_EARN_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Payment
export function Payment_Reducer(state = initialState, action) {
  switch (action.type) {
    case "PAYMENT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Withdraw Methods
export function Withdraw_method_Reducer(state = initialState, action) {
  switch (action.type) {
    case "WITHDRAW_METHOD_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Withdraw
export function Withdraw_Reducer(state = initialState, action) {
  switch (action.type) {
    case "WITHDRAW_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Add Money
export function Add_money_Reducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_MONEY_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// CheckSum
export function Verify_check_sum_Reducer(state = initialState, action) {
  switch (action.type) {
    case "VERIFY_CHECK_SUM_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Paystack
export function Paystack_Reducer(state = initialState, action) {
  switch (action.type) {
    case "PAYSTACK_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Razorpay
export function Razporpay_Reducer(state = initialState, action) {
  switch (action.type) {
    case "RAZPORPAY_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Paypal
export function Paypal_Reducer(state = initialState, action) {
  switch (action.type) {
    case "PAYPAL_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Instamojo
export function Instamojo_Reducer(state = initialState, action) {
  switch (action.type) {
    case "PAYPAL_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

// Cashfree
export function Cashfree_Reducer(state = initialState, action) {
  switch (action.type) {
    case "CASHFREE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

//Offline-Cash
export function Cash_Reducer(state = initialState, action) {
  switch (action.type) {
    case "CASH_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
