import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { t } from "i18next";

const SlideBar = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  var pageArray = {};
  useEffect(() => {
    Acc.map(async (data) => {
      if (data.subNav) {
        data.subNav.map(async (data) => {
          if (data.superSubNav) {
            data.superSubNav.map(async (data) => {
              var response = await fetch(`/api/check_permission/${data.text}`, {
                headers: { Authorization: user.api_token },
              });
              var res_data = await response.json();
              pageArray[data.text] = res_data.status;
              Object.assign(data, { value: res_data.status });
            });
          } else {
            var response = await fetch(`/api/check_permission/${data.text}`, {
              headers: { Authorization: user.api_token },
            });
            var res_data = await response.json();
            pageArray[data.text] = res_data.status;
            Object.assign(data, { value: res_data.status });
          }
        });
      } else {
        var response = await fetch(`/api/check_permission/${data.text}`, {
          headers: { Authorization: user.api_token },
        });
        var res_data = await response.json();
        pageArray[data.text] = res_data.status;
        Object.assign(data, { value: res_data.status });
      }
    });
  }, []);
  console.log(pageArray);

  // Array for all NavLink in Sidebar
  const Acc = [
    {
      link: "/admin/dashboard",
      icon: "fa fa-home",
      text: t("text_dashboard"),
    },
    {
      id: "users",
      icon: "fa fa-users",
      text: t("text_users"),
      subNav: [
        {
          link: "/admin/all_users",
          icon: "fa fa-user",
          text: t("text_all_user"),
        },
        {
          link: "/admin/register_referral",
          icon: "fa fa-user-plus",
          text: t("text_register_referral"),
        },
        {
          link: "/admin/joined_referral",
          icon: "fa fa-user-plus",
          text: t("text_joined_referral"),
        },
      ],
    },
    {
      id: "games",
      icon: "fa fa-gamepad",
      text: t("text_games"),
      subNav: [
        {
          link: "/admin/all_game",
          icon: "fa fa-gamepad",
          text: t("text_all_game"),
        },
        {
          link: "/admin/matches",
          icon: "fa fa-gamepad",
          text: t("text_matches"),
        },
        {
          link: "/admin/challenges",
          icon: "fa fa-trophy",
          text: t("text_challenges"),
        },
        {
          link: "/admin/images",
          icon: "fa fa-image",
          text: t("text_images"),
        },
      ],
    },
    {
      id: "shop",
      icon: "fa fa-hospital-o",
      text: t("text_shop"),
      subNav: [
        {
          link: "/admin/product",
          icon: "fa fa-product-hunt",
          text: t("text_product"),
        },
        {
          link: "/admin/order",
          icon: "fa fa-first-order",
          text: t("text_order"),
        },
        {
          link: "/admin/courier",
          icon: "fa fa-truck",
          text: t("text_courier"),
        },
      ],
    },
    {
      link: "/admin/country",
      icon: "fa fa-gamepad",
      text: t("text_country"),
    },
    {
      link: "/admin/money_order",
      icon: "fa fa-dropbox",
      text: t("text_money_orders"),
    },
    {
      link: "/admin/withdraw_request",
      icon: "fa fa-money",
      text: t("text_withdraw_requests"),
    },
    {
      id: "reports",
      icon: "fa fa-file-text-o",
      text: t("text_reports"),
      subNav: [
        {
          link: "/admin/top_players",
          icon: "fa fa-trophy",
          text: t("text_top_players"),
        },
        {
          link: "/admin/leader_board",
          icon: "fa fa-mortar-board",
          text: t("text_leaderboard"),
        },
      ],
    },
    {
      link: "/admin/notification",
      icon: "fa fa fa-bell-o",
      text: t("text_one_signal_notification"),
    },

    {
      link: "/admin/announcement",
      icon: "fa fa-bullhorn",
      text: t("text_announcement"),
    },
    {
      link: "/admin/lottery",
      icon: "fa fa-thumbs-o-up",
      text: t("text_lottery"),
    },
    {
      id: "website",
      icon: "fa fa-cogs",
      text: t("text_website_setting"),
      subNav: [
        {
          id: "pages",
          link: "/admin/pages",
          icon: "fa fa-file-text",
          text: t("text_pages"),
        },
        {
          id: "subwebsite",
          icon: "fa fa-home",
          text: t("text_home_page_setting"),
          superSubNav: [
            {
              id: "mainBanner",
              link: "/admin/main_banner",
              icon: "fa fa-header",
              text: t("text_main_banner"),
            },
            {
              link: "/admin/screenshots",
              icon: "fa fa-camera-retro",
              text: t("text_app_screenshots"),
            },
            {
              link: "/admin/features_tab",
              icon: "fa fa-th",
              text: t("text_features_tab"),
            },
            {
              link: "/admin/features",
              icon: "fa fa-th-list",
              text: t("text_features"),
            },
            {
              link: "/admin/how_to_play",
              icon: "fa fa-play-circle",
              text: t("text_howtoplay"),
            },
          ],
        },
        {
          link: "/admin/how_to_install",
          icon: "fa fa-download",
          text: t("text_howtoinstall"),
        },
      ],
    },
    {
      id: "app",
      icon: "fa fa-cogs",
      text: t("text_appsetting"),
      subNav: [
        {
          link: "/admin/app_setting",
          icon: "fa fa-android",
          text: t("text_appsetting"),
        },
        {
          link: "/admin/currency",
          icon: "fa fa-dollar",
          text: t("text_currency_settings"),
        },
        {
          link: "/admin/withdraw_method",
          icon: "fa fa-money",
          text: t("text_withdraw_method"),
        },
        {
          link: "/admin/payment_method",
          icon: "fa fa-credit-card",
          text: t("text_payment_method"),
        },
        {
          link: "/admin/app_tutorial",
          icon: "fa fa-youtube",
          text: t("text_app_tutorial"),
        },
        {
          link: "/admin/slider",
          icon: "fa fa-sliders",
          text: t("text_slider"),
        },
        {
          link: "/admin/banner",
          icon: "fa fa-flag",
          text: t("text_banner"),
        },
      ],
    },
    {
      link: "/admin/admin",
      icon: "fa fa-users",
      text: t("text_admin"),
    },
    {
      link: "/admin/license",
      icon: "fa fa fa-bug",
      text: t("text_license"),
    },
  ];
  console.log("Accc", Acc);

  return (
    <>
      <div id="sidebar-wrapper" className="border-right ">
        {/* List of Sidebar */}
        <ul className="nav flex-column list-group list-group-flush text-dark">
          {Acc.map((data, index) => {
            return data.link ? (
              <li className="nav-item" key={index}>
                {/* <p>{data.value}</p> */}
                {/* <p>{data.value === true ? "yes" : "no"}</p> */}
                <NavLink className="navlink" to={data.link}>
                  <i className={data.icon} style={{ fontSize: "18px" }}></i>
                  {data.text}
                </NavLink>
              </li>
            ) : (
              <li className="active nav-index" key={index}>
                <a
                  href={`#${data.id}`}
                  data-toggle="collapse"
                  aria-expanded="true"
                  className="dropdown-toggle1 navlink text-dark"
                >
                  <i className={`${data.icon} navicon`}></i>
                  {data.text}
                  <i
                    className="fa fa-angle-down float-end"
                    style={{ fontSize: "18px" }}
                  ></i>
                </a>
                {/* subNav */}
                {data.subNav ? (
                  <ul className="collapse list-unstyled ms-3" id={data.id}>
                    {data.subNav.map((data, index) => {
                      return (
                        <li className="nav-item" key={index}>
                          {data.link ? (
                            <NavLink className="navlink" to={data.link}>
                              <i className={`${data.icon} navicon`}></i>
                              {data.text}
                            </NavLink>
                          ) : (
                            <>
                              <a
                                href={`#${data.id}`}
                                data-toggle="collapse"
                                aria-expanded="true"
                                className="dropdown-toggle1 navlink text-dark"
                              >
                                <i className={`${data.icon} navicon`}></i>
                                {data.text}
                                <i
                                  className="fa fa-angle-down float-end"
                                  style={{ fontSize: "18px" }}
                                ></i>
                              </a>
                              {/* superSubNav */}
                              {data.superSubNav ? (
                                <ul
                                  className="collapse list-unstyled text-dark ms-4"
                                  id={data.id}
                                >
                                  {data.superSubNav.map((data, index) => {
                                    return (
                                      <li className="nav-item" key={index}>
                                        <NavLink
                                          className="navlink"
                                          to={data.link}
                                        >
                                          <i
                                            className={`${data.icon} navicon`}
                                          ></i>
                                          {data.text}
                                        </NavLink>
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : (
                                false
                              )}
                            </>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  false
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default SlideBar;
