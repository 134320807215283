import { React, useState } from "react";
import Ongoing from "./luckydrawTab/ongoing";
import MainTheme from "../theme/MainTheme";
import Result from "./luckydrawTab/result";
import Title from "../../common/Helmet";
import { t } from "i18next";

const Luckydraw = () => {
  const [toggle, setToggle] = useState(true);

  return (
    <>
      <Title props={t("text_luckey_draw")} />
      <MainTheme title={t("text_luckey_draw")} backPath headCoin>
        <div className="tab-section single-game">
          <ul className="nav nav-tabs">
            {/* ongoing tab  */}
            <li className="nav-item" style={{ width: "50%" }}>
              <a
                className="nav-link text-uppercase active"
                data-toggle="tab"
                href="#ongoing"
                onClick={() => setToggle(true)}
              >
                {t("text_ongoing")}
              </a>
            </li>
            {/* result  */}
            <li className="nav-item" style={{ width: "50%" }}>
              <a
                className="nav-link text-uppercase "
                data-toggle="tab"
                href="#result"
                onClick={() => setToggle(false)}
              >
                {t("text_results")}
              </a>
            </li>
          </ul>
          {/* ongoing  */}
          <div className="tab-content">
            <div
              id="ongoing"
              className={"container tab-pane " + (toggle ? "active" : "hide")}
            >
              <Ongoing />
            </div>
            {/* result */}
            <div
              id="result"
              className={"container tab-pane" + (toggle ? "hide" : "active")}
            >
              <Result />
            </div>
          </div>
        </div>
      </MainTheme>
    </>
  );
};

export default Luckydraw;
