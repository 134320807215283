import React, { useState } from "react";
import UserHeader from "../../theme/userHeader";
import { t } from "i18next";
import { NavLink, useHistory } from "react-router-dom";
import Title from "../../../../views/common/Helmet";

const AddImage = () => {
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  //handle image data
  const [image, setImage] = useState({
    image_title: "",
    image_name: "",
  });
  const [state, setState] = useState({
    formErrors: {},
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setImage({
      ...image,
      [name]: value,
    });
  };

  // handle image file
  const handleImage = (e) => {
    const value = e.target.files[0];
    setImage({
      ...image,
      image_name: value,
    });
  };

  //handle the validation
  function handleFormValidation() {
    const { image_title, image_name } = image;
    let formErrors = {};
    let formIsValid = true;
    if (!image_title) {
      formIsValid = true;
      formErrors["imageTitleErr"] = t("translation2:err_image_title_req");
    }
    if (!image_name) {
      formIsValid = true;
      formErrors["imageImageErr"] = t("translation2:err_game_image_req");
    }
    setState({ formErrors: formErrors });
    return formIsValid;
  }

  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image_title", image.image_title);
    formData.append("image_name", image.image_name);
    handleFormValidation();
    // insert image
    var response = await fetch(`/api/insert_image`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      history.push("/admin/images", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_image")} />
      {/* Header */}
      <UserHeader title={t("text_image")} />
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            {/* card-header */}
            <div className="card-header">
              <strong>{t("text_image")}</strong>
            </div>
            <div className="card-body">
              <div className="col-md-12">
                <form className="needs-validation" id="validate" method="POST">
                  <div className="row">
                    {/* image title */}
                    <div className="form-group col-md-6">
                      <label htmlFor="image_title">
                        {t("text_image_title")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        name="image_title"
                        value={image.image_title}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="image_title"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.imageTitleErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state.formErrors.imageTitleErr}
                      </label>
                    </div>
                    {/* image name */}
                    <div className="form-group col-md-6">
                      <label htmlFor="image_name">
                        {t("text_image")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      <input
                        id="image_name"
                        type="file"
                        className="file-input d-block mt-2"
                        name="image_name"
                        onChange={handleImage}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.imageImageErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state.formErrors.imageImageErr}
                      </label>
                      <p>
                        <b>{t("text_note")} : </b>
                        {t("text_image_note_1000x500")}
                      </p>
                      <br />
                    </div>
                  </div>
                  {/* submit */}
                  <div className="form-group text-center mt-3">
                    <input
                      type="submit"
                      value="Submit"
                      name="submit"
                      className={`btn btn-lightpink ${
                        demouser === "1" && "disabled"
                      }`}
                      onClick={demouser === "0" && handleSubmit}
                    />
                    {/* cancel */}
                    <NavLink
                      className="btn btn-secondary ms-1"
                      to="/admin/images"
                      name="cancel"
                    >
                      {t("text_btn_cancel")}
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddImage;
