// leader Board page

import React from "react";
import { useEffect } from "react";
import Title from "../../common/Helmet";
import UserHeader from "../theme/UserHeader";
import MainTheme from "../theme/MainTheme";
import { connect } from "react-redux";
import { MyLeaderBoardData } from "../../../store/Action/UserFatchData";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const LeaderBoard = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(MyLeaderBoardData());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_leaderboard")} />
      <MainTheme title={t("text_leaderboard")}>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : data.leader_board.length ? (
          <table className="table tr-bordered bg-white box-shadow">
            <thead>
              <tr className="bg-lightgreen text-white border-0">
                <th className="border-0">{t("text_user_name")}</th>
                <th className="border-0">{t("text_total_referral")}</th>
              </tr>
            </thead>
            <tbody>
              {data.leader_board.map((data, index) => (
                <tr key={index}>
                  <td>{data.user_name}</td>
                  <td>{data.tot_referral}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center">
            <strong className="text-dark">Leader Board is Empty</strong>
          </div>
        )}
      </MainTheme>
    </>
  );
};

//redux connect
const mapStateToProps = (state) => ({
  res: state.my_leaderboard,
});

export default connect(mapStateToProps)(LeaderBoard);
