import { React, useState, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import { connect } from "react-redux";
import { GetCountry } from "../../../store/Action/AdminData";
import { t } from "i18next";

const CompanySetting = ({ props, dispatch, res }) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [state1, setState1] = useState({
    formErrors: {},
  });
  // manage state
  const [show, setShow] = useState(false);
  //handle alert message
  const [message, setMessage] = useState({
    status: false,
    msg: "",
  });

  const [state, setState] = useState({
    company_logo: "",
    company_favicon: "",
    company_name: props.company_name,
    company_email: props.company_email,
    comapny_country_code: props.comapny_country_code,
    comapny_phone: props.comapny_phone,
    company_street: props.company_street,
    company_address: props.company_address,
    company_time: props.company_time,
  });
  const [copy, setCopy] = useState({
    copyright_text: props.copyright_text,
  });
  const [about, setAbout] = useState({
    company_about: props.company_about,
  });
  useEffect(() => {
    dispatch(GetCountry());
  }, [dispatch]);
  if (res.loading) return false;
  const data = res.data.all_country;
  const handleChange = (e) => {
    const { name } = e.target;
    if (name == "company_logo") var value = e.target.files[0];
    else if (name == "company_favicon") var value = e.target.files[0];
    else value = e.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };
  function handleFormValidation() {
    const {
      company_logo,
      company_favicon,
      company_name,
      company_email,
      comapny_country_code,
      comapny_phone,
      company_street,
      company_address,
      company_time,
    } = state;
    const { copyright_text } = copy;
    const { company_about } = about;
    let formErrors = {};
    let formIsValid = true;

    if (!company_logo) {
      formIsValid = true;
      formErrors["compnyLogoErr"] = t("translation2:err_image_req");
    }

    if (!company_favicon) {
      formIsValid = true;
      formErrors["companyErr"] = t("translation2:err_image_req");
    }
    if (!company_name) {
      formIsValid = true;
      formErrors["companyNameErr"] = t("translation2:err_company_name_valid");
    }
    if (!company_email) {
      formIsValid = true;
      formErrors["companyEmailErr"] = t("translation2:err_email_req");
    }
    if (!comapny_country_code) {
      formIsValid = true;
      formErrors["companyCodeErr"] = t("translation2:err_country_code_req");
    }
    if (!comapny_phone) {
      formIsValid = true;
      formErrors["companyPhoneErr"] = t("translation2:err_mobile_no_req");
    }
    if (!company_street) {
      formIsValid = true;
      formErrors["companystreetErr"] = t(
        "translation2:err_company_street_valid"
      );
    }
    if (!company_address) {
      formIsValid = true;
      formErrors["companyAddErr"] = t("translation2:err_company_address_valid");
    }
    if (!company_time) {
      formIsValid = true;
      formErrors["companyTimeErr"] = t("translation2:err_company_time_valid");
    }
    if (!copyright_text) {
      formIsValid = true;
      formErrors["companyTextErr"] = t("translation2:err_copyright_text_valid");
    }
    if (!company_about) {
      formIsValid = true;
      formErrors["companyLabelErr"] = t(
        "translation2:err_copyright_text_valid"
      );
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  // submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    var formData = new FormData();

    formData.append("company_logo", state.company_logo);
    formData.append("company_favicon", state.company_favicon);
    formData.append("company_name", state.company_name);
    formData.append("company_email", state.company_email);
    formData.append("comapny_country_code", state.comapny_country_code);
    formData.append("comapny_phone", state.comapny_phone);
    formData.append("company_street", state.company_street);
    formData.append("company_address", state.company_address);
    formData.append("company_time", state.company_time);
    formData.append("copyright_text", copy.copyright_text);
    formData.append("company_about", about.company_about);
    formData.append("contact_submit", "Submit");
    console.log(state);
    var response = await fetch(`/api/update_profile_data`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    console.log(res_data);
    setShow(true);
    setMessage({ status: res_data.status, msg: res_data.message });
  };
  return (
    <>
      <div className="row mb-4" id="companysetting">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            <div
              className={
                (!message.status
                  ? "alert alert-danger "
                  : "alert alert-success ") +
                "alert-dismissible fade show " +
                (show ? "d-block" : "d-none")
              }
              role="alert"
            >
              {message.msg}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
            <div className="card-header">
              <strong>{t("text_company_setting")}</strong>
            </div>
            <div className="card-body">
              <div className="col-md-12">
                <form method="POST" id="contactus-form">
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="company_logo">
                        {t("text_logo")}
                      </label>
                      <br />
                      <input
                        type="file"
                        className=""
                        name="company_logo"
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.compnyLogoErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.compnyLogoErr}
                      </label>
                      <br />

                      <p>
                        <b>{t("text_note")} : </b>
                        {t("text_image_note_189x40")}
                      </p>
                      <br />
                      <img src={props.company_logo} />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="company_favicon">
                        {t("text_favicon")}
                      </label>
                      <br />
                      <input
                        type="file"
                        className=""
                        name="company_favicon"
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.companyErr ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.companyErr}
                      </label>
                      <br />

                      <p>
                        <b>{t("text_note")} : </b> {t("text_image_note_40x40")}
                      </p>
                      <br />
                      <img src={props.company_favicon} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="company_name">
                        {t("text_company_name")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="company_name"
                        value={state.company_name}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.companyNameErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.companyNameErr}
                      </label>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="company_email">
                        {t("text_email")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="last_name"
                        type="text"
                        className="form-control"
                        name="company_email"
                        value={state.company_email}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.companyEmailErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.companyEmailErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="comapny_country_code">
                        {t("text_country_code")}
                      </label>
                      <select
                        className="form-control"
                        name="comapny_country_code"
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {data.map((country, index) => {
                          return (
                            <option key={index} value={country.p_code}>
                              {country.p_code || ""}
                            </option>
                          );
                        })}
                      </select>
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.companyCodeErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.companyCodeErr}
                      </label>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="comapny_phone">
                        {t("text_phone")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="comapny_phone"
                        value={state.comapny_phone}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.companyPhoneErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.companyPhoneErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="company_street">
                        {t("text_street")}
                      </label>
                      <textarea
                        id="company_street"
                        rows="3"
                        className="form-control "
                        name="company_street"
                        value={state.company_street}
                        onChange={handleChange}
                      ></textarea>
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.companystreetErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.companystreetErr}
                      </label>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="company_address">
                        {t("text_address")}
                      </label>
                      <textarea
                        id="company_address"
                        rows="3"
                        className="form-control "
                        name="company_address"
                        value={state.company_address}
                        onChange={handleChange}
                      ></textarea>
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.companyAddErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.companyAddErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="company_time">
                        {t("text_support_time")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="company_time"
                        value={state.company_time}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.companyTimeErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.companyTimeErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-md-12">
                      <label className="mb-3" htmlFor="copyright_text">
                        {t("text_copyright")}
                      </label>
                      <CKEditor
                        initData={<p>{props.copyright_text}</p>}
                        onChange={(event, editor) => {
                          const data = event.editor.getData();
                          setCopy({
                            copy_right_text: data,
                          });
                        }}
                      />
                      <label
                        htmlFor="withdraw_method_field"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.companyTextErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.companyTextErr}
                      </label>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <label className="mb-3" htmlFor="company_about">
                          {t("text_about")}
                        </label>
                        <CKEditor
                          initData={<p>{props.company_about}</p>}
                          onChange={(event, editor) => {
                            const data = event.editor.getData();
                            setAbout({
                              company_about: data,
                            });
                          }}
                        />
                        <label
                          htmlFor="withdraw_method_field"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.companyLabelErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.companyLabelErr}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3 text-center">
                    <input
                      type="submit"
                      value="Submit"
                      name="contact_submit"
                      className="btn btn-lightpink"
                      onClick={handleSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.GetCountry,
});

export default connect(mapStateToProps)(CompanySetting);
