// HomeRoutes shows to all users

import Home from "../views/Main/home/home";
import How_to_install from "../views/Main/how_to_install/how_to_install";
import Aboutus from "../views/Main/others/aboutus";
import Contact from "../views/Main/others/contact";
import Terms from "../views/Main/others/Terms";
import Privacy from "../views/Main/others/Privacy";
import Registration from "../views/Main/user/registration";
import Login from "../views/Main/user/login";
import ForgotPassword from "../views/Main/user/forgotPassword";
import Sendotp from "../views/Main/user/sendotp";
import LoginAdmin from "../admin-panel/login/Login";
import ResetPsd from "../admin-panel/login/ResetPsd";

const HomeRoutes = [
  { path: "/", component: Home },
  { path: "/how to install", component: How_to_install },
  { path: "/contact", component: Contact },
  { path: "/about-us", component: Aboutus },
  { path: "/terms & conditions", component: Terms },
  { path: "/privacy policy", component: Privacy },
  { path: "/sendotp", component: Sendotp },
  { path: "/login", component: Login },
  { path: "/registration", component: Registration },
  { path: "/reset", component: ForgotPassword },
  { path: "/admin", component: LoginAdmin },
  { path: "/admin/reset", component: ResetPsd },
  // { path: "*", component: Login },
];

export default HomeRoutes;
