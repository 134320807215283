import { useEffect, React } from "react";
import { FooterData } from "../../../store/Action/FatchData";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Spinner from "react-bootstrap/Spinner";

function Footer({ dispatch, res }) {
  useEffect(() => {
    dispatch(FooterData());
  }, [dispatch]);
  const data = res.data;
  const page_data = res.data.page_data;

  return (
    <>
      {res.loading ? (
        <div className="loader">
          <Spinner />
        </div>
      ) : (
        <footer className="bm-section-padding bm-dark-bg" id="footer">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xs-12 col-sm-12 col-md-12 mt-2">
                <ul className="list-unstyled list-inline social text-center justify-content-around">
                  {page_data &&
                    page_data.map((data, index) => {
                      return (
                        data.page_order != 0 && (
                          <li className="list-inline-item">
                            <NavLink
                              className="nav-link"
                              to={
                                data.page_slug == "home"
                                  ? "/"
                                  : `/${data.page_slug}`
                              }
                            >
                              {data.page_menutitle}
                            </NavLink>
                          </li>
                        )
                      );
                    })}
                  {page_data &&
                    page_data.map((data, index) => {
                      return (
                        data.page_order == 0 && (
                          <li className="list-inline-item" key={index}>
                            <NavLink
                              className="nav-link"
                              to={
                                data.page_slug == "home"
                                  ? "/"
                                  : `/${data.page_slug}`
                              }
                            >
                              {data.page_menutitle}
                            </NavLink>
                          </li>
                        )
                      );
                    })}
                  <div className="row justify-content-center">
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-2">
                      <ul className="list-unstyled list-inline social text-center justify-content-around">
                        {data.fb_link !== "" && (
                          <li className="list-inline-item">
                            <a href={data.fb_link}>
                              <i className="fa fa-facebook"></i>
                            </a>
                          </li>
                        )}
                        {data.twitter_link !== "" && (
                          <li className="list-inline-item">
                            <a href={data.twitter_link}>
                              <i className="fa fa-twitter"></i>
                            </a>
                          </li>
                        )}
                        {data.insta_link !== "" && (
                          <li className="list-inline-item">
                            <a href={data.insta_link}>
                              <i className="fa fa-instagram"></i>
                            </a>
                          </li>
                        )}
                        {data.google_link !== "" && (
                          <li className="list-inline-item">
                            <a href={data.google_link}>
                              <i className="fa fa-google-plus"></i>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            {/* copyright text */}
            <div className="row copyright">
              <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
                {/* ReactHtmlParser parse JSX to HTML */}
                <div>{ReactHtmlParser(data.company_about)}</div>
                <div className="h6">{ReactHtmlParser(data.copyright_text)}</div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
}

// redux connect
const mapStateToProps = (state) => ({
  res: state.footer,
});

export default connect(mapStateToProps)(Footer);
