import React from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";

const PopupMenu = () => {
  // Array of Dropdown menu of Header
  const menu = [
    { link: "/account/my_referral", text: t("text_my_referrals") },
    { link: "/account/leader_board", text: t("text_leaderboard") },
    { link: "/account/terms & conditions", text: t("text_terms_conditions") },
  ];

  return (
    <>
      <span
        className="badge pointer pull-right f-30 text-white d-inline"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        id="dropdownMenuButton1"
      >
        <i className="fa fa-ellipsis-v"></i>
      </span>
      {/* Dropdown Menu */}
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        {menu.map((data, index) => (
          <li key={index}>
            <Link className="dropdown-item" to={data.link}>
              {data.text}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default PopupMenu;
