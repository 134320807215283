import React from "react";
import coin from "../../../../views/assets/images/coin.png";
import { Scrollbars } from "react-custom-scrollbars";
import { NavLink } from "react-router-dom";
import { t } from "i18next";

const OngoingTab = ({ props }) => {
  return (
    <>
      <Scrollbars>
        <div className="content-section">
          <div className="bm-content-listing tournaments">
            {props.my_match.length ? (
              props.my_match
                .filter((item) => item.match_status === "3")
                .map((data, index) => {
                  return (
                    <div className="card br-5 hide-over mb-3" key={index}>
                      <NavLink to={`/play/ongoing_match/${data.m_id}`}>
                        <img
                          src={data.match_banner}
                          className="img-fluid card-img-top"
                          alt="img"
                        />
                      </NavLink>
                      <div className="card-body">
                        {/* type */}
                        <span className="btn btn-sm btn-lightpink">
                          {data.type}
                        </span>
                        {/* map */}
                        <span className="btn btn-sm btn-primary ms-1">
                          {data.MAP}
                        </span>
                        {/* match name and match id */}
                        <h6 className="card-title mt-2">
                          <i className="fa fa-bomb"></i>&nbsp;
                          <span className="text-dark">
                            {data.match_name +
                              t("text_for_macth_id") +
                              data.m_id}
                          </span>
                        </h6>
                        {/* table */}
                        <table className="card-table table text-center mt-3">
                          <tbody>
                            <tr>
                              {/* match time */}
                              <td>
                                <span className="bm_text_lightpink ">
                                  {data.match_time}
                                </span>
                              </td>
                              {/* prize pole */}
                              <td>
                                <span className="bm_text_lightgreen text-uppercase">
                                  {t("text_prize_pool")} <br />
                                  <img
                                    src={coin}
                                    className="coin"
                                    alt="img-coin"
                                  />
                                  &nbsp;
                                  {data.win_prize}
                                </span>
                              </td>
                              {/* per kill */}
                              <td>
                                <span className="text-primary text-uppercase">
                                  {t("text_per_kill")} <br />
                                  <img
                                    src={coin}
                                    className="coin"
                                    alt="img-coin"
                                  />
                                  &nbsp;
                                  {data.per_kill}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {/* entry fees */}
                        <a
                          href={data.match_url}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-sm btn-block btn-lightpink"
                          alt="img"
                        >
                          <img src={coin} className="coin" alt="img-coin" />
                          {data.entry_fee + t("text_Spactate")}
                        </a>
                      </div>
                    </div>
                  );
                })
            ) : (
              // if there is no any live match
              <div className="bm-content-listing tournaments">
                <div className="col-md-12 text-center text-black">
                  <strong>{t("text_no_live_macth")}</strong>
                </div>
              </div>
            )}
          </div>
        </div>
      </Scrollbars>
    </>
  );
};

export default OngoingTab;
