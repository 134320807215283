import { React, useState, useEffect } from "react";
import UserHeader from "../../theme/userHeader";
import { NavLink } from "react-router-dom";
import Title from "../../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const MainBanner = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  const [loading, setLoading] = useState(true);
  //handle alert message
  const [message, setMessage] = useState({
    status: false,
    msg: "",
  });
  const [show, setShow] = useState(false);
  //store the data of api
  const [insertData, SetInsertData] = useState({});
  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [state, setState] = useState({
    home_sec_title: "",
    home_sec_text: "",
    home_sec_btn: "",
    home_sec_side_image: "",
    home_sec_bnr_image: "",
  });

  // get data
  const page = async () => {
    var response = await fetch(`/api/get_home_page`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    if (res_data) {
      setLoading(false);
    }
    SetInsertData(res_data.get_home_header);
    setState({
      home_sec_title: res_data.get_home_header.home_sec_title,
      home_sec_text: res_data.get_home_header.home_sec_text,
      home_sec_btn: res_data.get_home_header.home_sec_btn,
      home_sec_side_image: "",
      home_sec_bnr_image: "",
    });
  };

  //on page refresh api is call
  useEffect(() => {
    page();
  }, []);
  const handleChange = (e) => {
    const { name } = e.target;
    if (name == "home_sec_side_image" || name == "home_sec_bnr_image")
      var value = e.target.files[0];
    else value = e.target.value;

    setState({
      ...state,
      [name]: value,
    });
  };

  // form validation
  function handleFormValidation() {
    const {
      home_sec_title,
      home_sec_text,
      home_sec_btn,
      home_sec_side_image,
      home_sec_bnr_image,
    } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!home_sec_title) {
      formIsValid = true;
      formErrors["homeSecTitleErr"] = t("translation2:err_home_sec_title_req");
    }
    if (!home_sec_text) {
      formIsValid = true;
      formErrors["homeSecTextErr"] = t("translation2:err_home_sec_text_req");
    }
    if (!home_sec_btn) {
      formIsValid = true;
      formErrors["homeSecBtnErr"] = t("translation2:err_home_sec_btn_req");
    }
    if (!home_sec_side_image) {
      formIsValid = true;
      formErrors["homeSecSideImageErr"] = t("translation2:err_image_accept");
    }
    if (!home_sec_bnr_image) {
      formIsValid = true;
      formErrors["homeSecBnrImageErr"] = t("translation2:err_image_accept");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("home_sec_title", state.home_sec_title);
    formData.append("home_sec_text", state.home_sec_text);
    formData.append("home_sec_btn", state.home_sec_btn);
    formData.append("home_sec_side_image", state.home_sec_side_image);
    formData.append("home_sec_bnr_image", state.home_sec_bnr_image);
    handleFormValidation();
    // update banner
    var response = await fetch(`/api/update_home_page`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      window.scrollTo(0, 0);
      setShow(true);
      setMessage({ status: res_data.status, msg: res_data.message });
    }
  };

  return (
    <>
      <Title props={t("text_main_banner")} />
      {/* Header */}
      <UserHeader title={t("text_main_banner")} />
      {loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card bg-light text-dark">
              {/* message */}
              <div
                className={
                  (!message.status
                    ? "alert alert-danger "
                    : "alert alert-success ") +
                  "alert-dismissible fade show " +
                  (show ? "d-block" : "d-none")
                }
                role="alert"
              >
                {message.msg}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
              {/* card-header */}
              <div className="card-header">
                <strong>{t("text_main_banner_setting")}</strong>
              </div>
              <div className="card-body">
                <div className="col-md-12">
                  <form method="POST" id="validate">
                    {/* title */}
                    <div className="form-group mb-3">
                      <label className="mb-3" htmlFor="home_sec_title">
                        {t("text_title")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        id="home_sec_title"
                        name="home_sec_title"
                        value={state.home_sec_title}
                        onChange={handleChange}
                        className="form-control"
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.homeSecTitleErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.homeSecTitleErr}
                      </label>
                    </div>
                    {/* sub title */}
                    <div className="form-group mb-3">
                      <label className="mb-3" htmlFor="home_sec_text">
                        {t("text_sub_title")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <textarea
                        id="home_sec_text"
                        name="home_sec_text"
                        rows="5"
                        value={state.home_sec_text}
                        onChange={handleChange}
                        className="form-control"
                      ></textarea>
                      <label
                        htmlFor="home_sec_text"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.homeSecTextErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.homeSecTextErr}
                      </label>
                    </div>
                    {/* button text */}
                    <div className="form-group mb-3">
                      <label className="mb-3" htmlFor="home_sec_btn">
                        {t("text_button_text")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        id="home_sec_btn"
                        name="home_sec_btn"
                        value={state.home_sec_btn}
                        onChange={handleChange}
                        className="form-control"
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.homeSecBtnErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.homeSecBtnErr}
                      </label>
                    </div>
                    {/* main banner image */}
                    <div className="form-group mb-3">
                      <label className="mb-3" htmlFor="home_sec_bnr_image">
                        {t("text_main_banner_image")}
                      </label>
                      <br />
                      <input
                        id="home_sec_bnr_image"
                        type="file"
                        className="file-input d-block"
                        name="home_sec_bnr_image"
                        onChange={handleChange}
                      />

                      <p>
                        <b>{t("text_note")} : </b>
                        {t("text_image_note_1920x500")}
                      </p>
                      <img src={insertData.home_sec_bnr_image} />
                    </div>
                    {/* image */}
                    <div className="form-group mb-3">
                      <label className="mb-3" htmlFor="home_sec_side_image">
                        {t("text_image")}
                      </label>
                      <br />
                      <input
                        id="home_sec_side_image"
                        type="file"
                        className="file-input d-block"
                        name="home_sec_side_image"
                        onChange={handleChange}
                      />
                      <img src={insertData.home_sec_side_image} />
                    </div>
                    {/* submit */}
                    <div className="form-group d-flex justify-content-center mb-3 ">
                      <input
                        type="submit"
                        value="Submit"
                        name="submit"
                        className={`btn btn-lightpink ${
                          demouser === "1" && "disabled"
                        }`}
                        onClick={demouser === "0" && handleSubmit}
                      />
                      {/* cancel */}
                      <NavLink
                        className="btn btn-secondary ms-1 d-block"
                        to="/admin/main_banner"
                        name="cancel"
                      >
                        {t("text_btn_cancel")}
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MainBanner;
