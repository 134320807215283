import { React, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { instamojoApi } from "../../../../store/Action/FatchData";

const Instamojo = ({ dispatch, res }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  let location = useLocation();
  const amount = location.state.TXN_AMOUNT;
  useEffect(() => {
    dispatch(instamojoApi(amount));
  }, [dispatch, amount]);

  //Configuration
  Instamojo.configure({
    handlers: {
      onOpen: onOpenHandler,
      onClose: onCloseHandler,
      onSuccess: onPaymentSuccessHandler,
      onFailure: onPaymentFailureHandler,
    },
  });

  function onOpenHandler() {
    alert("Payments Modal is Opened");
  }

  function onCloseHandler() {
    alert("Payments Modal is Closed");
  }

  function onPaymentSuccessHandler(response) {
    alert("Payment Success");
    console.log("Payment Success Response", response);
  }

  function onPaymentFailureHandler(response) {
    alert("Payment Failure");
    console.log("Payment Failure Response", response);
  }
  /* End client-defined Callback Handler Functions */

  /* Configuring Handlers */
  function onButtonClick() {
    Instamojo.open(`http://test.instamojo.com/${user.username}`);
  }
  return (
    <>
      <button onClick={onButtonClick}>Open Instapay</button>
    </>
  );
};
const mapStateToProps = (state) => ({
  res: state.addmoney,
});

export default connect(mapStateToProps)(Instamojo);
