import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD8cObRp0JsqbLB5CgMae7GjNbV7XoknS0",
  authDomain: "number-signin-f7e04.firebaseapp.com",
  projectId: "number-signin-f7e04",
  storageBucket: "number-signin-f7e04.appspot.com",
  messagingSenderId: "587959740397",
  appId: "1:587959740397:web:1c22ee65d7ea3562e7a9e4",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export { firebase };
