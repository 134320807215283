import { React, useState, useEffect } from "react";
import Title from "../../../common/Helmet";
import MainTheme from "../../theme/MainTheme";
import { connect } from "react-redux";
import { withdraw_method_data } from "../../../../store/Action/UserFatchData";
import coin from "../../assets/images/coin.png";
import ReactHtmlParser from "react-html-parser";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";

const Withdraw = ({ dispatch, res_withdrawmethod }) => {
  var user1 = JSON.parse(localStorage.getItem("user"));
  var user = user1.member_id;

  // manage state
  const [display, setDisplay] = useState(false);
  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(true);
  const [Payment, setPayment] = useState("PayTm");
  const [message, setMessage] = useState(false);
  const [data1, getData1] = useState({});
  const [withdrawResponse, setWithdrawResponse] = useState({});
  //withdrawmethod
  const [data, getData] = useState({});
  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });

  useEffect(() => {
    dispatch(withdraw_method_data());
  }, [dispatch]);
  // success withdraw notify
  const notifyS = () => toast.success(withdrawResponse.message);
  //Error withdraw notify
  const notifyE = () => toast.error(withdrawResponse.message);

  const change_withdraw = (e) => {
    const { name, value } = e.target;
    getData1({
      ...data1,
      member_id: user,
      submit: "withdraw",
      withdraw_method: Payment,
      [name]: value,
    });
  };
  // withdraw change
  const change_withdraw1 = (e) => {
    const { name, value } = e.target;
    console.log(value);
    getData({
      ...data,
      [name]: value,
    });
    setPayment(value);

    if (value === "PayPal") {
      setDisplay(true);
      setDisplay2(false);
    } else {
      setDisplay(false);
      setDisplay2(true);
    }
    if (value === "In Account") {
      setDisplay1(true);
      setDisplay2(false);
    } else {
      setDisplay1(false);
    }
    setMessage(true);
  };

  // form validation
  function handleFormValidation() {
    const { pyatmnumber, upi, email, amount, withdraw_method } = data1;
    let formErrors = {};
    let formIsValid = true;
    if (!pyatmnumber) {
      formIsValid = true;
      formErrors["pyatmnumberErr"] = t("translation2:err_mkey_req");
    }
    if (!upi) {
      formIsValid = true;
      formErrors["upiErr"] = t("translation2:err_upi_id_req");
    }
    if (!email) {
      formIsValid = true;
      formErrors["emailErr"] = t("translation2:err_email_req");
    }
    if (!withdraw_method) {
      formIsValid = true;
      formErrors["withdrawMethodErr"] = t(
        "translation2:err_select_withdraw_method_req"
      );
    }
    if (!amount) {
      formIsValid = true;
      formErrors["amountErr"] = t("translation2:err_amount_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //function call when Click withdraw money button
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    var user = JSON.parse(localStorage.getItem("user"));
    console.log(data1);
    var response = await fetch(`/api/withdraw`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data1),
    });
    var res_data = await response.json();
    setWithdrawResponse(res_data);
    console.log(res_data);
  };
  useEffect(() => {
    if (withdrawResponse.status === false) {
      notifyE();
    } else {
      notifyS();
    }
  }, [withdrawResponse]);
  var res = res_withdrawmethod.data;

  return (
    <>
      <Title props={t("text_withdraw_money")} />
      <MainTheme title={t("text_withdraw_money")}>
        <ToastContainer
          hideProgressBar={true}
          autoClose={3000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {res_withdrawmethod.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <div className="row d-flex">
            <div className="col-md-12">
              <div className="card bg-light text-dark">
                <div className="card-header">
                  <strong>
                    {t("text_withdraw_to")} {Payment}
                  </strong>
                </div>
                <div className="card-body">
                  <div className="text-danger mb-2">
                    <strong>{t("text_image_note")} : </strong>
                    {t("text_withdraw_money_note")}
                  </div>
                  <form method="POST" id="withdraw-form">
                    <div className="row">
                      <div
                        className={
                          "form-group col-md-6 mobile_no-div " +
                          (display2 ? "d-block" : "d-none")
                        }
                      >
                        <label htmlFor="pyatmnumber">
                          &nbsp;
                          {t("text_mobile_no")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control1"
                          name="pyatmnumber"
                          value={data1.pyatmnumber}
                          onChange={change_withdraw}
                        />
                        <label
                          htmlFor="pyatmnumber"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.pyatmnumberErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.pyatmnumberErr}
                        </label>
                      </div>
                      <div
                        className={
                          "form-group col-md-6 upi-div " +
                          (display1 ? "d-block" : "d-none")
                        }
                      >
                        <label htmlFor="upi">
                          &nbsp;
                          {t("text_upi_id")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control1"
                          name="upi"
                          value={data1.upiid}
                          onChange={change_withdraw}
                        />
                        <label
                          htmlFor="upi"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.upiErr ? " d-block" : "d-none")
                          }
                        >
                          {state1.formErrors.upiErr}
                        </label>
                      </div>

                      <div
                        className={
                          "form-group col-md-6 email-div  " +
                          (display ? "d-block" : "d-none")
                        }
                      >
                        <label htmlFor="email">
                          &nbsp;
                          {t("text_email")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control1"
                          name="email"
                          value={data1.email}
                          onChange={change_withdraw}
                        />
                        <label
                          htmlFor="email"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.emailErr ? " d-block" : "d-none")
                          }
                        >
                          {state1.formErrors.emailErr}
                        </label>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="amount">
                          &nbsp;
                          {t("text_amount")}(
                          <span>
                            <img src={coin} className="coin" alt="img-coin" />
                          </span>
                          )
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          id="amount"
                          type="text"
                          className="form-control1"
                          name="amount"
                          value={data1.amount}
                          onChange={change_withdraw}
                        />
                        <label
                          htmlFor="amount"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.amountErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.amountErr}
                        </label>
                      </div>
                      <div className="form-group col-md-6">
                        <label> {t("text_withdraw_to")} :&nbsp; </label>
                        {res.withdraw_method.map((res, index) => {
                          return (
                            <div
                              className="custom-control custom-radio ml-5"
                              key={index}
                            >
                              <input
                                id="Paytm"
                                name="withdraw_method"
                                type="radio"
                                className="custom-control-input"
                                value={res.withdraw_method}
                                onChange={change_withdraw1}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="Paytm"
                              >
                                {res.withdraw_method}
                              </label>
                            </div>
                          );
                        })}
                        <label
                          htmlFor="withdraw_method"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.withdrawMethodErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.withdrawMethodErr}
                        </label>
                      </div>
                      {message &&
                        res.withdraw_method
                          .filter(
                            (res1) =>
                              res1.withdraw_method === data.withdraw_method
                          )
                          .map((res) => (
                            <div className="form-group bm_text_lightgreen getcurrency_div">
                              &nbsp;
                              {ReactHtmlParser(t("text_you_will_get"))}
                              {res.currency_symbol +
                                " " +
                                data1.amount /
                                  res.withdraw_method_currency_point}
                              &nbsp;
                            </div>
                          ))}

                      <div className="form-group text-center">
                        <input
                          type="submit"
                          value="Withdraw Money"
                          name="withdraw_money"
                          className="btn btn-lightpink"
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res_withdrawmethod: state.withdrawmethod,
});
export default connect(mapStateToProps)(Withdraw);
