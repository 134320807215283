import { React, useState } from "react";
import UserHeader from "../../theme/userHeader";
import coin from "../../../assets/images/coin.png";
import { useHistory } from "react-router-dom";
import Title from "../../../../views/common/Helmet";
import Swal from "sweetalert2";
import { t } from "i18next";

const Order = () => {
  const $ = window.$;
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  // manage state
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  //change the order status swal
  const handleStatus = (status, id, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>Are you sure to change status?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const value = {
          order_status: status,
        };
        var response = await fetch(`/api/change_order_status/${id}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
          body: JSON.stringify(value),
        });
        var res_data = await response.json();
        if (res_data) {
          setShow(true);
          setMessage(res_data.message);
          table1.ajax.reload(null, false);
        }
      }
    });
  };

  // view
  const handleView = (id) => {
    history.push(`/admin/order/order_detail/${id}`);
  };

  //member
  const handleMember = (id) => {
    history.push(`/admin/all_users/user_details/${id}`);
  };

  // table order
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#order");
      var table1 = table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: "/api/get_order",
          headers: {
            Authorization: user.api_token,
          },
          type: "POST",
          dataSrc: "all_order",
        },
        columns: [
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          { data: "order_no" },
          {
            data: "user_name",
            render: function (data, type, row) {
              return (
                "<a class='text-lightpink pointer' id='user'>" + data + "</a>"
              );
            },
          },
          {
            data: "product_price",
            render: function (data, type, row) {
              return Number(data).toFixed(2);
            },
          },
          {
            data: "order_status",
            render: function (data, type, row) {
              if (data == "Hold") var a = "selected";
              if (data == "Processing") var b = "selected";
              if (data == "Delivered") var c = "selected";
              if (data == "failed") var d = "selected";
              if (data == "Cancelled") var e = "selected";
              return `<select id='btn-status'><option value='Hold' ${a}>Hold</option><option value='Processing' ${b}>Processing</option><option value='Delivered' ${c}>Delivered</option><option value='failed' ${d}>Failed</option><option value='Cancelled' ${e}>Cancelled</option></select>`;
            },
          },
          { data: "created_date" },
          {
            data: null,
            render: function () {
              return '<i class="fa fa-eye text-lightblue fa-lg pointer" id="view-btn"></i>';
            },
          },
        ],
        Destroy: true,
      });
      // view
      $("#order tbody").on("click", "#view-btn", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().orders_id;
        handleView(id);
      });
      // user
      $("#order tbody").on("click", "#user", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().member_id;
        handleMember(id);
      });
      // status
      $("#order tbody").on("click", "#btn-status", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().orders_id;
        const status = $("#btn-status option:selected").val();
        handleStatus(status, id, table1);
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    // error throw
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      <Title props={t("text_order")} />
      {/* Header */}
      <UserHeader title={t("text_order")} />
      {/* message */}
      <div
        class={
          "alert alert-success alert-dismissible fade show " +
          (show ? "d-block" : "d-none")
        }
        role="alert"
      >
        {message}
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
      {/* datatable */}
      <div className="row">
        <div className="col-md-12 ">
          <table id="order" className="display table table-bordered mb-2">
            <thead className="mt-5">
              <tr>
                <th>{t("text_sr_no")}</th>
                <th>{t("text_order_no")}</th>
                <th>{t("text_user_name")}</th>
                <th>
                  {t("text_order_price")}
                  {"("}
                  <img src={coin} className="coin" alt="img-coin" />
                  {")"}
                </th>
                <th>{t("text_status")}</th>
                <th>{t("text_date")}</th>
                <th>{t("text_view")}</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>{t("text_sr_no")}</th>
                <th>{t("text_order_no")}</th>
                <th>{t("text_user_name")}</th>
                <th>
                  {t("text_order_price")}
                  {"("}
                  <img src={coin} className="coin" alt="img-coin" />
                  {")"}
                </th>
                <th>{t("text_status")}</th>
                <th>{t("text_date")}</th>
                <th>{t("text_view")}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default Order;
