import React from "react";
import { Link } from "react-router-dom";

const UserHeader = ({ title, path, icon, btnText, alignCenter }) => {
  return (
    <>
      <div
        className={`d-flex  flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom text-dark ${
          alignCenter ? "justify-content-center" : "justify-content-between"
        }`}
      >
        {alignCenter ? (
          <center>
            <h2 className="text-dark">{title}</h2>
          </center>
        ) : (
          <h2 className="text-dark">{title}</h2>
        )}
        {path && (
          <div className="btn-toolbar mb-2 mb-md-0">
            <Link className="btn btn-sm btn-outline-secondary" to={path}>
              {icon} {btnText}
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default UserHeader;
