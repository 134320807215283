import { React, useEffect } from "react";
import UserHeader from "../theme/userHeader";
import coin from "../../assets/images/coin.png";
import { connect } from "react-redux";
import {
  GetGameData,
  getTopPlayerDetail,
} from "../../../store/Action/AdminData";
import { NavLink } from "react-router-dom";
import Title from "../../../views/common/Helmet";
import { t } from "i18next";

const TopPlayers = ({ dispatch, res, res1 }) => {
  const $ = window.$;

  useEffect(() => {
    dispatch(GetGameData());
    dispatch(getTopPlayerDetail());
  }, [dispatch]);
  if (res1.loading) return false;
  const data = res1.data.game_name;
  if (res.loading) return false;

  const res_data = res.data;
  const arr = res_data.top_player;
  const obj = Object.keys(arr);

  data.map((data) => {
    $(document).ready(function () {
      $(`#${data.game_id}`).DataTable();
    });
  });

  return (
    <>
      <Title props={t("text_top_players")} />
      {/* Header */}
      <UserHeader title={t("text_top_players")} />
      {/* datatables */}
      {obj.map((data, index) => {
        return (
          <div key={index}>
            <h4 className="text-dark mt-4">{data}</h4>
            <div className="row">
              <div className="col-md-12">
                <table
                  id={data.game_id}
                  className="display table table-bordered mb-2"
                >
                  <thead className="mt-5">
                    <tr>
                      <th>{t("text_position")}</th>
                      <th>{t("text_player_name")}</th>
                      <th>
                        {t("text_winning")}
                        {"("}
                        <img src={coin} className="coin" alt="img-coin" />
                        {")"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {res_data.top_player[data].length ? (
                      res_data.top_player[data].map((data, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <NavLink
                                  to={`/admin/all_users/user_details/${data.member_id}`}
                                  className="text-lightpink text-decoration-none"
                                  id="user"
                                >
                                  <span> {data.user_name}</span>
                                </NavLink>
                              </td>
                              <td>{data.member_id}</td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={3}>No data available in table</td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>{t("text_position")}</th>
                      <th>{t("text_player_name")}</th>
                      <th>
                        {t("text_winning")}
                        {"("}
                        <img src={coin} className="coin" alt="img-coin" />
                        {")"}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.topPlayer,
  res1: state.Game,
});

export default connect(mapStateToProps)(TopPlayers);
