import React, { useEffect, useState } from "react";
import UserHeader from "../../theme/userHeader";
import { NavLink, useParams } from "react-router-dom";
import coin from "../../../assets/images/coin.png";
import { GetOrderInsertesData } from "../../../../store/Action/AdminData";
import { connect } from "react-redux";
import Title from "../../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const ViewOrder = ({ dispatch, res }) => {
  const params = useParams();
  const user = JSON.parse(sessionStorage.getItem("user"));

  //handle alert message
  const [message, setMessage] = useState({
    status: false,
    msg: "",
  });
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [show, setShow] = useState(false);
  //update the tracking info
  const [state, setState] = useState({
    courier_id: "",
    tracking_id: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // form validation
  function handleFormValidation() {
    const { courier_id, tracking_id } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!courier_id) {
      formIsValid = true;
      formErrors["courierIdErr"] = t("translation2:err_courier_id_req");
    }
    if (!tracking_id) {
      formIsValid = true;
      formErrors["trackingIdErr"] = t("translation2:err_tracking_id_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  // submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    var response = await fetch(`/api/update_courier_details/${params.id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      setShow(true);
      setMessage({ status: res_data.status, msg: res_data.message });
    }
  };

  useEffect(() => {
    dispatch(GetOrderInsertesData(params.id));
  }, [dispatch]);
  const data = res.data;

  return (
    <>
      <Title props={t("text_order")} />
      {/* Header */}
      <UserHeader title={t("text_order") + "-" + params.id} />
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <>
          {/* Message */}
          <div
            className={
              (!message.status
                ? "alert alert-danger "
                : "alert alert-success ") +
              "alert-dismissible fade show " +
              (show ? "d-block" : "d-none")
            }
            role="alert"
          >
            {message.msg}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
          <div className="row" style={{ display: "flex" }}>
            <div className="col-md-8 mb-3">
              <div
                className="card bg-light text-dark"
                style={{ height: "100%" }}
              >
                {/* card-header */}
                <div className="card-header">
                  <strong>{t("text_shipping_detail")}</strong>
                </div>
                {/* user name */}
                <div className="card-body">
                  <div>
                    <b>{t("text_user_name")} : </b>
                    <span>
                      <NavLink
                        to={`/admin/all_users/user_details/${data.member_id}`}
                        className="text-lightpink text-decoration-none"
                      >
                        {data.get_order_details[0].user_name}
                      </NavLink>
                    </span>
                  </div>
                  <div>
                    <b>{t("text_name")} : </b>
                    <span>{data.get_order_details[0].name}</span>
                  </div>
                  <div>
                    <b>{t("text_address")}: </b>
                    <span>{data.get_order_details[0].address}</span>
                  </div>
                  <div>
                    <b>{t("text_additional_info")} : </b>
                    <span>{data.get_order_details[0].add_info}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* tracking info */}
            <div className="col-md-4 mb-3">
              <div className="card bg-light text-dark">
                <div className="card-header">
                  <strong>{t("text_tracking_info")}</strong>
                </div>
                <div className="card-body">
                  <form className="track_form" name="track_form">
                    <div className="row">
                      {/* courier id */}
                      <div className="form-group col-md-12">
                        <label htmlFor="courier_id">{t("text_courier")}</label>
                        <select
                          className="form-control mt-2"
                          name="courier_id"
                          value={state.courier_id}
                          onChange={handleChange}
                        >
                          <option value="">Select..</option>
                          <option value="1">{t("text_test")}</option>
                        </select>
                        <label
                          htmlFor="courier_id"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.courierIdErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.courierIdErr}
                        </label>
                      </div>
                      {/* tracking id */}
                      <div className="form-group col-md-12 mt-2">
                        <label htmlFor="tracking_id">
                          {t("text_tracking_id")}
                        </label>
                        <input
                          id="tracking_id"
                          type="text"
                          className="form-control mt-2"
                          name="tracking_id"
                          value={state.tracking_id}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="tracking_id"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.trackingIdErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.trackingIdErr}
                        </label>
                      </div>
                    </div>
                    {/* submit */}
                    <div className="form-group mt-3">
                      <button
                        className="btn btn-lightpink"
                        type="submit"
                        value="Submit"
                        name="submit"
                        onClick={handleSubmit}
                      >
                        {t("text_btn_submit")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* product */}
            <div className="col-md-12">
              <div className="card bg-light text-dark">
                <div className="card-header">
                  <strong>{t("text_product")}</strong>
                </div>
                <div className="card-body">
                  <div className="row">
                    {data.get_order_details[0].product_image && (
                      <div className="col-md-4">
                        <img
                          src={data.get_order_details[0].product_image}
                          className="img-responsive"
                        />
                      </div>
                    )}
                    <div className="col-md-8">
                      <div>
                        <b>{t("text_product_name")}: </b>
                        <span>{data.get_order_details[0].product_name} </span>
                      </div>
                      <div>
                        <b>{t("text_product_price")} : </b>
                        <span>
                          <img src={coin} className="coin" />
                          &nbsp;
                          {data.get_order_details[0].product_price}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.Order,
});

export default connect(mapStateToProps)(ViewOrder);
