import React from "react";
import Typist from "react-typist";
import { t } from "i18next";

const Banner = ({ props }) => {
  return (
    <>
      <div
        className="masthead bm-px-110 d-flex"
        style={{ backgroundImage: "url(" + props.main_banner_image + ")" }}
      >
        <div className="container m-auto">
          <div className="row align-items-center">
            <div className="col-md-6 col_left col-sm-12">
              {/* Type heading */}
              <Typist cursor={{ show: false }}>
                <h1>
                  <span id="typed">{props.main_banner_title_text}</span>
                  <span className="typed-cursor">|</span>
                </h1>
              </Typist>
              <p className="banner_subtext">{props.main_banner_sub_text}</p>
              <a className="bm_button1 text-uppercase" href={props.apk_url}>
                {t("text_download_now")}!
              </a>
            </div>
            <div className="col-md-6 col_right col-sm-12 text-center">
              <img
                src={props.main_banner_side_image}
                alt="section1-sideimg"
                className="img-fluid animated slideInRight"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
