import { NavLink } from "react-router-dom";
import coin from "../../../views/assets/images/coin.png";
import { t } from "i18next";

function UserFooter() {
  return (
    <div className="bm-mdl-footer text-white">
      <nav className="navbar navbar-expand">
        <ul className="navbar-nav">
          {/* Earn */}
          <li className="nav-item text-center">
            <NavLink to="/refer_earn" className="nav-link f-18">
              <img src={coin} alt="coin_image" className="coin" />
              <br />
              {t("text_earn")}
            </NavLink>
          </li>
          {/* Play */}
          <li className="nav-item text-center">
            <NavLink to="/play" className="nav-link f-18">
              <i
                className="fa fa-gamepad d-block"
                style={{ color: "#fff" }}
              ></i>
              {t("text_play")}
            </NavLink>
          </li>
          {/* Account */}
          <li className="nav-item text-center">
            <NavLink to="/accounts" className="nav-link f-18">
              <i className="fa fa-user d-block" style={{ color: "#fff" }}></i>
              {t("text_account")}
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default UserFooter;
