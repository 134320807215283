import { React, useEffect } from "react";
import Header from "../theme/header";
import Footer from "../theme/footer";
import Title from "../../common/Helmet";
import { connect } from "react-redux";
import { PageData } from "../../../store/Action/FatchData";
import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Terms = ({ dispatch, res }) => {
  const location = useLocation();

  useEffect(() => {
    dispatch(PageData());
  }, [dispatch]);
  const data = res.data.page;

  return (
    <>
      <Title props={t("text_terms_conditions")} />
      <Header subHeader path={location.pathname} />
      {res.loading ? (
        <div className="loader">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="container">
            <div className="bm-mdl-center bm-full-height text-dark p-4">
              {data.map((data) => {
                return (
                  "/" + data.page_slug == location.pathname &&
                  ReactHtmlParser(data.page_content)
                );
              })}
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.PageData,
});

export default connect(mapStateToProps)(Terms);
