import { React, useEffect, useState } from "react";
import UserHeader from "../theme/userHeader";
import { CheckAllPermission } from "../../../store/Action/AdminData";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Title from "../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const EditAdmin = ({ dispatch, res }) => {
  const history = useHistory();
  const params = useParams();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  // manage state
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    admin_id: "",
    name: "",
    email: "",
    password: "",
    permission: [],
  });
  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });

  //change the value of admin data is store in state
  const handleAdmin = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };
  const handleAdmin1 = (e) => {
    const { value, checked } = e.target;
    const { permission } = state;
    if (checked) {
      setState({
        ...state,
        permission: [...permission, value],
      });
    }
  };

  // get admin data
  var admin = async () => {
    var response = await fetch(`/api/get_insert_adminuser/${params.id}`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    if (res_data) {
      setLoading(false);
    }
    setState({
      admin_id: res_data.get_admin_data[0].id,
      name: res_data.get_admin_data[0].name,
      email: res_data.get_admin_data[0].email,
    });
  };

  // form validation
  function handleFormValidation() {
    const { name, email, password } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!name) {
      formIsValid = true;
      formErrors["adminNameErr"] = t("translation2:err_company_name_valid");
    }
    if (!email) {
      formIsValid = true;
      formErrors["emailErr"] = t("translation2:err_email_req");
    }
    if (!password) {
      formIsValid = true;
      formErrors["passwordErr"] = t("translation2:err_password_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  // submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    var response = await fetch(`/api/update_adminuser/${params.id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      history.push("/admin/admin", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    } else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    admin();
    dispatch(CheckAllPermission());
  }, [dispatch]);
  const data = res.data;

  return (
    <>
      <Title props={t("text_admin")} />
      <UserHeader
        title={t("text_admin")}
        path={"/admin/admin"}
        icon={<i className="fa fa-eye"></i>}
        btnText={t("text_view_admin")}
      />
      {res.loading || loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card bg-light text-dark">
              <div className="card-header">
                <strong>{t("text_admin")}</strong>
              </div>
              <div className="card-body">
                <div className="col-md-12">
                  <form
                    className="needs-validation"
                    id="validate"
                    method="POST"
                  >
                    <div className="row">
                      <div className="form-group col-6 mb-3">
                        <label htmlFor="name" className="mb-3">
                          {t("text_name")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={state.name}
                          onChange={handleAdmin}
                        />
                        <label
                          htmlFor="name"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.adminNameErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.adminNameErr}
                        </label>
                      </div>

                      {/* email */}
                      <div className="form-group col-6 mb-3">
                        <label htmlFor="email" className="mb-3">
                          {t("text_email")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          value={state.email}
                          onChange={handleAdmin}
                        />
                        <label
                          htmlFor="email"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.emailErr ? " d-block" : "d-none")
                          }
                        >
                          {state1.formErrors.emailErr}
                        </label>
                      </div>
                    </div>
                    <hr />

                    {/* permission */}
                    <div className="row justify-content-md-center">
                      <div className="form-group col-6">
                        <h4 htmlFor="name">{t("text_permission")}</h4>
                        <table className="table table-striped">
                          <tbody>
                            {data.check_per.map((data, index) => {
                              return (
                                <tr key={index}>
                                  {data.parent_status == "parent" ? (
                                    <td>
                                      <b>{data.name}</b>
                                    </td>
                                  ) : (
                                    <td className="ps-5">{data.name}</td>
                                  )}
                                  <td>
                                    <input
                                      type="checkbox"
                                      name={data.name}
                                      value={data.permission_id}
                                      onChange={handleAdmin1}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <br />

                    {/* submit */}
                    <div className="form-group text-center mb-3">
                      <input
                        type="submit"
                        value="Submit"
                        name="submit"
                        className={`btn btn-lightpink ${
                          demouser === "1" && "disabled"
                        }`}
                        onClick={demouser === "0" && handleSubmit}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.CheckAllPermission,
});

export default connect(mapStateToProps)(EditAdmin);
