import { React, useState } from "react";
import UserHeader from "../../theme/userHeader";
import { useHistory, NavLink } from "react-router-dom";
import coin from "../../../assets/images/coin.png";
import Title from "../../../../views/common/Helmet";
import { t } from "i18next";

const AddWithdrawMethod = () => {
  //get the id from parameter
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));

  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [state, setState] = useState({
    withdraw_method: "",
    withdraw_method_field: "",
    withdraw_method_currency: "",
    withdraw_method_currency_point: "",
  });

  // withdraw method
  const handleWithdrawMethod = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // form validation
  function handleFormValidation() {
    const {
      withdraw_method,
      withdraw_method_field,
      withdraw_method_currency,
      withdraw_method_currency_point,
    } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!withdraw_method) {
      formIsValid = true;
      formErrors["withdrawMethodErr"] = t(
        "translation2:err_enter_withdraw_method_req"
      );
    }
    if (!withdraw_method_field) {
      formIsValid = true;
      formErrors["withdrawMethodFieldErr"] = t(
        "translation2:err_withdraw_method_field_req"
      );
    }
    if (!withdraw_method_currency) {
      formIsValid = true;
      formErrors["withdrawMethodCurrencyMethodErr"] = t(
        "translation2:err_currency_req"
      );
    }
    if (!withdraw_method_currency_point) {
      formIsValid = true;
      formErrors["withdrawMethodCurrencyPointMethodErr"] = t(
        "translation2:err_point_req"
      );
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    var response = await fetch(`/api/insert_withdraw_method`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      history.push("/admin/withdraw_method", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };
  return (
    <>
      <Title props={t("text_withdraw_method")} />
      <UserHeader
        title={t("text_withdraw_method")}
        path={"/admin/withdraw_method"}
        icon={<i className="fa fa-eye"></i>}
        btnText={t("text_view_withdraw_method")}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            <div className="card-header">
              <strong>{t("text_withdraw_method")}</strong>
            </div>
            <div className="card-body">
              <form method="POST" id="validate">
                <div className="row">
                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="withdraw_method" className="mb-3">
                      {t("text_withdraw_method")}
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <input
                      id="withdraw_method"
                      type="text"
                      className="form-control"
                      name="withdraw_method"
                      value={state.withdraw_method}
                      onChange={handleWithdrawMethod}
                    />
                    <label
                      htmlFor="withdraw_method"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.withdrawMethodErr
                          ? " d-block"
                          : "d-none")
                      }
                    >
                      {state1.formErrors.withdrawMethodErr}
                    </label>
                  </div>
                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="withdraw_method_field" className="mb-3">
                      {t("text_withdraw_method_field")}
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <div>
                      <div className="custom-control custom-radio">
                        <input
                          id="mobile_no"
                          name="withdraw_method_field"
                          type="radio"
                          className="custom-control-input"
                          value="mobile_no"
                          onChange={handleWithdrawMethod}
                        />
                        &nbsp;
                        <label
                          className="custom-control-label"
                          htmlFor="mobile_no"
                        >
                          {t("text_mobile_no")}
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          id="email"
                          name="withdraw_method_field"
                          type="radio"
                          className="custom-control-input"
                          value="email"
                          onChange={handleWithdrawMethod}
                        />
                        &nbsp;
                        <label className="custom-control-label" htmlFor="email">
                          {t("text_email")}
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          id="UPI ID"
                          name="withdraw_method_field"
                          type="radio"
                          className="custom-control-input"
                          value="UPI ID"
                          onChange={handleWithdrawMethod}
                        />
                        &nbsp;
                        <label
                          className="custom-control-label"
                          htmlFor="UPI ID"
                        >
                          {t("text_upi_id")}
                        </label>
                      </div>
                    </div>
                    <label
                      htmlFor="withdraw_method_field"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.withdrawMethodFieldErr
                          ? " d-block"
                          : "d-none")
                      }
                    >
                      {state1.formErrors.withdrawMethodFieldErr}
                    </label>
                  </div>
                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="withdraw_method_currency" className="mb-3">
                      {t("text_currency")}
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <select
                      name="withdraw_method_currency"
                      id="withdraw_method_currency"
                      className="form-control"
                      onChange={handleWithdrawMethod}
                    >
                      <option value="">Select..</option>
                      <option value="3"> India Rupee</option>
                      <option value="5"> US Dollar</option>
                      <option value="6"> Token</option>
                      <option value="7"> BDT</option>
                      <option value="8"> Taka</option>
                    </select>
                    <label
                      htmlFor="withdraw_method_currency"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.withdrawMethodCurrencyMethodErr
                          ? " d-block"
                          : "d-none")
                      }
                    >
                      {state1.formErrors.withdrawMethodCurrencyMethodErr}
                    </label>
                  </div>
                  <div className="form-group col-md-6 mb-3">
                    <label
                      htmlFor="withdraw_method_currency_point"
                      className="mb-3"
                    >
                      {t("text_point")} (<i className="fa fa-point"></i>
                      <img src={coin} className="coin" alt="img-coin" />)
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <input
                      id="withdraw_method_currency_point"
                      type="text"
                      className="form-control"
                      name="withdraw_method_currency_point"
                      value={state.banner_title}
                      onChange={handleWithdrawMethod}
                    />
                    <label
                      htmlFor="withdraw_method_currency_point"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.withdrawMethodCurrencyPointMethodErr
                          ? " d-block"
                          : "d-none")
                      }
                    >
                      {state1.formErrors.withdrawMethodCurrencyPointMethodErr}
                    </label>
                  </div>
                </div>
                <div className="form-group d-flex justify-content-center mb-3">
                  <input
                    type="submit"
                    value="Submit"
                    name="submit"
                    className="btn btn-lightpink"
                    onClick={handleSubmit}
                  />
                  <NavLink
                    className="btn btn-secondary ms-1 d-block"
                    to="/admin/withdraw_method"
                    name="cancel"
                  >
                    {t("text_btn_cancel")}
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWithdrawMethod;
