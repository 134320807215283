import React, { useState } from "react";
import UserHeader from "../../theme/userHeader";
import { NavLink, useHistory } from "react-router-dom";
import Title from "../../../../views/common/Helmet";
import { t } from "i18next";

const AddAppTutorial = () => {
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [state, setState] = useState({
    youtube_link_title: "",
    youtube_link: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  // handle form validation
  function handleFormValidation() {
    const { youtube_link_title, youtube_link } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!youtube_link_title) {
      formIsValid = true;
      formErrors["youtubeTitleErr"] = t(
        "translation2:err_youtube_link_title_req"
      );
    }
    if (!youtube_link) {
      formIsValid = true;
      formErrors["youtubeLinkErr"] = t("translation2:err_youtube_link_req");
    }

    setState1({ formErrors: formErrors });
    return formIsValid;
  }
  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("data", state);
    handleFormValidation();
    var response = await fetch(`/api/insert_tutorial`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      history.push("/admin/app_tutorial", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_app_tutorial")} />
      <UserHeader title={t("text_app_tutorial")} />
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            <div className="card-header">
              <strong>{t("text_app_tutorial")}</strong>
            </div>
            <div className="card-body">
              <form method="POST" id="validate">
                <div className="row">
                  <div className="form-group col-md-6 mb-3">
                    <label className="mb-3" htmlFor="youtube_link_title">
                      {t("text_app_tutorial_title")}
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <input
                      type="text"
                      id="youtube_link_title"
                      className="form-control"
                      name="youtube_link_title"
                      value={state.youtube_link_title}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="currency_name"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.youtube_link_title
                          ? " d-block"
                          : "d-none")
                      }
                    >
                      {state1.formErrors.youtube_link_title}
                    </label>
                  </div>
                  <div className="form-group col-md-6 mb-3">
                    <label className="mb-3" htmlFor="youtube_link">
                      {t("text_app_tutorial_link")}
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <input
                      id="youtube_link"
                      type="text"
                      className="form-control"
                      name="youtube_link"
                      value={state.youtube_link}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="currency_name"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.youtube_link ? " d-block" : "d-none")
                      }
                    >
                      {state1.formErrors.youtube_link}
                    </label>
                  </div>
                </div>
                <div className="form-group d-flex justify-content-center mb-3">
                  <input
                    type="submit"
                    value="Submit"
                    name="submit"
                    className={`btn btn-lightpink ${
                      demouser === "1" && "disabled"
                    }`}
                    onClick={demouser === "0" && handleSubmit}
                  />
                  <NavLink
                    className="btn btn-secondary ms-1 d-block"
                    to="/admin/app_tutorial"
                    name="cancel"
                  >
                    {t("text_btn_cancel")}
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAppTutorial;
