import { React, useEffect } from "react";
import MainTheme from "../theme/MainTheme";
import { Dashboard } from "../../../store/Action/UserFatchData";
import { connect } from "react-redux";
import coin from "../../assets/images/coin.png";
import Title from "../../common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Account = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Dashboard());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_dashboard")} />
      <MainTheme title={t("text_dashboard")}>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="row">
              {/* Total Match */}
              <div className="col-lg-3 col-md-6 dash-box pointer">
                <span>
                  <div className="bg-lightgreen small-box card card-sm-3">
                    <div className="card-icon ">
                      <i className="fa fa-gamepad"></i>
                    </div>
                    <div className="card-wrap">
                      <div className="card-header">
                        <h4>{t("text_matches_played")}</h4>
                      </div>
                      <div className="card-body">
                        {data.tot_match_play.total_match}
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              {/* Total Kill */}
              <div className="col-lg-3 col-md-6 dash-box pointer">
                <span>
                  <div className="bg-lightblue small-box card card-sm-3">
                    <div className="card-icon ">
                      <i className="fa fa-credit-card"></i>
                    </div>
                    <div className="card-wrap">
                      <div className="card-header">
                        <h4>{t("text_total_killed")}</h4>
                      </div>
                      <div className="card-body">
                        {data.tot_kill.total_kill}
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              {/* Total Win */}
              <div className="col-lg-3 col-md-6 dash-box pointer">
                <span>
                  <div className="bg-lightpink small-box card card-sm-3">
                    <div className="card-icon ">
                      <i className="fa fa-money"></i>
                    </div>
                    <div className="card-wrap">
                      <div className="card-header">
                        <h4>{t("text_amount_won")}</h4>
                      </div>
                      <div className="card-body">
                        <img src={coin} alt="coin-img" className="coin" />
                        {data.tot_win.total_win}
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              {/* Total Wallet Balance */}
              <div className="col-lg-3 col-md-6 dash-box pointer">
                <span>
                  <div className="bg-lightgreen small-box card card-sm-3">
                    <div className="card-icon ">
                      <i className="fa fa-money"></i>
                    </div>
                    <div className="card-wrap">
                      <div className="card-header">
                        <h4>{t("text_balance")}</h4>
                      </div>
                      <div className="card-body">
                        <img src={coin} alt="coin-img" className="coin" />
                        &nbsp;
                        {Number(data.member.wallet_balance).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// Redux Connect
const mapStateToProps = (state) => ({
  res: state.dashboard,
});

export default connect(mapStateToProps)(Account);
