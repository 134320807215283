import { React, useEffect } from "react";
import { All_ongoing_match } from "../../../store/Action/UserFatchData";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import coin from "../../../views/assets/images/coin.png";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const OngoingTab = ({ dispatch, res }) => {
  var { id } = useParams();
  useEffect(() => {
    dispatch(All_ongoing_match(id));
  }, [dispatch, id]);
  var data = res.data;

  return (
    <>
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <>
          {data.all_ongoing_match.length ? (
            <div className="bm-content-listing tournaments">
              {data.all_ongoing_match.map((data, index) => (
                <div className="card br-5 hide-over mb-3" key={index}>
                  <Link
                    to={{
                      pathname: `/play/ongoing_match/${data.m_id}`,
                      query: data.match_banner,
                    }}
                  >
                    <img
                      src={data.match_banner}
                      className="img-fluid card-img-top mCS_img_loaded"
                      alt="img-banner"
                    />
                  </Link>
                  <div className="card-body">
                    {/* type */}
                    <span className="btn btn-sm btn-lightpink">
                      {data.type}
                    </span>
                    {/* map */}
                    <span className="btn btn-sm btn-primary ms-1">
                      {data.MAP}
                    </span>
                    <h6 className="card-title mt-2">
                      <i className="fa fa-bomb"></i>
                      {/* match name  */}
                      <span className="text-dark no-underline">
                        {data.match_name}
                        {t("text_for_macth_id")}
                        {data.m_id}
                      </span>
                    </h6>
                    {/* table */}
                    <table className="card-table table text-center mt-3">
                      <tbody>
                        <tr>
                          <td>
                            {/* match time  */}
                            <span className="bm_text_lightpink ">
                              {data.match_time}
                            </span>
                          </td>
                          <td>
                            {/* winning prize */}
                            <span className="no-underline">
                              <span className="bm_text_lightgreen text-uppercase">
                                {t("text_prize_pool")}
                                <br />
                                <img
                                  src={coin}
                                  className="coin"
                                  alt="img-coin"
                                />
                                {data.win_prize}
                              </span>
                            </span>
                          </td>
                          <td>
                            {/* per kill */}
                            <span className="text-primary text-uppercase">
                              {t("text_per_kill")}
                              <br />
                              <img src={coin} className="coin" alt="img-coin" />
                              {data.per_kill}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* Spactate */}
                    <div className="row">
                      <a
                        href={data.match_url}
                        className="btn btn-sm btn-block btn-lightpink text-uppercase"
                      >
                        {data.entry_fee}
                        {t("text_Spactate")}
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            // if there is no any live match
            <div className="bm-content-listing tournaments">
              <div className="col-md-12 text-center text-black">
                <strong>{t("text_no_live_macth")}</strong>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

//  redux connect
const mapStateToProps = (state) => ({
  res: state.All_ongoing_match,
});

export default connect(mapStateToProps)(OngoingTab);
