import { React } from "react";
import ReactDOM from "react-dom";
import "./languages/i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "font-awesome/css/font-awesome.min.css";
import App from "./App";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./store/index";
import { ErrorBoundary } from "react-error-boundary";
import ErrorHandler from "./views/common/ErrorHandler";

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
ReactDOM.render(
  <>
    <Provider store={store}>
      {/* set error boundary */}
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        {/* render App */}
        <App />
      </ErrorBoundary>
    </Provider>
  </>,
  document.getElementById("root")
);
