import { React, useState, useEffect } from "react";
import { t } from "i18next";
import { CKEditor } from "ckeditor4-react";
import coin from "../../../assets/images/coin.png";
const PayStackP = ({ props, onMessage }) => {
  const [state, setState] = useState({
    id: props.id,
    payment_name: props.payment_name,
    payment_description: props.payment_description,
    payment_status: props.payment_status,
    name: props.name,
    mid: props.mid,
    mkey: props.mkey,
    wname: props.wname,
    itype: props.itype,
    currency: props.currency,
    currency_point: props.currency_point,
    date: props.date,
    status: props.status,
    created_date: props.created_date,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
    // onMessage({
    //     ...state,
    //     [name]: value
    // })
  };
  useEffect(() => {
    onMessage(state);
  }, [state]);
  return (
    <>
      <div className="row">
        <div className="form-group col-md-6 mb-3">
          <label className="mb-3" htmlFor="payment_status">
            {t("text_mode")}
          </label>
          <div className="form-group col-md-12 d-flex">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                id="Test"
                name="payment_status"
                type="radio"
                className="custom-control-input"
                value="Test"
                defaultChecked={state.payment_status === "Test" && true}
                onChange={handleChange}
              />
              &nbsp;
              <label className="custom-control-label" htmlFor="Test">
                {t("text_test")}
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                id="Production"
                name="payment_status"
                type="radio"
                className="custom-control-input"
                value="Production"
                defaultChecked={state.payment_status === "Production" && true}
                onChange={handleChange}
              />
              &nbsp;
              <label className="custom-control-label" htmlFor="Production">
                {t("text_production")}
              </label>
            </div>
          </div>
        </div>

        <div className="form-group col-md-6 mb-3">
          <label htmlFor="mid">{t("text_secret_key")}</label>
          <input
            id="mid"
            type="text"
            className="form-control valid"
            name="mid"
            value={state.mid}
            onChange={handleChange}
          />
        </div>

        <div className="form-group col-md-6 mb-3">
          <label htmlFor="mid">{t("text_public_key")}</label>
          <input
            id="mid"
            type="text"
            className="form-control valid"
            name="mkey"
            value={state.mkey}
            onChange={handleChange}
          />
        </div>

        <div className="form-group col-md-6 mb-3">
          <label htmlFor="currency">
            {t("text_currency")}
            <span className="required" aria-required="true">
              &nbsp;*&nbsp;
            </span>
          </label>
          <select
            name="currency"
            id="currency"
            className="form-control"
            onChange={handleChange}
          >
            <option value="">Select..</option>
            <option
              value=" 3"
              selected={state.currency == "3" ? "selected" : false}
            >
              India Rupee
            </option>
            <option
              value=" 5"
              selected={state.currency == "5" ? "selected" : false}
            >
              US Dollar
            </option>
            <option
              value=" 6"
              selected={state.currency == "6" ? "selected" : false}
            >
              Token
            </option>
            <option
              value=" 7"
              selected={state.currency == "7" ? "selected" : false}
            >
              BDT
            </option>
            <option
              value=" 8"
              selected={state.currency == "8" ? "selected" : false}
            >
              Taka
            </option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-md-6 mb-3">
          <label className="mb-3" htmlFor="currency_point">
            {t("text_point")} (<i className="fa fa-point"></i>
            <img src={coin} className="coin" alt="img-coin" />)
            <span className="required" aria-required="true">
              &nbsp;*&nbsp;
            </span>
          </label>
          <input
            id="currency_point"
            type="text"
            className="form-control"
            name="currency_point"
            value={state.currency_point}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default PayStackP;
