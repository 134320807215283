import { useEffect, React } from "react";
import Header from "../theme/header";
import Footer from "../theme/footer";
import { HowToInstall } from "../../../store/Action/FatchData";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-multi-carousel/lib/styles.css";
import Title from "../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import Carousel from "./carousel";
import { t } from "i18next";

function How_to_install({ dispatch, res }) {
  const location = useLocation();

  useEffect(() => {
    dispatch(HowToInstall());
  }, [dispatch]);
  const data = res.data;

  return (
    <>
      <Title props={t("text_howtoinstall")} />
      <Header subHeader path={location.pathname} />
      {res.loading ? (
        <div className="loader">
          <Spinner />
        </div>
      ) : (
        <>
          <Carousel data={data} />
          <Footer />
        </>
      )}
    </>
  );
}

// redux connect
const mapStateToProps = (state) => ({
  res: state.how_to_install,
});

export default connect(mapStateToProps)(How_to_install);
