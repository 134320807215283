import { React, useEffect } from "react";
import Title from "../../../common/Helmet";
import UserHeader from "../../theme/UserHeader";
import MainTheme from "../../theme/MainTheme";
import { connect } from "react-redux";
import { my_order_data } from "../../../../store/Action/UserFatchData";
import { useParams } from "react-router-dom";
import coin from "../../assets/images/coin.png";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const OrderDetail = ({ dispatch, res }) => {
  let { id } = useParams();
  useEffect(() => {
    dispatch(my_order_data());
  }, [dispatch]);
  var data = res.data;
  if (res.loading !== true) {
    var order_data = data.my_orders.filter((data) => {
      return data.orders_id === id;
    });
  }

  return (
    <>
      <Title props={t("text_order")} />
      <MainTheme>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <UserHeader props={order_data[0].product_name} />
            <div className="row">
              <div className="col-md-12">
                <div className="card bg-light text-dark">
                  <div className="card-header">
                    {/* Product Image */}
                    <div className="match-info">
                      <img
                        src={order_data[0].product_image}
                        alt="product_img"
                        className="img-fluid1"
                      />
                    </div>
                    <div className="py-3">
                      <div
                        className="card mb-2 p-2"
                        style={{ minHeight: "unset" }}
                      >
                        {/* Product Name */}
                        <h6 className="text-black">
                          {order_data[0].product_name}
                        </h6>
                        <div className="row ms-auto">
                          <h6 className="col-12 text-info">
                            {/* Product Price */}
                            <span>
                              <img src={coin} alt="coin-img" className="coin" />
                            </span>
                            {order_data[0].product_price}
                          </h6>
                        </div>
                      </div>
                    </div>
                    {/* order data */}
                    <div
                      className="card mb-2 p-2"
                      style={{ minHeight: "unset" }}
                    >
                      {/* Name */}
                      <h6 className="text-black mb-0">{t("text_name")}:</h6>
                      <div className="text-black mb-2">
                        {order_data[0].name}
                      </div>
                      {/* Address */}
                      <h6 className="text-black mb-0">{t("text_address")}:</h6>
                      <div className="text-black mb-2">
                        {order_data[0].address}
                      </div>
                      {/* Additional Info */}
                      <h6 className="text-black mb-0">
                        {t("text_additional_info")}:
                      </h6>
                      <div className="text-black mb-2">
                        {order_data[0].add_info}
                      </div>
                      {/* Order date */}
                      <h6 className="text-black mb-0">
                        {t("text_order_date")}:
                      </h6>
                      <div className="text-black mb-2">
                        {order_data[0].created_date}
                      </div>
                      {/* Order Status */}
                      <h6 className="text-black mb-0">{t("text_status")}:</h6>
                      <div className="text-black mb-2">
                        {order_data[0].order_status}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.order,
});

export default connect(mapStateToProps)(OrderDetail);
