import { React } from "react";
import UserHeader from "../theme/userHeader";
import { useHistory } from "react-router-dom";
import Title from "../../../views/common/Helmet";
import { t } from "i18next";

const LeaderBoard = () => {
  const $ = window.$;
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));

  //member detail
  const handleMember = (id) => {
    history.push(`/admin/all_users/user_details/${id}`);
  };

  // table leader board
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#leader");
      var table1 = table.DataTable({
        processing: true,
        serverSide: true,
        paging: false,
        info: false,
        ajax: {
          url: "/api/get_leader_board",
          headers: {
            Authorization: user.api_token,
          },
          type: "POST",
          dataSrc: "leader_board",
        },
        columns: [
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          {
            data: "user_name",
            render: function (data, type, row) {
              return (
                "<a id='user' class='text-lightpink pointer'>" + data + "</a>"
              );
            },
          },
          { data: "tot_referral" },
        ],
        Destroy: true,
      });
      // user
      $("#leader tbody").on("click", "#user", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().member_id;
        handleMember(id);
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    // error throw
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      <Title props={t("text_leaderboard")} />
      {/* Header */}
      <UserHeader title={t("text_leaderboard")} />
      {/* datatable */}
      <div className="row">
        <div className="col-md-12">
          <table id="leader" className="display table table-bordered mb-2">
            <thead className="mt-5">
              <tr>
                <th>{t("text_sr_no")}</th>
                <th>{t("text_player_name")}</th>
                <th>{t("text_total_referral")}</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>{t("text_sr_no")}</th>
                <th>{t("text_player_name")}</th>
                <th>{t("text_total_referral")}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default LeaderBoard;
