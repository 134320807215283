import React from "react";
import ReactHtmlParser from "react-html-parser";
import coin from "../../../../assets/images/coin.png";
import { t } from "i18next";

const Description = ({ data }) => {
  return (
    <>
      <div className="d-flex flex-wrap justify-content-xl-between">
        <div className="border-md-right flex-grow-1 p-3">
          <h6 className="text-lightgreen">
            {data.match.match_name} {t("text_for_macth_id")} {data.match.m_id}
          </h6>
          <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
            &nbsp;
            {t("text_team")} : <strong> {data.match.type}</strong>
          </span>
          <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
            &nbsp;
            {t("text_entry_fee")}:&nbsp;
            <strong>
              &nbsp;
              <i style={{ color: "#000" }}>
                <img
                  src={coin}
                  alt="coin-img"
                  style={{ verticalAlign: "sub", width: "20px" }}
                />
              </i>
              &nbsp;
              {data.match.entry_fee}&nbsp;
            </strong>
          </span>
          <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
            {t("text_match_type")}: <strong> {t("text_free")} </strong>
          </span>
          <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
            &nbsp;
            {t("text_map")} : <strong> {data.match.MAP} </strong>
          </span>
          <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
            &nbsp;
            {t("text_match_schedule")} :&nbsp;
            <strong> {data.match.match_time} </strong>
          </span>
          <h6 className="text-lightgreen"> {t("text_prize_details")}</h6>
          <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
            &nbsp;
            {t("text_winning_prize")} :&nbsp;
            <strong>
              &nbsp;
              <i style={{ color: "#000" }}>
                <img
                  src={coin}
                  alt="coin-img"
                  style={{ verticalAlign: "sub", width: "20px" }}
                />
              </i>
              &nbsp;
              {data.match.win_prize}&nbsp;
            </strong>
          </span>
          <span className="d-inline-block bg-white rounded px-2 mx-1 mb-2 py-1 box-shadow text-dark">
            &nbsp;
            {t("text_per_kill")} :&nbsp;
            <strong>
              &nbsp;
              <i style={{ color: "#000" }}>
                <img
                  src={coin}
                  alt="coin-img"
                  style={{ verticalAlign: "sub", width: "20px" }}
                />
              </i>
              &nbsp;
              {data.match.per_kill}&nbsp;
            </strong>
          </span>
          <h6 className="text-lightgreen text-dark">{t("text_about_match")}</h6>
          <span className="text-dark">
            {ReactHtmlParser(data.match.match_desc)}
          </span>
        </div>
      </div>
    </>
  );
};

export default Description;
