import { React, useState, useEffect } from "react";
import Title from "../../../views/common/Helmet";
import MainTheme from "../theme/MainTheme";
import Scrollbars from "react-custom-scrollbars";
import { connect } from "react-redux";
import { withdraw_method_data } from "../../../store/Action/UserFatchData";
import { useHistory } from "react-router-dom";
import coin from "../../../views/assets/images/coin.png";
import ReactHtmlParser from "react-html-parser";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Withdraw = ({ dispatch, res_withdrawmethod }) => {
  const history = useHistory();
  var user1 = JSON.parse(localStorage.getItem("user"));
  var user = user1.member_id;

  // manage state
  const [display, setDisplay] = useState(false);
  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(true);
  const [message, setMessage] = useState(false);
  const [Payment, setPayment] = useState("PayTm");
  const [data1, getData1] = useState({});

  //withdrawmethod
  const [data, getData] = useState({});
  //withdraw notify
  const notifyS = (data) => toast.success(data);
  //Error withdraw notify
  const notifyE = (data) => toast.error(data);

  useEffect(() => {
    dispatch(withdraw_method_data());
  }, [dispatch]);

  const change_withdraw = (e) => {
    const { name, value } = e.target;
    getData1({
      ...data1,
      member_id: user,
      submit: "withdraw",
      withdraw_method: Payment,
      [name]: value,
    });
  };

  // withdraw change
  const change_withdraw1 = (e) => {
    const { name, value } = e.target;
    getData({
      ...data,
      [name]: value,
    });
    setPayment(value);
    //change the status of payment
    if (value === "PayPal") {
      setDisplay(true);
      setDisplay2(false);
    } else {
      setDisplay(false);
      setDisplay2(true);
    }
    if (value === "In Account") {
      setDisplay1(true);
      setDisplay2(false);
    } else {
      setDisplay1(false);
    }
    setMessage(true);
  };

  // withdraw on submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    var user = JSON.parse(localStorage.getItem("user"));
    console.log(data1);
    var response = await fetch(`/api/withdraw`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data1),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      notifyS(res_data.message);
      setTimeout(() => {
        history.push("/account/my_wallet");
      }, 3000);
    } else {
      notifyE(res_data.message);
    }
  };
  var res = res_withdrawmethod.data;

  return (
    <>
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Title props={t("text_add_money")} />
      <MainTheme title={t("text_withdraw_money")} backPath>
        {res_withdrawmethod.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <Scrollbars>
            <div className="bm-content-listing p-4">
              <div className="profile-content text-black ">
                <form
                  className="contanier  mt-2"
                  id="withdraw-form"
                  onSubmit={handleSubmit}
                >
                  <div className="form-group row">
                    {/* Mobile number */}
                    <div
                      className={
                        "col-12 mt-0  mobile_no-div " +
                        (display2 ? "d-block" : "d-none")
                      }
                    >
                      <label htmlFor="pyatmnumber">
                        {t("text_mobile_no")}
                        <span className="required" aria-required="true">
                          *&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        id="pyatmnumber"
                        name="pyatmnumber"
                        className="form-control border-bottom rounded-0 profile"
                        value={data1.pyatmnumber}
                        onChange={change_withdraw}
                      />
                    </div>
                    {/* UPI ID */}
                    <div
                      className={
                        "col-12 mt-0 upi-div " +
                        (display1 ? "d-block" : "d-none")
                      }
                    >
                      <label htmlFor="pyatmnumber">
                        {t("text_upi_id")}
                        <span className="required" aria-required="true">
                          *&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        id="upi"
                        name="pyatmnumber"
                        className="form-control border-bottom rounded-0 profile"
                        value={data1.upiid}
                        onChange={change_withdraw}
                      />
                    </div>
                    {/* email */}
                    <div
                      className={
                        "col-12 mt-0 email-div  " +
                        (display ? "d-block" : "d-none")
                      }
                    >
                      <label htmlFor="email">
                        {t("text_email")}
                        <span className="required" aria-required="true">
                          &nbsp; *&nbsp;
                        </span>
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="pyatmnumber"
                        className="form-control border-bottom rounded-0 profile"
                        value={data1.email}
                        onChange={change_withdraw}
                      />
                    </div>
                    {/* amount */}
                    <div className="col-12 mt-4">
                      <label htmlFor="amount">
                        {t("text_amount")}(
                        <span>
                          <img src={coin} alt="coin-img" className="coin" />
                        </span>
                        )
                        <span className="required" aria-required="true">
                          &nbsp; *&nbsp;
                        </span>
                      </label>
                      <input
                        type="text"
                        id="amount"
                        name="amount"
                        className="form-control border-bottom rounded-0 profile"
                        onblur="change_withdraw();"
                        value={data1.amount}
                        onChange={change_withdraw}
                      />
                    </div>
                  </div>
                  {/* withdraw to */}
                  <div className="form-group row mt-3">
                    <div className="col-6">
                      <label htmlFor="gender">
                        {t("text_withdraw_to")} :&nbsp;
                      </label>
                      {res.withdraw_method.map((res, index) => {
                        return (
                          <div
                            className="custom-control custom-radio ml-5"
                            key={index}
                          >
                            <input
                              id="Paytm"
                              name="withdraw_method"
                              type="radio"
                              className="custom-control-input"
                              value={res.withdraw_method}
                              onChange={change_withdraw1}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="Paytm"
                            >
                              {res.withdraw_method}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* amount message */}
                  {message &&
                    res.withdraw_method
                      .filter(
                        (res1) => res1.withdraw_method === data.withdraw_method
                      )
                      .map((res) => (
                        <div className="form-group bm_text_lightgreen getcurrency_div">
                          &nbsp;
                          {ReactHtmlParser(t("text_you_will_get"))}
                          &nbsp;
                          {res.currency_symbol +
                            " " +
                            data1.amount / res.withdraw_method_currency_point}
                          &nbsp;
                        </div>
                      ))}
                  {/* withdraw money submit button */}
                  <div className="form-group row">
                    <div className="col-12">
                      <button
                        type="submit"
                        value="Withdraw Money"
                        name="withdraw_money"
                        className="btn btn-lg form-control p-1 btn-block btn-green text-uppercase"
                      >
                        {t("text_withdraw_money")}
                      </button>
                    </div>
                  </div>
                </form>
                {/* withdraw money note */}
                <div className="text-danger my-2 text-center">
                  <strong>{t("text_image_note")} : </strong>
                  {t("text_withdraw_money_note")}
                </div>
              </div>
            </div>
          </Scrollbars>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res_withdrawmethod: state.withdrawmethod,
});

export default connect(mapStateToProps)(Withdraw);
