import { useEffect, React } from "react";
import { connect } from "react-redux";
import { Single_match_data } from "../../../../store/Action/UserFatchData";
import UserHeader from "../../theme/header";
import MainTheme from "../../theme/MainTheme";
import Title from "../../../../views/common/Helmet";
import { useParams, useLocation } from "react-router-dom";
import Description from "././ongoing_detail/description";
import JoinedMember from "././ongoing_detail/joined_member";
import OngoingFooter from "././ongoing_detail/ongoing_footer";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Ongoing_Match = ({ dispatch, res }) => {
  var location = useLocation();
  var { id } = useParams();

  useEffect(() => {
    dispatch(Single_match_data(id));
  }, [dispatch, id]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_my_matches")} />
      <MainTheme>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <UserHeader title={data.match.match_name} backPath />
            <div className="bm-mdl-center bm-full-height">
              <div className="bm-content-listing">
                <div className="match-info">
                  <img
                    src={location.query}
                    alt="match-banner"
                    className="img-fluid card-img-top"
                  />
                  <div className="tab-section single-game mb-0">
                    <ul className="nav nav-tabs">
                      <li className="nav-item" style={{ width: "50%" }}>
                        {/* description tab  */}
                        <a
                          className="nav-link text-uppercase active"
                          data-toggle="tab"
                          href="#description"
                        >
                          {t("text_description")}
                        </a>
                      </li>
                      <li className="nav-item" style={{ width: "50%" }}>
                        {/* joined member tab  */}
                        <a
                          className="nav-link text-uppercase"
                          data-toggle="tab"
                          href="#join"
                        >
                          {t("text_joined_member")}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      {/* description tab  */}
                      <div
                        className="tab-pane fade show active"
                        id="description"
                        role="tabpanel"
                        aria-labelledby="description-tab"
                      >
                        {/* description component and passing the props */}
                        <Description data={data} />
                      </div>
                      {/* joined member tab  */}
                      <div
                        className="tab-pane fade"
                        id="join"
                        role="tabpanel"
                        aria-labelledby="join-tab"
                      >
                        <JoinedMember />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <OngoingFooter data={data} />
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.singlematch,
});

export default connect(mapStateToProps)(Ongoing_Match);
