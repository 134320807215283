import React from "react";
import { t } from "i18next";

const Download = ({ props }) => {
  return (
    <>
      {/* button of download now  */}
      <section className="text-center bm-bg-lightpink" id="download">
        <div className="container">
          <div className="row">
            <div className=" col-md-4 col-sm-12 py-3 mx-auto">
              <a className="bm-btn-white btn-block" href={props.apk_url}>
                {t("text_download_now")}!
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Download;
