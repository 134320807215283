import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Add_money_data } from "../../../store/Action/FatchData";
import MainTheme from "../theme/MainTheme";
import { useLocation, useHistory } from "react-router-dom";
import copy from "copy-to-clipboard";
import CountdownTimer from "react-component-countdown-timer";
import { QRCodeCanvas } from "qrcode.react";
import { t } from "i18next";

const Tron = ({ res, dispatch }) => {
  const history = useHistory();
  const location = useLocation();

  // manage state
  const [copyWord, setCopy] = useState("");

  useEffect(() => {
    dispatch(Add_money_data(location.state));
  }, [dispatch, location.state]);
  var data = res.data;

  // Clipboard
  const clipBoard = () => {
    copy(data.wallet_address);
    setCopy("copy the clipboard");
    setTimeout(() => {
      setCopy("");
    }, 2000);
  };

  return (
    <>
      <MainTheme title={t("text_add_money")}>
        <div className="row d-flex justify-content-md-center">
          <div className="col-md-6">
            {/* card */}
            <div className="card bg-light text-dark">
              <div className="card-header">
                <strong>{t("text_add_money")}</strong>
              </div>
              <div className="card-body text-center">
                <div className="row">
                  <div className="form-group col-md-12">
                    <div className="mb-2"> {t("text_tron_scan_note")}</div>
                    {/* Timer */}
                    <CountdownTimer
                      count={900}
                      border={false}
                      showTitle={false}
                      noPoints={false}
                      responsive={true}
                      hideDay={true}
                      hideHours={true}
                      onEnd={() => {
                        history.goBack();
                      }}
                    />
                  </div>
                  <div className="form-group col-md-12 mt-3">
                    <div id="qrcode">
                      {/* QR code  */}
                      <QRCodeCanvas
                        value={data.wallet_address}
                        style={{ width: "185px", height: "185px" }}
                      />
                    </div>
                    <h6
                      id="contract_address"
                      className="mt-4"
                      onClick={clipBoard}
                      style={{
                        cursor: "pointer",
                        wordBreak: "break-all",
                        fontSize: "15px",
                      }}
                    >
                      {data.wallet_address}&nbsp;
                      <i className="fa fa-copy ml-3"></i>
                    </h6>
                    <br />
                    <span
                      className="copied text-white bg-black rounded px-2"
                      style={{
                        position: "absolute",
                        left: "35%",
                        zIndex: "10",
                        bottom: "0px",
                      }}
                    >
                      {copyWord}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.addmoney,
});

export default connect(mapStateToProps)(Tron);
