import { React, useEffect } from "react";
import MainTheme from "../theme/MainTheme";
import UserHeader from "../theme/header";
import { All_game } from "../../../store/Action/UserFatchData";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import OngoingTab from "./ongoingTab";
import UpcomingTab from "./upcomingTab";
import ResultTab from "./resultTab";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Game_pages = ({ dispatch, res }) => {
  const params = useParams();
  useEffect(() => {
    dispatch(All_game());
  }, [dispatch]);
  var data = res.data;

  var name;
  data.all_game &&
    data.all_game.map((data) => {
      if (data.game_id == params.id) {
        name = data.game_name;
      }
    });

  return (
    <>
      <MainTheme>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <UserHeader title={name} backPath />
            <div className="bm-mdl-center bm-full-height pb-0">
              <div className="tab-section single-game mb-0">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    {/* ongoing tab  */}
                    <a
                      className="nav-link text-uppercase active"
                      data-toggle="tab"
                      href="#ongoing"
                    >
                      {t("text_ongoing")}
                    </a>
                  </li>
                  <li className="nav-item">
                    {/* upcoming tab  */}
                    <a
                      className="nav-link text-uppercase "
                      data-toggle="tab"
                      href="#upcoming"
                    >
                      {t("text_upcoming")}
                    </a>
                  </li>
                  <li className="nav-item">
                    {/* result tab  */}
                    <a
                      className="nav-link text-uppercase"
                      data-toggle="tab"
                      href="#result"
                    >
                      {t("text_results")}
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  {/* ongoing  */}
                  <div
                    className="tab-pane fade show active"
                    id="ongoing"
                    role="tabpanel"
                    aria-labelledby="ongoing-tab"
                  >
                    <Scrollbars>
                      <OngoingTab />
                    </Scrollbars>
                  </div>
                  {/* upcoming  */}
                  <div
                    className="tab-pane fade"
                    id="upcoming"
                    role="tabpanel"
                    aria-labelledby="upcoming-tab"
                  >
                    <Scrollbars>
                      <UpcomingTab />
                    </Scrollbars>
                  </div>
                  {/* result */}
                  <div
                    className="tab-pane fade"
                    id="result"
                    role="tabpanel"
                    aria-labelledby="result"
                  >
                    <Scrollbars>
                      <ResultTab />
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.All_game,
});

export default connect(mapStateToProps)(Game_pages);
