import React from "react";
import { MyLeaderBoardData } from "../../../store/Action/UserFatchData";
import Title from "../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import MainTheme from "../theme/MainTheme";
import { connect } from "react-redux";
import { useEffect } from "react";
import { t } from "i18next";

const Leader_board = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(MyLeaderBoardData());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_leaderboard")} />
      <MainTheme title="Leader-Board" backPath>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <div className="static-table">
            {/* table leaderboard */}
            <table className="table table-responsive bg-white text-center">
              <thead className="btn-green">
                <tr>
                  <th style={{ width: "50%" }}>{t("text_user_name")}</th>
                  <th style={{ width: "50%" }}>{t("text_total_referral")}</th>
                </tr>
              </thead>
              {/* table body */}
              <tbody className="text-black">
                {data.leader_board.map((data, index) => (
                  <tr key={index}>
                    <td style={{ width: "2%" }}>{data.user_name}</td>
                    <td style={{ width: "50%" }}>{data.tot_referral}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </MainTheme>
    </>
  );
};

//redux connect
const mapStateToProps = (state) => ({
  res: state.my_leaderboard,
});

export default connect(mapStateToProps)(Leader_board);
