import { React, useEffect, useState } from "react";
import UserHeader from "../../theme/userHeader";
import { useLocation, useHistory } from "react-router-dom";
import Title from "../../../../views/common/Helmet";
import Swal from "sweetalert2";
import { t } from "i18next";

const Images = () => {
  const $ = window.$;
  const location = useLocation();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  // manage state
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [danger, setDanger] = useState(false);
  const [selected, setSelected] = useState("");
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (location.state) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  // delete
  const handleDelete = (id, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>You won't be able to revert this?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var response = await fetch(`/api/delete_image/${id}`, {
          method: "POST",
          headers: {
            Authorization: user.api_token,
          },
        });
        var res_data = await response.json();
        table1.ajax.reload(null, false);
        setShow(true);
        setMessage(res_data.message);
        setDanger(false);
      }
    });
  };

  // delete multi game
  const handleMultiDelete = (id, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>You won't be able to revert this?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const value = {
          action: "delete",
          image_id: id,
        };
        var response = await fetch(`/api/change_image_multi_status`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
          body: JSON.stringify(value),
        });
        var res_data = await response.json();
        table1.ajax.reload(null, false);
        setShow(true);
        setMessage(res_data.message);
        setDanger(false);
        setSelected("");
        setCheck(false);
      } else if (result.dismiss == "cancel") {
        table1.ajax.reload(null, false);
        setSelected("");
        setCheck(false);
      }
    });
  };

  // edit
  const handleEdit = (id) => {
    history.push(`/admin/images/edit/${id}`);
  };

  // table imagetable
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#imagetable");
      var table1 = table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: "/api/get_image",
          headers: {
            Authorization: user.api_token,
          },
          type: "POST",
          dataSrc: "all_image",
        },
        columns: [
          { data: null, sortable: false },
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          { data: "image_title" },
          {
            data: "image_name",
            render: function (data, type, row) {
              return '<img src="' + data + '" />';
            },
          },
          {
            data: "image_id",
            render: function (data, type, row) {
              if (demouser === "1" && data <= 2) {
                return (
                  '<i class="fa fa-edit text-lightpink fa-lg" id="edit-btn"></i>' +
                  '<i class="fa fa-trash-o text-lightblue fa-lg ps-1 disabled"></i>'
                );
              } else {
                return (
                  '<i class="fa fa-edit text-lightpink fa-lg pointer" id="edit-btn"></i>' +
                  '<i class="fa fa-trash-o text-lightblue fa-lg ps-1 pointer" aria-hidden="true" id="del-btn"></i>'
                );
              }
            },
          },
        ],
        columnDefs: [
          {
            orderable: false,
            className: "select-checkbox",
            checkboxes: true,
            targets: 0,
            render: function (data, type, full, meta) {
              return (
                '<input type="checkbox"  value="' +
                $("<div/>").text(data.image_id).html() +
                '">'
              );
            },
          },
        ],
        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[2, "asc"]],
        Destroy: true,
      });
      $("#imagetable tbody").on("click", "#edit-btn", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().image_id;
        handleEdit(id);
      });
      $("#imagetable tbody").on("click", "#del-btn", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().image_id;
        handleDelete(id, table1);
      });
      // Handle click on "Select all" control
      $("#checkall").on("click", function () {
        // Check/uncheck all checkboxes in the table
        var rows = table1.rows().nodes();
        $('input[type="checkbox"]', rows).prop("checked", this.checked);
      });
      var arr = [];
      $("#multi_action").on("change", function () {
        table1.$('input[type="checkbox"]').each(function () {
          // If checkbox doesn't exist in DOM
          var val = $("#multi_action option:selected").text();
          if ($.contains(document, this)) {
            // If checkbox is checked
            if (this.checked) {
              arr.push(this.value);
            }
          }
          if (val == "Delete") {
            if (arr.length === 0) {
              setShow(true);
              setMessage("please do select records");
              setDanger(true);
            } else {
              handleMultiDelete(arr, table1);
            }
          }
        });
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      <Title props={t("text_image")} />
      <UserHeader
        title={t("text_image")}
        path={"/admin/add_images"}
        icon={<i className="fa fa-plus"></i>}
        btnText={t("text_add_image")}
      />
      <div className="row">
        <div className="col-md-12">
          <div
            class={
              "alert alert-dismissible fade show " +
              (show ? "d-block " : "d-none ") +
              (danger ? "alert-danger" : "alert-success")
            }
            role="alert"
          >
            {location.state && !message ? (
              <span>{location.state.state.data}</span>
            ) : (
              <span></span>
            )}
            {!location.state && message ? (
              <span>{message}</span>
            ) : (
              <span></span>
            )}
            {location.state && message ? <span>{message}</span> : <span></span>}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
          <div className="card-body">
            <table
              id="imagetable"
              className="display table table-bordered mb-2"
            >
              <thead className="mt-5">
                <tr>
                  <th colSpan={10}>
                    <label htmlFor="action">
                      {t("text_actions_performed")} :&nbsp;
                    </label>
                    <select
                      name="action"
                      id="multi_action"
                      value={selected}
                      onChange={(e) => setSelected(e.target.value)}
                    >
                      <option value="">{t("text_select")}</option>
                      <option value="Delete">{t("text_delete")}</option>
                    </select>
                  </th>
                </tr>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="checkall"
                      id="checkall"
                      checked={check}
                      onChange={(e) => setCheck(e.target.value)}
                    />
                  </th>
                  <th>{t("text_sr_no")}</th>
                  <th>{t("text_image_title")}</th>
                  <th>{t("text_image")}</th>
                  <th>{t("text_view")}</th>
                </tr>
              </thead>
              <tbody></tbody>
              <tfoot>
                <tr>
                  <th></th>
                  <th>{t("text_sr_no")}</th>
                  <th>{t("text_image_title")}</th>
                  <th>{t("text_image")}</th>
                  <th>{t("text_view")}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Images;
