import React from "react";
import { useLocation } from "react-router-dom";

function PayTm() {
  const location = useLocation();
  console.log("loc", location);
  const makePayment = async (e) => {
    e.preventDefault();
    try {
      var amount = "1.00";
      var mobile_number = "+919999999999";
      var email = "abcd@gmail.com";
      var orderId = "ORDER_ID" + new Date().getTime();
      let params = {
        orderId: orderId,
        email: email,
        amount: amount,
        mobile_number: mobile_number,
      };
      var url = "api/paytm_response";
      var request = {
        url: url,
        params: params,
        method: "get",
      };

      const response = await request;
      const processParams = await response.data;
      console.log(processParams);
      console.log(request);

      var details = {
        action: "https://securegw-stage.paytm.in/order/process",
        // params : params
        params: processParams,
      };

      this.post(details);
    } catch (error) {}
  };

  return (
    <div>
      <button onClick={makePayment}>Pay Now</button>
    </div>
  );
}
export default PayTm;
