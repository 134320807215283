import React from "react";
import { t } from "i18next";

const OngoingFooter = (data) => {
  return (
    <>
      <div className="bm-mdl-footer text-white ">
        <a
          rel="noreferrer"
          target="_blank"
          href={data.data.match.match_url}
          className="btn btn-sm d-block f-18 btn-lightpink text-uppercase"
        >
          {t("text_Spactate")}
        </a>
      </div>
    </>
  );
};
export default OngoingFooter;
