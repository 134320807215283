import { React, useEffect } from "react";
import Title from "../../../views/common/Helmet";
import { connect } from "react-redux";
import { Product_data } from "../../../store/Action/UserFatchData";
import MainTheme from "../theme/MainTheme";
import UserHeader from "../theme/header";
import { useParams } from "react-router-dom";
import coin from "../../../views/assets/images/coin.png";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import ReactHtmlParser from "react-html-parser";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Productdetail = ({ dispatch, res }) => {
  const { id } = useParams();

  useEffect(() => {
    dispatch(Product_data());
  }, [dispatch]);
  var data = res.data;
  if (res.loading == false) {
    var data1 = data.product.filter((data) => {
      return data.product_id === id;
    });
  }

  return (
    <>
      <Title props={t("text_product")} />
      <MainTheme>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <UserHeader title={data1[0].product_name} backPath />
            <div className="bm-mdl-center bm-full-height  ">
              <Scrollbars>
                <div className="bm-content-listing tournaments">
                  <div className="match-info">
                    <img
                      src={data1[0].product_image}
                      alt="product_image"
                      style={{ width: "100%" }}
                      className="mCS_img_loaded"
                    />
                  </div>
                  <div
                    className="px-3"
                    style={{ position: "relative", top: "-20px" }}
                  >
                    <div
                      className="card mb-2 p-2"
                      style={{ minHeight: "unset" }}
                    >
                      {/* product short description */}
                      <h6 className="text-black">
                        {data1[0].product_short_description}
                      </h6>
                      <div className="text-black">{data1[0].product_name}</div>
                      <div className="row">
                        {/* actual price */}
                        <h6 className="col-6 text-info">
                          <strike>
                            <span>
                              <img src={coin} className="coin" alt="img-coin" />
                            </span>
                            {Number(data1[0].product_actual_price).toFixed(2)}
                          </strike>
                        </h6>
                        {/* selling price */}
                        <h6
                          className="col-6 pull-right text-info "
                          style={{ textAlign: "right" }}
                        >
                          <span>
                            <img src={coin} className="coin" alt="img-coin" />
                          </span>
                          {Number(data1[0].product_selling_price).toFixed(2)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card mb-3 p-2 m-2"
                  style={{ minHeight: "unset" }}
                >
                  <h6 style={{ color: "black" }}>{t("text_description")}</h6>
                  <div className="text-black">
                    {/* convert jsx to html */}
                    {ReactHtmlParser(data1[0].product_description)}
                    <h2>{t("text_Why_do_we_use_it")}</h2>
                    {ReactHtmlParser(data1[0].product_description)}
                    &nbsp;
                    <h2>{t("text_where_does_it_come_from")}</h2>
                    {ReactHtmlParser(data1[0].product_description)}
                  </div>
                </div>
              </Scrollbars>
            </div>
            {/* footer */}
            <div className="bm-mdl-footer text-white">
              <Link
                style={{
                  display: "block",
                  width: "inherit",
                }}
                className="btn btn-sm btn-block f-18 btn-lightpink text-uppercase "
                to={{
                  pathname: `/buyform/${id}`,
                  state: {
                    title: data1[0].product_name,
                    amount: data1[0].product_selling_price,
                  },
                }}
              >
                {t("text_buy_now")}
              </Link>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};
// redux connect
const mapStateToProps = (state) => ({
  res: state.product,
});

export default connect(mapStateToProps)(Productdetail);
