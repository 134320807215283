const user = JSON.parse(sessionStorage.getItem("user"));

// Login
export function AdminLogin(data) {
  return async (dispatch) => {
    console.log(data);
    var response = await fetch(`/api/adminlogin`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      sessionStorage.setItem("status", 1);
      sessionStorage.setItem("admin_user", JSON.stringify(res_data.admin_user));
      sessionStorage.setItem("user", JSON.stringify(res_data.message));
      sessionStorage.setItem("demouser", JSON.stringify(res_data.demouser));
      window.location.href = "/admin/dashboard";
    } else {
      sessionStorage.setItem("status", 0);
    }
    var return_response = {
      type: "ADMIN_LOGIN",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//sendotp
export function AdminSendOtp(data) {
  return async (dispatch) => {
    var response = await fetch(`/api/admin_sentotp`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "ADMIN_SEND_OTP",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// permission
export function Permission(name) {
  return async (dispatch) => {
    var response = await fetch(`/api/check_permission/${name}`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "PERMISSION",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//total user
export function TotalUser() {
  return async (dispatch) => {
    var response = await fetch("/api/totaluser", {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "TOTAL_USER",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//total matches
export function TotalMatch() {
  return async (dispatch) => {
    var response = await fetch("/api/totalmatches", {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "TOTAL_MATCHES",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//total receive amount
export function TotalReceivedAmount() {
  return async (dispatch) => {
    var response = await fetch("/api/total_received_amount", {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "TOTAL_RECEIVED_AMOUNT",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//total withdraw amount
export function TotalWithdrawAmount() {
  return async (dispatch) => {
    var response = await fetch("/api/total_withdraw_amount", {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "TOTAL_WITHDRAW_AMOUNT",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get package
export function GetPackage() {
  return async (dispatch) => {
    var response = await fetch("/api/get_package", {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_PACKAGE",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get country
export function GetCountry() {
  return async (dispatch) => {
    var response = await fetch("/api/all_country");
    var res_data = await response.json();
    var return_response = {
      type: "GET_COUNTRY",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// top players
export function GetTopPlayers() {
  return async (dispatch) => {
    var response = await fetch("/api/get_top_player");
    var res_data = await response.json();
    var return_response = {
      type: "GET_TOP_PLAYERS",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// top players
export function GetImage() {
  return async (dispatch) => {
    var response = await fetch("/api/get_img");
    var res_data = await response.json();
    var return_response = {
      type: "GET_IMAGE",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get game name
export function GetGameName() {
  return async (dispatch) => {
    var response = await fetch("/api/get_game_name");
    var res_data = await response.json();
    var return_response = {
      type: "GET_GAME_NAME",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get challenges details
export function GetChallengeDetail(challenge_id) {
  return async (dispatch) => {
    var response = await fetch(`/api/get_challange_detail/${challenge_id}`, {
      headers: { Authorization: user.api_token },
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_CHALLENGE_DETAIL",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get leader board
export function GetLeaderBoard() {
  return async (dispatch) => {
    var response = await fetch("/api/get_leader_board");
    var res_data = await response.json();
    var return_response = {
      type: "GET_LEADER_BOARD",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get insert lottery
export function GetInsertLottery(lottery_id) {
  return async (dispatch) => {
    var response = await fetch(`/api/get_insert_lottery/${lottery_id}`);
    var res_data = await response.json();
    var return_response = {
      type: "GET_INSERT_LOTTERY",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get features
export function GetFeatures() {
  return async (dispatch) => {
    var response = await fetch("/api/get_feature_data", {
      headers: { Authorization: user.api_token },
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_FEATURES",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get how to play
export function GetHowToPlay() {
  return async (dispatch) => {
    var response = await fetch("/api/get_htp_data", {
      headers: { Authorization: user.api_token },
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_HOW_TO_PLAY",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get app upload
export function GetAppUpload() {
  return async (dispatch) => {
    var response = await fetch("/api/get_other_setting", {
      headers: { Authorization: user.api_token },
      method: "GET",
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_APP_UPLOAD",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get_withdraw_method
export function GetWithdrawMethod() {
  return async (dispatch) => {
    var response = await fetch("/api/get_withdraw_method");
    var res_data = await response.json();
    var return_response = {
      type: "GET_WITHDRAW_METHOD",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get_payment_method
export function GetPaymentMethod() {
  return async (dispatch) => {
    var response = await fetch("/api/get_payment_method", {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_PAYMENT_METHOD",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get permission
export function CheckAllPermission() {
  return async (dispatch) => {
    var response = await fetch(`/api/get_permission`);
    var res_data = await response.json();
    var return_response = {
      type: "CHECK_ALL_PERMISSSION",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get permission
export function CheckPermission(name) {
  return async (dispatch) => {
    var response = await fetch(`/api/check_permission/${name}`, {
      headers: { Authorization: user.api_token },
    });
    var res_data = await response.json();
    var return_response = {
      type: "CHECK_PERMISSSION",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get profile
export function ProfileData() {
  return async (dispatch) => {
    var response = await fetch("/api/get_profile_data", {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_PROFILE_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// get license
export function GetLicense() {
  return async (dispatch) => {
    var response = await fetch("/api/get_licence", {
      headers: { Authorization: user.api_token },
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_LICENSE",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// check license
export function CheckLicense() {
  return async (dispatch) => {
    var response = await fetch("/api/check_license", {
      headers: { Authorization: user.api_token },
    });
    var res_data = await response.json();
    var return_response = {
      type: "CHECK_LICENSE",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//get specific game id
export function GetGameInsertesData(id) {
  return async (dispatch) => {
    var response = await fetch(`/api/get_game_inserted_data/${id.id}`);
    var res_data = await response.json();
    var return_response = {
      type: "GET_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//game update
export function GameUpdateDetail(data, id) {
  return async (dispatch) => {
    var response = await fetch(`/api/game_update_details/${id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "GAME_UPDATE",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//game add
export function GameAddDetail(data) {
  return async (dispatch) => {
    var response = await fetch(`/api/game_insert_data`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "GAME_ADD",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//match room update
export function RoomUpdateDetail(data, id) {
  return async (dispatch) => {
    var response = await fetch(`/api/update_match_details/${id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "ROOM_UPDATE",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//get the specific match
export function GetMatchInsertesData(id) {
  return async (dispatch) => {
    var response = await fetch(`/api/get_match_inserted_data/${id}`);
    var res_data = await response.json();
    var return_response = {
      type: "GET_SPECIFIC_MATCH",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//get the specific order details
export function GetOrderInsertesData(id) {
  return async (dispatch) => {
    var response = await fetch(`/api/get_order_detail/${id}`, {
      headers: { Authorization: user.api_token },
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_SPECIFIC_ORDER",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//get the lottery member details
export function GetLotteryMemberData(id) {
  return async (dispatch) => {
    var response = await fetch(`/api/view_lottery_member/${id}`);
    var res_data = await response.json();
    var return_response = {
      type: "GET_LOTTERY_MEMBER",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//tracking info update
export function CourierUpdateDetail(id, data) {
  return async (dispatch) => {
    var response = await fetch(`/api/update_courier_details/${id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "COURIER_UPDATE",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//tracking info update
export function OrderStatusDetail(id, data) {
  return async (dispatch) => {
    var response = await fetch(`/api/change_order_status/${id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "ORDER_STATUS",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//tracking info update
export function updateLotteryMemberDetail(id, data) {
  return async (dispatch) => {
    var response = await fetch(`/api/update_lottery_member/${id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
    var return_response = {
      type: "UPDATE_LOTTERY_MEMBER",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//get all member details
export function GetMemberData(id) {
  return async (dispatch) => {
    var response = await fetch(`/api/member_details/${id}`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_MEMBER_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//get all member details
export function GetMemberJoinData(id) {
  return async (dispatch) => {
    var response = await fetch(`/api/get_match_inserted_data/${id}`, {
      headers: { Authorization: user.api_token },
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_MEMBER_JOIN_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//withdraw request data
export function withdrawDetail() {
  return async (dispatch) => {
    var response = await fetch(`/api/get_withdraw_method`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "WITHDRAW_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//withdraw request data
export function getTopPlayerDetail() {
  return async (dispatch) => {
    var response = await fetch(`/api/get_top_player`, {
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "TOP_PLAYER_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//get game details
export function GetGameData() {
  return async (dispatch) => {
    var response = await fetch(`/api/get_game_name`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_GAME_DATA",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//get member position
export function GetMemberPosition(id) {
  return async (dispatch) => {
    var response = await fetch(`/api/get_member_position/${id}`, {
      headers: {
        Authorization: user.api_token,
      },
      method: "POST",
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_MEMBER_POSITION",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//get member list
export function GetMemberList() {
  return async (dispatch) => {
    var response = await fetch(`/api/get_member_list`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_MEMBER_LIST",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

// download result
export function DownloadResult(id) {
  return async (dispatch) => {
    var response = await fetch(`/api/challange_down_result/${id}`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "DOWNLOAD_RESULT",
      payload: res_data,
    };
    dispatch(return_response);
  };
}
// edit position
export function MemberEditPosition() {
  return async (dispatch) => {
    var response = await fetch(`/api/edit_member_position`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    var return_response = {
      type: "MEMBER_EDIT",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//game match
export function GetGameMatch(match_id, member_id) {
  return async (dispatch) => {
    var response = await fetch(
      `/api/join_match_single/${match_id}/${member_id}`
    );
    var res_data = await response.json();
    var return_response = {
      type: "GET_GAME_MATCH",
      payload: res_data,
    };
    dispatch(return_response);
  };
}

//all user
export function GetAllUser() {
  return async (dispatch) => {
    var response = await fetch(`/api/get_user_name`, {
      headers: { Authorization: user.api_token },
    });
    var res_data = await response.json();
    var return_response = {
      type: "GET_ALL_USER",
      payload: res_data,
    };

    dispatch(return_response);
  };
}
