import { React, useEffect } from "react";
import { TotalUser } from "../../../store/Action/AdminData";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const TotalUsers = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(TotalUser());
  }, [dispatch]);
  const data = res.data;

  return (
    <>
      {res.loading ? (
        <div className="loader">
          <Spinner />
        </div>
      ) : (
        <div className="col-lg-3 col-md-6 dash-box pointer">
          <div className="bg-lightpink small-box card card-sm-3">
            <div className="card-icon ">
              <i className="fa fa-user"></i>
            </div>
            <div className="card-wrap">
              <div className="card-header">
                <h4>{t("text_total_user")}</h4>
              </div>
              <div className="card-body">{data.total_user}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.TotalUser,
});

export default connect(mapStateToProps)(TotalUsers);
