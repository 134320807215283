import React, { useEffect } from "react";
import UserHeader from "../../theme/userHeader";
import coin from "../../../assets/images/coin.png";
import MemberDetail from "./Member_detail";
import WalletDetail from "./wallet_detail";
import WalletHistoryTable from "./walletHistory_table";
import MyStasticsTable from "./myStastics_table";
import ReferralTable from "./Referral_table";
import { connect } from "react-redux";
import { GetMemberData } from "../../../../store/Action/AdminData";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import { t } from "i18next";

const Member = ({ dispatch, res }) => {
  const params = useParams();
  useEffect(() => {
    dispatch(GetMemberData(params.id));
  }, [dispatch, params.id]);
  var data = res.data;

  return (
    <>
      {/* Header */}
      <UserHeader title={t("text_member")} />
      {res.loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="row">
            {/* match played */}
            <div className="col-lg-3 col-md-6 dash-box pointer">
              <div className="bg-lightpink small-box card card-sm-3">
                <div className="card-icon ">
                  <i className="fa fa-gamepad"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header">
                    <h4>{t("text_match_played")}</h4>
                  </div>
                  <div className="card-body">
                    <img src={coin} alt="coin-img" className="coin" />
                    &nbsp;
                    {data.total_match.map((data) => {
                      return data.total_match;
                    })}
                  </div>
                </div>
              </div>
            </div>
            {/* total win */}
            <div className="col-lg-3 col-md-6 dash-box pointer">
              <div className="bg-lightgreen small-box card card-sm-3">
                <div className="card-icon ">
                  <i className="fa fa-gamepad"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header">
                    <h4>{t("text_total_win")}</h4>
                  </div>
                  <div className="card-body">
                    <img src={coin} alt="coin-img" className="coin" />
                    &nbsp;
                    {data.total_win.map((data) => {
                      return data.total_win;
                    })}
                  </div>
                </div>
              </div>
            </div>
            {/* win balance */}
            <div className="col-lg-3 col-md-6 dash-box pointer">
              <div className="bg-lightblue small-box card card-sm-3">
                <div className="card-icon ">
                  <i className="fa fa-gamepad"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header">
                    <h4>{t("text_win_balance")}</h4>
                  </div>
                  <div className="card-body">
                    <img src={coin} alt="coin-img" className="coin" />
                    &nbsp;
                    {data.win_balance.map((data) => {
                      return data.wallet_balance;
                    })}
                  </div>
                </div>
              </div>
            </div>
            {/* total balance */}
            <div className="col-lg-3 col-md-6 dash-box pointer">
              <div className="bg-lightpink small-box card card-sm-3">
                <div className="card-icon ">
                  <i className="fa fa-gamepad"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header">
                    <h4>{t("text_total_balance")}</h4>
                  </div>
                  <div className="card-body">
                    <img src={coin} alt="coin-img" className="coin" />
                    &nbsp;
                    {data.total_balance.map((data) => {
                      return data.total_bal;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MemberDetail props={data.member_data} props1={data.pubg_id} />
          <WalletDetail />
          <WalletHistoryTable />
          <MyStasticsTable />
          <ReferralTable />
        </>
      )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.member,
});

export default connect(mapStateToProps)(Member);
