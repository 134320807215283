import { React, useState } from "react";
import UserHeader from "../theme/userHeader";
import { useHistory, useLocation } from "react-router-dom";
import coin from "../../assets/images/coin.png";
import Title from "../../../views/common/Helmet";
import Swal from "sweetalert2";
import { t } from "i18next";

const MoneyOrder = () => {
  const $ = window.$;
  const history = useHistory();
  const location = useLocation();
  const user = JSON.parse(sessionStorage.getItem("user"));

  //manage state
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  //change the order status
  const handleStatus = (id, status, table1) => {
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" + "<p>Are you sure to change status?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = {
          deposit_status: status,
        };
        var response = await fetch(`/api/change_money_order_status/${id}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
          body: JSON.stringify(result),
        });
        var res_data = await response.json();
        console.log(res_data);
        if (res_data) {
          setShow(true);
          setMessage(res_data.message);
          table1.ajax.reload(null, false);
        }
      }
    });
  };

  //member detail
  const handleMember = (id) => {
    history.push(`/admin/all_users/user_details/${id}`);
  };

  // money_order table
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#money");
      var table1 = table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: "/api/get_money_order",
          headers: {
            Authorization: user.api_token,
          },
          type: "POST",
          dataSrc: "money_order",
        },
        columns: [
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          { data: "deposit_id" },
          {
            data: "user_name",
            render: function (data, type, row) {
              return (
                "<a class='text-lightpink pointer' id='user'>" + data + "</a>"
              );
            },
          },
          { data: "deposit_amount" },
          { data: "deposit_by" },
          {
            data: "deposit_status",
            render: function (data, type, row) {
              var deposit_by = row.deposit_by;

              if (data == 0 && deposit_by == "Offline") {
                return "<select id='btn-status'><option value='0'>pending</option><option value='1'>Complete</option><option value='2'>Failed</option></select>";
              } else if (data == 1) {
                return "<span class='badge badge-success'>Complete</span>";
              } else if (data == 2) {
                return "<span class='badge bg-danger'>Failed</span>";
              } else {
                return "<span class='badge bg-warning'>pending</span>";
              }
            },
          },
          { data: "bank_transection_no" },
          { data: "deposit_dateCreated" },
        ],
        Destroy: true,
      });
      // user link
      $("#money tbody").on("click", "#user", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().member_id;
        handleMember(id);
      });
      // change status
      $("#money tbody").on("change", "#btn-status", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().deposit_id;
        const status = $("#btn-status option:selected").val();
        handleStatus(id, status, table1);
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      <Title props={t("text_money_orders")} />
      <UserHeader title={t("text_money_orders")} />
      <div
        className={
          "alert alert-success alert-dismissible fade show " +
          (show ? "d-block" : "d-none")
        }
        role="alert"
      >
        {location.state && !message ? (
          <span>{location.state.state.data}</span>
        ) : (
          <span></span>
        )}
        {!location.state && message ? <span>{message}</span> : <span></span>}
        {location.state && message ? <span>{message}</span> : <span></span>}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card-body">
            <table id="money" className="display table table-bordered mb-2">
              <thead className="mt-5">
                <tr>
                  <th>{t("text_sr_no")}</th>
                  <th>{t("text_order_no")}</th>
                  <th>{t("text_user_name")}</th>
                  <th>
                    {t("text_amount")}
                    {"("}
                    <img src={coin} className="coin" alt="img-coin" />
                    {")"}
                  </th>
                  <th>{t("text_deposit_by")}</th>
                  <th>{t("text_status")}</th>
                  <th>{t("text_transaction_no")}</th>
                  <th>{t("text_date")}</th>
                </tr>
              </thead>
              <tbody></tbody>
              <tfoot>
                <tr>
                  <th>{t("text_sr_no")}</th>
                  <th>{t("text_order_no")}</th>
                  <th>{t("text_user_name")}</th>
                  <th>
                    {t("text_amount")}
                    {"("}
                    <img src={coin} className="coin" alt="img-coin" />
                    {")"}
                  </th>
                  <th>{t("text_deposit_by")}</th>
                  <th>{t("text_status")}</th>
                  <th>{t("text_transaction_no")}</th>
                  <th>{t("text_date")}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoneyOrder;
