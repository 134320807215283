import { React } from "react";
import UserHeader from "../theme/userHeader";
import TotalUsers from "./total_users";
import TotalMatches from "./total_matches";
import TotalReceivedAmounts from "./total_received_amounts";
import TotalWithdrawAmounts from "./total_withdraw_amounts";
import ContactUsSetting from "./contact_us_setting";
import Title from "../../../views/common/Helmet";
import { t } from "i18next";

const Dashboard = () => {
  return (
    <>
      <Title props={t("text_dashboard")} />
      <>
        <UserHeader title={t("text_dashboard")} />
      </>
      <>
        <div className="row">
          <>
            <TotalUsers />
            <TotalMatches />
            <TotalReceivedAmounts />
            <TotalWithdrawAmounts />
            <ContactUsSetting />
          </>
        </div>
      </>
    </>
  );
};

export default Dashboard;
