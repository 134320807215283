import { useEffect, useState, React } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
  MyProfileData,
  UpdateMyProfile,
  ChangePassword,
} from "../../../store/Action/UserFatchData";
import { GetCountry } from "./../../../store/Action/FatchData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import MainTheme from "../theme/MainTheme";
import Title from "../../common/Helmet";
import { t } from "i18next";

const MyProfile = ({
  dispatch,
  all_country_data,
  res,
  res_update_profile,
  res_change_password,
}) => {
  // manage states
  const [input, setInput] = useState({});
  const [password, showpassword] = useState(false);
  const [webTemplate, setWebTemplate] = useState("");
  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });

  const status = localStorage.getItem("view_status");
  console.log("sr", status);

  // notify function for toast
  const notify = () =>
    toast.success(t("translation1:text_succ_change_password"));
  const notify1 = () => toast.success(t("translation1:text_succ_profile"));

  const handleInput = (event) => {
    var name = event.target.name;
    if (name === "profile_image") var value = event.target.files[0];
    else value = event.target.value;
    setInput((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    dispatch(MyProfileData());
    dispatch(GetCountry());
  }, [dispatch]);
  var data = res.data;

  //change the view
  const handleView = (e) => {
    const value = e.target.value;
    setWebTemplate(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    //profile data store in formdata
    formData.append("member_id", data.my_profile.member_id);
    formData.append(
      "first_name",
      input.first_name ? input.first_name : data.my_profile.first_name
    );
    formData.append(
      "last_name",
      input.last_name ? input.last_name : data.my_profile.last_name
    );
    formData.append(
      "user_name",
      input.user_name ? input.user_name : data.my_profile.user_name
    );
    formData.append(
      "email_id",
      input.email_id ? input.email_id : data.my_profile.email_id
    );
    formData.append(
      "country_code",
      input.country_code ? input.country_code : data.my_profile.country_code
    );
    formData.append(
      "mobile_no",
      input.mobile_no ? input.mobile_no : data.my_profile.mobile_no
    );
    formData.append(
      "gender",
      input.gender ? input.gender : data.my_profile.gender
    );
    formData.append("dob", input.dob ? input.dob : data.my_profile.dob);
    formData.append(
      "profile_image",
      input.profile_image ? input.profile_image : ""
    );
    formData.append("submit", "save");
    dispatch(UpdateMyProfile(formData));
    if (webTemplate == t("text_admin_view")) {
      localStorage.setItem("view_status", 1);
      window.location.reload()
    } else {
      localStorage.setItem("view_status", 0);
      window.location.reload()
    }
    notify1();
  };

  function handleFormValidation() {
    const { oldpass, newpass, confpass } = input;
    let formErrors = {};
    let formIsValid = true;
    if (!oldpass) {
      formIsValid = true;
      formErrors["oldpassErr"] = t("translation2:err_old_password_req");
    }

    if (!newpass) {
      formIsValid = true;
      formErrors["newpassErr"] = t("translation2:err_new_password_req");
    }
    if (newpass && newpass.length < 6) {
      formIsValid = true;
      formErrors["newpassErr"] = t("translation2:err_password_min");
    }
    if (!confpass) {
      formIsValid = true;
      formErrors["confpassErr"] = t("translation2:err_c_passowrd_req");
    }
    if (confpass && confpass.length < 6) {
      formIsValid = true;
      formErrors["confpassErr"] = t("translation2:err_c_passowrd_equal");
    }
    if (
      newpass &&
      newpass > 6 &&
      confpass &&
      confpass.length > 6 &&
      newpass != confpass
    ) {
      formIsValid = true;
      formErrors["confpassErr"] = t("translation2:err_c_passowrd_equal");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }
  //change password function
  const handleChangePassword = (event) => {
    event.preventDefault();
    handleFormValidation();
    const ChangePassFormData = new FormData();

    ChangePassFormData.append("member_id", data.my_profile.member_id);
    ChangePassFormData.append("oldpass", input.oldpass ? input.oldpass : "");
    ChangePassFormData.append("newpass", input.newpass ? input.newpass : "");
    ChangePassFormData.append("confpass", input.confpass ? input.confpass : "");
    ChangePassFormData.append("submit", "reset");

    dispatch(ChangePassword(ChangePassFormData), [dispatch]);

    notify();
  };

  return (
    <>
      {/* Container for Notify */}
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Title props={t("text_my_profile")} />
      <MainTheme title={t("text_my_profile")}>
        {res.loading || all_country_data.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            {/* Update Profile */}
            <div className="row mb-4" id="profile_setting">
              <div className="col-md-12">
                <div className="card bg-light text-dark">
                  <div className="card-header">
                    <strong>{t("text_edit_profile")}</strong>
                  </div>
                  <div className="card-body">
                    <div className="col-md-12">
                      <form id="profile-form" onSubmit={handleSubmit}>
                        <div className="row">
                          {/* First Name */}
                          <div className="form-group col-md-6 mb-3">
                            <label htmlFor="first_name" className="form_label">
                              {t("text_first_name")}
                              <span className="required" aria-required="true">
                                &nbsp;*&nbsp;
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="first_name"
                              defaultValue={data.my_profile.first_name}
                              onChange={handleInput}
                            />
                          </div>

                          {/* Last Name */}
                          <div className="form-group col-md-6 mb-3">
                            <label htmlFor="last_name" className="form_label">
                              {t("text_last_name")}
                              <span className="required" aria-required="true">
                                &nbsp;*&nbsp;
                              </span>
                            </label>
                            <input
                              id="last_name"
                              type="text"
                              className="form-control"
                              name="last_name"
                              defaultValue={data.my_profile.last_name}
                              onChange={handleInput}
                            />
                          </div>

                          {/* User Name */}
                          <div className="form-group col-md-6 mb-3">
                            <label htmlFor="user_name" className="form_label">
                              {t("text_user_name")}
                              <span className="required" aria-required="true">
                                &nbsp;*&nbsp;
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="user_name"
                              defaultValue={data.my_profile.user_name}
                              readOnly
                            />
                          </div>

                          {/* Email Id */}
                          <div className="form-group col-md-6 mb-3">
                            <label htmlFor="email_id" className="form_label">
                              {t("text_email")}
                              <span className="required" aria-required="true">
                                &nbsp;*&nbsp;
                              </span>
                            </label>
                            <input
                              id="email_id"
                              type="text"
                              className="form-control"
                              name="email_id"
                              defaultValue={data.my_profile.email_id}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row">
                          {/* country */}
                          <div className="form-group col-md-2 mb-3">
                            <label
                              htmlFor="country_code"
                              className="form_label"
                            >
                              {t("text_country")}
                              <span className="required" aria-required="true">
                                &nbsp;*&nbsp;
                              </span>
                            </label>
                            <select
                              className="form-control"
                              name="country_code"
                              defaultValue={data.my_profile.country_code}
                              onChange={handleInput}
                            >
                              <option value="">{t("text_select")}</option>
                              {all_country_data.data.all_country.map(
                                (country, index) => {
                                  return (
                                    <option key={index} value={country.p_code}>
                                      {country.p_code || ""}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>

                          {/* Mobile No */}
                          <div className="form-group col-md-4">
                            <label htmlFor="mobile_no" className="form_label">
                              {t("text_mobile_no")}.
                              <span className="required" aria-required="true">
                                &nbsp;*&nbsp;
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="mobile_no"
                              defaultValue={data.my_profile.mobile_no}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                        <div className="row">
                          {/* Date of Birth */}
                          <div className="form-group col-md-6 mb-3">
                            <label htmlFor="dob" className="form_label">
                              {t("text_date_birth")}
                              <span className="required" aria-required="true">
                                &nbsp;*&nbsp;
                              </span>
                            </label>
                            <input
                              id="datetimepicker1"
                              type="text"
                              className="form-control datepicker"
                              autocomplete="off"
                              name="dob"
                              defaultValue={data.my_profile.dob}
                              onChange={handleInput}
                            />
                          </div>

                          {/* Gender */}
                          <div className="form-group col-md-6 mb-3">
                            <label htmlFor="gender" className="form_label">
                              {t("text_gender")}
                            </label>
                            {/* Male */}
                            <div className="custom-control custom-radio">
                              <input
                                id="male"
                                name="gender"
                                type="radio"
                                className="custom-control-input"
                                onChange={handleInput}
                                checked={
                                  input.gender === "0" ||
                                  data.my_profile.gender === "0"
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="male"
                              >
                                &nbsp; &nbsp;&nbsp;{t("text_male")}&nbsp;&nbsp;
                              </label>
                            </div>
                            {/* Female */}
                            <div className="custom-control custom-radio">
                              <input
                                id="female"
                                name="gender"
                                type="radio"
                                className="custom-control-input"
                                onChange={handleInput}
                                checked={
                                  input.gender === "1" ||
                                  data.my_profile.gender === "1"
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="female"
                              >
                                &nbsp; &nbsp;&nbsp;{t("text_female")}
                                &nbsp;&nbsp;
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* Profile Picture */}
                        <div className="row">
                          <div className="form-group col-md-6 mb-3">
                            <label
                              htmlFor="profile_image"
                              className="form_label"
                            >
                              {t("text_profile_image")}
                            </label>
                            <input
                              type="file"
                              id="profile_image"
                              name="profile_image"
                              className="form-control"
                              onChange={handleInput}
                            />
                          </div>

                          {/* View Options */}
                          <div className="form-group col-md-6 mb-3">
                            <label
                              htmlFor="user_template "
                              className="form_label"
                            >
                              {t("text_web_template")}
                              <span className="required" aria-required="true">
                                &nbsp;*&nbsp;
                              </span>
                            </label>
                            <select
                              id="user_template"
                              name="user_template"
                              className="form-control"
                              onChange={handleView}
                            >
                              <option value="">{t("text_select")}</option>
                              <option
                                value={t("text_admin_view")}
                                selected={status == 1}
                              >
                                {t("text_admin_view")}
                              </option>
                              <option
                                value={t("text_mobile_view")}
                                selected={status == 0}
                              >
                                {t("text_mobile_view")}
                              </option>
                            </select>
                          </div>
                        </div>

                        {/* Error Messages */}
                        <p className="error text-center">
                          {res_update_profile.data.message}
                        </p>

                        {/* Update Profile button */}
                        <div className="form-group text-center">
                          <button
                            type="submit"
                            value="Update Profile"
                            name="profile_submit"
                            className="btn btn-lightpink mt-3"
                          >
                            {t("text_update_profile")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Change Password */}
            <div className="row mb-4" id="profile_setting">
              <div className="col-md-12">
                <div className="card bg-light text-dark">
                  <div className="card-header">
                    <strong>{t("text_changepassword")}</strong>
                  </div>
                  <div className="card-body">
                    <div className="col-md-12">
                      <form
                        id="change-password-form"
                        onSubmit={handleChangePassword}
                      >
                        <div className="row">
                          {/* Old Password */}
                          <div className="form-group col-12">
                            <label
                              htmlFor="old_password"
                              className="form_label"
                            >
                              {t("text_old_password")}
                            </label>
                            <input
                              id="old_password"
                              type="password"
                              className="form-control"
                              name="old_password"
                              defaultValue={data.my_profile.oldpass}
                              onChange={handleInput}
                            />
                            <label
                              htmlFor="oldpass"
                              generated="true"
                              className={
                                "error " +
                                (state1.formErrors.oldpassErr
                                  ? " d-block"
                                  : "d-none")
                              }
                            >
                              {state1.formErrors.oldpassErr}
                            </label>
                          </div>

                          {/* New Password */}
                          <div className="form-group col-12">
                            <label className="form_label">
                              {t("text_new_password")}
                            </label>
                            <input
                              type={password ? "text" : "password"}
                              id="newpass"
                              name="newpass"
                              className="form-control"
                              data-tip="Min 4 chars"
                              data-place="bottom"
                              data-delay-show="200"
                              defaultValue={data.my_profile.newpass}
                              onChange={handleInput}
                            />
                            <span
                              className={!password ? "hideicon" : ""}
                              style={{
                                position: "absolute",
                                top: "33px",
                                right: "21px",
                              }}
                              onClick={() => showpassword(!password)}
                            >
                              &nbsp;
                              <i className="fa fa-eye"></i>
                            </span>
                            {/* tooltip component */}
                            <ReactTooltip />
                            <label
                              htmlFor="newpass"
                              generated="true"
                              className={
                                "error " +
                                (state1.formErrors.newpassErr
                                  ? " d-block"
                                  : "d-none")
                              }
                            >
                              {state1.formErrors.newpassErr}
                            </label>
                          </div>

                          {/* Confirm Password */}
                          <div className="form-group col-12">
                            <label htmlFor="c_passowrd" className="form_label">
                              {t("text_confirm_password")}
                            </label>
                            <input
                              id="c_passowrd"
                              type="password"
                              className="form-control"
                              name="c_passowrd"
                              defaultValue={data.my_profile.confpass}
                              onChange={handleInput}
                            />
                            <label
                              htmlFor="confpass"
                              generated="true"
                              className={
                                "error " +
                                (state1.formErrors.confpassErr
                                  ? " d-block"
                                  : "d-none")
                              }
                            >
                              {state1.formErrors.confpassErr}
                            </label>
                          </div>
                        </div>

                        {/* Submit */}
                        <div className="form-group text-center">
                          <input
                            type="submit"
                            value={t("text_changepassword")}
                            name="change_password"
                            className="btn btn-lightpink mt-3"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.my_profile,
  all_country_data: state.get_all_country,
  res_update_profile: state.update_my_profile,
  res_change_password: state.change_password,
});

export default connect(mapStateToProps)(MyProfile);
