import { React, useEffect, useState } from "react";
import UserHeader from "../../theme/userHeader";
import AppUpload1 from "./app_upload1";
import AppUpload2 from "./app_upload2";
import OtherSetting from "./other_setting";
import { useLocation } from "react-router-dom";
import { GetAppUpload, GetAllUser } from "../../../../store/Action/AdminData";
import { connect } from "react-redux";
import Title from "../../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const AppSetting = ({ dispatch, res, res1 }) => {
  const location = useLocation();

  // manage state
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({});

  useEffect(() => {
    dispatch(GetAppUpload());
    dispatch(GetAllUser());
  }, [dispatch]);
  var data = res.data;
  var data1 = res1.data;

  const handleShow = (value) => {
    setShow(value);
  };
  const handleMessage = (value) => {
    setMessage(value);
  };

  return (
    <>
      <Title props={t("text_appsetting")} />
      <UserHeader title={t("text_appsetting")} />
      {res.loading || res1.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ):(
          <>
      {/* <div
        className={
          "alert alert-success alert-dismissible fade show " +
          (show ? "d-block" : "d-none")
        }
        role="alert"
      >
        {location.state && !message ? (
          <span>{location.state.state.data}</span>
        ) : (
          <span></span>
        )}
        {!location.state && message ? <span>{message}</span> : <span></span>}
        {location.state && message ? <span>{message}</span> : <span></span>}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div> */}
      <div className="row">
        <div className="col-md-12">
          <AppUpload1 onShow={handleShow} onMessage={handleMessage} />
          <AppUpload2 />
          <OtherSetting
            props={data.get_other_setting}
            time={data.time}
            users={data1}
            onShow={handleShow}
            onMessage={handleMessage}
            languages={data.languages}
          />
        </div>
      </div>
      </>
        )}
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.GetAppUpload,
  res1: state.GetAllUser,
});

export default connect(mapStateToProps)(AppSetting);
