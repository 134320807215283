import { useEffect, useState, React } from "react";
import { GetCountry, DemoUser } from "../../../../store/Action/FatchData";
import {
  MyProfileData,
  UpdateMyProfile,
  ChangePassword,
} from "../../../../store/Action/UserFatchData";
import { ToastContainer, toast } from "react-toastify";
import { Scrollbars } from "react-custom-scrollbars";
import Title from "../../../common/Helmet";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import MainTheme from "../../theme/MainTheme";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { t } from "i18next";

function MyProfile({
  dispatch,
  res_all_country_data,
  res,
  res_update_profile,
  res_change_password,
  res_demouser,
}) {
  // manage state
  const [input, setInput] = useState({});
  const [password, showpassword] = useState(false);
  const [webTemplate, setWebTemplate] = useState("");
  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });

  const status = localStorage.getItem("view_status");

  // notification
  const notify = () =>
    toast.success(t("translation1:text_succ_change_password"));
  const notify1 = () => toast.success(t("translation1:text_succ_profile"));

  // input
  const handleInput = (event) => {
    var name = event.target.name;
    if (name === "profile_image") var value = event.target.files[0];
    else value = event.target.value;
    setInput((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    dispatch(MyProfileData());
    dispatch(GetCountry());
    dispatch(DemoUser());
  }, [dispatch]);
  var data = res.data;
  const demouser = res_demouser.data.demo_user;

  //change the view
  const handleView = (e) => {
    const value = e.target.value;
    setWebTemplate(value);
  };

  // handlle submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    //profile data store in formdata
    formData.append("member_id", data.my_profile.member_id);
    formData.append(
      "first_name",
      input.first_name ? input.first_name : data.my_profile.first_name
    );
    formData.append(
      "last_name",
      input.last_name ? input.last_name : data.my_profile.last_name
    );
    formData.append(
      "user_name",
      input.user_name ? input.user_name : data.my_profile.user_name
    );
    formData.append(
      "email_id",
      input.email_id ? input.email_id : data.my_profile.email_id
    );
    formData.append(
      "country_code",
      input.country_code ? input.country_code : data.my_profile.country_code
    );
    formData.append(
      "mobile_no",
      input.mobile_no ? input.mobile_no : data.my_profile.mobile_no
    );
    formData.append(
      "gender",
      input.gender ? input.gender : data.my_profile.gender
    );
    formData.append("dob", input.dob ? input.dob : data.my_profile.dob);
    formData.append(
      "profile_image",
      input.profile_image ? input.profile_image : ""
    );
    formData.append("submit", "save");
    dispatch(UpdateMyProfile(formData));
    if (webTemplate === t("text_admin_view")) {
      localStorage.setItem("view_status", 1);
      window.location.reload()
    } else {
      localStorage.setItem("view_status", 0);
      window.location.reload()

    }
    notify1();
  };

  function handleFormValidation() {
    const { oldpass, newpass, confpass } = input;
    let formErrors = {};
    let formIsValid = true;
    if (!oldpass) {
      formIsValid = true;
      formErrors["oldpassErr"] = t("translation2:err_old_password_req");
    }

    if (!newpass) {
      formIsValid = true;
      formErrors["newpassErr"] = t("translation2:err_new_password_req");
    }
    if (newpass && newpass.length < 6) {
      formIsValid = true;
      formErrors["newpassErr"] = t("translation2:err_password_min");
    }
    if (!confpass) {
      formIsValid = true;
      formErrors["confpassErr"] = t("translation2:err_c_passowrd_req");
    }
    if (confpass && confpass.length < 6) {
      formIsValid = true;
      formErrors["confpassErr"] = t("translation2:err_c_passowrd_equal");
    }
    if (
      newpass &&
      newpass > 6 &&
      confpass &&
      confpass.length > 6 &&
      newpass != confpass
    ) {
      formIsValid = true;
      formErrors["confpassErr"] = t("translation2:err_c_passowrd_equal");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //change password function
  const handleChangePassword = (event) => {
    event.preventDefault();
    handleFormValidation();
    const ChangePassFormData = new FormData();
    ChangePassFormData.append("member_id", data.my_profile.member_id);
    ChangePassFormData.append("oldpass", input.oldpass ? input.oldpass : "");
    ChangePassFormData.append("newpass", input.newpass ? input.newpass : "");
    ChangePassFormData.append("confpass", input.confpass ? input.confpass : "");
    ChangePassFormData.append("submit", "reset");
    dispatch(ChangePassword(ChangePassFormData), [dispatch]);
    if (res_change_password.status == true) {
      notify();
    }
  };

  return (
    <>
      <Title props={t("text_my_profile")} />
      {/* toastify */}
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <MainTheme title={t("text_my_profile")} backPath footer>
        {res.loading || res_all_country_data.loading ? (
          <div className="loader">
            <Spinner />
          </div>
        ) : (
          <Scrollbars>
            <div className="content-section">
              <div className="bm-content-listing">
                <div className="profile-content text-black">
                  {/* edit profile */}
                  <h6 className="mt-1">{t("text_edit_profile")}</h6>
                  <form
                    className="container profile-form mt-2"
                    id="profile-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="mb-2 row">
                      <div className="col-6">
                        <label>{t("text_first_name")}</label>
                        <input
                          type="text"
                          id="first_name"
                          name="first_name"
                          className="form-control border-bottom rounded-0"
                          defaultValue={data.my_profile.first_name}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="col-6">
                        <label>{t("text_last_name")}</label>
                        <input
                          type="text"
                          id="last_name"
                          name="last_name"
                          className="form-control border-bottom rounded-0"
                          defaultValue={data.my_profile.last_name}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="mb-2 row">
                      <div className="col-12">
                        <label>{t("text_user_name")}</label>
                        <input
                          type="text"
                          id="user_name"
                          name="user_name"
                          className="form-control border-bottom rounded-0"
                          defaultValue={data.my_profile.user_name}
                          readonly
                        />
                      </div>
                    </div>
                    <div className="mb-2 row">
                      <div className="col-12">
                        <label>{t("text_email")}</label>
                        <input
                          type="text"
                          id="email_id"
                          name="email_id"
                          className="form-control border-bottom rounded-0"
                          defaultValue={data.my_profile.email_id}
                          readonly
                        />
                      </div>
                    </div>
                    <div className="mb-2 row">
                      <div className="col-4">
                        <label>{t("text_country")}</label>
                        <select
                          className="form-control border-bottom rounded-0"
                          name="country_code"
                          defaultValue={data.my_profile.country_code}
                          onChange={handleInput}
                        >
                          <option value="">{t("text_select")}</option>
                          {res_all_country_data.data.all_country.map(
                            (country, index) => {
                              return (
                                <option key={index} value={country.p_code}>
                                  {country.p_code}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                      <div className="col-8">
                        <label>{t("text_mobile_no")}.</label>
                        <input
                          type="text"
                          id="mobile_no"
                          name="mobile_no"
                          className="form-control border-bottom rounded-0"
                          defaultValue={data.my_profile.mobile_no}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="mb-2 row">
                      <div className="col-12">
                        <label>{t("text_date_birth")}</label>
                        <input
                          type="date"
                          id="dob"
                          name="dob"
                          className="form-control border-bottom rounded-0"
                          defaultValue={data.my_profile.dob}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="mb-2 row">
                      <div className="col-4">
                        <label>{t("text_gender")}</label>
                      </div>
                      <div className="col-8">
                        <input
                          type="radio"
                          name="gender"
                          id="male"
                          value="0"
                          onChange={handleInput}
                          checked={
                            input.gender === "0" ||
                            data.my_profile.gender === "0"
                          }
                        />
                        <label
                          className="align-top"
                          htmlFor="male"
                          style={{ margin: "4px" }}
                        >
                          &nbsp;&nbsp;{t("text_male")}&nbsp;&nbsp;
                        </label>
                        <input
                          type="radio"
                          name="gender"
                          id="female"
                          value="1"
                          onChange={handleInput}
                          checked={
                            input.gender === "1" ||
                            data.my_profile.gender === "1"
                          }
                        />
                        <label
                          className="align-top"
                          htmlFor="female"
                          style={{ margin: "4px" }}
                        >
                          &nbsp;&nbsp;{t("text_female")}&nbsp;&nbsp;
                        </label>
                      </div>
                    </div>
                    <div className="mb-2 row">
                      <div className="col-12">
                        <label>{t("text_profile_image")}</label>
                        <input
                          type="file"
                          name="profile_image"
                          className="form-control border-bottom rounded-0"
                          onChange={handleInput}
                        />
                        {data.my_profile.profile_image !== "" ? (
                          <img
                            className="mt-3"
                            src={data.my_profile.profile_image}
                            alt="img"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* Web template */}
                    <div className="mb-2 ">
                      <label>{t("text_web_template")}</label>
                      <select
                        className="form-control border-bottom rounded-0"
                        name="web_template"
                        onChange={handleView}
                      >
                        <option value="">{t("text_select")}</option>
                        <option
                          value={t("text_admin_view")}
                          selected={status == 1}
                        >
                          {t("text_admin_view")}
                        </option>
                        <option
                          value={t("text_mobile_view")}
                          selected={status == 0}
                        >
                          {t("text_mobile_view")}
                        </option>
                      </select>
                    </div>
                    {/* <p className="error text-center">
                      {res_update_profile.data.message}
                    </p> */}
                    <div className="my-3 row">
                      <div className="col-12">
                        {demouser == 0 ? (
                          <input
                            type="submit"
                            value={t("text_update_profile")}
                            name="submit"
                            className="mb-4 btn btn-submit btn-block btn-lg btn-lightgreen form-control"
                          />
                        ) : (
                          <input
                            value={t("text_update_profile")}
                            name="submit"
                            className="mb-4 btn btn-submit btn-block btn-lg btn-lightgreen form-control disabled"
                          />
                        )}
                      </div>
                    </div>
                  </form>
                  {/* change password */}
                  <h6 className="mt-1">{t("text_changepassword")}</h6>
                  <form
                    className="container profile-form mt-2"
                    id="change-pass-form"
                    onSubmit={handleChangePassword}
                  >
                    <div className="mb-2 row">
                      <div className="col-12">
                        <label>{t("text_old_password")}</label>
                        <input
                          type="password"
                          id="oldpass"
                          name="oldpass"
                          className="form-control border-bottom rounded-0"
                          defaultValue={data.my_profile.oldpass}
                          onChange={handleInput}
                        />
                        <label
                          htmlFor="oldpass"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.oldpassErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.oldpassErr}
                        </label>
                      </div>
                    </div>
                    <div className="mb-2 row" style={{ position: "relative" }}>
                      <div className="col-12">
                        <label>{t("text_new_password")}</label>
                        <input
                          type={password ? "text" : "password"}
                          id="newpass"
                          name="newpass"
                          className="form-control border-bottom rounded-0"
                          data-tip="Min 4 chars"
                          data-place="bottom"
                          data-delay-show="200"
                          defaultValue={data.my_profile.newpass}
                          onChange={handleInput}
                        />
                        <span
                          className={password ? "hideicon" : ""}
                          style={{
                            position: "absolute",
                            top: "33px",
                            right: "21px",
                          }}
                          onClick={() => showpassword(!password)}
                        >
                          &nbsp;
                          <i className="fa fa-eye"></i>
                        </span>
                        {/* tooltip component */}
                        <ReactTooltip />
                        <label
                          htmlFor="newpass"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.newpassErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.newpassErr}
                        </label>
                      </div>
                    </div>
                    <div className="mb-2 row">
                      <div className="col-12">
                        <label>{t("text_confirm_password")}</label>
                        <input
                          type="password"
                          id="confpass"
                          name="confpass"
                          className="form-control border-bottom rounded-0"
                          defaultValue={data.my_profile.confpass}
                          onChange={handleInput}
                        />
                        <label
                          htmlFor="confpass"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.confpassErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state1.formErrors.confpassErr}
                        </label>
                      </div>
                    </div>
                    <div className="my-3 row">
                      <div className="col-12">
                        {demouser == 0 ? (
                          <input
                            type="submit"
                            value={t("text_changepassword")}
                            name="submit"
                            className="btn btn-submit btn-block btn-lg btn-lightgreen mb-4 form-control"
                          />
                        ) : (
                          <input
                            value={t("text_changepassword")}
                            name="submit"
                            className="btn btn-submit btn-block btn-lg btn-lightgreen mb-4 form-control disabled"
                          />
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Scrollbars>
        )}
      </MainTheme>
    </>
  );
}

// redux connect
const mapStateToProps = (state) => ({
  res: state.my_profile,
  res_all_country_data: state.get_all_country,
  res_update_profile: state.update_my_profile,
  res_change_password: state.change_password,
  res_demouser: state.DemoUser,
});

export default connect(mapStateToProps)(MyProfile);
