import { React, useEffect, useState } from "react";
import {
  GetMemberPosition,
  GetGameMatch,
} from "../../../../store/Action/AdminData";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import UserHeader from "../../theme/userHeader";
import { useParams, useLocation, useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { t } from "i18next";

const Position = ({ dispatch, res, res1, res2 }) => {
  const { id } = useParams();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const admin_user = JSON.parse(sessionStorage.getItem("admin_user"));
  console.log("id", admin_user);

  // manage state
  const [show2, setShow2] = useState(false);
  const [show4, setShow4] = useState(false);
  const [message, setMessage] = useState("");

  var location = useLocation();
  const params = useParams();
  const [checkSelection, setCheckSelection] = useState([]);
  const [checkSelection1, setCheckSelection1] = useState([]);
  const [editPos, setEditPos] = useState({});
  // manage state
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose = () => setShow(false);

  const handleAdd = (data) => {
    // console.log("edit", data);
    setCheckSelection(data);

    setShow3(true);
    setShow(false);
  };
  const handleClose1 = () => setShow3(false);
  const handleClose4 = () => setShow4(false);
  console.log("seef", checkSelection);

  useEffect(() => {
    dispatch(GetGameMatch(id, admin_user));
    dispatch(GetMemberPosition(params.id));
  }, [checkSelection, id, admin_user, dispatch]);
  useEffect(() => {}, []);

  if (res.loading) return false;
  var joined_players = res.data.message.result;
  console.log("wd", joined_players);

  if (res1.loading) return false;
  const data = res1.data;
  console.log("GetMemberPosition", data);

  const handleEdit = (data) => {
    setShow4(true);
    setShow(false);
  };

  const handleShow = (index) => {
    setShow(true);
    let obj = data.member_position.find((o) => o.pubg_id === index[0]);
    setCheckSelection(obj);
    setEditPos({ ...editPos, pubg_id: obj.pubg_id });
    setCheckSelection1(obj.match_join_member_id);
    console.log("edsa", obj.match_join_member_id);
  };

  let type = 1;
  //game type
  if (data.match_type[0].type == "Duo") {
    type = 2;
  } else if (data.match_type[0].type == "Squad") {
    type = 4;
  } else if (data.match_type[0].type == "Squad5") {
    type = 5;
  } else {
    type = 1;
  }
  console.log("sdhsfgdsfdh", checkSelection);

  const get_team_position_pubgid = {
    team: checkSelection[0],
    position: checkSelection[1],
    pubg_id: "",
  };

  const handleChange = (e) => {
    console.log("dsssd", e.target.value);
    get_team_position_pubgid.pubg_id = e.target.value;
  };
  const handleChange1 = (e) => {
    console.log("dsssd", e.target.value);
    setEditPos({
      pubg_id: e.target.value,
      match_join_member_id: checkSelection1,
      match_id: params.id,
    });
  };
  // console.log("as", get_team_position_pubgid);

  //join match data
  const jsonData = {
    submit: "joinnow",
    match_id: params.id,
    member_id: admin_user,
    join_status: false,
    teamposition: [get_team_position_pubgid],
  };

  // join match api call
  const Join = async (e) => {
    e.preventDefault();
    console.log("jsonj", jsonData);
    var response = await fetch(`/api/join_match_process`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(jsonData),
    });
    var res_data = await response.json();
    console.log("mansi", res_data);
    history.push("/admin/matches", {
      state: {
        status: true,
        data: { status: res_data.status, msg: res_data.message },
      },
    });
  };
  const data1 = res2;
  console.log("qwe", data1);

  // edit pubg_id
  const Join1 = async (e) => {
    e.preventDefault();
    console.log("edit", editPos);
    var response = await fetch(`/api/edit_member_position`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(editPos),
    });
    var res_data = await response.json();
    console.log("sad", res_data);
    history.push("/admin/matches", {
      state: {
        status: true,
        data: { status: res_data.status, msg: res_data.message },
      },
    });
  };

  //handle the alert
  function handleDelete(id) {
    console.log("id", id);
    Swal.fire({
      icon: "warning",
      html:
        "<h2> Are you sure ?</h2>" +
        "<p>Are you sure to blank this position?</p>",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var response = await fetch(`/api/delete_member_position/${id}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: user.api_token,
          },
        });
        var res_data = await response.json();
        console.log("sdfDfsdfsf", res_data);
        history.push("/admin/matches", {
          state: {
            status: true,
            data: { status: res_data.status, msg: res_data.message },
          },
        });
      }
    });
  }

  return (
    <>
      <Modal show={show}>
        <Modal.Header>
          <h3 className="text-dark">{t("text_joined_member")}</h3>
          <div>
            <i
              className="fa fa-edit text-secondary pointer"
              onClick={() => handleEdit(checkSelection.pubg_id)}
            ></i>
            <i
              className="fa fa-trash-o text-secondary ps-1 pointer"
              aria-hidden="true"
              id="del-btn"
              onClick={() => handleDelete(checkSelection1)}
            ></i>
          </div>
        </Modal.Header>
        <Modal.Body className="text-dark">
          Game ID {checkSelection.pubg_id}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-secondary" onClick={handleClose}>
            {t("text_btn_cancel")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* add */}
      <Modal show={show3}>
        <Modal.Header>
          <h3 className="text-dark">{t("text_joined_member")}</h3>
        </Modal.Header>
        <Modal.Body className="text-dark">
          <label htmlFor="gameid">Game ID </label>
          <input
            type="text"
            id="gameid"
            name="gameid"
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-lightpink" onClick={Join}>
            Join Now
          </Button>
          <Button className="btn-secondary" onClick={handleClose1}>
            {t("text_btn_close")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* edit */}
      <Modal show={show4}>
        <Modal.Header>
          <h3 className="text-dark">{t("text_joined_member")}</h3>
        </Modal.Header>
        <Modal.Body className="text-dark">
          <label htmlFor="gameid">Game ID </label>
          <input
            type="text"
            id="gameid"
            name="gameid"
            value={editPos.pubg_id}
            onChange={handleChange1}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-lightpink" onClick={Join1}>
            Join Now
          </Button>
          <Button className="btn-secondary" onClick={handleClose4}>
            {t("text_btn_close")}
          </Button>
        </Modal.Footer>
      </Modal>
      <UserHeader
        title={
          data.match_detail[0].match_name + t("text_for_macth_id") + params.id
        }
      />
      <div
        className={
          "alert alert-success alert-dismissible fade show " +
          (show ? "d-block" : "d-none")
        }
        role="alert"
      >
        {location.state && !message ? (
          <span>{location.state.state.data}</span>
        ) : (
          <span></span>
        )}
        {!location.state && message ? <span>{message}</span> : <span></span>}
        {location.state && message ? <span>{message}</span> : <span></span>}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          onClick={() => setShow2(false)}
        ></button>
      </div>
      <div className="m-3">
        <div className="row">
          {/* type wise */}
          {joined_players
            .slice(0, joined_players.length / type)
            .map((data, index) => {
              return type === 1 ? (
                <>
                  <div
                    className="col-md-1 col-sm-1 col-xs-1  position "
                    style={{ margin: "2px", color: "#000" }}
                  >
                    {joined_players
                      .slice(index, index + type)
                      .map((data, index) => {
                        return (
                          <div key={index}>
                            {data.user_name ? (
                              <div
                                class="col-md-12 col-sm-12 col-xs-12 bordered position pt-3"
                                style={{ margin: "2px" }}
                                onClick={() =>
                                  handleShow([data.pubg_id, data.position])
                                }
                              >
                                <div
                                  style={{
                                    minHeight: "50px",
                                    cursor: "pointer",
                                  }}
                                  class="modal-div text-dark"
                                >
                                  {data.pubg_id}
                                </div>
                              </div>
                            ) : (
                              <div
                                class="col-md-12 col-sm-12 col-xs-12 bordered position pt-3"
                                style={{ margin: "2px" }}
                              >
                                <div
                                  style={{
                                    minHeight: "50px",
                                    cursor: "pointer",
                                  }}
                                  class="modal-div  text-primary"
                                  onClick={() =>
                                    handleAdd([data.pubg_id, data.position])
                                  }
                                ></div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-4 text-center text-dark">
                    <div key={index} className={`grid grid-cols-${type + 1}`}>
                      <div
                        class="col-md-10 col-sm-10 col-xs-10 bordered position pt-3"
                        style={{ margin: "2px" }}
                      >
                        <div style={{ minHeight: "50px" }}>
                          <h1 className="font-semibold text-sm text-dark">
                            {t("text_team")} {index + 1}
                          </h1>
                        </div>
                      </div>
                      {joined_players
                        .filter((data) => {
                          return data.team == index + 1;
                        })
                        .map((data, index) => {
                          return (
                            <div key={index}>
                              {data.user_name ? (
                                <div
                                  class="col-md-10 col-sm-10 col-xs-10 bordered position pt-3"
                                  style={{ margin: "2px" }}
                                >
                                  <div
                                    style={{
                                      minHeight: "50px",
                                      cursor: "pointer",
                                    }}
                                    class="modal-div text-primary"
                                    onClick={() =>
                                      handleShow([
                                        data.pubg_id,
                                        data.team,
                                        data.position,
                                      ])
                                    }
                                  >
                                    {data.pubg_id}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  class="col-md-10 col-sm-10 col-xs-10 bordered position pt-3"
                                  style={{ margin: "2px" }}
                                >
                                  <div
                                    style={{
                                      minHeight: "50px",
                                      cursor: "pointer",
                                    }}
                                    class="modal-div text-primary"
                                    onClick={() =>
                                      handleAdd([data.team, data.position])
                                    }
                                  ></div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.GetGameMatch,
  res1: state.GetMemberPosition,
  res2: state.Join_Match,
});

export default connect(mapStateToProps)(Position);
