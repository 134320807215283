import { React, useEffect, useState } from "react";
import Title from "../../common/Helmet";
import MainTheme from "../theme/MainTheme";
import { connect } from "react-redux";
import {
  Dashboard,
  Product_buy_data,
} from "../../../store/Action/UserFatchData";
import coin from "../../assets/images/coin.png";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";

const BuyForm = ({ dispatch, res, res_dashboard }) => {
  // get user Details from Local Storage
  var user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const { id } = useParams();
  let location = useLocation();

  // manage state
  const [state, setState] = useState({
    submit: "order",
    product_id: id,
    member_id: user.member_id,
    shipping_address: {},
  });
  const [state1, setState1] = useState({
    formErrors: {},
  });

  const notifyS = (data) => toast.success(data);
  const notifyE = (data) => toast.error(data);

  useEffect(() => {
    dispatch(Dashboard());
  }, [dispatch]);

  const handleData = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      shipping_address: {
        ...state.shipping_address,
        [name]: value,
      },
    });
  };

  // form validation
  function handleFormValidation() {
    const { name, address } = state.shipping_address;
    let formErrors = {};
    let formIsValid = true;
    if (!name) {
      formIsValid = true;
      formErrors["nameErr"] = t("translation2:err_company_name_valid");
    }
    if (!address) {
      formIsValid = true;
      formErrors["addressErr"] = t("translation2:err_company_address_valid");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  console.log("state", state);
  //onclick of submit button then call function
  const handleSubmit = async () => {
    // e.preventDefault();
    handleFormValidation();
    var response = await fetch(`/api/product_order`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + user.api_token,
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    console.log("res", res_data);
    if (res_data.status === true) {
      notifyS(res_data.message);
      setTimeout(() => {
        history.goBack();
      }, 3000);
    }
  };

  var data = res_dashboard.data;
  if (res_dashboard.loading == false) {
    var total =
      parseInt(data.member.wallet_balance) + parseInt(data.member.join_money);
    var payable = data.member.wallet_balance + data.member.join_money;
    var amt = location.state.amount;
  }

  return (
    <>
      <Title props={t("text_order")} />
      <MainTheme title={location.state.title}>
        {res_dashboard.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            {/* toastify component */}
            <ToastContainer
              hideProgressBar={true}
              autoClose={3000}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div className="row d-flex mb-3">
              <div
                className="col-3 m-auto text-lightgreen"
                style={{ height: "100%" }}
              >
                <i
                  className="fa fa-google-wallet"
                  style={{ fontSize: " 50px" }}
                ></i>
              </div>
              <div className="col-9 text-end text-dark">
                <span className="d-block">
                  {t("text_your_curr_balance")}:&nbsp;
                  <strong>
                    &nbsp;
                    <i>
                      <img src={coin} alt="coin-img" className="coin" />
                    </i>
                    &nbsp;
                    {total}
                  </strong>
                </span>
                <span className="d-block">
                  {t("text_tot_payable_amt")}:&nbsp;
                  <strong>
                    &nbsp;
                    <i>
                      <img src={coin} alt="coin-img" className="coin" />
                    </i>
                    &nbsp;
                    {location.state.amount}
                  </strong>
                </span>
              </div>
            </div>
            {/* buy form */}
            <form
              className="profile-form text-dark"
              name="order-form"
              id="order-form"
            >
              <div className="form-group row">
                {/* name */}
                <div className="col-12">
                  <label htmlFor="name">{t("text_name")}</label>
                  <input
                    type="text"
                    id="name "
                    name="name"
                    className="form-control border-bottom rounded-0"
                    value={state.shipping_address.name}
                    onChange={handleData}
                  />
                  <label
                    htmlFor="name"
                    generated="true"
                    className={
                      "error " +
                      (state1.formErrors.nameErr ? " d-block" : "d-none")
                    }
                  >
                    {state1.formErrors.nameErr}
                  </label>
                </div>
                {/* address */}
                <div className="col-12">
                  <label htmlFor="address">{t("text_address")}</label>
                  <textarea
                    id="address"
                    name="address"
                    className="form-control border-bottom rounded-0"
                    value={state.shipping_address.address}
                    onChange={handleData}
                  ></textarea>
                  <label
                    htmlFor="address"
                    generated="true"
                    className={
                      "error " +
                      (state1.formErrors.addressErr ? " d-block" : "d-none")
                    }
                  >
                    {state1.formErrors.addressErr}
                  </label>
                </div>
                {/* additional info */}
                <div className="col-12">
                  <label htmlFor="add_info">{t("text_additional_info")}</label>
                  <textarea
                    id="add_info "
                    name="add_info"
                    className="form-control border-bottom rounded-0"
                    value={state.shipping_address.add_info}
                    onChange={handleData}
                  ></textarea>
                </div>
              </div>
              {/* buy now button */}
              <button
                type="button"
                id="buy_now"
                className="btn btn-lightgreen text-white text-uppercase mt-3"
                name="submit"
                onClick={() => (amt < total ? notifyE() : handleSubmit())}
              >
                {t("text_buy_now")}
              </button>
            </form>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.product_buy_data,
  res_dashboard: state.dashboard,
});

export default connect(mapStateToProps)(BuyForm);
