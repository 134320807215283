import { React, useState } from "react";
import Header from "../theme/header";
import Footer from "../theme/footer";
import Title from "../../common/Helmet";
import { useLocation, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { t } from "i18next";

function Sendotp() {
  const history = useHistory();
  const location = useLocation();
  console.log("location", location);

  // manage state
  const [otp, setotp] = useState();

  const notify = (data) => toast.info(data);
  const notifyErr = (data) => toast.error(data);

  const handleotp = (e) => {
    const num = e.target.value;
    setotp(num);
  };

  const validateotp = (e) => {
    e.preventDefault();
    console.log(otp);
    if (otp == location.state.otp) {
      notify("please reset your password");
      setTimeout(() => {
        history.push("/reset", location);
      }, 2000);
    } else {
      notifyErr("OTP incorrect");
    }
  };

  return (
    <>
      <Title props={t("text_login")} />
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <div className="page-header d-flex">
        <div className="black-overlay"></div>
        <div className="container m-auto">
          <div className="row align-items-center">
            <div className="col-md-12 text-center">
              <h1 className=" text-dark">{t("text_otp")}</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="bm-light-bg text-dark">
        <div className="container">
          <div className="row">
            <div className="offset-md-3 col-md-6 col_right">
              <div className="card cnt-card my-5">
                <div className="card-body">
                  <form id="login-form">
                    <div className="form-group mb-3">
                      <label className="text-dark">
                        {t("text_enter_otp")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        name="otp"
                        type="text"
                        className="form-control"
                        value={otp}
                        onChange={handleotp}
                      />
                      &nbsp;
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="btn btn-submit btn-block btn-lg btn-lightpink mb-2 form-control"
                        type="submit"
                        onClick={validateotp}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Sendotp;
