import { React, useEffect, useState } from "react";
import UserHeader from "../../theme/header";
import { connect } from "react-redux";
import { Add_money_data } from "../../../../store/Action/FatchData";
import { useLocation, useHistory } from "react-router-dom";
import copy from "copy-to-clipboard";
import CountdownTimer from "react-component-countdown-timer";
import { QRCodeCanvas } from "qrcode.react";
import { t } from "i18next";

const Tron = ({ res, dispatch }) => {
  const history = useHistory();
  const location = useLocation();
  console.log(location);
  const props = location.state.state;

  // manage state
  const [copyWord, setCopy] = useState("");

  useEffect(() => {
    dispatch(Add_money_data(props));
  }, [dispatch, props]);
  var data = res.data;

  // Clipboard
  const clipBoard = () => {
    copy(data.wallet_address);
    setCopy("copy the clipboard");
    setTimeout(() => {
      setCopy("");
    }, 2000);
  };

  return (
    <>
      <main className="bm-full-width bm-full-height">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-xl-4 col-left">
              <div className="bm-modal">
                <UserHeader title={t("text_add_money")} backPath />
                <div className="bm-mdl-center bm-full-height text-dark">
                  <div className="bm-content-listing">
                    <div className="profile-content text-black">
                      <div className="container profile-form mt-2 text-center">
                        <div className="form-group row">
                          <div className="col-12 mt-4">
                            <div className="mb-2">
                              {t("text_tron_scan_note")}
                            </div>
                            {/* Timer */}
                            <CountdownTimer
                              className="btn-lightgreen border-dashed px-2 m-auto"
                              count={900}
                              border={false}
                              showTitle={false}
                              noPoints={false}
                              responsive={true}
                              hideDay={true}
                              hideHours={true}
                              backgroundColor="#91e2c0"
                              onEnd={() => {
                                history.goBack();
                              }}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="form-group row">
                          <div className="col-12">
                            <div id="qrcode">
                              {/* QR code  */}
                              <QRCodeCanvas
                                value={data.wallet_address}
                                style={{ width: "185px", height: "185px" }}
                              />
                            </div>
                            <h6
                              id="contract_address"
                              className="mt-4"
                              onClick={clipBoard}
                              style={{
                                cursor: "pointer",
                                wordBreak: " break-all",
                                fontSize: "15px",
                              }}
                            >
                              {data.wallet_address}&nbsp;
                              <i className="fa fa-copy ml-3"></i>
                            </h6>
                            <br />
                            <span
                              className="copied  text-white bg-black rounded px-2"
                              style={{ position: "absolute", left: "35%" }}
                            >
                              {copyWord}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.addmoney,
});

export default connect(mapStateToProps)(Tron);
