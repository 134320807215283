import { React, useEffect, useState } from "react";
import UserHeader from "../../theme/userHeader";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { GetGameName } from "../../../../store/Action/AdminData";
import { connect } from "react-redux";
import Select2 from "./Select2";
import { Select } from "@material-ui/core";
import Title from "../../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const MatcheEdit = ({ res, dispatch }) => {
  const params = useParams();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  // manage state
  const [loading, setLoading] = useState(true);
  const [insertData, SetInsertData] = useState({});
  const [state, setState] = useState({
    formErrors: {},
  });
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [editor, setEditor] = useState({
    match_desc: "",
  });
  const [editor1, setEditor1] = useState({
    match_sponsor: "",
  });
  const [editor2, setEditor2] = useState({
    prize_description: "",
  });
  const [editor3, setEditor3] = useState({
    match_private_desc: "",
  });
  useEffect(() => {
    dispatch(GetGameName());
  }, [dispatch]);

  //store room data
  const [room, setRoom] = useState({
    // match_id: "",
    // game_id: "",
    room_description: "",
    // match_password: "",
    // match_name: "",
    // match_url: "",
    // match_time: "",
    // win_prize: "",
    // per_kill: "",
    // match_type: "",
    // entry_fee: "",
    // number_of_position: "",
    // MAP: "",
    // match_banner: "",
    // type: "",
    id_submit: "Submit",
  });
  //edit match details store
  const [match, setMatch] = useState({
    game_id: "",
    match_name: "",
    match_url: "",
    match_time: "",
    win_prize: "",
    per_kill: "",
    type: "",
    entry_fee: "",
    number_of_position: "",
    MAP: "",
    match_banner: "",
    match_type: "",
    image_id: "",
  });

  useEffect(() => {
    const GetMatchData = async () => {
      var response = await fetch(`/api/get_match_inserted_data/${params.id}`, {
        headers: {
          Authorization: user.api_token,
        },
      });
      var res_data = await response.json();
      if (res_data) {
        setLoading(false);
      }
      const data = res_data.get_match_data[0];
      console.log(data);
      SetInsertData(data);

      setMatch({
        game_id: data.game_id,
        match_name: data.match_name,
        match_url: data.match_url,
        match_time: data.match_time,
        win_prize: data.win_prize,
        per_kill: data.per_kill,
        type: data.type,
        entry_fee: data.entry_fee,
        number_of_position: data.number_of_position,
        MAP: data.MAP,
        match_type: data.match_type,
        match_banner: data.match_banner,
      });
    };
    GetMatchData();
  }, []);
  useEffect(() => {
    setEditor((editor) => ({
      match_desc: insertData.match_desc,
    }));
    setEditor1((editor) => ({
      match_sponsor: insertData.match_sponsor,
    }));
    setEditor2((editor) => ({
      prize_description: insertData.prize_description,
    }));
    setEditor3((editor) => ({
      match_private_desc: insertData.match_private_desc,
    }));
  }, [insertData]);

  const match_data = res.data.game_name;
  // console.log(match_data);
  // let uniqueObjArray = [...new Set(match_data.map((item) => item.game_name))];
  // console.log(uniqueObjArray);
  //room data edit
  const handleInput = (e) => {
    const { name, value } = e.target;
    setRoom({
      ...room,
      [name]: value,
    });
  };
  //handle file
  const handleFile = (e) => {
    const file = e.target.files[0];
    setMatch({
      ...match,
      match_banner: file,
    });
  };
  //match data edit
  const handleMatch = (e) => {
    const { name, value } = e.target;
    setMatch({
      ...match,
      [name]: value,
    });
  };
  const handleData = (value) => {
    setMatch({
      ...match,
      image_id: value,
    });
  };

  function handleFormValidation() {
    const { room_description } = room;

    let formErrors = {};
    let formIsValid = true;
    console.log("dsagdj");

    if (!room_description) {
      formIsValid = true;
      formErrors["matchIdErr"] = t("translation2:err_room_desc_req");
    }

    setState({ formErrors: formErrors });
    return formIsValid;
  }
  function handleFormValidation1() {
    const { type, match_banner } = match;

    let formErrors = {};
    let formIsValid = true;
    console.log("dsagdj");

    if (!type) {
      formIsValid = true;
      formErrors["typeErr"] = t("translation2:err_match_type_req");
    }

    if (!match_banner) {
      formIsValid = true;
      formErrors["matchBannerErr"] = t("translation2:err_match_banner_req");
    }

    setState1({ formErrors: formErrors });
    return formIsValid;
  }
  //room update
  const handleSubmit = async (e) => {
    e.preventDefault();

    handleFormValidation();
    var response = await fetch(`/api/update_match_details/${params.id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(room),
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      console.log("fsf");
      history.push("/admin/matches", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    } else {
    }
  };
  //match update
  const handleUpdate = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("game_id", match.game_id);
    formData.append("match_name", match.match_name);
    formData.append("match_url", match.match_url);
    formData.append("match_time", match.match_time);
    formData.append("win_prize", match.win_prize);
    formData.append("per_kill", match.per_kill);
    formData.append("type", match.type);
    formData.append("entry_fee", match.entry_fee);
    formData.append("number_of_position", match.number_of_position);
    formData.append("MAP", match.MAP);
    formData.append("match_type", match.match_type);
    formData.append("match_banner", match.match_banner);
    formData.append("match_desc", editor.match_desc);
    formData.append("image_id", match.image_id);
    formData.append("submit", "Submit");

    handleFormValidation1();
    var response = await fetch(`/api/update_match_details/${params.id}`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      history.push("/admin/matches", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_match")} />
      <UserHeader title={t("text_match") + "#" + params.id} />
      {res.loading || loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card bg-light text-dark">
              <div className="card-header">
                <strong>{t("text_match")}</strong>
              </div>

              <div className="card-body">
                <form id="validate-form">
                  <div className="row">
                    <input
                      type="hidden"
                      className="form-control mt-3"
                      name="match_id"
                      value="57"
                    />
                    <div className="form-group col-12">
                      <label htmlFor="match_id" className="mt-3">
                        {t("text_room_desc")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        id="match_id"
                        type="textarea"
                        className="form-control mt-3"
                        name="room_description"
                        value={room.room_description}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state.formErrors.matchIdErr ? " d-block" : "d-none")
                        }
                      >
                        {state.formErrors.matchIdErr}
                      </label>
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <input
                      type="submit"
                      value="Submit"
                      name="id_submit"
                      className="btn btn-lightpink mt-3 "
                      onClick={handleSubmit}
                    />
                  </div>
                </form>
              </div>
              <div className="card-body">
                <div className="col-md-12">
                  <form className="needs-validation" id="validate">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="game_id" className="mt-3">
                          {t("text_game")}&nbsp;
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <select
                          className="form-control mt-3"
                          name="game_id"
                          value={match.game_id}
                          onChange={handleMatch}
                        >
                          <option value="">Select..</option>
                          {match_data.map((data, index) => {
                            return (
                              <option value={data.game_name} key={index}>
                                {data.game_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <input
                        type="hidden"
                        className="form-control mt-3"
                        name="match_id"
                        value="57"
                      />
                      <div className="form-group col-md-6">
                        <label htmlFor="match_name" className="mt-3">
                          {t("text_match_name")}&nbsp;
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-3"
                          name="match_name"
                          value={match.match_name}
                          onChange={handleMatch}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="match_url" className="mt-3">
                          {t("text_match_url")}&nbsp;
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-3"
                          name="match_url"
                          value={match.match_url}
                          onChange={handleMatch}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="match_time" className="mt-3">
                          {t("text_match_schedule")}&nbsp;
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-3"
                          id="datetimepicker1"
                          name="match_time"
                          value={match.match_time}
                          onChange={handleMatch}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="win_prize" className="mt-3">
                          {t("text_prize_pool")}&nbsp;
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-3"
                          name="win_prize"
                          value={match.win_prize}
                          onChange={handleMatch}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="per_kill" className="mt-3">
                          {t("text_per_kill")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          id="email"
                          type="text"
                          className="form-control mt-3"
                          name="per_kill"
                          value={match.per_kill}
                          onChange={handleMatch}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="type" className="mt-3">
                          {t("text_team")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <select
                          className="form-control mt-3"
                          name="type"
                          value={match.type}
                          onChange={handleMatch}
                        >
                          <option value="">Select..</option>
                          <option value="Solo">{t("text_solo")}</option>
                          <option value="Duo">{t("text_duo")}</option>
                          <option value="Squad">{t("text_squad")}</option>
                          <option value="Squad5">{t("text_squad5")}</option>
                        </select>
                        <label
                          htmlFor="package_name"
                          generated="true"
                          className={
                            "error " +
                            (state1.formErrors.typeErr ? " d-block" : "d-none")
                          }
                        >
                          {state1.formErrors.typeErr}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="entry_fee" className="mt-3">
                          {t("text_entry_fee")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          id="entry_fee"
                          className="form-control mt-3"
                          name="entry_fee"
                          value={match.entry_fee}
                          onChange={handleMatch}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="number_of_position" className="mt-3">
                          {t("text_total_player")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-3"
                          name="number_of_position"
                          value={match.number_of_position}
                          onChange={handleMatch}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="MAP" className="mt-3">
                          {t("text_map")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-3"
                          name="MAP"
                          value={match.MAP}
                          onChange={handleMatch}
                        />
                      </div>
                      <div className="col-md-6 mb-3 d-none ">
                        <label htmlFor="match_type" className="mt-3">
                          {t("text_match_type")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <br />
                        <div className="form-group col-md-12 ">
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              id="free"
                              name="match_type"
                              type="radio"
                              className="custom-control-input"
                              value="free"
                              checked=""
                              onChange={handleMatch}
                            />
                            &nbsp;
                            <label
                              className="custom-control-label mt-3"
                              htmlFor="free"
                            >
                              {t("text_free")}
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              id="paid"
                              name="match_type"
                              type="radio"
                              className="custom-control-input"
                              value="paid"
                              checked=""
                              onChange={handleMatch}
                            />
                            &nbsp;
                            <label
                              className="custom-control-label mt-3"
                              htmlFor="paid"
                            >
                              {t("text_paid")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-5">
                        <label htmlFor="match_banner" className="mt-3">
                          {t("text_browse_banner")}&nbsp;
                        </label>
                        <br />
                        <input
                          id="match_banner"
                          type="file"
                          className="file-input d-block mt-3"
                          name="match_banner"
                          onChange={handleFile}
                        />
                        {/* <label htmlFor="package_name" generated="true" className={"error " + (state1.formErrors.matchBannerErr ? ' d-block' : 'd-none')}>{state.formErrors.matchBannerErr}</label> */}

                        <p>
                          <b>{t("text_note")} : </b>&nbsp;
                          {t("text_image_note_1000x500")}
                        </p>
                        <input
                          type="hidden"
                          id="file-input"
                          name="old_match_banner"
                          value=""
                          className="form-control mt-3-file"
                        />
                      </div>
                      <div className="form-group col-md-1 m-auto text-center">
                        <b>
                          <u>{t("text_or")}</u>
                        </b>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="image_id" className="mt-3">
                          {t("text_select_banner")}
                        </label>
                        <Select2 onHandle={handleData} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12 my-2">
                        <label htmlFor="prize_description my-2">
                          {t("text_prize_description")}
                        </label>
                        <CKEditor
                          data={editor2.prize_description}
                          onChange={(event, editor) => {
                            const data = event.editor.getData();
                            setEditor2({
                              prize_description: data,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12 my-2">
                        <label htmlFor="match_sponsor my-2">
                          {t("text_match_sponsor")}
                        </label>
                        <CKEditor
                          data={editor1.match_sponsor}
                          onChange={(event, editor) => {
                            const data = event.editor.getData();
                            setEditor1({
                              match_sponsor: data,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12 my-2">
                        <label htmlFor="match_desc my-2">
                          {t("text_match_description")}
                        </label>
                        <CKEditor
                          data={editor.match_desc}
                          onChange={(event, editor) => {
                            const data = event.editor.getData();
                            setEditor({
                              match_desc: data,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12 my-2">
                        <label htmlFor="match_private_desc my-2">
                          {t("text_match_private_description")}
                        </label>
                        <CKEditor
                          data={editor3.match_private_desc}
                          onChange={(event, editor) => {
                            const data = event.editor.getData();
                            setEditor3({
                              match_private_desc: data,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group text-center">
                      <input
                        type="submit"
                        value="Submit"
                        name="submit"
                        className={`btn btn-lightpink ${
                          demouser === "1" && "disabled"
                        }`}
                        onClick={demouser === "0" && handleUpdate}
                      />
                      <a
                        className="btn btn-secondary ms-1"
                        href="#"
                        name="cancel"
                      >
                        {t("text_btn_cancel")}
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
// redux connect
const mapStateToProps = (state) => ({
  res: state.GetGameName,
});

export default connect(mapStateToProps)(MatcheEdit);
