import { react, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { cashfreeSandbox, cashfreeProd } from "cashfree-dropjs";

function Cashfree() {
  const location = useLocation();
  console.log("location", location);
  const orderToken = "7RyleyCOzRftapYCmSDb";
  // const orderToken = location.state.state.message.cftoken
  console.log("token", orderToken);

  const isProd = false;
  const components = ["order-details", "card", "upi", "app", "netbanking", "paylater", "credicardemi", "cardlessemi"]

  const cbs = (data) => {
    if (data.order && data.order.status === "PAID") {
      alert("order is paid. Call api to verify");
    }
  };
  const cbf = (data) => {
    alert(data.order.errorText || "AAAA");
    console.log(data.order.errorText);
  };
  const renderDropin = () => {
    if (orderToken === "") {
      alert("Order Token is empty");
      return;
    }
    if (!components.length) {
      alert("No drop in specified");
      return;
    }
    let parent = document.getElementById("drop_in_container");
    parent.innerHTML = "";
    let cashfree;
    if (isProd) {
      cashfree = new cashfreeProd.Cashfree();
    } else {
      cashfree = new cashfreeSandbox.Cashfree();
    }
    cashfree.initialiseDropin(parent, {
      orderToken,
      onSuccess: cbs,
      onFailure: cbf,
      components,
    });
  };

  useEffect(() => {
    renderDropin()
  }, [])

  return (
    <div className="App">
      <div
        className="dropin-parent text-dark"
        id="drop_in_container"
        style={{ height: "600px" }}
      ></div>
    </div>
  );
}

export default Cashfree;
