import React, { useEffect, useState } from "react";
import UserHeader from "../../theme/userHeader";
import { NavLink, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Title from "../../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const EditImage = () => {
  const params = useParams();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  const [loading, setLoading] = useState(true);
  //store the data of api
  const [insertData, SetInsertData] = useState({});
  //handle image data
  const [image, setImage] = useState({
    image_title: "",
    image_name: "",
  });
  //handle the validation
  const [state, setState] = useState({
    formErrors: {},
  });

  //on page refresh api is call
  useEffect(async () => {
    var response = await fetch(`/api/get_insert_data/${params.id}`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    if (res_data) {
      setLoading(false);
    }
    SetInsertData(res_data.get_insert_img_data[0]);
    setImage({
      image_title: res_data.get_insert_img_data[0].image_title,
      image_name: "",
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setImage({
      ...image,
      [name]: value,
    });
  };
  const handleImage = (e) => {
    const value = e.target.files[0];
    setImage({
      ...image,
      image_name: value,
    });
  };

  //handle the validation
  function handleFormValidation() {
    const { image_title } = image;
    let formErrors = {};
    let formIsValid = true;
    if (!image_title) {
      formIsValid = true;
      formErrors["imageTitleErr"] = t("translation2:err_image_title_req");
    }
    setState({ formErrors: formErrors });
    return formIsValid;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image_title", image.image_title);
    formData.append("image_name", image.image_name);
    var response = await fetch(`/api/update_image/${params.id}`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      history.push("/admin/images", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    } else {
      handleFormValidation();
    }
  };

  return (
    <>
      <Title props={t("text_image")} />
      <UserHeader title={t("text_image")} />
      {loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card bg-light text-dark">
              <div className="card-header">
                <strong>{t("text_image")}</strong>
              </div>
              <div className="card-body">
                <div className="col-md-12">
                  <form
                    className="needs-validation"
                    id="validate"
                    method="POST"
                  >
                    <div className="row">
                      <input
                        type="hidden"
                        className="form-control"
                        name="image_id"
                        value="1"
                      />
                      <div className="form-group col-md-6">
                        <label htmlFor="image_title">
                          {t("text_image_title")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          name="image_title"
                          value={image.image_title}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="image_title"
                          generated="true"
                          className={
                            "error " +
                            (state.formErrors.imageTitleErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state.formErrors.imageTitleErr}
                        </label>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="image_name">
                          {t("text_image")}
                          <span className="required" aria-required="true">
                            &nbsp;*&nbsp;
                          </span>
                        </label>
                        <br />
                        <input
                          id="image_name"
                          type="file"
                          className="file-input d-block mt-2"
                          name="image_name"
                          onChange={handleImage}
                        />
                        <label
                          htmlFor="package_name"
                          generated="true"
                          className={
                            "error " +
                            (state.formErrors.imageNameErr
                              ? " d-block"
                              : "d-none")
                          }
                        >
                          {state.formErrors.imageNameErr}
                        </label>

                        <input
                          type="hidden"
                          id="file-input"
                          name="old_image_name"
                          value="202101191200241701009224__ff.jpg"
                          className="form-control-file"
                        />
                        <p>
                          <b>{t("text_note")} : </b>
                          {t("text_image_note_1000x500")}
                        </p>
                        <br />
                        <img src={insertData.image_name} />
                      </div>
                    </div>
                    <div className="form-group d-flex justify-content-center mt-3">
                      <input
                        type="submit"
                        value="Submit"
                        name="submit"
                        className={`btn btn-lightpink ${
                          demouser === "1" && "disabled"
                        }`}
                        onClick={demouser === "0" && handleSubmit}
                      />
                      <NavLink
                        className="btn btn-secondary ms-1 d-block"
                        to="/admin/images"
                        name="cancel"
                      >
                        {t("text_btn_cancel")}
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditImage;
