import { React, useState, useEffect } from "react";
import UserHeader from "../../theme/userHeader";
import { CKEditor } from "ckeditor4-react";
import { NavLink, useParams, useHistory } from "react-router-dom";
import Title from "../../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const EditPages = () => {
  //get the id from parameter
  const params = useParams();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  const [loading, setLoading] = useState(true);
  //store the data of api
  const [insertData, SetInsertData] = useState({});
  //handle checkbox of add menu
  const [check, setCheck] = useState(false);
  const [display, setDisplay] = useState(null);
  //handle checkbox of add footer

  const [display1, setDisplay1] = useState(false);
  //handle the validation
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [state, setState] = useState({
    page_title: "",
    page_menutitle: "",
    page_metakeyword: "",
    page_metadesc: "",
    page_baner: "",
    page_order: "",
    parent: "",
  });
  const [editor, setEditor] = useState({
    page_content: "",
  });

  // insert page
  const page = async () => {
    var response = await fetch(`/api/get_insert_page/${params.id}`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    if (res_data) {
      setLoading(false);
    }
    SetInsertData(res_data.get_page[0]);

    setState({
      page_title: res_data.get_page[0].page_title,
      page_menutitle: res_data.get_page[0].page_menutitle,
      page_metakeyword: res_data.get_page[0].page_metakeyword,
      page_metadesc: res_data.get_page[0].page_metadesc,
      page_baner: "",
      page_order: res_data.get_page[0].page_order,
      parent: res_data.get_page[0].parent,
      // addmenu: res_data.get_page[0].add_to_menu,
      // addfooter: res_data.get_page[0].add_to_footer,
    });
  };
  console.log(check);
  //on page refresh api is call
  useEffect(() => {
    page();
  }, []);
  useEffect(() => {
    setEditor((editor) => ({
      page_content: insertData.page_content,
    }));
    setCheck(insertData.add_to_footer == "1");
  }, [insertData]);
  console.log(insertData);
  const handleChange = (e) => {
    const { name } = e.target;
    if (name == "page_baner") var value = e.target.files[0];
    else value = e.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };
  console.log("asd", state);
  // form validation
  function handleFormValidation() {
    const {
      page_title,
      page_menutitle,
      page_metakeyword,
      page_metadesc,
      page_baner,
      page_order,
    } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!page_title) {
      formIsValid = true;
      formErrors["pageTitleErr"] = t("translation2:err_page_title_req");
    }
    if (!page_menutitle) {
      formIsValid = true;
      formErrors["pageMenuTitleErr"] = t("translation2:err_page_menutitle_req");
    }
    if (!page_metakeyword) {
      formIsValid = true;
      formErrors["pageMetaKeywordErr"] = t(
        "translation2:err_page_metakeyword_req"
      );
    }
    if (!page_metadesc) {
      formIsValid = true;
      formErrors["pageMetaDescErr"] = t("translation2:err_page_metadesc_req");
    }
    if (!page_baner) {
      formIsValid = true;
      formErrors["pageBanerErr"] = t("translation2:err_image_req");
    }
    if (!page_order) {
      formIsValid = true;
      formErrors["pageOrderErr"] = t("translation2:err_pageorder_req");
    }
    if (page_order && !page_order.match(/^[0-9]+$/)) {
      formIsValid = true;
      formErrors["pageLOrderErr"] = t("translation2:err_order_num_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }
  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("page_title", state.page_title);
    formData.append("page_menutitle", state.page_menutitle);
    formData.append("page_metakeyword", state.page_metakeyword);
    formData.append("page_metadesc", state.page_metadesc);
    formData.append("page_baner", state.page_baner);
    formData.append("page_order", state.page_order);
    formData.append("parent", state.parent);
    formData.append("page_content", editor.page_content);
    formData.append("addmenu", display ? "1" : "");
    formData.append("addfooter", display1 ? "1" : "");
    console.log("dis", display1);
    handleFormValidation();
    // update page
    var response = await fetch(`/api/update_page/${params.id}`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    console.log("wewe", formData);
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      history.push("/admin/pages", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };
  console.log("aaa", state);
  return (
    <>
      <Title props={t("text_page")} />
      {/* Header */}
      <UserHeader title={t("text_page")} />
      {loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="card bg-light text-dark">
              {/* card-header */}
              <div className="card-header">
                <strong>{t("text_edit_page")}</strong>
              </div>
              <div className="card-body">
                <div className="col-md-12">
                  <form
                    method="post"
                    className="needs-validation"
                    id="validate"
                  >
                    <div className="row">
                      {/* title */}
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="control-label mb-3">
                            {t("text_title")}
                            <span className="required" aria-required="true">
                              &nbsp;*&nbsp;
                            </span>
                          </label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              name="page_title"
                              value={state.page_title}
                              onChange={handleChange}
                            />
                            <label
                              htmlFor="package_name"
                              generated="true"
                              className={
                                "error " +
                                (state1.formErrors.pageTitleErr
                                  ? " d-block"
                                  : "d-none")
                              }
                            >
                              {state1.formErrors.pageTitleErr}
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* page menu title */}
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="control-label mb-3">
                            {t("text_page_menu_title")}
                            <span className="required" aria-required="true">
                              &nbsp;*&nbsp;
                            </span>
                          </label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"
                              name="page_menutitle"
                              value={state.page_menutitle}
                              onChange={handleChange}
                            />
                            <label
                              htmlFor="package_name"
                              generated="true"
                              className={
                                "error " +
                                (state1.formErrors.pageMenuTitleErr
                                  ? " d-block"
                                  : "d-none")
                              }
                            >
                              {state1.formErrors.pageMenuTitleErr}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* meta keyword */}
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="control-label mb-3">
                            {t("text_meta_keyword")}
                            <span className="required" aria-required="true">
                              &nbsp;*&nbsp;
                            </span>
                          </label>
                          <div className="">
                            <textarea
                              className="form-control"
                              name="page_metakeyword"
                              id="page_metakeyword"
                              value={state.page_metakeyword}
                              onChange={handleChange}
                            >
                              {t("text_battlemania")}
                            </textarea>
                            <label
                              htmlFor="package_name"
                              generated="true"
                              className={
                                "error " +
                                (state1.formErrors.pageMetaKeywordErr
                                  ? " d-block"
                                  : "d-none")
                              }
                            >
                              {state1.formErrors.pageMetaKeywordErr}
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* meta description */}
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="control-label mb-3">
                            {t("text_meta_description")}
                            <span className="required" aria-required="true">
                              &nbsp;*&nbsp;
                            </span>
                          </label>
                          <div className="">
                            <textarea
                              className="form-control"
                              name="page_metadesc"
                              id="page_metadesc"
                              value={state.page_metadesc}
                              onChange={handleChange}
                            >
                              {t("text_battlemania")}
                            </textarea>
                            <label
                              htmlFor="package_name"
                              generated="true"
                              className={
                                "error " +
                                (state1.formErrors.pageMetaDescErr
                                  ? " d-block"
                                  : "d-none")
                              }
                            >
                              {state1.formErrors.pageMetaDescErr}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* page banner image */}
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label htmlFor="page_baner" className="mb-3">
                            {t("text_page_banner_image")}
                          </label>
                          <br />
                          <input
                            id="page_baner"
                            type="file"
                            className="file-input d-block"
                            name="page_baner"
                            onChange={handleChange}
                          />
                          <p>
                            <b>{t("text_note")} : </b>&nbsp;
                            {t("text_image_note_1920x500")}
                          </p>
                          <img src={insertData.page_banner_image} alt="img" />
                        </div>
                      </div>
                      {/* add menu */}
                      <div className="col-md-6 m-auto">
                        <div className="form-group mb-3">
                          <input
                            type="checkbox"
                            name="addmenu"
                            defaultChecked={
                              state.addmenu === "1" ? true : false
                            }
                            onChange={(e) => setDisplay(!display)}
                          />
                          <label
                            htmlFor="addmenu"
                            className="control-label mb-3 ps-1"
                          >
                            {t("text_page_add_menu")}
                            <span
                              className="required"
                              aria-required="true"
                            ></span>
                          </label>
                        </div>
                      </div>
                      {/* add footer */}
                      <div className="col-md-6 m-auto">
                        <div className="form-group mb-3">
                          <input
                            type="checkbox"
                            id="addfooter"
                            name="addfooter"
                            defaultChecked={
                              state.addfooter === "1" ? true : false
                            }
                            onChange={(e) => setDisplay1(e.target.checked)}
                          />
                          <label
                            htmlFor="addfooter"
                            className="control-label mb-3"
                          >
                            {t("text_page_add_footer")}
                            <span
                              className="required"
                              aria-required="true"
                            ></span>
                          </label>
                        </div>
                      </div>
                      {/* page order */}
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label
                            htmlFor="page_order"
                            className="control-label mb-3"
                          >
                            {t("text_page_order")}
                            <span className="required" aria-required="true">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="page_order"
                            value={state.page_order}
                            onChange={handleChange}
                          />
                          <label
                            htmlFor="package_name"
                            generated="true"
                            className={
                              "error " +
                              (state1.formErrors.pageOrderErr
                                ? " d-block"
                                : "d-none")
                            }
                          >
                            {state1.formErrors.pageOrderErr}
                          </label>
                          <label
                            htmlFor="package_name"
                            generated="true"
                            className={
                              "error " +
                              (state1.formErrors.pageLOrderErr
                                ? " d-block"
                                : "d-none")
                            }
                          >
                            {state1.formErrors.pageLOrderErr}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className={"row " + (display ? "d-block" : "d-none")}>
                      {/* parent menu */}
                      <div className="col-md-6">
                        <div
                          className="form-group mb-3 d-block"
                          id="parent_menu"
                        >
                          <label htmlFor="parent" className="mb-3">
                            {t("text_parent_menu")}
                          </label>
                          <br />
                          <select
                            className="form-control"
                            name="parent"
                            id="parent"
                            onChange={handleChange}
                          >
                            <option value="0">{t("text_main")}</option>
                            <option value="6">
                              -- {t("text_contact_us")}&nbsp;
                            </option>
                            <option value="7">
                              -- {t("text_how_to_install")}
                            </option>
                            <option value="8">-- {t("text_home")}</option>
                            <option value="9">-- {t("text_about_us")}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* page content */}
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <label className="control-label mb-3">
                            {t("text_page_content")}
                          </label>
                          {/* ckeditor */}
                          <div className="">
                            <CKEditor
                              initData={<p>{editor.page_content}</p>}
                              onChange={(event, editor) => {
                                const data = event.editor.getData();
                                setEditor({
                                  page_content: data,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* submit */}
                    <div className="form-group mb-3 d-flex justify-content-center">
                      <input
                        type="submit"
                        value="Submit"
                        name="submit"
                        className={`btn btn-lightpink ${
                          demouser === "1" && "disabled"
                        }`}
                        onClick={demouser === "0" && handleSubmit}
                      />
                      {/* cancel */}
                      <NavLink
                        className="btn btn-secondary ms-1 d-block"
                        to="/admin/pages"
                        name="cancel"
                      >
                        {t("text_btn_cancel")}
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditPages;
