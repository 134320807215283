// Footer

import React from "react";
import { t } from "i18next";

const Footer = () => {
  return (
    <>
      <footer className="main-footer ">
        <div className="footer-left">
          <p className="text-dark">{t("text_copyright_text")}</p>
        </div>
        <div className="footer-right"></div>
      </footer>
    </>
  );
};

export default Footer;
