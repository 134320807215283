import { React, useState, useEffect } from "react";
import UserHeader from "../../../theme/userHeader";
import { NavLink, useParams, useHistory } from "react-router-dom";
import Title from "../../../../../views/common/Helmet";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const EditFeaturesTab = () => {
  //get the id from parameter
  const params = useParams();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  const [loading, setLoading] = useState(true);
  //store the data of api
  const [insertData, SetInsertData] = useState({});
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const [state, setState] = useState({
    f_tab_name: "",
    f_tab_title: "",
    f_tab_img_position: "",
    f_tab_order: "",
    f_tab_image: "",
    f_tab_text: "",
  });

  //get feature data
  const page = async () => {
    var response = await fetch(`/api/get_insert_feature/${params.id}`, {
      headers: {
        Authorization: user.api_token,
      },
    });
    var res_data = await response.json();
    if (res_data) {
      setLoading(false);
    }
    SetInsertData(res_data.get_feature[0]);
    setState({
      f_tab_name: res_data.get_feature[0].f_tab_name,
      f_tab_title: res_data.get_feature[0].f_tab_title,
      f_tab_img_position: res_data.get_feature[0].f_tab_img_position,
      f_tab_order: res_data.get_feature[0].f_tab_order,
      f_tab_image: "",
      f_tab_text: res_data.get_feature[0].f_tab_text,
    });
  };

  //on page refresh api is call
  useEffect(() => {
    page();
  }, []);
  const handleChange = (e) => {
    const { name } = e.target;
    if (name == "f_tab_image") var value = e.target.files[0];
    else value = e.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  // handle the validation
  function handleFormValidation() {
    const {
      f_tab_name,
      f_tab_title,
      f_tab_img_position,
      f_tab_order,
      f_tab_image,
      f_tab_text,
    } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!f_tab_name) {
      formIsValid = true;
      formErrors["fTabNameErr"] = t("translation2:err_f_tab_name_req");
    }
    if (!f_tab_title) {
      formIsValid = true;
      formErrors["fTabTitleErr"] = t("translation2:err_features_title_req");
    }
    if (!f_tab_img_position) {
      formIsValid = true;
      formErrors["fTabImgPosErr"] = t(
        "translation2:err_f_tab_img_position_req"
      );
    }
    if (!f_tab_order) {
      formIsValid = true;
      formErrors["fTabOrderErr"] = t("translation2:err_dp_order_req");
    }
    if (!f_tab_image) {
      formIsValid = true;
      formErrors["fTabImageErr"] = t("translation2:err_image_req");
    }
    if (!f_tab_text) {
      formIsValid = true;
      formErrors["fTabTextErr"] = t("translation2:err_features_text_req");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("f_tab_name", state.f_tab_name);
    formData.append("f_tab_title", state.f_tab_title);
    formData.append("f_tab_img_position", state.f_tab_img_position);
    formData.append("f_tab_order", state.f_tab_order);
    formData.append("f_tab_img", state.f_tab_image);
    formData.append("f_tab_text", state.f_tab_text);
    handleFormValidation();
    // update feature
    var response = await fetch(`/api/update_feature/${params.id}`, {
      method: "POST",
      headers: {
        Authorization: user.api_token,
      },
      body: formData,
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      history.push("/admin/features_tab", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_features_tab")} />
      {/* Header */}
      <UserHeader
        title={t("text_features_tab")}
        path={"/admin/features_tab"}
        icon={<i className="fa fa-eye"></i>}
        btnText={t("text_view_features")}
      />
      {loading ? (
        <div className="loader my-auto">
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            {/* card-header */}
            <div className="card bg-light text-dark">
              <div className="card-header">
                <strong>{t("text_features_tab")}</strong>
              </div>
              <div className="card-body">
                <form method="POST" id="validate">
                  <div className="row">
                    {/* feature tab name */}
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="f_tab_name">
                        {t("text_feature_tab_name")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      <input
                        id="f_tab_name"
                        type="text"
                        className="form-control"
                        name="f_tab_name"
                        value={state.f_tab_name}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.fTabNameErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.fTabNameErr}
                      </label>
                    </div>
                    {/* feature tab title */}
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="f_tab_title">
                        {t("text_feature_tab_title")}
                      </label>
                      <br />
                      <input
                        id="f_tab_title"
                        type="text"
                        className="form-control"
                        name="f_tab_title"
                        value={state.f_tab_title}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.fTabTitleErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.fTabTitleErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    {/* feature tab text */}
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="f_tab_text">
                        {t("text_feature_tab_text")}
                      </label>
                      <br />
                      <textarea
                        id="f_tab_text"
                        type="text"
                        className="form-control"
                        name="f_tab_text"
                        value={state.f_tab_text}
                        onChange={handleChange}
                      ></textarea>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.fTabTextErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.fTabTextErr}
                      </label>
                    </div>
                    {/* feature tab position */}
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="f_tab_img_position">
                        {t("text_feature_tab_image_position")}

                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      {/* select option for tab image position */}
                      <select
                        id="f_tab_img_position"
                        name="f_tab_img_position"
                        className="form-control"
                        onChange={handleChange}
                      >
                        <option value=""> Select.. </option>
                        <option value="left">{t("text_left")}</option>
                        <option value="center">{t("text_center")}</option>
                        <option value="right">{t("text_right")}</option>
                      </select>
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.fTabImgPosErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.fTabImgPosErr}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    {/* feature tab image */}
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="f_tab_img">
                        {t("text_feature_tab_image")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      <input
                        id="f_tab_img"
                        type="file"
                        className="file-input d-block"
                        name="f_tab_image"
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.fTabImageErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.fTabImageErr}
                      </label>
                      <p>{t("text_feature_tab_image")}</p>
                      <img src={insertData.f_tab_image} alt="fea" />
                    </div>
                    {/* display order of tab image */}
                    <div className="form-group mb-3 col-md-6">
                      <label className="mb-3" htmlFor="f_tab_order">
                        {t("text_display_order")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <br />
                      <input
                        id="f_tab_order"
                        type="text"
                        className="form-control"
                        name="f_tab_order"
                        value={state.f_tab_order}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="package_name"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.fTabOrderErr
                            ? " d-block"
                            : "d-none")
                        }
                      >
                        {state1.formErrors.fTabOrderErr}
                      </label>
                    </div>
                  </div>
                  {/* submit */}
                  <div className="form-group mb-3 d-flex justify-content-center">
                    <input
                      type="submit"
                      value="Submit"
                      name="submit"
                      className={`btn btn-lightpink ${
                        demouser === "1" && "disabled"
                      }`}
                      onClick={demouser === "0" && handleSubmit}
                    />
                    {/* cancel */}
                    <NavLink
                      className="btn btn-secondary ms-1 d-block "
                      to="/admin/features_tab"
                      name="cancel"
                    >
                      {t("text_btn_cancel")}
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditFeaturesTab;
