import { React, useState } from "react";
import UserHeader from "../../../theme/userHeader";
import { useHistory, NavLink } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Title from "../../../../../views/common/Helmet";
import { t } from "i18next";

const AddFeatures = () => {
  //get the id from parameter
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const demouser = JSON.parse(sessionStorage.getItem("demouser"));

  //store the data of api
  const [state1, setState1] = useState({
    formErrors: {},
  });
  //member data stored
  const [features, setFeatures] = useState({
    ftc_id: "",
    features_tab_id: "",
    content_title: "",
    content_text: "",
    content_icon: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFeatures({
      ...features,
      [name]: value,
    });
  };

  // form validation
  function handleFormValidation() {
    const {
      ftc_id,
      features_tab_id,
      content_title,
      content_text,
      content_icon,
    } = features;
    let formErrors = {};
    let formIsValid = true;
    if (!ftc_id) {
      formIsValid = true;
      formErrors["ftcIdErr"] = t("translation2:err_features_tab_id_req");
    }
    if (!features_tab_id) {
      formIsValid = true;
      formErrors["fTabIdErr"] = t("translation2:err_features_tab_id_req");
    }
    if (!content_title) {
      formIsValid = true;
      formErrors["contentTitleErr"] = t("translation2:err_content_title_req");
    }
    if (!content_text) {
      formIsValid = true;
      formErrors["contentTextErr"] = t("translation2:err_content_text_req");
    }
    if (!content_icon) {
      formIsValid = true;
      formErrors["contentIconErr"] = t("translation2:err_content_icon_req");
    }
    if (content_icon && content_icon.match(/^[0-9]+$/)) {
      formIsValid = true;
      formErrors["contentNIconErr"] = t(
        "translation2:err_content_icon_only_text"
      );
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  //onclick of submit button then call function
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    // insert tab content
    var response = await fetch(`/api/insert_tab_content`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.api_token,
      },
      body: JSON.stringify(features),
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data.status === true) {
      history.push("/admin/features", {
        state: {
          status: true,
          data: res_data.message,
        },
      });
    }
  };

  return (
    <>
      <Title props={t("text_features")} />
      {/* Header */}
      <UserHeader title={t("text_features")} />
      <div className="row">
        <div className="col-md-12">
          <div className="card bg-light text-dark">
            {/* card-header */}
            <div className="card-header">
              <strong>{t("text_features")}</strong>
            </div>
            <div className="card-body">
              <form method="POST" id="validate">
                <div className="row">
                  {/* content title */}
                  <div className="form-group mb-3 col-md-6">
                    <input
                      id="content_title"
                      type="hidden"
                      className="form-control"
                      value={features.ftc_id}
                      onChange={handleInput}
                    />
                    <label htmlFor="features_tab_id" className="mb-3">
                      {t("text_feature_tab_name")}&nbsp;
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <br />
                    {/* select option for features tab id */}
                    <select
                      className="form-control"
                      name="features_tab_id"
                      id="features_tab_id"
                      value={features.features_tab_id}
                      onChange={handleInput}
                    >
                      <option value="">Select..</option>
                      <option value="1">{t("text_join_contest")}</option>
                      <option value="2">{t("text_participates")}</option>
                      <option value="3">{t("text_content_result")}</option>
                    </select>
                    <label
                      htmlFor="features_tab_id"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.fTabIdErr ? " d-block" : "d-none")
                      }
                    >
                      {state1.formErrors.fTabIdErr}
                    </label>
                  </div>
                  {/* content title */}
                  <div className="form-group mb-3 col-md-6">
                    <label htmlFor="content_title" className="mb-3">
                      {t("text_content_title")}&nbsp;
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <br />
                    <input
                      id="content_title"
                      type="text"
                      className="form-control"
                      name="content_title"
                      value={features.content_title}
                      onChange={handleInput}
                    />
                    <label
                      htmlFor="content_title"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.contentTitleErr
                          ? " d-block"
                          : "d-none")
                      }
                    >
                      {state1.formErrors.contentTitleErr}
                    </label>
                  </div>
                </div>
                {/* content text */}
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label htmlFor="content_text" className="mb-3">
                      {t("text_content_text")}&nbsp;
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                    </label>
                    <br />
                    <textarea
                      id="content_text"
                      type="text"
                      className="form-control"
                      name="content_text"
                      value={features.content_text}
                      onChange={handleInput}
                    ></textarea>
                    <label
                      htmlFor="content_text"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.contentTextErr
                          ? " d-block"
                          : "d-none")
                      }
                    >
                      {state1.formErrors.contentTextErr}
                    </label>
                  </div>
                  {/* content icon */}
                  <div className="form-group mb-3 col-md-6">
                    <label htmlFor="content_icon" className="mb-3">
                      {t("text_content_icon")}&nbsp;
                      <span className="required" aria-required="true">
                        &nbsp;*&nbsp;
                      </span>
                      {ReactHtmlParser(t("text_note_tab"))}
                    </label>
                    <br />
                    <input
                      id="content_icon"
                      type="text"
                      className="form-control"
                      name="content_icon"
                      value={features.content_icon}
                      onChange={handleInput}
                    />
                    <label
                      htmlFor="content_icon"
                      generated="true"
                      className={
                        "error " +
                        (state1.formErrors.contentIconErr
                          ? " d-block"
                          : "d-none")
                      }
                    >
                      {state1.formErrors.contentIconErr}
                    </label>
                  </div>
                </div>
                {/* submit */}
                <div className="form-group d-flex justify-content-center mb-3">
                  <input
                    type="submit"
                    value="Submit"
                    name="submit"
                    className={`btn btn-lightpink ${
                      demouser === "1" && "disabled"
                    }`}
                    onClick={demouser === "0" && handleSubmit}
                  />
                  {/* cancel */}
                  <NavLink
                    className="btn btn-secondary ms-1 d-block"
                    to="/admin/features"
                    name="cancel"
                  >
                    {t("text_btn_cancel")}
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFeatures;
