import React from "react";

const PayU = () => {
  return (
    <a href="https://pmny.in/NrjfZbHuCZ2f" className="text-dark">
      PayU
    </a>
  );
};

export default PayU;
// mid="8657555"
