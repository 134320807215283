import { React, useEffect } from "react";
import { Dashboard } from "../../../store/Action/UserFatchData";
import MyWalletTable from "./my_wallet_details/MyWalletTable";
import coin from "../../assets/images/coin.png";
import Spinner from "react-bootstrap/Spinner";
import MainTheme from "../theme/MainTheme";
import { NavLink } from "react-router-dom";
import Title from "../../common/Helmet";
import { connect } from "react-redux";
import { t } from "i18next";

const MyWallet = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Dashboard());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_my_wallet")} />
      <MainTheme title={t("text_my_wallet")}>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="row d-flex">
              <div className="col-md-6 mb-3">
                <div className="card text-dark border-0 rounded box-shadow">
                  <div className="card-header bg-lightgreen text-white text-center border-0 text-capitalize">
                    <strong>{t("text_total_balance")}</strong>
                  </div>
                  <div className="card-body border-0 bg-lightgreenlight rounded-bottom">
                    <div className="row">
                      <div className="col-6 text-sm-center">
                        {/* total balance */}
                        <h5>
                          <img src={coin} alt="coin-img" className="coin" />
                          &nbsp;
                          {Number(
                            parseInt(data.member.join_money) +
                              parseInt(data.member.wallet_balance)
                          ).toFixed(2)}
                        </h5>
                        {/* wallet balance */}
                        <div>
                          {t("text_win_money")}:&nbsp;
                          <img src={coin} alt="coin-img" className="coin" />
                          {Number(data.member.wallet_balance).toFixed(2)}
                        </div>
                        {/* join money */}
                        <div>
                          {t("text_join_money")} :&nbsp;
                          <img src={coin} alt="coin-img" className="coin" />
                          &nbsp;
                          {Number(data.member.join_money).toFixed(2)}
                        </div>
                      </div>
                      <div className="col-6 text-end">
                        {/* Add Money */}
                        <div>
                          <NavLink
                            to="/account/my_wallet/add"
                            className="btn btn-lightpink mb-1 px-4"
                          >
                            {t("text_action_add")}
                          </NavLink>
                        </div>
                        {/* Withdraw Money */}
                        <div>
                          <NavLink
                            to="/account/my_wallet/withdraw"
                            className="btn btn-lightpink px-2"
                          >
                            {t("text_withdraw")}
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* earnings */}
              <div className="col-md-3 col-6 mb-3">
                <div
                  className="card text-dark border-0 rounded box-shadow text-center"
                  style={{ height: "100%" }}
                >
                  <div className="card-header bg-lightgreen text-white border-0">
                    <strong>{t("text_earnings")}</strong>
                  </div>
                  <div className="card-body border-0 bg-lightgreenlight rounded-bottom align-middle">
                    <img src={coin} alt="coin-img" className="coin" />
                    &nbsp;
                    {Number(data.tot_win.total_win).toFixed(2)}
                  </div>
                </div>
              </div>
              {/* payouts */}
              <div className="col-md-3 col-6 mb-3">
                <div
                  className="card text-dark border-0 rounded box-shadow m-auto text-center"
                  style={{ height: "100%" }}
                >
                  <div className="card-header bg-lightgreen text-white border-0">
                    <strong>{t("text_payouts")}</strong>
                  </div>
                  <div className="card-body border-0 bg-lightgreenlight rounded-bottom align-middle">
                    <img src={coin} alt="coin-img" className="coin" />
                    &nbsp;
                    {Number(data.tot_withdraw.tot_withdraw).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
            {/* wallet datatable */}
            <div className="col-md-12">
              <div className="card bg-light text-dark">
                <div className="card-header">
                  <strong>{t("text_wallet_history")}</strong>
                </div>
                <div className="card-body table-responsive">
                  <MyWalletTable />
                </div>
              </div>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.dashboard,
});

export default connect(mapStateToProps)(MyWallet);
