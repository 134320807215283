import { React, useEffect } from "react";
import Title from "../../common/Helmet";
import MainTheme from "../theme/MainTheme";
import { connect } from "react-redux";
import { PageData } from "../../../store/Action/FatchData";
import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const AboutUs = ({ dispatch, res }) => {
  const location = useLocation();

  useEffect(() => {
    dispatch(PageData());
  }, [dispatch]);
  var data = res.data.page;

  return (
    <>
      <Title props={t("text_about_us")} />
      <MainTheme title={t("text_about_us")}>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="text-dark">
              {data.map((data) => {
                return (
                  "/account/" + data.page_slug == location.pathname &&
                  ReactHtmlParser(data.page_content)
                );
              })}
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.PageData,
});

export default connect(mapStateToProps)(AboutUs);
