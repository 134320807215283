import React from "react";

const JoinedMember = ({ data }) => {
  return (
    <>
      {data.length ? (
        data.map((data, index) => (
          <div className="bm-content-listing text-black" key={index}>
            <ul className="list-unstyled member-list">
              <li>
                &nbsp;
                {index + 1}. {data.user_name}
              </li>
            </ul>
          </div>
        ))
      ) : (
        // if there is no joined member for lottery
        <div class="bm-content-listing tournaments">
          <div class="col-md-12 text-center text-dark">
            <strong>No Joined Member</strong>
          </div>
        </div>
      )}
    </>
  );
};

export default JoinedMember;
