import { React, useEffect } from "react";
import { AppTutorialData } from "../../../store/Action/UserFatchData";
import Title from "../../../views/common/Helmet";
import Scrollbars from "react-custom-scrollbars";
import Spinner from "react-bootstrap/Spinner";
import MainTheme from "../theme/MainTheme";
import { connect } from "react-redux";
import { t } from "i18next";

const App_Tutorial = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(AppTutorialData());
  }, [dispatch]);
  var data = res.data.youtube_links;

  return (
    <>
      <Title props={t("text_app_tutorial")} />
      <MainTheme title={t("text_app_tutorial")} backPath>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <Scrollbars>
            {data &&
              data.map((data, index) => {
                return (
                  <div
                    className="bm-content-listing text-black mb-4"
                    key={index}
                  >
                    {/* youtube link title */}
                    <h6>{data.youtube_link_title}</h6>
                    {/* iframe for youtube videos */}
                    <iframe
                      width="475"
                      height="315"
                      src={data.youtube_link.replace("watch?v=", "embed/")}
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen=""
                    ></iframe>
                    {/* redirect to youtube link button */}
                    <a
                      target="_blank"
                      href={data.youtube_link}
                      className="btn btn-block btn-green mt-2 text-uppercase"
                    >
                      {t("text_play_in_youtube")}
                    </a>
                  </div>
                );
              })}
          </Scrollbars>
        )}
      </MainTheme>
    </>
  );
};

//redux connect
const mapStateToProps = (state) => ({
  res: state.AppTutorialData,
});

export default connect(mapStateToProps)(App_Tutorial);
