import { React, useEffect } from "react";
import { Referral_data } from "../../../store/Action/UserFatchData";
import coin from "../../assets/images/coin.png";
import Spinner from "react-bootstrap/Spinner";
import MainTheme from "../theme/MainTheme";
import Title from "../../common/Helmet";
import { connect } from "react-redux";
import { t } from "i18next";

const MyReferral = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Referral_data());
  }, [dispatch]);
  var data = res.data;

  return (
    <>
      <Title props={t("text_my_referrals")} />
      <MainTheme title={t("text_my_referrals")}>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-12 mb-4">
                <div
                  className="card text-dark border-0 rounded"
                  style={{ height: "100%" }}
                >
                  {/* Referral summary */}
                  <div className="card-header bg-lightgreen text-white border-0 text-center">
                    <strong> {t("text_my_referral_summary")}</strong>
                  </div>
                  <div className="card-body box-shadow border-0">
                    <div className="row">
                      {/* referrals */}
                      <div className="col-md-6 text-center">
                        {t("text_referrals")} <br />
                        {data.tot_referrals.total_ref}&nbsp;
                      </div>
                      {/* earnings */}
                      <div className="col-md-6 text-center">
                        {t("text_earnings")} <br />
                        <img src={coin} alt="coin-img" className="coin" />
                        {/* fix the number */}
                        {Number(data.tot_earnings.total_earning).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                {/* Table of referrals */}
                <table className="table tr-bordered bg-white box-shadow rounded-bottom">
                  <caption
                    className="bg-lightgreen text-white text-center rounded-top"
                    style={{ captionSide: "top" }}
                  >
                    {t("text_winner")}
                  </caption>
                  <thead>
                    <tr className="bg-black text-white">
                      <th>{t("text_date")}</th>
                      <th>{t("text_player_name")}</th>
                      <th>{t("text_status")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {data.length ? (
                        <>
                          <td>{data.date}</td>
                          <td>{data.user_name}</td>
                          <td>{data.status}</td>
                        </>
                      ) : (
                        // If there is no Any Referral
                        <td colSpan="3" className="text-center">
                          {t("text_no_referral_found")}
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

// redux connect
const mapStateToProps = (state) => ({
  res: state.referral,
});

export default connect(mapStateToProps)(MyReferral);
