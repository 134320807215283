import React, { useState } from "react";
import UserHeader from "../theme/userHeader";
import { useParams, useHistory } from "react-router-dom";
import Title from "../../../views/common/Helmet";
import { t } from "i18next";

const ViewMember = () => {
  const $ = window.$;
  const params = useParams();
  const history = useHistory();
  const user = JSON.parse(sessionStorage.getItem("user"));

  // manage state
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    status: false,
    msg: "",
  });
  const [state, setState] = useState("");

  // submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(state);
    // update lottery member
    var response = await fetch(`/api/update_lottery_member/${params.id}`, {
      headers: {
        Authorization: user.api_token,
      },
      method: "POST",
    });
    var res_data = await response.json();
    console.log(res_data);
    if (res_data) {
      setShow(true);
      setMessage({
        status: res_data.status,
        msg: res_data.message,
      });
    }
  };

  // user
  const handleUser = (id) => {
    history.push(`/admin/all_users/user_details/${id}`);
  };

  // table lotterymember
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var table = $("#lotterymember");
      var table1 = table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: `/api/view_lottery_member/${params.id}`,
          headers: {
            Authorization: user.api_token,
          },
          type: "POST",
          dataSrc: "lottery_member",
        },
        columns: [
          {
            data: "status",
            render: function (data, type, row) {
              if (data == "1") {
                return (
                  `<input type="radio" name="member_id" value=` +
                  data +
                  ` checked>`
                );
              } else {
                return (
                  `<input type="radio" name="member_id" value=` + data + `>`
                );
              }
            },
          },
          {
            data: null,
            sortable: false,
            render: function (data, type, row, meta) {
              return meta.row + meta.settings._iDisplayStart + 1;
            },
          },
          {
            data: "user_name",
            render: function (data, type, row) {
              return "<a class='text-lightpink' id='user'>" + data + "</a>";
            },
          },
          {
            data: "status",
            render: function (data, type, row) {
              if (data == 1) {
                return "Winner";
              } else {
                return "-";
              }
            },
          },
          { data: "date_created" },
        ],
        Destroy: true,
      });
      // user
      $("#lotterymember tbody").on("click", "#user", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row).data().member_id;
        handleUser(id);
      });
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    // error throw
    $.fn.dataTableExt.sErrMode = "throw";
    TableDatatablesManaged.init();
  });

  return (
    <>
      <Title props={t("text_lottery")} />
      {/* Header */}
      <UserHeader title={t("text_lottery_member_list")} />
      {/* message */}
      <div
        className={
          (message.status ? "alert  alert-success " : "alert alert-danger ") +
          "alert-dismissible fade show " +
          (show ? "d-block" : "d-none")
        }
        role="alert"
      >
        {message ? <span>{message.msg}</span> : <span></span>}

        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
      {/* datatable */}
      <div className="row">
        <div className="col-md-12">
          <table
            id="lotterymember"
            className="display table table-bordered mb-2"
          >
            <thead className="mt-5">
              <tr>
                <th>{t("text_winner")}</th>
                <th>{t("text_sr_no")}</th>
                <th>{t("text_user_name")}</th>
                <th>{t("text_status")}</th>
                <th>{t("text_date")}</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>{t("text_winner")}</th>
                <th>{t("text_sr_no")}</th>
                <th>{t("text_user_name")}</th>
                <th>{t("text_status")}</th>
                <th>{t("text_date")}</th>
              </tr>
            </tfoot>
          </table>
          {/* update button */}
          <div className="mt-2 text-center">
            <button className="btn btn-lightpink" onClick={handleSubmit}>
              {t("text_btn_update")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewMember;
