import { React, useEffect } from "react";
import Title from "../../../views/common/Helmet";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Product_data } from "../../../store/Action/UserFatchData";
import MainTheme from "../theme/MainTheme";
import coin from "../../../views/assets/images/coin.png";
import Spinner from "react-bootstrap/Spinner";
import { t } from "i18next";

const Buyproduct = ({ dispatch, res }) => {
  useEffect(() => {
    dispatch(Product_data());
  }, [dispatch]);
  var data = res.data;
  console.log("data", data);

  return (
    <>
      <Title props={t("text_product")} />
      <MainTheme title={t("text_shop_now")} backPath>
        {res.loading ? (
          <div className="loader my-auto">
            <Spinner />
          </div>
        ) : (
          <>
            {/* All products */}
            <div className="bm-mdl-center bm-full-height p-1">
              {data.product.map((data, index) => (
                <div className="product-card" key={index}>
                  <Link to={`/Buy Product/productdetail/${data.product_id}`}>
                    <img
                      src={data.product_image}
                      className="img-fluid card-img-top1"
                      alt="img"
                    />
                  </Link>
                  {/* product name and prices */}
                  <div className="card-body p-2">
                    <h6 className=" card-title mb-0 text-black overflow">
                      {data.product_name}
                    </h6>
                    <div className="row">
                      <div className="col-6 text-info">
                        <strike>
                          <span>
                            <img src={coin} className="coin" alt="img" />
                          </span>
                          {/* fix the price number with .00 */}
                          {Number(data.product_actual_price).toFixed(2)}
                        </strike>
                      </div>
                      <div
                        className="col-6 pull-right text-info"
                        style={{ textAlign: "right" }}
                      >
                        <span>
                          <img src={coin} className="coin" alt="img" />
                        </span>
                        {/* fix the price number with .00 */}
                        {Number(data.product_selling_price).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </MainTheme>
    </>
  );
};

const mapStateToProps = (state) => ({
  res: state.product,
});

export default connect(mapStateToProps)(Buyproduct);
