import { React, useState } from "react";
import Header from "../theme/header";
import Footer from "../theme/footer";
import Title from "../../common/Helmet";
import { useLocation, useHistory } from "react-router-dom";
import { t } from "i18next";

const ForgotPassword = () => {
  const location = useLocation();
  const history = useHistory();
  const member_id = location.state.state.member_id;

  const [state, setState] = useState({
    member_id: member_id,
    password: "",
    cpassword: "",
    submit: "forgotpass",
  });
  const [state1, setState1] = useState({
    formErrors: {},
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };
  // form validation
  function handleFormValidation() {
    const { password, cpassword } = state;
    let formErrors = {};
    let formIsValid = true;
    if (!password) {
      formIsValid = true;
      formErrors["password"] = t("translation2:err_password_req");
    }
    if (password && password.length < 6) {
      formIsValid = true;
      formErrors["password"] = t("translation2:err_password_min");
    }
    if (!cpassword) {
      formIsValid = true;
      formErrors["cpassword"] = t("translation2:err_password_req");
    }
    if (cpassword && cpassword.length < 6) {
      formIsValid = true;
      formErrors["cpassword"] = t("translation2:err_password_min");
    }
    if (
      password &&
      cpassword &&
      cpassword.length >= 6 &&
      password.length >= 6 &&
      password != cpassword
    ) {
      formIsValid = true;
      formErrors["cpassword"] = t("translation2:err_c_passowrd_equal");
    }
    setState1({ formErrors: formErrors });
    return formIsValid;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleFormValidation();
    console.log(state);
    var response = await fetch(`/api/forgotpassword`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(state),
    });
    var res_data = await response.json();
    if (res_data.status === true) {
      history.push("/login");
    } else {
      alert(res_data);
    }
  };

  return (
    <>
      <Title props={t("text_login")} />
      <Header />
      <div className="page-header d-flex">
        <div className="black-overlay"></div>
        <div className="container m-auto">
          <div className="row align-items-center">
            <div className="col-md-12 text-center">
              <h1 className=" text-dark">{t("text_otp")}</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="bm-light-bg text-dark">
        <div className="container">
          <div className="row">
            <div className="offset-md-3 col-md-6 col_right">
              <div className="card cnt-card my-5">
                <div className="card-body">
                  <form id="login-form">
                    {/* new password */}
                    <div className="form-group mb-3">
                      <label className="text-dark">
                        {t("text_new_password")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        name="password"
                        type="text"
                        className="form-control"
                        value={state.password}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="password"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.password ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.password}
                      </label>
                    </div>
                    {/* confirm password */}
                    <div className="form-group mb-3">
                      <label className="text-dark">
                        {t("text_confirm_password")}
                        <span className="required" aria-required="true">
                          &nbsp;*&nbsp;
                        </span>
                      </label>
                      <input
                        name="cpassword"
                        type="text"
                        className="form-control"
                        value={state.cpassword}
                        onChange={handleInput}
                      />
                      <label
                        htmlFor="cpassword"
                        generated="true"
                        className={
                          "error " +
                          (state1.formErrors.cpassword ? " d-block" : "d-none")
                        }
                      >
                        {state1.formErrors.cpassword}
                      </label>
                    </div>
                    {/* submit */}
                    <div className="form-group mb-3">
                      <input
                        className="btn btn-submit btn-block btn-lg btn-lightpink mb-2 form-control"
                        type="submit"
                        onClick={handleSubmit}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ForgotPassword;
